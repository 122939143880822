import { Button, Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomStyle } from '../components/CustomStyle'
import CustomViewPage from '../components/CustomViewPage'
import axios from 'axios'
import { BASE_URL, EXTERNAL_USER } from '../utills/ApplicationRouting'
import { useSelector } from 'react-redux';
import CustomLoading from '../components/CustomLoading'
import CustomViewWithToolTip from "../components/CustomViewWithTooltip"
import CustomViewHeading from "../components/CustomViewHeading"
function ViewExternal() {
  const navigate = useNavigate()
  const { token } = useSelector((state) => state.hybridhoa);

  const handleClick = () => {
    navigate(-1)
  }
  const { homeOwnerLoginId } = useParams();
  //   const handleEditClick =() =>{
  //     navigate(`/edit/externaluser/${homeOwnerLoginId}`)
  // }
  const [viewDetails, setViewDetails] = useState('')
  const [exterNalDetails, setExternalDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const [occupation, setOccupation] = useState("");
  const getViewDetailsId = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BASE_URL}${EXTERNAL_USER}/${homeOwnerLoginId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      console.log(response.data.renter);
      if (response.status === 200) {
        const data = response.data.userDetails;
        const datas = response.data.loginData;
        const occupationData = response.data.occupationDetails
        console.log(occupationData);
        setViewDetails(data);
        setExternalDetails(datas)
        setOccupation(occupationData);
        // setFileUrl(data.fileUrl);
        setIsLoading(false);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        };
        const formattedDate = new Date(data.createdDateAndTime).toLocaleString(
          "en-us",
          options
        );
        setCreatedDateAndTime(formattedDate);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getViewDetailsId();
  }, [homeOwnerLoginId]);


  // const data = {
  //   pageTitle: "View External User",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick
  //     },

  //   ],


  //   content : [
  //     {
  //       topic :'First Name ',
  //       description :viewDetails.firstName === "null" ? "" : viewDetails.firstName
  //     },
  //     {
  //       topic :'Last Name',
  //       description :viewDetails.lastName === "null" ? "" : viewDetails.lastName
  //     },
  //     {
  //       topic :'Contact',
  //       description :viewDetails.contact === "null" ? "" : viewDetails.contact
  //     },
  //     {
  //       topic: "Roles",
  //       description: Array.isArray(exterNalDetails.roles)
  //         ? exterNalDetails.roles.map(role => {
  //             switch(role) {
  //               case "ROLE_GU":
  //                 return "General User";
  //               case "ROLE_MC":
  //                 return "Management Company";
  //               case "ROLE_BM":
  //                 return "Board Member";
  //                 case "ROLE_ARC":
  //                   return "ARC";
  //               default:
  //                 return role;
  //             }
  //           }).join(", ")
  //         : exterNalDetails.roles,
  //     },
  //     {
  //       topic :'Email',
  //       description :viewDetails.email === "null" ? "" : viewDetails.email
  //     },
  //     {
  //       topic :'City',
  //       description :viewDetails.city === "null" ? "" : viewDetails.city
  //     },
  //     {
  //       topic :'Address ',
  //       description :viewDetails.address
  //     },
  //     {
  //       topic :'Job',
  //       description :occupation === null ? "" :occupation.occupation
  //     },
  //      ]
  // }
  // topic :'First Name ',
  // description :viewDetails.firstName === "null" ? "" : viewDetails.firstName
  const roles = Array.isArray(exterNalDetails.roles)
  ? exterNalDetails.roles.map(role => {
      switch (role) {
        case "ROLE_GU":
          return "General User";
        case "ROLE_MC":
          return "Management Company";
        case "ROLE_BM":
          return "Board Member";
        case "ROLE_ARC":
          return "ARC";
        default:
          return role;
      }
    }).join(", ")
  : exterNalDetails.roles;

const data = [
  {
    groupName: 'External User Details',
    items: [
      {
        label: 'First Name',
        data: `${viewDetails.firstName === "null" ? "" : viewDetails.firstName}`,
        text:'First name of the External user'
      },
      {
        label: 'Last Name',
        data: `${viewDetails.lastName === "null" ? "" : viewDetails.lastName}`,
        text: 'Last name of the External user'
      },
    ],
  },
  {
    groupName: 'Contact Details',
    items: [
      {
        label: 'Email',
        data: `${viewDetails.email === "null" ? "" : viewDetails.email}`,
        text: 'Email of the External user for contact purposes'
      },
      {
        label: 'Cell',
        data: `${viewDetails.contact === null ? "" : viewDetails.contact}`,
        text: 'Personal phone number of the External user for contact purposes'
      },
      {
        label: 'Home',
        data: `${viewDetails.home === null ? "" : viewDetails.home}`,
        text:  'Home contact number of the External user for contact purposes'
      },
      {
        label: 'Work',
        data: `${viewDetails.work === null ? "" : viewDetails.work}`,
        text:'Work contact number of the External user for contact purposes'
      },
      {
        label: 'Fax',
        data: `${viewDetails.fax === null ? "" : viewDetails.fax}`,
        text: 'Fax number of the External user for contact purposes'
      },
    ],
  },
  {
    groupName: 'Role',
    items: [
      {
        label: 'Role',
        data: roles,
        text: 'Role of the external user'
      },
    ],
  },
];

if (exterNalDetails.roles && exterNalDetails.roles.includes('ROLE_GU')) {
  data.push({
    groupName: 'Job-Category',
    items: [
      {
        label: 'job',
        data: `${occupation === null ? "" : occupation.occupation}`,
        text: 'Job of the external user'
      },
    ],
  });
}


  return (
    <div>
      {isLoading && <CustomLoading />
      }
       <CustomViewHeading onClick={handleClick}  title={"View External User"}  data={data}>
      <CustomViewWithToolTip data={data} onClick={handleClick} />
      </CustomViewHeading>
    </div >
  )
}

export default ViewExternal