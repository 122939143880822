import React from "react";
import { CustomStyle } from "./CustomStyle";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

function CustomBackButtonWithoutDisable(props) {

    return (
        <Button
            variant="contained"
            type={props.fieldType}
            onClick={props.click}
            disabled={props.disabled}

            title={props.title}
            autoFocus

        >
            <span className={CustomStyle.containedButtonStyle}> {props.buttonName} </span>
        </Button>
    );
}

export default CustomBackButtonWithoutDisable
