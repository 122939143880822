import {
  Close,
  Delete,
  DeleteForever,
  DeleteOutline,
  EditNoteOutlined,
  EditOutlined,
  EmailOutlined,
  FileDownload,
  FileUploadOutlined,
  ListAlt,
  Telegram,
  InfoOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,Card,CardContent
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import CustomInputField from "../components/CustomInputField";
import {
  ADDRESS_INVALID_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  JOB_CATEGORY_INVAILD_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE_FIFTY,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  EMAIL_REGEX_PATTERN,
  ONLY_ALPHABETIC,EMAIL_REGEX_PATTERNS,
} from "../utills/ApplicationRegex";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomModel from "../components/CustomModel";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import {
  BASE_URL,
  HOMES,
  HOME,
  UPLOADS,
  HOME_UPLOAD,
  HOMES_DETAILS,
  ADD_SINGLE_HO,
  SINGLE_HOME_ADD,
  JOB_CATEGORY,
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomAutoComplete from "../components/CustomAutoComplete";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import moment from "moment";
import CustomLoading from "../components/CustomLoading";
import HomesAutoComplete from "../components/Homesautocomplete";

function AllocatedHome() {
  const [singleHoOpen, setSingleHoOpen] = useState(false);
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const [homesData, setHomesData] = useState([]);
  const [homeId, setHomeId] = useState("");
  const [updateRes, setUpdateRes] = useState([]);
  const [editData, setEditData] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [homeIdchange, setHomeIdchange] = useState("");
  const navigate = useNavigate();

 

  const handleSingleHoClose = (status) => {
    if (status === 200) {
      setSingleHoOpen(false);
    }
  };

  const handleClick = () => {
    navigate(-1);
  };
  const getHomes = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        // Filter out homes with null homeOwnerDetails
        const filteredData = data.filter((home) => home.isAssigned === "0");
        setHomesData(filteredData);

        const homeIds = filteredData.map((home) => home.homeId);

        homeIds.forEach((singleHomeId) => {
          setHomeId(singleHomeId);

          console.log("Individual HomeId:", singleHomeId);
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);


  const renderedAddresses = homesData
    .map((home, index) => ({
      address: home.address,
      value: home.homeId,
      key: index,
    }));

  

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();



  const getSingleHomeById = async (id) => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${SINGLE_HOME_ADD}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        setUpdateRes(data.homeId);
        setEditData(data.address);
        console.log("Occupation" + data.homeId);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homesData.find(
        (home) => home.homeId === selectedHomeId
      );
      const homeOwner = selectedHome;
      console.log(selectedHomeId);
      setHomeIdchange(selectedHomeId)
      setValue("selectedOption", selectedHomeId);
    } else {
      setValue("selectedOption", "");
    }

  };

  const onSubmitHo = (data) => {
    setIsLoading(true);
    setSubmitted(true);
    const putData = {
      email: data.email,
    };

    request({
      url: `${ADD_SINGLE_HO}/${homeIdchange}`,
      method: "post",
      data: putData,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          setValue("selectedOption", null);
          // handleSingleHoClose(res.status);
          // getAllocatedHomeDetails();
          // getHomes();
          setTimeout(() => {
            navigate(`/homes`);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };


  





  const addSingleHOAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Add"
        click={handleSingleHoClose}
      />
    </>
  );




  return (
   
      <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Assign Homes" />

        {isLoading && <CustomLoading />}
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmitHo)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          {/* <div class="grid xl:grid-cols-2"> */}
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <HomesAutoComplete
            fieldName="selectedOption"
            id="selectId"
            requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
            options={renderedAddresses}
            fieldLabel=" Select Home Address"
            fieldControl={control}
            setValue={setValue}
            onChange={handleHomeChange}
            fieldRequiredIcon="*"
            invalidOption="Please select valid home address"
    tooltipMessage="You can select the Home address to assign email "
          />

          <CustomInputField
            fieldName="email"
            requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
            fieldPattern={EMAIL_REGEX_PATTERNS}
            patternErrorMessage={EMAIL_INVALID_MESSAGE}
            fieldId="email"
            fieldType="text"
            fieldLabel="Email"
            fieldRequiredIcon="*"
            fieldControl={control}
            fieldError={errors}
            tooltipMessage="You can enter the Email here "
          />
 
            </section>
            {/* </div> */}
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Add" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default AllocatedHome;
