
import React, { useEffect } from "react";
import FileSaver from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import ImageDownloadIcon from "@mui/icons-material/Image";
// import PdfDownloadIcon from "@mui/icons-material/PictureAsPdf";
// import "@fortawesome/fontawesome-free/css/all.css";y
import axios from "axios";
import { BASE_URL_IMAGE } from "../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import { CustomStyle } from "./CustomStyle";

function AttachmentListsView({ fileList }) { // Accept fileList containing array of file objects
  const { token } = useSelector((state) => state.hybridhoa);

  const getContentType = (fileUrl) => {
    const extension = fileUrl.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return "application/pdf";
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "csv":
        return "text/csv";
      case "doc":
        return "application/msword";
      case "xls":
        return "application/vnd.ms-excel";
      default:
        return "application/octet-stream";
    }
  };

  const handleSubmit = async (fileUrl, documentnamed) => {
    if (!fileUrl) {
      console.log("Error: No file URL provided");
      return;
    }

    try {
      const response = await axios.get(`${BASE_URL_IMAGE}/${fileUrl}`, {
        responseType: "blob",
      });

      const contentType = getContentType(fileUrl);
      const fileBlob = new Blob([response.data], { type: contentType });
      const fileURL = URL.createObjectURL(fileBlob);
      const trimmedDocumentName = documentnamed.substring(12);

      if (contentType.startsWith("image/")) {
        const newTab = window.open("");
        newTab.document.write(`
          <div style="display: flex; flex-direction: row; align-items: flex-end; position: fixed; top: 1px; right: 60px;">
            <div id="downloadButton" class="btn btn-secondary " style="right: 0px; border: none;">
              <svg width="64px" height="64px" viewBox="-15.12 -15.12 54.24 54.24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24" fill="white"></rect> <path d="M5 12V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V12" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 3L12 15M12 15L16 11M12 15L8 11" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </div>
            <div id="closeButton" class="btn btn-secondary " style="border: none; right: 0px;">
              <svg width="64px" height="64px" viewBox="-14.4 -14.4 52.80 52.80" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
            </div>
          </div>
          <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
            <img src="${fileURL}" style="max-width: 70%; max-height: 70%; height: auto;">
          </div>
        `);
        newTab.document.getElementById("downloadButton").onclick = () => {
          const downloadLink = document.createElement("a");
          downloadLink.href = fileURL;
          downloadLink.download = trimmedDocumentName;
          downloadLink.click();
        };
        newTab.document.getElementById("closeButton").onclick = () => {
          newTab.close();
        };
      } else {
        const downloadLink = document.createElement("a");
      downloadLink.href = fileURL;
      downloadLink.download = trimmedDocumentName; // Use documentnamed for the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
        // const fileURL = URL.createObjectURL(fileBlob);
        // window.open(fileURL);
// window.saveAs(fileURL)
        // const downloadLink = document.createElement("a");
        // downloadLink.href = fileURL;
        // downloadLink.download = documentnamed; // Use documentnamed for the download
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-29 !important">
        {/* <div className={CustomStyle.viewPageText}>
          {fileList.map((file, index) => (
            <div key={index} className="break-all">
              <span>{file.docname} on {file.uploadedTimes}</span>
              <a style={{ cursor: "pointer" }} onClick={() => handleSubmit(file.fileUrl, file.documentnamed)}>
                {file.fileUrl.endsWith(".jpg") || file.fileUrl.endsWith(".jpeg") || file.fileUrl.endsWith(".png") ? (
                  <FileDownloadIcon />
                ) : file.fileUrl.endsWith(".pdf") ? (
                  <FileDownloadIcon />
                ) : (
                  <FileDownloadIcon />
                )}
              </a>
            </div>
          ))}
        </div> */}
        <div className={CustomStyle.viewPageText}>
  {fileList.map((file, index) => (
    <div key={index} className="break-all">
      <a 
        style={{ 
          display: "flex", 
          alignItems: "center", 
          cursor: "pointer", 
          textDecoration: "none", 
          color: "inherit" 
        }} 
        onClick={() => handleSubmit(file.fileUrl, file.documentnamed)}
        onMouseEnter={(e) => {
          e.currentTarget.style.textDecoration = "underline"; 
          e.currentTarget.style.color = "#045F9F"; 
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.textDecoration = "none"; 
          e.currentTarget.style.color = "inherit"; 
        }}
      >
        {/* File Download Icon */}
        {file.fileUrl.endsWith(".jpg") || file.fileUrl.endsWith(".jpeg") || file.fileUrl.endsWith(".png") ? (
          <FileDownloadIcon />
        ) : file.fileUrl.endsWith(".pdf") ? (
          <FileDownloadIcon />
        ) : (
          <FileDownloadIcon />
        )}
        {/* File Name and Upload Time */}
        <span style={{ marginLeft: "8px" }}>
          {file.docname} on {file.uploadedTimes}
        </span>
      </a>
    </div>
  ))}
</div>
      </div>
    </div>
  );
}

export default AttachmentListsView;