import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomInputField from "../components/CustomInputField";
import {
  GENRAL_COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import { useForm } from "react-hook-form";
import CustomButton from "../components/CustomButton";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  BASE_URL_IMAGE,
  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,
  GROUP_CONFIG,
  MAINTENANCE_REQUEST,
} from "../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import {
  DeleteOutline,
  EditNoteOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import Loading from "react-loading";
// import Attachment from "../components/Attachment";
import CustomLoading from "../components/CustomLoading";
import CustomToastContainer from "../components/CustomToastContainer";
import { request } from "../services/AxiosConfig";
import CustomHeading from "../components/CustomHeading";
import CustomModel from "../components/CustomModel";
import CustomViewPages from "../components/CustomViewExportPage";
import PDFDocument from "../components/Templatepdf";
import { pdf } from "@react-pdf/renderer";
import CustomViewReportHeading from "../components/CustomViewReportHeading";
import CustomViewWithTooltip from "../components/CustomViewWithTooltip";
import AttachmentList from "../components/AttachmentList";
import CustomViewReportToolTip from "../components/CustomViewReportToolTip";
import AttachmentListsView from "../components/AttachmentListView";


function Audit_Mr_View() {
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const { maintenanceId } = useParams();
  const [maintenanceRequestData, setMaitenanceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  // const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showTopic,setShowTopic]=useState(false)
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState("");
  const [commentsId, setCommentsId] = useState("");
  const { token, userDetails } = useSelector((state) => state.hybridhoa);
  const [firstNameData, setFirstNameData] = useState("");
  const [fileImage,setFileImage]=useState([]);
  const [fileImages,setFileImages]=useState("")
  const [filePdf,setFilePdf]=useState("");

  const [lastNameData, setLastNameData] = useState("");
  const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const [footerContent,setFooterContent]=useState('');
  const[footerAdress,setFooterAdress]=useState('')
  const[groupAdress,setGroupAdress]=useState('')
  const [includeComments, setIncludeComments] = useState(false);
    const handleCheckboxChange = (event) => {
      setIncludeComments(event.target.checked);
    };
  const getGroupConfigurationDetails = async () => {
    try {
      // setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data); 
        setIsLoading(false);
        const logoConfig = data.find(config => config.topic === 'Logo');
        const footerValue =data.find(config => config.topic === 'Contact Details');

        console.log(logoConfig);
        if (logoConfig) {
        setIsLoading(false);

          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        if(footerValue){
        setIsLoading(false);

          setFooterAdress(footerValue.content);
        } const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }
       
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
        
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
     getGroupConfigurationDetails()
  },[])
  const getFile=async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${storedToken}`,
        // },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    getFile()
  },[logoUrl])
  const handleClick = () => {
    navigate(-1);
  };

  
  const userName = userDetails.firstName + " " + userDetails.lastName;
  const getGeneralComments = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
  
      console.log(storedToken);
  
      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);
  
      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        // setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    getGeneralComments();
  }, [maintenanceId]);
  const getMaintenanceRequestDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${MAINTENANCE_REQUEST}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setFileImage(data.attachments);
        setFilePdf(data.fileUrl)

        setMaitenanceRequestData(data); 
        setIsLoading(false);
        setFileUrl(data.fileUrl);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        };
        const formattedDate = new Date(data.createdDateAndTime).toLocaleString(
          "en-us",
          options
        );
        setCreatedDateAndTime(formattedDate);
        const createdDetails = data.createdByDetails;

        setFirstNameData(createdDetails.firstName);
        setLastNameData(createdDetails.lastName);
        console.log(data.fileUrl);
       
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getMaintenanceRequestDetailsById();
  }, []);
  
  

  const fullName = firstNameData + " " + lastNameData;
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';

    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
  
  const fileList = fileImage.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleToggleTopic = () => {
    setShowTopic(!showTopic);
  };
  const renderDescription = () => {
    const description = maintenanceRequestData.description || ''; // Default to an empty string if null or undefined
  
    if (!showFullDescription) {
      return (
        <>
          {description.slice(0, 250)}
          {description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Read More</Button>
          )}
        </>
      );
    } else {
      return (
        <>
          {description}
          <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Less</Button>
        </>
      );
    }
  };
  const renderTopic = () => {
    console.log("inside render ");
    if (maintenanceRequestData.topic && !showTopic) {
    console.log("inside render ",maintenanceRequestData.topic);
  
      return (
        <>
        
          {maintenanceRequestData.topic.slice(0, 250)}
          {maintenanceRequestData.topic.length > 250 && (
            <Button onClick={handleToggleTopic} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",maintenanceRequestData.topic);
  
      return (
        <>
          {maintenanceRequestData.topic}
          <Button onClick={handleToggleTopic} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };
  const data = [
    // pageTitle: "Maintenance Request",
    // buttons: [
    //   {
    //     buttonTitle: "Back",
    //     onClick: handleClick,
    //   },
     
    // ],
    // content: [
    //   {
    //     topic: "Maintenance Request ID",
    //     description:complainceDetails.mrId??"",
    //   },
    //   {
    //     topic: "What needs attention?",
    //     description:  moment(complainceDetails.createdDateAndTime??"").format("MM/DD/YYYY"),
    //   },
    //   {
    //     topic: "More Information",
    //     description: "Attention",
    //   },
    //   {
    //     topic: "Requested Date Time",
    //     description: moment(complainceDetails.createdDateAndTime??"").format("MM/DD/YYYY hh:mm:ss a"),
    //   },
    //   {
    //     topic: "Updated Date Time",
    //     description: moment(complainceDetails.auditDateAndTime??"").format("MM/DD/YYYY hh:mm:ss a"),
    //   },
    //   {
    //     topic: "Is this issue urgent?",
    //     description: complainceDetails.isUrgent ?'Yes':'No',
    //   },
    //   {
    //     topic: "Created By",
    //     description: byFullName??"",
    //   },
    //   {
    //     topic: "CreatedBy Address",
    //     description:`${complainceDetails.createdByDetails?.addressForCommunication ||""}`
    //   },
    //   {
    //     topic: "Contact",
    //     description:`${complainceDetails.createdByDetails?.contact ||""}`
    //   },
    //   {
    //     topic: "Is this issue causing a threat to personal safety?",
    //     description:complainceDetails.isSafty ?'Yes':'No',
    //   },
      
    //   {
    //     topic: "Status",
    //     description: convertToPascalCase(complainceDetails.status??""),
    //   },
    //   {
    //     topic: "Request Type",
    //     description: complainceDetails.type??"",
    //   },
    //   {
    //     topic: "Desired Start Date",
       
    //     description: complainceDetails.startDate !== null ? moment(complainceDetails.startDate).format("MM/DD/YYYY hh:mm:ss a") : "",
    //   },
    //   {
    //     topic: "Desired End Date",
    //     description: complainceDetails.endDate !== null ? moment(complainceDetails.endDate).format("MM/DD/YYYY hh:mm:ss a") : "",
    //   },

    //   {
    //     topic: "Attachments",
    //     component: "AttachmentList", 
    //     props: {
    //       fileUrl: fileUrl,
    //       documentName: fileUrl,
    //       docname: fileUrl,
    //       uploadedTimes: createdDateAndTime,
    //     },
    //   },
    // ],
     
    {
      groupName: 'Maintenance  Details',
      items: [
        { label: 'Maintenance Request ID', data: `${ maintenanceRequestData?.mrId || ""}`, text: 'Unique identifier for the maintenance request.' },
        { label: 'What needs attention?', data:renderTopic()  ,text: 'Description of the issue or topic that needs attention.'},

         
    { label: 'More Information?', data: renderDescription() ,text: 'Additional information or details about the maintenance request.'},
      { label: 'Is this issue Urgent?', data:  `${maintenanceRequestData.isUrgent ?'Yes':'No'}` ,text: 'Indicates whether the issue requires immediate attention (Yes/No).'},
       { label: 'Is this issue  causing a threat to personal safety??', data:  `${maintenanceRequestData.isSafty ?'Yes':'No'}` ,text: 'Indicates whether the issue poses a threat to personal safety (Yes/No).'},
         {
                label: 'Status',
                data: convertToPascalCase(maintenanceRequestData.status ?? ""),
                text: 'Current status of the maintenance request.'
              },
 { label: 'Request Type', data:  `${maintenanceRequestData?.type || "" }` ,text: 'Type or category of the maintenance request.'},

         
              ],
     },
     {
      groupName: 'Date Details',
      items: [
       
        {
          label: 'Start Date ',
          data: maintenanceRequestData.startDate 
          ? moment(maintenanceRequestData.startDate).isValid() 
            ? moment(maintenanceRequestData.startDate).format("MM/DD/YYYY ") 
            : "" 
          : ""  , 
                    text: 'Date and time when the maintenance request was initiated.'
          },
          {
          label: 'End Date ',
          data: maintenanceRequestData.startDate 
          ? moment(maintenanceRequestData.startDate).isValid() 
            ? moment(maintenanceRequestData.startDate).format("MM/DD/YYYY ") 
            : "" 
          : ""  , 
                    text: 'Date and time when the maintenance request is expected to be completed.'
          },
          // { label: 'Requested Date Time', data: `${moment(maintenanceRequestData.createdDateAndTime).format(
          //   "MM/DD/YYYY hh:mm:ss A")}` ,text: 'Date and time when the maintenance request was submitted.'},
          //   { label: 'Updated Date Time', data: `${moment(maintenanceRequestData.auditDateAndTime).format(
          //    "MM/DD/YYYY hh:mm:ss A")}` ,text: 'Date and time when the maintenance request was last updated.'}, 
      ],
    },

     {
    groupName: 'Attachment Details',
items:[
  
 { label:'Attachment', data: (
  <AttachmentListsView fileList={fileList} />

), text: 'List of attached files/documents related to the maintenance request.'},   , 
]  
  }
    ];
  // const data = {
  //   pageTitle: "View Maintenance",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "What needs attention?",
  //       description: maintenanceRequestData.topic || "",
  //     },
  //     {
  //       topic: "More Information ",
  //       description: maintenanceRequestData.description || "",
  //     },
  //     {
  //       topic: "Requested Date Time",
  //       description: moment(maintenanceRequestData.createdDateAndTime).format(
  //         "MM/DD/YYYY hh:MM:ss a"
  //       ) || "",
  //     },
  //     {
  //       topic: "Updated Date Time",
  //       description: moment(maintenanceRequestData.auditDateAndTime).format(
  //         "MM/DD/YYYY hh:MM:ss a"
  //       )|| "",
  //     },
  //     {
  //       topic: "Is this issue urgent? ",
  //       description: maintenanceRequestData.isUrgent ? "Yes" : "No",
  //     },
  //     {
  //       topic: "Is this issue causing a threat to personal safety?",
  //       description: maintenanceRequestData.isSafty ? "Yes" : "No",
  //     },
  //     {
  //       topic: "Created By",
  //       description: fullName || "",
  //     },
  //     {
  //       topic: "Status",
  //       description: maintenanceRequestData.status ||"",
  //     },
  //     {
  //       topic: "Request Type",
  //       description: maintenanceRequestData.type || "",
  //       fileUrl: maintenanceRequestData.fileUrl,
  //     },
  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList", // Instead of the JSX component, provide the component name as a string
  //       props: {
  //         fileUrl: fileUrl,
  //         // documentName: fileUrl,
  //         docname: fileUrl,
  //         // documentnamed: fileUrl,
  //         uploadedTimes: createdDateAndTime,
  //       },
  //     },
  //   ],
  // };
  const currentDate = moment(); // Get the current date
    let dateTimeText = currentDate.format("DD MMMM YYYY");
    const handleRenderPDF = () => {
    
      const styles = {
        title: {
          fontSize: 12,
          color: "black",
          textAlign: "center",
          padding:"5px",
          fontWeight: "bold" 
        },
      empty:{
        color:"red",
      padding:"500px"
      },
      content: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px"
      },
      comments:{
        fontSize:12,
        color:"black",
        marginTop:"2%"
      },
      emptys:{
          marginTop:"3%"
      },
      empty:{
      marginTop:"120%"
      },
      secondEmpty:{
       marginTop:"31%"
      },
      from:{
        fontSize: 12,
        color: "black",
        textAlign: "left",
        alignItems:"center",
        padding:"2px",
       },
     headers:{
      fontSize: 12,
      color: "black",
      textAlign: "left",
      alignItems:"center",
      padding:"5px",
      
     },
     emptyModule:{
      marginTop:"7px"
     },
     re:{
  fontSize:12,
  color:"black",
  textAlign:"left",
  padding:"5px"
     },
      adress:{
        fontSize: 12,
        color: "black",
        textAlign: "left",
        
        // paddingLeft:"60px",
        padding:"5px",
        // fontWeight:"bold"
      },
      toDate:{
          marginTop:"1%",
      },
      description:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      dear:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      signature: {
        fontSize: 16,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      heading: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Oswald'
      },
      pageInitial:{
        marginTop:"100px"
      },
      afterfrom:{
        marginTop:"7%"
      },
      formempty:{
        marginTop:"47%"
      },anotherempty:{
        marginTop:"8%"
      },images:{
  width:"200px",
  height:"100px"
  
      },
      fromBorder:{
         border:"1px solid black"
      },toBorder:{
        border:"1px solid black"
  
      },
      headerImage:{
        marginTop:"1%",
   paddingLeft:"70px",
      paddingRight:"50px",
      alignItems:"center",
  
        width:"470px",
        height:"160px",
        // backgroundColor:"yellow"
      }
    };
    const datas = {
      // title:"Complaince Request Notice",
      // hoaName:groupAdress,
  
      logo: imageBlob,
            letterContent: [
        // { type: 'text',text: "",style:styles.emptys }, // Empty line for spacing
        { type: 'text', text: groupAdress, style: styles.title },
  
        { type: 'text', text: "Maintenance Request", style: styles.title },
        { type: 'text', text: " "  }, 
        // { type: 'text',text: dateTimeText, style:styles.from },
        // { type: 'text',text: "",  }, // Empty line for spacing
        // { type: 'text',text: "",  },
        
        {style:styles.fromBorder},
        
        {type: 'text', text: "",  style:styles.formempty},
        // {type: 'text', text: "",  style:styles.anotherempty},
        // { type: 'text',text: "",  style:styles.secondEmpty},
            
        { type: 'text',text: "From :", style: styles.from  } ,
        { type: 'text', text: groupAdress, style: styles.from },

        { type: "text", text: footerAdress, style: styles.from },
  
        // {type: 'text',text: ""+ `${maintenanceRequestData.createdByDetails?.firstName || ""}`,style: styles.from, },
        // {type: 'text',text: ""+ `${maintenanceRequestData.address?.address || ""}`,style: styles.from,},
        // { type: 'text',text: `${maintenanceRequestData.createdByDetails?.city || ""}, ${maintenanceRequestData.createdByDetails?.zipcode || ""}`, style: styles.from },
       
               // {style:styles.toBorder},
  
        { type: 'text',text: "",  style:styles.afterfrom},
        // { type: 'text',text: "To :", style: styles.headers  },
        {
          type: 'text',
          text: `${maintenanceRequestData?.createdByDetails?.firstName || ""}${maintenanceRequestData?.createdByDetails?.lastName || ""}`,
          style: styles.headers
        },
        { 
          type: 'text',
          text:`${maintenanceRequestData?.createdByDetails?.addressForCommunication ?? maintenanceRequestData?.homeDetails?.address ?? ""}`,
          style: styles.headers
        },
        {
          type: 'text',
          text: `${maintenanceRequestData?.createdByDetails?.city || ""}${maintenanceRequestData?.createdByDetails?.zipcode ? `, ${maintenanceRequestData?.createdByDetails?.zipcode}` : ""}`,
          style: styles.headers
        },
        
        {type: 'text', text: "",  style:styles.empty},
        { type: 'text',text: "",  style:styles.secondEmpty},
        { type: 'text',text: "",  style:styles.toDate},
        ...(imageBlob ? 
          [{ type: 'image', src: imageBlob, style: styles.headerImage }] : 
          [{ type: 'text', text: "", style: styles.headerImage }]
        ),
        // {type: 'image',src:imageBlob, style:styles.headerImage  },
        { type: 'text', text: groupAdress, style: styles.title },
       
        { type: 'text', text: "Maintenance Request", style: styles.title },
        {type:'text',text:dateTimeText,style: styles.content},
        { type: 'text',text: "",  style:styles.emptyModule},
        {
          type: 'text',
          text: `${maintenanceRequestData?.createdByDetails?.firstName || ""}${maintenanceRequestData?.createdByDetails?.lastName || ""}`,
          style: styles.headers
        },
        { 
          type: 'text',
          text:`${maintenanceRequestData?.createdByDetails?.addressForCommunication ?? maintenanceRequestData?.homeDetails?.address ?? ""}`,
          style: styles.headers
        },
        {
          type: 'text',
          text: `${maintenanceRequestData?.createdByDetails?.city || ""}${maintenanceRequestData?.createdByDetails?.zipcode ? `, ${maintenanceRequestData?.createdByDetails?.zipcode}` : ""}`,
          style: styles.headers
        },
        {
          type: 'text',
          text: `RE: ${maintenanceRequestData?.createdByDetails?.addressForCommunication ?? maintenanceRequestData?.homeDetails?.address ?? ""}`,
          style: styles.re
        },
        {type: 'text', text: "Dear Resident,"  , style: styles.content },
        {type: 'text',text:"You have previously asked to adress the following matter with your property.",style: styles.content},
      {type: 'text', text: "This is to inform you about the maintenance request:"+ maintenanceRequestData.mrId+"_"+dateTimeText+",which has been "+ maintenanceRequestData.status, style: styles.content},
      { type: 'text',text: "" + `${maintenanceRequestData.description ||""}`, style: styles.description,},
      {
        type: 'text',
        text: maintenanceRequestData.startDate && maintenanceRequestData.endDate
          ? "We have scheduled the desired start of the maintenance " + 
            moment(maintenanceRequestData.startDate).format("MM/DD/YYYY") + 
            " and desired end of maintenance on " + 
            moment(maintenanceRequestData.endDate).format("MM/DD/YYYY") + 
            ". Please ensure these dates are noted. Should any adjustments be needed, contact me at your earliest convenience."
          : "",
        style: styles.description
      }
      ,
      
  { type: 'text',text: "If u have any questions,feel that you have recieved this letter in error,or would  like to request a variance,please feel free to contact our office.", style: styles.content},
  
     {type: 'text',text:"Thank you in advance for your attention to this matter.", style: styles.content},
      {type: 'text', text: "",  }, 
      {type: 'text', text: "", style: styles.content },
      {type: 'text', text: "", style: styles.content },
        
        // {type: 'text', text: "Sincerely", style: styles.adress },
        // {text: ""+ complainceDetails.createdByDetails?.firstName + "",style: styles.adress},
        // {text: ""+ complainceDetails.address?.address + "",style: styles.adress,fontSize: 15 ,},
        // { text: `${complainceDetails.createdByDetails?.city || ""}, ${complainceDetails.createdByDetails?.zipcode || ""}`, style: styles.adress, fontSize: 15 },    
        //  {type: 'text',text: footerContent , style: styles.adress}
        {type: 'text', text: "Sincerely,", style: styles.adress },
        { 
          type: "text", 
          text: `${footerAdress} (computer generated letter)`, 
          style: styles.adress 
        },
        // {type: 'text', text: "", },
        // {type: 'text', text: "",  style:styles.empty},
        // { type: 'text',text: "",  style:styles.secondEmpty},
        // {type: 'image',src:fileImage,style:styles.images },
  
      ]
    };
    if (Array.isArray(fileImage) && fileImage.length > 0) {
      datas.letterContent.push({ type: 'text', text: "Attachments :", style: styles.content });
  
      fileImage.forEach((fileImages, index) => {
        const trimmedFileName = fileImages.file.substring(12);
        datas.letterContent.push({ type: 'text', text: trimmedFileName, style: styles.content });
        datas.letterContent.push({ type: 'image', src: `${BASE_URL_IMAGE}/${fileImages.file}`, style: styles.images, alt: `Attachment ${index + 1}` });
      });
    } else {
      // Handle the case where fileImage is an empty array
      datas.letterContent.push({ type: 'text', text: "", style: styles.content });
    }
    if (includeComments && generalCommentDetails.length > 0) {
      // Add the "Comments" heading before looping through comments
      datas.letterContent.push({ type: 'text', text: "Comments :", style: styles.content });
  
      generalCommentDetails.forEach((commentDetail) => {
        // Combine the name and comment into a single line
        const combinedText = `@${commentDetail.createdByName} : ${commentDetail.comments}`;
        datas.letterContent.push({ type: 'text', text: combinedText, style: styles.content });
    });
  }
    return (
      <PDFDocument data={datas} />
    );
  };
  const handleClickPdf = async () => {
    // console.log("test",logoUrl);
    try {
      const pdfData = handleRenderPDF();
      const blob = await pdf(pdfData).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    //   const a = document.createElement('a');
    // a.href = url;
    // a.download = `${complainceDetails.crId}.pdf`; // Set the filename here
    // a.target = '_blank';
    // a.click();
    
    // // Clean up
    // URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  return (
    <div>
     
      <CustomToastContainer />
      {isLoading && <CustomLoading/>}
      {/* <CustomViewPages data={data} exportedBy={true} 
      handleExportss={handleClickPdf}  checked={includeComments}
      handleCheckBoxChange={handleCheckboxChange}/> */}
          <CustomViewReportHeading onClick={handleClick} 
      handleExportss={handleClickPdf} 
       title={"View Maintenance Request"} checked={includeComments}
       handleCheckBoxChange={handleCheckboxChange} exportedBy={true}>
              <CustomViewReportToolTip data={data}  />
              <>
      <CustomHeading title="General Comments" />

  <Card>
  
  <CardContent>
    {generalCommentDetails && generalCommentDetails.length > 0 ? (
      generalCommentDetails.map((comment, index) => (
        <div key={comment.generalCommentsId}>
          <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
            <p>
              <span className="text-primary" style={{ float: "left" }}>
                @ {comment.createdByName}{" "}
              </span>
              <span style={{ float: "right" }}>


               
                {moment(comment.createdDateAndTime).format(
                  "MM/DD/YYYY hh:mm:ss a"
                )}
              </span>
              
                <p className="mt-8 mb-8">{comment.comments}</p>
            </p>
          </section>

          <Divider />
        </div>
      ))) : (
        <div className="text-center mt-10">
          <p>No comments to display</p>
        </div>
      )}
  </CardContent>
</Card>
</>
              </CustomViewReportHeading>
      <br />
 
    </div>
  );
}

export default Audit_Mr_View;
