import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomViewPage from "../components/CustomViewPage";
import { useParams } from "react-router-dom/dist";
import { BASE_URL, REIMBURSED_DUES } from "../utills/ApplicationRouting";
import axios from "axios";
import CustomLoading from "../components/CustomLoading";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomViewwithTooltip from "../components/CustomViewWithTooltip"
import CustomViewHeading from "../components/CustomViewHeading"

function ViewReimburseDue() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const formatDate = (dateString) => {
    if (dateString === "null" || !dateString) {
      return "";
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const { reimbursedDueId } = useParams();
  const [reimburseDue, setReimburseDue] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const { token } = useSelector((state) => state.hybridhoa);
  const [createdfor,setCreatedFor] = useState('');
  const [firstName,setFirstName]=useState('');
  const [lastName,setLastName] =useState('');
  const getHomeOwnerDetailsId = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BASE_URL}${REIMBURSED_DUES}/${reimbursedDueId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      console.log(response.data.renter);
      if (response.status === 200) {
        const data = response.data;
        setReimburseDue(data);
        setFileUrl(data.fileUrl);
        setCreatedFor(data.appliedToDetails);
        setFirstName(createdfor.firstName);
        setLastName(createdfor.lastName);
        console.log(createdfor.firstName);
        setIsLoading(false);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        };
        const formattedDate = new Date(data.createdDateAndTime).toLocaleString(
          "en-us",
          options
        );
        setCreatedDateAndTime(formattedDate);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getHomeOwnerDetailsId();
  }, [reimbursedDueId]);
  
  const fullName = firstName + " " + lastName

  // const data = {
  //   pageTitle: "View Reimbursed Due",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "Total Amount ",
  //       description:
  //         reimburseDue.totalAmount === "null" ? "" : reimburseDue.totalAmount,
  //     },
  //     {
  //       topic: "Waived Amount",
  //       description:
  //         reimburseDue.waivedAmount === "null" ? "" : reimburseDue.waivedAmount,
  //     },
  //     {
  //       topic: "Due Type",
  //       description:
  //         reimburseDue.dueType === "null" ? "" : reimburseDue.dueType,
  //     },
   
  //     {
  //       topic: "Created For",
  //       description: reimburseDue.appliedToDetails && reimburseDue.appliedToDetails.firstName !== null && reimburseDue.appliedToDetails.firstName !== undefined ? reimburseDue.appliedToDetails.firstName : "",
  //     },
  //     {
  //       topic: "Comment",
  //       description:
  //         reimburseDue.comment === "null" ? "" : reimburseDue.comment,
  //     },
  //     {
  //       topic: "Created Date Time",
  //       description: moment(reimburseDue.createdDateAndTime).format("MM/DD/YYYY hh:mm A"),
  //     },
  //     {
  //       topic: "Audit Date Time",
  //       description: moment(reimburseDue.auditDateAndTime).format("MM/DD/YYYY hh:mm A"),
  //     },
     
  //     {
  //       topic: "Title ",
  //       description: reimburseDue.title === "null" ? "" : reimburseDue.title,
  //     },
  //   ],
  // };
  
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const data = [
    {
      groupName: 'Waived Off Details',
      items: [ 
        {
          label: 'Title',
          data: `${reimburseDue.title === "null" ? "" : reimburseDue.title}`,
          text: 'title of the due'
        },
       
        {
          label: 'Due Type',
          data: `${reimburseDue.dueType === "null" ? "" : convertToPascalCase(reimburseDue.dueType)}`, text: 'Type of the Due which is waived off'
        }, 
        {
          label: 'Total Amount',
          data: `${ reimburseDue.totalAmount === "null" ? "" : reimburseDue.totalAmount}`
          , text: 'Total amount need to paid '
        }, 
         {
          label: 'Waived Amount',
          data: `${reimburseDue.waivedAmount === "null" ? "" : reimburseDue.waivedAmount}`
          , text: 'Waived from the total amount'
        }, 
        {
          label: 'Comments',
          data: `${reimburseDue.comment === "null" ? "" : reimburseDue.comment}`
          , text: 'Comments '
        },
      ],
    },
{
      groupName: 'Created Details',
      items: [
        {
          label: 'Created For',
          data: `${ reimburseDue.appliedToDetails && reimburseDue.appliedToDetails.firstName !== null && reimburseDue.appliedToDetails.firstName !== undefined ? reimburseDue.appliedToDetails.firstName : ""}`,
          text: 'To whom it is waived off'
        },
        {
          label: 'Created Date Time',
          data: `${ moment(reimburseDue.createdDateAndTime).format("MM/DD/YYYY hh:mm A")}`,
          text: 'at when it is waived off'
        },
        // {
        //   label: 'Audit Date Time',
        //   data: `${ moment(reimburseDue.auditDateAndTime).format("MM/DD/YYYY hh:mm A")}`
        //   , text: 'At when the reimbursed amount is edited it is reimbursed'
        // },
        
      ],
    },

  ];
 
  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewHeading onClick={handleClick}  title={"View Waived Off Details"}  data={data}>
      <CustomViewwithTooltip data={data}  />
      </CustomViewHeading>
    </div>
  );
}

export default ViewReimburseDue;
