import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoginPage from "./screens/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthToken, setToken } from "./redux/Hybridhoa"; // Import the action creator
import CenterLayout from "./components/backup/CenterLayout";
import Dashboard from "./screens/Dashboard";
import CustomLayout from "./components/CustomLayout";
import AllocatedHome from "./screens/AllocatedHome";
import JobCategory from "./screens/JobCategory";
import ExternalUser from "./screens/ExternalUser";
import GroupConfiguration from "./screens/GroupConfiguration";
import ComplianceRequest from "./screens/ComplianceRequest";
import MaintenanceRequest from "./screens/MaintenanceRequest";
import HomeOwners from "./screens/HomeOwners";
import Documents from "./screens/Documents";
import ArchivedDocuments from "./screens/ArchivedDocuments";
import Bylaw from "./screens/Bylaw";
import FAQ from "./screens/FAQ";
import PaymentConfig from "./screens/PaymentConfig";
import ReimburseDue from "./screens/ReimburseDue";
import ApplyReimburseDue from "./screens/ApplyReimburseDue";
import AddPaymentConfig from "./screens/AddPaymentConfig";
import Audit_Cr_Mr from "./screens/Audit_Cr_Mr";
import AuditDocument from "./screens/AuditDocument";
import AuditDocument_View from "./screens/AuditDocument_View"
import ViewExternal from "./screens/ViewExternal";
import ViewAllocatedHomes from "./screens/ViewAllocatedHomes";
import EditAllocatedHomes from "./screens/EditAllocatedHomes";
import AddRenter from "./screens/AddRenter";
import ViewRenter from "./screens/ViewRenter";
import ViewHomeowners from "./screens/ViewHomeowners";
import ViewMaintenance from "./screens/ViewMaintenance";
import ViewCompliance from "./screens/ViewCompliance";
import ViewBylaw from "./screens/ViewBylaw";
import ViewDocuments from "./screens/ViewDocuments";
import ViewArchivedDocument from "./screens/ViewArchivedDocument";
import ViewAuditDocument from "./screens/ViewAuditDocument";
import ViewReimburseDue from "./screens/ViewReimburseDue";
import ViewAudirCrMr from "./screens/ViewAudirCrMr";
import ViewPaymentConfig from "./screens/ViewPaymentConfig";
import EditPaymentConfig from "./screens/EditPaymentConfig";
import EditExternalUser from "./screens/EditExternalUser";
import Profile from "./screens/Profile";
import ChangePassword from "./screens/ChangePassword";
import Subscription from "./screens/Subscription";
import PhoneOtpVerfication from "./screens/PhoneOtpVerfication";
import PaymentGateway from "./screens/PaymentGateway";
import EditPaymentGateway from "./screens/EditPaymentGateway";
import BillingHistory from "./screens/BillingHistory";
import { Crisp } from "crisp-sdk-web";
import IdleTimeout from "./screens/idletimeout/IdleTimeout";

import PhoneOtpVerification from "./screens/PhoneOtpVerfication";
import Forgot from "./screens/forgotpage/Forgot";
import ForgotChange from "./screens/forgotpage/ForgotChange";
import PaymentGatewayAdd from "./screens/PaymentGatewayAdd"
import PaymentGatewayEdit from "./screens/PaymentGatewayEdit"
import PaymentGatewayView from "./screens/PaymentGatewayView"
import ContactVerified from "./screens/contactVerified";
import Audit_Mr from "./screens/Audit_Mr";
import Audit_Cr from "./screens/Audit_Cr";

import EmailVerified from "./screens/emailverify";

import Audit_Cr_View from "./screens/Audit_Cr_View";
import Audit_Mr_View from "./screens/Audit_Mr_View";
import AccountConfig from "./screens/AccountConfig";
import Offlinepayment from "./screens/Offlinepayment";
import AuditList from "./screens/AuditList"
import OfflinePaymentForm from "./screens/offlinePaymentForm";
import AddJobCategory from "./screens/AddJobCategory";
import EditJobCategory from "./screens/EditJobCategory";
import AddAllocatedHome from "./screens/AddAllocatedHome";
import AssignHome from "./screens/AssignHome";
import AddExternalUser from "./screens/AddExternalUser";
import HomeOwnerRoles from "./screens/HomeOwnerRoles";
import ViewOfflinePayment from "./screens/ViewOfflinePayment";
import AddBylaw from "./screens/AddBylaw";
import EditBylaw from "./screens/EditBylaw";
import EditHomeOwner from "./screens/EditHomeOwner"
import PrintEmailSrvice from "./screens/PrintEmailSrvice";
import ApplyAddDueReimburse from "./screens/ApplyAddDueReimburse";
import ApplyEditDueReimburse from "./screens/ApplyEditDueReimburse";
// import EditAllocatedHomes from "./screens/EditAllocatedHomes";



function App() {
  const { token, userLoginDetails } = useSelector((state) => state.hybridhoa);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log("localtion", window.location.href)
    console.log("localtion", location.pathname)
    console.log("localtion", location.pathname.startsWith('/verifyEmail'))
    if (location.pathname.startsWith('/verifyEmail') || location.pathname.startsWith('/verifyContact')) {
      if (typeof Crisp !== 'undefined' && Crisp.websiteId) {
        Crisp.chat.hide();
      } else {
        console.warn('Crisp is not initialized properly.'); // Log a warning
      }
      // navigate(location.pathname)
      // Crisp.chat.hide()
      localStorage.clear();

      dispatch(clearAuthToken());
      ;
      navigate(location.pathname)

    } else {
      const storedToken = localStorage.getItem("token");


      if (token === null && storedToken) {
        // Set the token from local storage to Redux state
        dispatch(setToken(storedToken));
      }
    }


    // if (!token) {
    //   // If no token exists, redirect to login
    //   navigate("/");
    // }
    // else{
    //   navigate('/dashboard')
    // }
  }, [token, dispatch, navigate]);
  // useEffect(() => {
  //   const storedToken = localStorage.getItem("token");


  //   if (token === null && storedToken) {
  //     // Set the token from local storage to Redux state
  //     dispatch(setToken(storedToken));
  //   }

  //   // if (!token) {
  //   //   // If no token exists, redirect to login
  //   //   navigate("/");
  //   // }
  //   // else{
  //   //   navigate('/dashboard')
  //   // }
  // }, [token, dispatch, navigate]);
  //
  // contactverification don't go to dashboard start by mohan

  useEffect(() => {
    if (token !== null) {
      if (location.pathname.startsWith('/')) {
        if (userLoginDetails.phoneVerified === false) {
          navigate("/contactverification");
        }
      }
    }
  }, [token, userLoginDetails, navigate]);
  // contactverification don't go to dashboard end by mohan
  return (


    <>
      {token == null && (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/forgetpassword/:tokenId" element={<ForgotChange />} />
          <Route path="/verifyContact/:tokenId" element={<ContactVerified />} />
          <Route path="/verifyEmail/:tokenId" element={<EmailVerified />} />
        </Routes>
      )}

      {token != null && userLoginDetails.phoneVerified === false && (
        // contactverification don't gothe dashboard
        <Routes>
          <Route path="/contactverification" element={<PhoneOtpVerification />} />
        </Routes>
      )}

      {token != null && (
        <>
          <IdleTimeout />
          <CustomLayout>
            {Crisp.configure("b0f63b1d-a465-4484-8ec0-9394f4c0ad63")}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/test" element={<CenterLayout />} />
              <Route path="/homes" element={<AllocatedHome />} />
              <Route path="/jobcategory" element={<JobCategory />} />
              <Route path="/externaluser" element={<ExternalUser />} />
              <Route path="/configuration" element={<GroupConfiguration />} />
              <Route path="/maintenance" element={<MaintenanceRequest />} />
              <Route path="/compliance" element={<ComplianceRequest />} />
              <Route path="/homeowners" element={<HomeOwners />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/archiveddocuments" element={<ArchivedDocuments />} />
              <Route path="/bylaw" element={<Bylaw />} />
              <Route path="/addbylaw" element={<AddBylaw />} />
              <Route path="/editbylaw/:byLawId" element={<EditBylaw />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/payment" element={<PaymentConfig />} />
              <Route path="/reimbursedue" element={<ReimburseDue />} />
              <Route path="/applyreimbursedue" element={<ApplyReimburseDue />} />
              <Route path="/payment/add" element={<AddPaymentConfig />} />
              <Route path="/auditcrmr" element={<Audit_Cr_Mr />} />
              <Route path="/auditdocument" element={<AuditDocument />} />
              <Route path="/auditdocument/:documentId" element={<AuditDocument_View />} />
              <Route path="/external/:homeOwnerLoginId" element={<ViewExternal />} />
              <Route path="/homes/:email/:homeId" element={<ViewAllocatedHomes />} />
              <Route path="/homeowner/edit/:homeId" element={<EditAllocatedHomes />} />
              <Route path="/addrenter/:homeOwnerLoginId/:homeId" element={<AddRenter />} />
              <Route path="/viewrenter" element={<ViewRenter />} />
              <Route path="/offlinepayment" element={<Offlinepayment />} />
              <Route path="/homeowner/:email/:homeId" element={<ViewHomeowners />} />
              <Route path="/maintenance/:maintenanceId" element={<ViewMaintenance />} />
              <Route path="/compliance/:complainceId" element={<ViewCompliance />} />
              <Route path="/viewbylaw/:byLawId" element={<ViewBylaw />} />
              <Route path="/document/:documentId" element={<ViewDocuments />} />
              <Route path="/viewarchiveddocument" element={<ViewArchivedDocument />} />
              <Route path="/document/:documentId" element={<ViewAuditDocument />} />
              <Route path="/viewreimbursedue/:reimbursedDueId" element={<ViewReimburseDue />} />
              <Route path="/viewauditcrmr/:maintenanceId" element={<ViewAudirCrMr />} />
              <Route path="/auditmr" element={<Audit_Mr />} />
              <Route path="/email&&printservice" element={<PrintEmailSrvice />} />
              <Route path="/auditcr" element={<Audit_Cr />} />
              <Route path="/payment/:groupDuesId" element={<ViewPaymentConfig />} />
              <Route path="/payment/edit/:groupDuesId" element={<EditPaymentConfig />} />
              <Route path="/auditcr/:complainceId" element={<Audit_Cr_View />} />
              <Route path="/auditmr/:maintenanceId" element={<Audit_Mr_View />} />
              <Route path="/offlinepayments" element={<OfflinePaymentForm />} />

              <Route path="/edit/externaluser/:homeOwnerLoginId" element={<EditExternalUser />} />

              <Route path="/profile" element={<Profile />} />
              <Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/subscription" element={<Subscription />} />

              <Route path="/paymentgateway" element={<PaymentGateway />} />
              <Route path="/editpaymentgateway" element={<EditPaymentGateway />} />
              <Route path="/billing" element={<BillingHistory />} />

              <Route path="/paymentgatewayadd" element={<PaymentGatewayAdd />} />
              <Route path="/paymentgatewayedit/:groupId" element={<PaymentGatewayEdit />} />
              <Route path="/paymentgatewayview" element={<PaymentGatewayView />} />

              <Route path="/accountconfig" element={<AccountConfig />} />
              <Route path="/auditlist" element={<AuditList />} />
              <Route path="/addjobcategory" element={<AddJobCategory />} />
              <Route path="/editjobcategory/:externalOccupationId" element={<EditJobCategory />} />
              <Route path="/addhomes" element={<AddAllocatedHome />} />
              <Route path="/assignhome" element={<AssignHome />} />
              <Route path="/addexternaluser" element={<AddExternalUser />} />
              <Route path="/homeowner/roles/:email/:homeId" element={<HomeOwnerRoles />} />
              {/* <Route path="/edithomes" element={<EditAllocatedHomes/>}/> */}
              <Route path="/viewofflinepayment/:homeOwnerDuesId" element={<ViewOfflinePayment />} />
              <Route path="/edithomeowner/:email/:homeId" element={<EditHomeOwner />} />
              <Route path="/applyeaddreimburse/:homeOwnerDuesId" element={<ApplyAddDueReimburse />} />
              <Route path="/applyeeditreimburse/:reimbursedDueId" element={<ApplyEditDueReimburse />} />


            </Routes>
          </CustomLayout>
        </>

      )}
    </>
  );
}

export default App;
