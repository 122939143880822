export const EMAIL_REQUIRED_MESSAGE = "Email address is required";

export const EMAIL_INVALID_MESSAGE = "Please enter a valid email address";

export const FAX_INVALID_MESSAGE = "Please enter a valid fax number";

export const PHONE_REQUIRED_MESSAGE="Contact is required"

export const PHONE_INVALID_MESSAGE="Please provide valid contact"

export const PASSWORD_REQUIRED_MESSAGE = "Password is required";

export const JOB_CATEGORY_REQUIRED_MESSAGE = "Job - Category is required";

export const JOB_CATEGORY_INVAILD_MESSAGE = "Job - Category field should only contain alphabets"

export const HOME_ADDRESS_REQUIRED_MESSAGE = "Home address is required";

export const FIRST_NAME_REQUIRED_MESSAGE = "First Name is required";

export const MIN_LENGTH_TEN ="Please provide a minimum of 10 characters";

export const MIN_LENGTH_VALIDATE_MESSAGE = "Please provide a minimum of 2 characters";

export const MAX_LENGTH_VALIDATE_MESSAGE = "Please provide a maximum of 60 characters only"

export const MAX_LENGTH_VALIDATE_MESSAGE_TEN = "Please provide a maximum of 10 characters only"

export const MAX_LENGTH_TWO_FIFTY = "Please provide a maximum of 250 characters only";

export const MAX_LENGTH_FIVE_THOUSAND = "Please provide a maximum of 5000 characters only";

export const MAX_LENGTH_FIVE_HUNDRED= "Please provide a maximum of 500 characters only";


export const MAX_LENGTH_THOUSAND = "Please provide a maximum of 1000 characters only";
export const END_DATE_REQUIRED_MESSAGE = "Please provide a maximum of 1000 characters only";

export const MAX_LENGTH_HUNDRED = "Please provide a maximum of 100 characters only";

export const LAST_NAME_REQUIRED_MESSAGE = "Last Name is required";

export const FIRST_NAME_INVALID_MESSAGE ="Please provide valid First Name";

export const LAST_NAME_INVALID_MESSAGE = "Please provide valid Last Name";

export const EXTERNAL_ROLE_REQUIRED_MESSAGE = "Please select Role";

export const ROLES_REQUIRED_MESSAGE = "Please select at least one role"

export const TOPIC_REQUIRED_MESSAGE = "Topic is required";

export const TOPIC_INVALID_MESSAGE = "Please provide valid topic";

export const DESCRIPTION_REQUIRED_MESSAGE = "Description is required";

export const DESCRIPTION_INVALID_MESSAGE = "Please provide valid Description"

export const TITLE_REQUIRED_MESSAGE ="Title is required";

export const TITLE_INVALID_MESSAGE = "Please provide valid Title";

export const AMOUNT_REQUIRED_MESSAGE = "Due amount is required";

export const AMOUNT_INVALID_MESSAGE = "Please provide valid  due amount";

export const AMOUNT_EXCEEDED ="Amount should not exceed due amount"

export const DUE_TYPE_REQUIRED_MESSAGE = " Due Type is required";

export const DUE_DATE_REQUIRED_MESSAGE = "Due Date is required";

export const END_DATE_REQUIRED_MESSAGES = "Last Date is required";

export const DUE_FREQUENCY_REQUIRED_MESSAGE = "Due Frequency is required";

export const SPECIAL_ASSESSMENT_DUE_TYPE = "please select Special Assessment Due Type";

export const REIMBURSE_COMMENT ="Waived Off Comments are required.";

export const TYPE_REQUIRED_MESSAGE = " Type is required";

export const FROM_DATE_REQUIRED_MESSAGE = "From date is required";

export const TO_DATE_REQUIRED_MESSAGE = "To date is required";

export const CITY_REQUIRED_MESSAGE = "City is required";

export const CITY_INVALID_MESSAGE = "Please provide valid city name";

export const MAX_LENGTH_TWENTY_MESSAGE = "Please provide 20 characters only";

export const STATE_REQUIRED_MESSAGE = "State is required";

export const STATE_INVALID_MESSAGE = "Please provide valid state";

export const ZIPCODE_REQUIRED_MESSAGE = "Zip code is required";

export const ZIPCODE_INVALID_MESSAGE = "Please provide valid zipcode";

export const ZIPCODE_MIN_LENGTH_MESSAGE = "Please provide minimum 5 digits";

export const ZIPCODE_MAX_LENGTH_MESSAGE = "Please provide a maximum of 5 digits";    

export const CONTACT_REQUIRED_MESSAGE = "Contact is required";

export const LEASE_START_DATE_REQUIRED_MESSAGE = "Lease start date is required";

export const LEASE_END_DATE_REQUIRED_MESSAGE ="Lease end date is required";


export const BYLAW_REQUIRED_MESSAGE = "Please select bylaw type";

export const TO_DATE_ERROR_MESSAGE = "Please provide To date greater than from date";

export const ADDRESS_REQUIRED_MESSAGE = "Address is required";

export const ADDRESS_INVALID_MESSAGE ="Please provide valid address";

export const CURRENT_AND_NEW_PASSWORD_CHECKING = "Current password and new password must not be the same";

export const NEW_PASSWORD_CURRENT_PASSWORD_CHECKING ="Confirm password and new password must be the same.";

export const CURRENT_PASSWORD_REQUIRED_MESSAGE = "Current Password is required";

export const NEW_PASSWORD_REQUIRED_MESSAGE = "New Password is required";

export const CONFIRM_PASSWORD_REQUIRED_MESSAGE = "Confirm Password is required";

export const GENRAL_COMMENTS_REQUIRED_MESSAGE = "General comments is required";

export const COMMON_ERROR_MESSAGE ="This field is required";

export const HEADER_REQUIRED_MESSAGE ="Header is required";

export const FOOTER_REQUIRED_MESSAGE ="Footer is required";

export const CONTACT_DETAILS_REQUIRED_MESSAGE = "Contact details is required";

export const LATE_FEE_CHARGE_REQUIRED_MESSAGE ="Late Fee Charge is required";

export const PERCENTAGE_REQUIRED_MESSAGE ="Minimum Amount is required";

export const CR_FINE_REQUIRED_MESSAGE ="Compliance Request Fine is required";

export const ONLY_DIGITS_ERROR_MESSAGE ="Value must contain only numbers";

export const MAX_LENGTH_TWO_FIFTY_VALIDATE_MESSAGE ="Please provide a maximum of 250 characters only";

export const MAX_PERCENTAGE_VALIDATE_MESSAGE ="Percentage value should not exceed 99%";

export const COMMON_INVALID_MESSAGE = "Please provide valid value";

export const MAX_LENGTH_SIX = "Please provide a maximum of 6 digits";

export const OTP_REQUIRED_MESSAGE ="Please provide OTP";

export const OTP_INVALID_MESSAGE ="Please provide valid OTP";

export const MAX_LENGTH_FOUR =" Please provide a maximum of 4 digits";


export const DATE_OF_BIRTH_REQUIRED_MESSAGE = "Date of birth is required";

export const ORGANIZATION_REQUIRED_MESSAGE = "Organization type is required";

export const ROLE_REQUIRED_MESSAGE = "Role is required";

export const ROLE_INVALID_MESSAGE = "Please provide valid Role";

export const SSN_INVALID_MESSAGE = "Please provide valid Social Security Number";

export const SSN_REQUIRED_MESSAGE = "Social Security Number is required";

export const ROUTING_NUMBER_REQUIRED_MESSAGE = "Routing number is required";

export const ROUTING_NUMBER_INVALID_MESSAGE = "Please provide valid Routing Number";

export const ACCOUNT_NUMBER_REQUIRED_MESSAGE = "Accounting number is required";

export const ACCOUNT_NUMBER_INVALID_MESSAGE = "Please provide valid Account Number";

export const NUMERS_ALLOWED ="Please provide valid due amount";

export const WEBSITE_INVALID_MESSAGE = "Please provide valid Business Website ";

export const MIN_LENGTH_ONE_CHARACTERS = "Please provide minimum 1 digit";

export const MIN_LENGTH_ONE_CHARACTERS_FOR_ALL = "Please provide minimum of 1 characters";

export const MAX_LENGTH_VALIDATE_MESSAGE_FIFTY = "Please provide a maximum of 50 characters only";

export const LEASE_END_DATE_REQUIRED_GREATER_MESSAGE ="Lease End Date must be greater than Lease Start Date";

export const TO_DATE_GREATER_MESSAGE = "To date must be greater than From date";

export const ACCOUNT_NUMBER_MINIMUM_MESSAGE = "Please provide minimum 2 digit";

export const AMOUNT_INVALID_MESSAGES="Please provide valid amount";

export const STTUS_REQUIRED_MESSAGE = "Status is required";

export const CARD_NUMBER_REQUIRED = "Card number is required";

export const CARD_NUMBER_INVALID_MESSAGE = "Please provide valid card number";

export const CARD_MIN_LENGTH = "Please provide a minimum of 16 digits only "

export const CARD_MAX_LENGTH = "Please provide a maximum of 16 digits only"

export const CVV_NUMBER_REQUIRED = "Cvv number is required";

export const CVV_INVALID_MESSAGE = "Please provide valid cvv number";

export const CVV_MIN_LENGTH = "Please provide a minimum of 3 digits only";

export const CVV_MAX_LENGTH = "Please provide a maximum of 3 digits only"

export const EXPIRATION_REQUIRED_MESSAGE = "Expiration date is required";

export const EXPIRATION_INVALID_MESSAGE = "Please provide valid expiration date (MM/YY)";

export const SSN_MIN_LENGTH = "Social Security Number must be 4 digits";

export const PURCHASE_EMAIL_REQUIRED_MESSAGE = "Purchase email is required";

export const SALES_EMAIL_REQUIRED_MESSAGE = "Sales email is required";

export const SUPPLIERS_EMAIL_REQUIRED_MESSAGE = "Suppliers email is required";

export const EXPENSE_EMAIL_REQUIRED_MESSAGE = "Expense email is required";