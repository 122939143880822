import React ,{ useEffect, useState }from "react";
import SideMenuItem from "./SideMenuItem";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SideMenuDropdown from "./SideMenuDropdown";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { AcUnitOutlined, AddHomeWorkOutlined, ExposureOutlined,AttachMoneyOutlined, DescriptionOutlined, FolderCopyOutlined, GavelOutlined, GroupsOutlined, HandymanOutlined, HelpOutlineOutlined, HomeOutlined, InsertDriveFileOutlined, ManageAccountsOutlined, PaymentOutlined, PriceChangeOutlined, SettingsSuggestOutlined, WysiwygOutlined } from "@mui/icons-material";
import './SideNavStyle.css';
// import { BASE_URL, MAINTENANCE_REQUEST } from "../utills/ApplicationRouting";
import axios from "axios";
import { BASE_URL, PAYMENT_DETAILS } from "../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
 import BalanceIcon from '@mui/icons-material/Balance';
 import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
 import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
 import MenuOpenIcon from '@mui/icons-material/MenuOpen';


const SideMenu = ({ onMenuItemClick }) => {
  const [PaymentDetailsData,setPaymentDetailsData] = React.useState([]);
  const [groupId, setGroupId] = React.useState("")
  const { token ,userGroupDetails} = useSelector(state => state.hybridhoa);

  // useEffect(() => {
    // const  tes=()=>{
    //   const persistRoot = JSON.parse(localStorage.getItem("persist:root"));
    //   // console.log(persistRoot);
    //   if (persistRoot && persistRoot.userGroupDetails && typeof persistRoot.userGroupDetails === 'string') {
    //       const userGroupDetailsObject = JSON.parse(persistRoot.userGroupDetails);
    //       // console.log(userGroupDetailsObject);
    //       if (userGroupDetailsObject && userGroupDetailsObject.groupId) {
    //           const groupId = userGroupDetailsObject.groupId;
    //           console.log('groupId:', groupId);
    
    //           setGroupId(groupId);
    //       } else {
    //           console.log('groupId not found in userGroupDetails.');
    //       }
    //   }
    // }
    // tes()
    // }, [groupId]);

  const getPaymentDetails = async () => {
    try {
      const storedToken = token;
      const groupId = userGroupDetails.groupId;
      console.log(groupId,"group id in get ");
      const response = await axios.get(`${BASE_URL}${PAYMENT_DETAILS}/${groupId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      if (response.status === 200) {
        const paymentdata = response.data;
        setPaymentDetailsData(paymentdata)
        console.log("paymentdetails" , paymentdata);   
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(()=>{
    getPaymentDetails();
  },[])

  const isLegalFirstNameEmpty = !PaymentDetailsData?.legalFirstName || PaymentDetailsData.legalFirstName.trim() === '';

  const menuItemdata = [
    {
      navLink: "/",
      name: "Dashboard",
      icon: <DashboardOutlinedIcon />,
      id: "collapse-dashboard",
      childItem: [
        {
          navLink: "/",
          name: "Dashboard",
          icon: <DashboardIcon />
        }
      ],
      isNested: false
    },

    {
      navLink: "#",
      name: "Homes",
      icon: <HomeOutlined />,
      id: "collapse-homes",
      childItem: [
        {
          navLink: "/homes",
          name: "Allocated Homes",
          icon: <AddHomeWorkOutlined />
        },
        {
          navLink: "/homeowners",
          name: "HomeOwners",
          icon: <GroupsOutlined />
        },
        {
          navLink: "/externaluser",
          name: "External Users",
          icon: <GroupsOutlined />
        },
        {
          navLink: "/jobcategory",
          name: "Job-Category",
          icon: <MenuOpenIcon />
        }
      ],
      isNested: true
    },

    {
      navLink: "#",
      name: "Configuration",
      icon: <SettingsSuggestOutlined />,
      id: "collapse-configuration",
      childItem: [
        {
          navLink: "/configuration",
          name: "Group Configuration",
          icon: <SettingsSuggestOutlined />,
        },
        {
          navLink: "/accountconfig",
          name: "Account Config",
          icon: <SettingsSuggestOutlined />,
        },
      ],
      isNested: true,
    },
    // {
    //   navLink: "/configuration",
    //   name: "Group Configuration",
    //   icon: <SettingsSuggestOutlined />,
    // },
    {
      navLink: "#",
      name: "Maintenance",
      icon: <HandymanOutlined />,
      id: "collapse-audit",
      childItem: [
        {
          navLink: "/maintenance",
          name: "Maintenance",
          icon: <HandymanOutlined />,
        },
        {
          navLink: "/auditmr",
          name: "Audit Maintenance",
          icon: <AddHomeWorkOutlined />,
        },
      ],
      isNested: true,
    },
    {
      navLink: "#",
      name: "Compliance",
      icon: <BalanceIcon />,
      id: "collapse-audit",
      childItem: [
        {
          navLink: "/compliance",
          name: "Compliance",
          icon: <BalanceIcon />,
        },
        {
          navLink: "/auditcr",
          name: "Audit Compliance",
          icon: <AddHomeWorkOutlined />,
        },
      ],
      isNested: true,
    },
    {
      navLink: "/bylaw",
      name: "Bylaws",
      icon: <GavelOutlined />,
    },
    {
      navLink: "#",
      name: "Payment",
      icon: <AttachMoneyIcon />,
      id: "collapse-audit",
      childItem: [
        {
          navLink: "/payment",
          name: "Dues",
          icon: <AttachMoneyIcon />,
        },
        {
          navLink: "/offlinepayment",
          name: "Offline Payments",
          icon: <AttachMoneyIcon />,
        },
        {
          navLink: "/auditlist",
          name: "Audit",
          icon: <ExposureOutlined />,
        },
      ],
      isNested: true,
    },
    
    {
      navLink: "/reimbursedue",
      name: "Waived Off Dues",
      icon: <CurrencyExchangeIcon />,
    },
    {
      navLink: "/faq",
      name: "Frequently Asked Questions (FAQ)",
      icon: <HelpOutlineOutlined />,
    },


    // {
    //   navLink: "#",
    //   name: "Audit",
    //   icon: <HomeOutlined />,
    //   id: "collapse-audit",
    //   childItem: [
    //     {
    //       navLink: "/auditcrmr",
    //       name: "Audit CR/MR",
    //       icon: <AddHomeWorkOutlined />
    //     },
    //     {
    //       navLink: "/auditdocument",
    //       name: "Audit Document",
    //       icon: <GroupsOutlined />
    //     },


    //   ],
    //   isNested: true
    // },
    {
      navLink: "#",
      name: "Documents",
      icon: <FolderCopyOutlined />,
      id: "collapse-document",
      childItem: [
        {
          navLink: "/documents",
          name: "Documents",
          icon: <DescriptionOutlined />,
        },
        // {
        //   navLink: "/archiveddocuments",
        //   name: "Documents(archived)",
        //   icon: <InsertDriveFileOutlined />,
        // },
            {
          navLink: "/auditdocument",
          name: "Audit Document",
          icon: <GroupsOutlined />
        },
      ],
      isNested: true,
    },

    {
      navLink: "#",
      name: "Subscription",
      icon: <PriceChangeOutlined />,
      id: "collapse-Subscription",
      childItem: [
        {
          navLink: "/subscription",
          name: "Subscription",
          icon: <PriceChangeOutlined />
        },
        // {
        //   navLink: "/paymentgateway",
        //   name: "Payment Gateway",
        //   icon: <GroupsOutlined/>
        // },
        // {
        //   navLink: "/paymentgatewayadd",
        //   name: "Payment Gateway Add",
        //   icon: <GroupsOutlined />,
        // },
        // {
        //   navLink: "/paym
        
        //   icon: <GroupsOutlined />,
        // },
        {
          navLink: isLegalFirstNameEmpty ? "/paymentgatewayadd" : "/paymentgatewayview",
          name:"Payment Gateway",
          icon: <PaymentOutlined />,
        },

        {
          navLink: "/billing",
          name: "Billing History",
          icon: <WysiwygOutlined />
        },
        

      ],
      isNested: true
    },
    // {
    //   navLink: "/subscription",
    //   name: "Subscription",
    //   icon: <AcUnitOutlined/>,
    // },
  ];

  return (
    <ul class="space-y-1 font-medium ">
      {menuItemdata.map((i) => {
        console.log(i.childItem);
        return (
          !i.isNested ? (
            <SideMenuItem
              navigationLink={i.navLink}
              itemName={i.name}
              icon={i.icon}
            onClick={onMenuItemClick}

            />

          ) :
            <SideMenuDropdown
              childItem={i.childItem}
              id={i.id}
              parrentName={i.name}
              icon={i.icon}
              onClick={onMenuItemClick}

            />


        );
      })}

    </ul>
  );
};

export default SideMenu;
