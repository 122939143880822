import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogActions,
  DialogContent,
  FormHelperText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import CustomHeading from "../components/CustomHeading";
import {
  AMOUNT_EXCEEDED,
  AMOUNT_INVALID_MESSAGE,
  AMOUNT_INVALID_MESSAGES,
  AMOUNT_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REIMBURSE_COMMENT,
} from "../utills/ApplicationConstants";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import CustomAutoComplete from "../components/CustomAutoComplete";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  HO,
  HOMES,
  OFFLINE_PAYMENT,
  OFFLINE_PAYMENT_VIEW,
  REIMBURSED_DUES,
} from "../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../services/AxiosConfig";
import CustomReimbursedAuto from "../components/CustomReimbursedAuto";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AMOUNT_REGEX, ONLY_DIGITS } from "../utills/ApplicationRegex";
import HomesAutoComplete from "../components/Homesautocomplete";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomPhoneInput from "../components/CustomPhoneInput";
import CustomTooltipField from "../components/CustomTooltipField";
import Offlinepaymentauto from "../components/Offlinepaymentauto";
import { setHomeAddress, setTableData } from "../redux/Hybridhoa";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomCellRenderer from "../components/CustomCellRenderer";

function Offlinepayment() {
  const { token, tableData, homeAddress } = useSelector((state) => state.hybridhoa);
  const [selectedRowId, setSelectedRowId] = useState("");
  
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const [totalAmount,setTotalAmount] = useState("");
  const [firstName,setFirstName]=useState("");
  const [lastName,setLastName]=useState("");
  const [email,setEmail]=useState("");
  const [contact,setContact]=useState("");
  const [address,setAddress]=useState("");

 
  const getDataRow = async () => {
    try {
      const storedToken = token;
      setIsLoading(false);

      const response = await axios.get(
        `${BASE_URL}${REIMBURSED_DUES}/${selectedRowId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);
      if (response.status == 200) {
        const data = response.data;
        // const datas = response.data.homeOwnerDetails;
        setIsLoading(false);

        // setType(data);
        console.log(data);
        // setHomeAddressOptions(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {}, [getDataRow]);
 
  useEffect(() => {
    // Set selectedOption to homeAddress if present, otherwise to an empty string
    setValue("selectedOption",homeAddress || null);
  }, [homeAddress]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    reset,
    watch, clearErrors,
  } = useForm();
  
  const dispatch = useDispatch();
  const [homeOwnerDetailsId, setHomeOwnerDetailsId] = useState("");
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dueData, setDueData] = useState(tableData || []);
  const [invoice,setInvoice]=useState("");
  const [errorPayment, setErrorPayment] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [appliedAmount, setAppliedAmount] = useState("");
  const [checkDues, setCheckDues] = useState([]);
  const [dueAmount,setDueAmount] = useState(amount);
  const [isMinimum,setIsMinimum] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event, newValue) => {
    setValue("selectedOption", newValue ? newValue.value : null);
    setSelectedOption(newValue);
  };

  const handleApplyClickOpen = () => {
    console.log(" 150 homeOwnerDetailsId"+homeOwnerDetailsId);
    navigate('/offlinepayments', { state: { selectedRows: selectedRows ,homeOwnerDetailsId: homeOwnerDetailsId} }); // Pass selectedRows to OfflinePaymentForm
  };
  
  const handleSelectionChange = (row) => {
    const selectedIndex = selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    const updatedDues = newSelected.map((selectedRow) => selectedRow.original);
    setCheckDues(updatedDues);
    // Calculate the sum of the 'amount' column from the selected rows
    const totalAmount = newSelected.reduce((total, selectedRow) => {
      // Parse the 'amount' value to integer
      const amount = parseInt(selectedRow.dueAmount);
      // Check if the parsed amount is a valid number
      if (!isNaN(amount)) {
        return total + amount;
      } else {
        return total;
      }
    }, 0);
  
    // Store the new selected rows and total amount in the state
    setSelectedRows(newSelected);
    setTotalAmount(totalAmount);
    setAmount(totalAmount)
    setDueAmount(totalAmount)
  };
  
  const isSelected = (row) => selectedRows.indexOf(row) !== -1;
  
  const [homeAddresses, setHomeAddresses] = useState([]);

  useEffect(()=>{
    if(tableData !== null){
    setDueData(tableData)
    }
  },[tableData])

  const getHomes = async () => {
    try {
        const storedToken = token;
        // setIsLoading(true);

        const persistRoot = JSON.parse(localStorage.getItem("persist:root"));
        const userDetails = JSON.parse(persistRoot?.userDetails);
        const storedHomeOwnerLoginId = userDetails?.homeOwnerLoginId;

        console.log("storedHomeOwnerLoginId", storedHomeOwnerLoginId);

        const response = await axios.get(`${BASE_URL}${HOMES}`, {
            headers: {
                Authorization: `Bearer ${storedToken}`,
            },
        });

        console.log(response.data);

        const data = response.data;
        if (response.status === 200) {
            setIsLoading(false);

            // Filter out entries with null values for specific fields
            const filteredData = data.map(home => {
                // Create a new object without null fields
                const filteredHome = {};
                for (const key in home) {
                    if (home.hasOwnProperty(key)) {
                        // Replace null values with empty strings
                        filteredHome[key] = home[key] !== null ? home[key] : "";
                    }
                }
                return filteredHome;
            });

            setHomeAddresses(filteredData);
        } else {
            throw new Error('Failed to fetch data');
        }

    } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
    }
};

  
  
  
  

  useEffect(() => {
    getHomes();
  }, []);

  const [homeDetails,setHomeDetails]=useState('');
  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homeAddresses.find(
        (home) => home.homeId === selectedHomeId
      );
      console.log(selectedHome);
      if (selectedHome) {
        setHomeOwnerDetailsId(selectedHomeId);
        setFirstName(selectedHome?.firstName);
        setLastName(selectedHome?.lastName);
        setEmail(selectedHome?.email);
        setContact(selectedHome?.contact);
        setAddress(selectedHome?.address);
        setValue("selectedOption", selectedHomeId);
       
      } else {
        setHomeOwnerDetailsId("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setContact("");
        setAddress("");
        setValue("selectedOption", null);
      }
    } else {
      setHomeOwnerDetailsId("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setContact("");
      setAddress("");
      setValue("selectedOption", null);
    }
  };

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
        try {
            setIsLoading(true);
            // Make your API call to fetch data
            const response = await axios.get(
                `${BASE_URL}${OFFLINE_PAYMENT_VIEW}${HO}/${homeOwnerDetailsId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                const data = response.data;
                dispatch(setHomeAddress(homeOwnerDetailsId))
                setDueData(data);
                dispatch(setTableData(data));
                setIsLoading(false);
                setSelectedRows([]);
            } else {
                setIsLoading(false);
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    // Call the fetchData function when the component mounts or when homeOwnerDetailsId changes
    if (homeOwnerDetailsId) {
        fetchData();
    }

}, [homeOwnerDetailsId, token, dispatch]);


  useEffect(() => {
    if (homeAddress) {
      setValue("selectedOption", homeAddress);
      const selectedHome = homeAddresses.find((home) => home.homeId === homeAddress);
      if (selectedHome) {
        setHomeOwnerDetailsId(selectedHome.homeId);
        setFirstName(selectedHome?.firstName);
        setLastName(selectedHome?.lastName);
        setEmail(selectedHome?.email);
        setContact(selectedHome?.contact);
        setAddress(selectedHome?.address);
      }
    }
  }, [homeAddress, homeAddresses, setValue]);

  useEffect(() => {
    const selectedHome = watch('selectedOption');
  
  
      if (!selectedHome || selectedHome === "") {
        setHomeDetails('');
        // setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingHome = homeAddresses.find((home) => home.homeId === selectedHome);
  console.log(matchingHome,"check");
 

        if (matchingHome) {
          
          const { firstName,lastName } = matchingHome;
  setHomeDetails(`${firstName,lastName}`);
          // setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setHomeDetails('');
          // setIsBylawFieldEmpty(true);
        }
      }
    
  }, [watch('selectedOption'), homeDetails]);


  const renderedAddresses = homeAddresses.map((home, index) => {
  
    const parts = [
      home.address,
      home.firstName,
      home.lastName,
      home.email,
      home.contact,
    ].filter(part => part); // Filter out any null or undefined parts
  
    const address = parts.join(', '); // Join the parts with a comma and a space
  
    return {
      address: address,
      value: home?.homeId,
      key: index,
    };
  });

  useEffect(() => {
    // Check if selectedRows length is exactly 1 and both amount and totalAmount are numbers
    if (selectedRows.length === 1 && !isNaN(amount) && !isNaN(totalAmount)) {
      // Check if parsedAmount is different from totalAmount
      if (
        parseFloat(amount).toFixed(1) !== parseFloat(totalAmount).toFixed(1) ||
        parseFloat(amount) !== parseFloat(totalAmount)
      ) {
        setIsMinimum(true); // Set isMinimum to true if amounts are not equal
      } else {
        setIsMinimum(false); // Set isMinimum to false if amounts are equal
      }
    }
  }, [amount, totalAmount, selectedRows]);

  const handleRowClick = (selectedRow) => {
  console.log(selectedRow.row)
    navigate(`/viewofflinepayment/${selectedRow.row.original.homeOwnerDuesId}`);
  };
 
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  // const filteredData = dueData.filter((row) => row.status === "PENDING");
 
  

  const reimburseDueTable = useMemo((rowData) => [
    // {
    //   accessorKey: "serialNo",
    //   header: "Id",
    //   size: 2,
    //   Cell: ({ row }) => row.index + 1,
    // },
    {
      accessorKey: "title",
      header: "Title",
    },
    {
      accessorKey: "dueType",
      header: "Due Type",
      accessorFn: (row) => convertToPascalCase(row.dueType),
    },
    {
      accessorKey: "dueAmount",
      header: "Amount",
    },
    {
      accessorKey: "status",
      header: "Status",
      accessorFn: (row) => convertToPascalCase(row.status),
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
      accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY"),
    },
  ]);

  const columnss = useMemo(
    () =>
      reimburseDueTable.map((column) => ({
        ...column,
        Cell: ({ cell }) => 
          column.accessorKey === "description" || column.accessorKey === "title" ? (
            <CustomCellRenderer cell={cell} />
          ) : (
            cell.getValue()
          ),
      })),
    [reimburseDueTable]
  );
  ;


  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Offline Payment" />
      
        {isLoading && <CustomLoading />}

      </div>
      <Card>
        <CardContent>
          <form>
          <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              
                <Offlinepaymentauto
              fieldName="selectedOption"
              requiredErrorMessage = {HOME_ADDRESS_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedAddresses}
              fieldLabel="Search By (Address,Firstname,Lastname,Email,Contact)"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*" 
              onChange={handleHomeChange}
              invalidOption= "Please select valid home address"
              tooltipMessage={"You can search by Address,Firstname,Lastname,Email,Contact it shows description below"}
               />
              
                {/* <div >
              <CustomButton fieldType="submit" buttonName="Search" click={handleSubmit(onSubmits)} />
            </div> */}
            </section>
           
          </form>
        <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Home Owner Information
            </AccordionSummary>
            <AccordionDetails>
              <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <div className="flex grid grid-cols-1">
               
              <CustomTooltipField
  fieldLabel="First Name"
  fieldName="firstName"
  value={firstName}
  fieldType="text"
  disabled={true}
  fieldControl={control}
  tooltipMessage={"The first name of the home owner."}
/>


<CustomTooltipField
  fieldLabel="Last Name"
  fieldName="lastName"
  value={lastName}
  fieldType="text"
  disabled={true}
  fieldControl={control}
  tooltipMessage={"The last name of the home owner."}
/>

</div>
<CustomTooltipField
  fieldLabel="Email"
  fieldName="email"
  value={email}
  fieldType="text"
  disabled={true}
  fieldControl={control}
  tooltipMessage={"The email address of the home owner."}
/>
<CustomPhoneInput
  label="Contact"
  fieldName="contact"
  value={contact}
  disabled={true}
  fieldControl={control}
  tooltipMessage={"The contact number of the home owner."}
  isRequiredIcon={false}
/>
<CustomTooltipField
  fieldLabel="Home Address"
  fieldName="address"
  value={address}
  fieldType="text"
  disabled={true}
  fieldControl={control}
  tooltipMessage={"The home address of the home owner."}
/>

              </section>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
   
     
      <br />
  


      <MaterialReactTable
  columns={[
    // Actions column including checkbox
    { 
      id: 'actions', 
      Header: 'Actions', 
      accessor: 'actions', 
      Cell: ({ row }) => (
        // Render checkbox only for rows with status "Pending"
        row.original.status === 'PENDING' && (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip title="Select" style={{ cursor: "pointer" }}>
              <Checkbox 
                checked={isSelected(row.original)} 
                onChange={(event) => {
                  event.stopPropagation(); 
                  handleSelectionChange(row.original);
                }}  
              />
            </Tooltip>
          </Box>
        )
      ) 
    },
    // ...reimburseDueTable,
    ...columnss // your other columns
  ]}
  data={dueData}
  enableColumnOrdering
  positionActionsColumn="none" // No action column
  enableSorting={false}
  enableColumnPinning
  enableRowActions={false} // No row actions
  isRowSelectable={(row) => true} // Make all rows selectable
  selectedRows={selectedRows}
  onSelectionChange={setSelectedRows}
  muiTableBodyRowProps={(row) => ({
    onClick: (event) => {
      // Check if the event target is not a checkbox
      if (event.target.type !== 'checkbox') {
        handleRowClick(row);
      }
    },
    sx: {
      cursor: 'pointer',
      // Reduce the row spacing
      padding: '4px 8px !important',
    },
  })}
  renderTopToolbarCustomActions={() => (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        padding: '8px',
        flexWrap: 'wrap',
      }}
    >
      <Button
        variant="contained"
        // export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
        onClick={handleApplyClickOpen}
        // startIcon={<FileDownloadIcon />}
        disabled={selectedRows.length === 0}
      >
        Payment
      </Button>
    </Box>
  )}
  // Set default page size to 5
  initialState={{
    pagination: {
      pageSize: 5,
    },
  }}
  // Optional: You might want to disable the ability to change page size
  enablePageSizeOptions={false}
  // Optional: You can also set the initial page index
  initialPageIndex={0}
/>





    </div>
  );
}

export default Offlinepayment;
