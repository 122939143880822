import { FileDownload, ListAlt } from "@mui/icons-material";
import UploadIcon from '@mui/icons-material/Upload';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomButton from "../components/CustomButton";
import { Controller, useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import {
  FROM_DATE_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  TYPE_REQUIRED_MESSAGE,
} from "../utills/ApplicationConstants";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL, REPORT_CR_MR } from "../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../services/AxiosConfig";
import { useEffect } from "react";
function Audit_Cr_Mr() {
  const [type, setType] = useState("");
  const { token } = useSelector((state) => state.hybridhoa);
  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const minDate = dayjs().subtract(3, "year").startOf("day");
  const maxDate = dayjs().endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedType, setSelectedType] = useState("ALL");
  const handleFromDateChange = (selectedFromDate) => {
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
  };
  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);
  };
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // Retrieve table data from sessionStorage when component mounts
    const storedData = sessionStorage.getItem("auditCrMrTableData");
    if (storedData) {
      setTableData(JSON.parse(storedData));
    }
  }, []);

  const onSubmit = (data) =>{
    setIsLoading(true);
    if (!data.fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!data.toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    const postData ={
      from:dayjs(fromDate).format("YYYY-MM-DD"),
      to : dayjs(toDate).format("YYYY-MM-DD"),
      type : type
    }
    request({url:REPORT_CR_MR, method:"post", data:postData}).then(res=>{
      // setIsLoading(false); 
      console.log(res);
      if(res.status==200){
        setTableData(res.data.data);
        setIsLoading(false); 
        sessionStorage.setItem("auditCrMrTableData", JSON.stringify(res.data.data));
    }
    }).catch(error=>{
      console.log(error.res);
      setIsLoading(false); 
      if (error.res) {
     
        console.log("error.response.status" + error.res.status);
       
    
      }  
    });


  }
  // const onSubmit = async (event) => {
  //   event.preventDefault();

  //   const fromDates = dayjs(fromDate);
  //   const toDates = dayjs(toDate);

  //   if (!fromDates.isValid() || !toDates.isValid()) {
  //     console.error("Invalid fromDate or toDate");
  //     return;
  //   }
  //   if (toDates.isBefore(fromDates)) {
  //     alert(TO_DATE_ERROR_MESSAGE);
  //     return;
  //   }
  //   const payload = {
  //     from: fromDates.format("YYYY-MM-DD"),
  //     to: toDates.format("YYYY-MM-DD"),
  //     type: type,
  //   };

  //   try {
  //     const response = await axios.post(`${BASE_URL}${REPORT_CR_MR}`, payload, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         // 'Content-Type':'multipart/form-data'
  //       },
  //     });

  //     console.log(response.data);

  //     if (response.status === 200) {
  //       // Set the data in the state variable
  //       setTableData(response.data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  


  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const navigate = useNavigate();
  // const handleViewClick = (selectedRow) => {
  //   navigate(`/viewmaintenance/${selectedRow.original.maintenanceId}`);
  // };

  const handleViewClick = (selectedRow) => {
    navigate(`/maintenance/${selectedRow.original.maintenanceId}`);
    console.log(selectedRow.original.maintenanceId);
  };
  const handleCrViewClick = (selectedRow) =>{
    navigate(`/auditcr/${selectedRow.original.complainceId}`);
console.log(selectedRow.original.complainceId);
  }
  const auditCrMrTable = useMemo((rowData, index) => [

    
     {
      accessorKey: "createdBy",
      header: "Created By",
      Cell: ({ row }) =>
      `${row.original.createdByDetails.firstName} ${row.original.createdByDetails.lastName}`,
    },
    {
      accessorKey: "type",
      header: "Type",
      Cell: ({ row }) => {
        const crId = row.original.complainceId ;
        const mrId = row.original.maintenanceId ;
  
        return (
          <span>
            {type === "ALL"
              ? `${crId ? "Compliance Request" : ""} ${mrId ? "Maintenance Request" : ""}`.trim()
              : type === "CR" && crId
              ? "Compliance Request"
              : type === "MR" && mrId
              ? "Maintenance Request"
              : ""}
          </span>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
    },

    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      size: 20,
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "auditDateAndTime",
      header: "Audit Date",
      size: 20,
      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY"),
    },
  ],[type]);
  const rowActions = (row) => {
    console.log(row.original.complainceId);
    const actions =[];
    
    if (row.original.maintenanceId) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleViewClick(row),
      });
    }
    if (row.original.complainceId) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleCrViewClick(row),
      });
    }
    return actions;
  };
  // const rowActions = [
  //   {
  //     label: "View",
  //     icon: <ListAlt color="primary" />,
  //     click: (row) => handleViewClick(row),
  //   },
  // ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const filteredData = tableData.map(record => ({
      CreatedBy: record.createdByDetails?.firstName ?? '',
    
      Status: record.status ?? '',

      createdDate:record.createdDateAndTime ?? '',
      AuditDate:record.auditDateAndTime ?? '',
     
    }));
    const csv = generateCsv(csvConfig)(filteredData);
    download(csvConfig)(csv);
  };
  const tableActionButtons = (
    <>
      {tableData && tableData.length > 0 && (
        <Button
          color="primary"
          variant="contained"
          onClick={handleExportData}
          startIcon={<UploadIcon  />}
        >
          <span className={CustomStyle.containedButtonStyle}>
            Export 
          </span>
        </Button>
      )}
    </>
  );
        
  return (
    <div>
      <CustomHeading title="Audit" />
      <Card>
        <CardContent>
          <form  onSubmit={handleSubmit(onSubmit)}>
            <section class="grid md:grid-cols-3 xl:grid-cols-3 gap-5">
              <CustomDatePicker
                fieldName="fromDate"
                label="From Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => handleFromDateChange(date)}
              />

              <CustomDatePicker
                fieldName="toDate"
                label="To Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                onChange={handleToDateChange}
              />

              <Controller
                name="dueFrequency"
                control={control}
                rules={{ required: TYPE_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                     Please select type  <span style={{color:'red'}}> * </span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                        field.onChange(e.target.value);
                      }}
                      label="Select type"
                    >
                      <MenuItem value="ALL">All</MenuItem>
                      <MenuItem value="CR">Compliance Request</MenuItem>
                      <MenuItem value="MR">Maintenance Request</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </section>
            <div class="text-left mt-10">
              <CustomButton fieldType="submit" onClick={handleSubmit(onSubmit)}  buttonName="Search" />
            </div>
          </form>
        </CardContent>
      </Card>
      <br />
      <br />

      <CustomMaterialTable
        columns={auditCrMrTable}
        data={tableData}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Audit_Cr_Mr;
