import React, { useEffect, useState } from "react";
import CustomHeading from "../components/CustomHeading";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Card, CardContent, FormHelperText } from "@mui/material";
import {
  CONTACT_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  EXPENSE_EMAIL_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_ONE_CHARACTERS,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  PURCHASE_EMAIL_REQUIRED_MESSAGE,
  SALES_EMAIL_REQUIRED_MESSAGE,
  SUPPLIERS_EMAIL_REQUIRED_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  EMAIL_REGEX_PATTERN,
  EMAIL_REGEX_PATTERNS,
  GROUP_ADMIN_EMAIL_REGEX_PATTERN,
  ONLY_ALPHABETIC,
} from "../utills/ApplicationRegex";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomInputField from "../components/CustomInputField";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  ACCOUNT_CONFIG,
  BASE_URL,
  PROFILE,
} from "../utills/ApplicationRouting";
import CustomLoading from "../components/CustomLoading";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomPhoneInput from "../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomTooltipField from "../components/CustomTooltipField";

function AccountConfig() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.hybridhoa);
  const [expenseMail, setExpenseMail] = useState("");
  const [purchaseMail, setPurchaseMail] = useState("");
  const [salesMail, setSalesMail] = useState("");
  const [supliersMail, setSuppliersMail] = useState("");
  const [accountsId, setAccountsId] = useState("");

  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState,
    setValue,
    formState: { errors },
  } = useForm();
  console.log("first", errors);
  const handleInputChange = (e) => {
    setPurchaseMail(e.target.value.trim());
  };
  const handleSalesMail = (e) => {
    setSalesMail(e.target.value.trim());
  };
  const handleSuppliers = (e) => {
    setSuppliersMail(e.target.value.trim());
  };
  const handleExpenseMail = (e) => {
    setExpenseMail(e.target.value.trim());
  };

  const getAccountDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${ACCOUNT_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("data", data.groupAccountsId);
        setPurchaseMail(data.purchaseMail);
        setSuppliersMail(data.supplierMail);
        setSalesMail(data.salesMail);
        setExpenseMail(data.expenseMail);
        setAccountsId(data.groupId);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    if (!purchaseMail||purchaseMail.trim()==="") {
      setError("purchaseMail", {
        type: "manual",
        message: PURCHASE_EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!EMAIL_REGEX_PATTERNS.test(purchaseMail)) {
      setError("purchaseMail", {
        type: "manual",
        message: EMAIL_INVALID_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!salesMail) {
      setError("salesMail", {
        type: "manual",
        message: SALES_EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!EMAIL_REGEX_PATTERNS.test(salesMail)) {
      setError("salesMail", {
        type: "manual",
        message: EMAIL_INVALID_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!supliersMail) {
      setError("supplierMail", {
        type: "manual",
        message: SUPPLIERS_EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!EMAIL_REGEX_PATTERNS.test(supliersMail)) {
      setError("supplierMail", {
        type: "manual",
        message: EMAIL_INVALID_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!expenseMail) {
      setError("expenseMail", {
        type: "manual",
        message: EXPENSE_EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!EMAIL_REGEX_PATTERNS.test(expenseMail)) {
      setError("expenseMail", {
        type: "manual",
        message: EMAIL_INVALID_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    const postData = {
      purchaseMail: purchaseMail.trim(),
      salesMail: salesMail.trim(),
      supplierMail: supliersMail.trim(),
      expenseMail: expenseMail.trim(),
    };

    
    request({
      url: `${ACCOUNT_CONFIG}/${accountsId}`,
      method: "put",
      data: postData,
    })
      .then((res) => {
        console.log(res);

        if (res.status == 200) {
          getAccountDetails();

          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />

        <CustomHeading title="Account Configuration" />
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          {isLoading && <CustomLoading />}
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputField
          
                fieldName="purchaseMail"
                fieldId="purchaseMail"
                fieldType="text"
                fieldLabel="Purchase Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={purchaseMail}
                onInput={handleInputChange}
                tooltipMessage={
                  "This purchase Email reflecting in your audit"
                }
              />
              <CustomInputField
               
                fieldName="salesMail"
                fieldId="salesMail"
                fieldType="text"
                fieldLabel="Sales Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={salesMail}
                onInput={handleSalesMail}
                tooltipMessage={
                  "This sales Email reflecting in your audit"
                }
              />
              <CustomInputField
       
                fieldName="supplierMail"
                fieldId="supplierMail"
                fieldType="text"
                fieldLabel="Supplier Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={supliersMail}
                onInput={handleSuppliers}
                tooltipMessage={
                  "This supplier Email reflecting in your audit"
                }
              />
              <CustomInputField

                fieldName="expenseMail"
                fieldId="expenseMail"
                fieldType="text"
                fieldLabel="Expense Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={expenseMail}
                onInput={handleExpenseMail}
                tooltipMessage={
                  "This expense Email reflecting in your audit"
                }
              />
            </section>
            <br />
            <br />

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default AccountConfig;
