import React, { useEffect, useState } from "react";
import CustomLayout from "../components/CustomLayout";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { Doughnut, Pie } from "react-chartjs-2";
import "chart.js/auto";
import Draggable from "react-draggable";
import "../index.css";
import CardLayout from "../components/dashboard/CardLayout";
import ChartLayout from "../components/dashboard/ChartLayout";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import logo from "../assets/img/logo.png";
import { BASE_URL, CHART, DASHBOARD } from "../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyIcon from '@mui/icons-material/Money';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BalanceIcon from '@mui/icons-material/Balance';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Approval, AttachMoneyOutlined, HandymanOutlined } from "@mui/icons-material";
import CustomLoading from "../components/CustomLoading";
import DvrIcon from '@mui/icons-material/Dvr';
import axios from "axios";

import moment from "moment";


import { setTrail } from "../redux/Hybridhoa";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState({});
  const [chartData, setChartData] = useState({});
const [startDate,setStartDate]=useState('')
const [endDate,setEndDate]=useState('')
const dispatch = useDispatch();
  const getGroupPortalDashboardData = async () => {
    try {
      const storedToken = token;

      // Fetch dashboard data
      const response1 = await axios.get(`${BASE_URL}${DASHBOARD}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response1.status === 200) {
        dispatch(setTrail(response1.data.trial));
        console.log("trail check",response1.data.trial);
        

        setDatas(response1.data);
        const data=response1.data
        const startDateString = data.startDate;

        console.log("original startDateString",startDateString);

        // const startDate = new Date(startDateString + ' UTC');
console.log("original startDatesss",startDateString);
        // Format the date to YYYY-MM-DD
        const formattedDate = startDateString.split('T')[0];
        // Set the formatted date
        setStartDate(formattedDate);
        const endDateString = data.endDate;
        // const endDates = new Date(endDateString + ' UTC');
        // const formattedEndDate = endDates.toISOString().split('T')[0];
        const formattedEndDate = endDateString.split('T')[0];
setEndDate(formattedEndDate)
console.log("startdate",formattedEndDate);
      } else {
        throw new Error("Failed to fetch dashboard data");
      }

      // Fetch chart data
      const response2 = await axios.get(`${BASE_URL}${DASHBOARD}${CHART}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response2.status === 200) {
        setChartData(response2.data);
      } else {
        throw new Error("Failed to fetch chart data");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getGroupPortalDashboardData();
}, [token]);

const chartDatas= ([
  {
    title: "Maintenance",
    tooltip: "Maintenance Request Status",
    card: [
      {
        count: chartData.maintenancePendingCount,
        title: "Pending",
        color: ["#FFA07A"], // Change to dodger blue: #1E90FF
      },
      {
        count: chartData.maintenanceApprovedCount,
        title: "Approved",
        color: ["#00CED1"],
      },
      {
        count: chartData.maintenanceAckCount,
        title: "Acknowledge",
        color: ["#6495ED"],
      },
      
      {
        count:  chartData.maintenancePendingApprovedCount,
        title: "Pending Approval",
        color: ["#00CED1"],
      },
      {
        count:  chartData.maintenanceRejectedCount,
        title: "Rejected",
        color: ["#00CED1"],
      },
      
    
    ],
    data: {
      labels: ["Approved", "Pending", "Acknowledge", "Pending Approval", "Rejected"],
      datasets: [
        {
          data: [
            chartData.maintenanceApprovedCount,
            chartData.maintenancePendingCount,
            chartData.maintenanceAckCount,
            chartData.maintenancePendingApprovedCount,
            chartData.maintenanceRejectedCount,
          ],
          backgroundColor: ["#9cd96c", "#1e90ff", "#6cd9a9", "#c970db", "#ff4da6"],
          hoverBackgroundColor: ["#74c533", "#006ad1", "#33c585", "#b233cb", "#ff0080"],
        },
      ],
    },
  },
  {
    title: "Compliance",
    tooltip: "HO Compliance Request Status",
    card: [
      {
        count: chartData.complaincePendingCount,
        title: "Pending",
        color: ["#87CEEB"],
      },
      {
        count: chartData.complainceAckCount,
        title: "Acknowledge",
        color: ["#20B2AA"],
      },
      {
        count: chartData.complainceResolvedCount,
        title: "Resolved",
        color: ["#1E90FF"], // Change to dodger blue: #1E90FF
      },
      {
        count: chartData.complainceClosedCount,
        title: "Closed",
        color: ["#00a5b3"], 
      },
      {
        count:chartData.complainceNotResolvedCount,
        title:"Not Resolved",
        color:["#00bfff"]
      }
    ],
    data: {
      labels: ["Pending", "Acknowledge", "Resolved", "Closed","Not Resolved"],
      datasets: [
        {
          data: [chartData.complaincePendingCount, chartData.complainceAckCount, chartData.complainceResolvedCount,chartData.complainceClosedCount,chartData.complainceNotResolvedCount,],
          backgroundColor: ["#ff794d", "#9cd96c", "#6cd9a9", "#00a5b3","#87cefa"],
          hoverBackgroundColor: ["#ff531a", "#74c533", "#33c585", "#007680","#00bfff"],
        },
      ],
    },
  },
]);



const cardDatas=([
  {
    count: `${datas.totalAssignedHomeCount}/${datas.totalHomeCount}`,
    name: "Allocated Homes/Total Homes",
    icon: <ApartmentIcon />,
    color: ["#bbddff"], //blue
    linkTo:"/homes",
    tooltip: "Click to view the list of homes"
  },
 
  {
    startDate:`Start Date: ${startDate ? moment(startDate).format("MM/DD/YYYY") : " "}`,
    endDate:`End Date     : ${endDate ? moment(endDate).format("MM/DD/YYYY") : " "}`,
    name: "Subscription Details",
    icon: <ShoppingCartCheckoutIcon />,
    color: ["#fee2e2"],
    linkTo:"/subscription",
    tooltip: "Click to view the subscription details."
  },
  {
    count: datas.maintenance,
    name: "Maintenance Requests",
    icon: <HandymanOutlined />,
    color: ["#bbddff"],
    linkTo: "/maintenance",
    tooltip: "Click to view the list of maintenance requests."
  },
  {
    count: datas.complaince,
    name: "Compliance Requests",
    icon: <DvrIcon />,
    color: ["#c2f6c2"],
    linkTo: "/compliance",
    tooltip: "Click to view the list of compliance requests."
  },
  {
    count: datas.totalOutstandingDues,
    name: "Total Outstanding Dues",
    icon: <MonetizationOnIcon />,
    color: "#ffffcc",
    // linkTo:"/auditlist",
    // tooltip:"Click to view the auditlist"
  },
  {
    count: datas.totalPaidDues,
    name: "Total Dues Received",
    icon: <CurrencyExchangeIcon  />,
    color: "#ffd8eb",
    // linkTo:"/auditlist",
    // tooltip:"Click to view the auditlist"
  },
]);

  if (isLoading) {
    return <CustomLoading />;
  }

  const chartOptions = {
    responsive: true,
  };

  return (
    <>
      <CustomHeading title="Dashboard" />
      <br />
      <CardLayout cardData={cardDatas} />
      <br />
      <br />
      <ChartLayout chartData={chartDatas} chartOptions={chartOptions} />
      <div class="fixed bottom-0 right-0 mr-24">
        <a href="https://hybridhoa.com" target="blank">
          <img src={logo} width={100} height={100} />
        </a>
      </div>
    </>
  );
};

export default Dashboard;
