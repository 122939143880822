import React, { useEffect, useRef, useState } from 'react';
import { Button, Typography, CircularProgress } from '@mui/material'; // Add CircularProgress for the loading spinner
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CustomStyle } from './CustomStyle';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, GROUP_CONFIG } from '../utills/ApplicationRouting';
import { useSelector } from 'react-redux';
import moment from 'moment';
import logo from "../assets/img/logo.png";  // Adjust path if necessary

function CustomViewHeading({ title, onClick, children, data, fileName, fileList, description, attention, information }) {
  const [rendered, setRendered] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [groupName, setGroupName] = useState(" ");
  const { token } = useSelector((state) => state.hybridhoa);
  const cardRef = useRef(null);
  const buttonsRef = useRef(null);

  useEffect(() => {
    const waitForRender = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setRendered(true);
    };
    waitForRender();
    window.scrollTo(0, 0);
  }, [title]);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const getGroupConfigurationDetails = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        const nameGroup = data.find((item) => item.topic === 'Group Name');
        setGroupName(nameGroup.content || "");  // Set group name
      } else {
        console.error("Failed to fetch data. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGroupConfigurationDetails();
  }, []);

  
   // Compress the image before adding it to the PDF and ensure the background is white
const compressImage = (img) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Set desired width and height for compression
  const width = 150;
  const scale = width / img.width;
  const height = img.height * scale;
  canvas.width = width;
  canvas.height = height;

  // Set a white background
  context.fillStyle = 'white';
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw the image on top of the white background
  context.drawImage(img, 0, 0, width, height);

  // Convert the canvas to a compressed base64 image with lower quality
  return canvas.toDataURL('image/jpeg', 0.5);
};


const generatePDF = () => {
  const doc = new jsPDF({ compress: true });
  let yPosition = 10; // Starting Y position for the text
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 10; // Set margin on both sides
  const maxWidth = pageWidth - margin * 2; // Available width for text
  const lineHeight = 10;
  const itemSpacing = 10; // Fixed spacing between each item
  let descriptionData = ''; // Store description separately
  let whatsneedattentionData='';
  let moreInformation='';
  try {
    const newTab = window.open('', '_blank');
    newTab.document.write(`
      <html>
        <head>
          <title>Generating PDF...</title>
          <style>
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              font-family: Arial, sans-serif;
              font-size: 24px;
            }
          </style>
        </head>
        <body>
          <div>Loading, please wait...</div>
        </body>
      </html>
    `);

    const currentDate = moment().format("MM/DD/YYYY");
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');

    const img = new Image();
    img.src = logo;

    img.onload = function () {
      const compressedImage = compressImage(img);
      doc.addImage(compressedImage, 'JPEG', 10, yPosition, 30, 15);
      yPosition += 20;

      const groupNameText = `Group Name: ${groupName}`;
      const groupNameTextWidth = doc.getTextWidth(groupNameText);
      doc.text(groupNameText, pageWidth - groupNameTextWidth - 10, yPosition - 10);
      
      const dateText = `Generated on: ${currentDate}`;
      const dateTextWidth = doc.getTextWidth(dateText);
      doc.text(dateText, pageWidth - dateTextWidth - 10, yPosition);
      yPosition += 10;

      doc.setLineWidth(0.5);
      doc.line(10, yPosition, pageWidth - 10, yPosition);
      yPosition += lineHeight;

      const processedData = data ; 

      console.log("processedData",data)
      // Process the rest of the data, skipping the description for now
      processedData.forEach(group => {
        if (group.groupName && group.groupName !== 'AttachmentDetails') {
          doc.setFontSize(12);
          doc.setFont('helvetica', 'bold');
          doc.text(group.groupName, 10, yPosition);
          yPosition += lineHeight;
      
          group.items.forEach(item => {
            // Skip rendering the 'Description' on the front page
            if (item.label && !item.label.includes('AttachmentDetails') &&!item.label.includes('Attachments') && item.label !== 'Description' && item.label !== 'What needs attention?' && item.label !== 'More Information') {
        
              const label = item.label ? `${item.label}: ` : '';
              const itemData = item.data || '';
      
              // Render the label in bold
              doc.setFont('helvetica', 'bold');
              const labelWidth = doc.getTextWidth(label);
              doc.text(label, 10, yPosition);
      
              // Render the data in normal font next to the label
              doc.setFont('helvetica', 'normal');
              const wrappedData = doc.splitTextToSize(itemData, maxWidth - labelWidth);
      
              wrappedData.forEach((line, index) => {
                // Check if adding this line exceeds the page height
                if (yPosition + lineHeight > pageHeight - margin) {
                  doc.addPage(); // Add a new page if necessary
                  yPosition = 10; // Reset yPosition for the new page
                }
                if (index === 0) {
                  // Place the first line of the data next to the label
                  doc.text(line, 10 + labelWidth, yPosition);
                } else {
                  // Subsequent lines go below the label, indented
                  doc.text(line, 10, yPosition);
                }
                yPosition += lineHeight;
              });
            } else if (item.label === 'Description' || item.label === 'What needs attention?' || item.label === 'More Information') {
              // Save the description for later
              descriptionData = description ? description : '';
              whatsneedattentionData=attention ? attention : '';
              moreInformation=information ?information :'';
            }
          });
          yPosition += lineHeight;
        }
      });
      
      // Process "Attachment Details" at the end
      doc.setFontSize(16);
      doc.setFont('helvetica', 'bold');
      doc.text('Attachment Details', 10, yPosition);
      yPosition += lineHeight;

      if (fileList && fileList.length > 0) {
        fileList.forEach(file => {
          doc.setFontSize(12);
          doc.setFont('helvetica', 'normal');
          if (yPosition + lineHeight > pageHeight - margin) {
            doc.addPage(); // Add a new page if necessary
            yPosition = 10; // Reset yPosition for the new page
          }
          doc.text(`Attachment: ${file.docname}`, 10, yPosition);
          yPosition += itemSpacing;
        });
      } else {
        if (yPosition + lineHeight > pageHeight - margin) {
          doc.addPage(); // Add a new page if necessary
          yPosition = 10; // Reset yPosition for the new page
        }
        
        // Set font to normal before writing the text
        doc.setFont('helvetica', 'normal'); // Set to normal font style
        doc.setFontSize(12); // Adjust font size if necessary
        doc.text('No attachments available.', 10, yPosition);
        yPosition += itemSpacing;
      }

      // Now add the description on the last page
      if (descriptionData) {
        doc.addPage(); // Add a new page for the description
        yPosition = 10; // Reset yPosition for the new page
        
        // Set font for the heading
        doc.setFontSize(16); // Adjust size if necessary
        doc.setFont('helvetica', 'bold'); // Bold font for the heading
        doc.text('Description', 10, yPosition);
        yPosition += lineHeight;
    
        // Set font for the description text
        doc.setFontSize(12); // Set size for description text
        doc.setFont('helvetica', 'normal'); // Normal font for the description
        const wrappedDescription = doc.splitTextToSize(descriptionData, maxWidth);
        wrappedDescription.forEach((line) => {
          if (yPosition + lineHeight > pageHeight - margin) {
            doc.addPage(); // Add a new page if necessary
            yPosition = 10; // Reset yPosition for the new page
          }
          
          doc.text(line, 10, yPosition);
          yPosition += lineHeight;
        });
      }

      

      if (whatsneedattentionData) {
        doc.addPage(); // Add a new page for the "What needs attention?" section
        yPosition = 10; // Reset yPosition for the new page
        
        // Set font for the heading
        doc.setFontSize(16); // Adjust size if necessary
        doc.setFont('helvetica', 'bold'); // Bold font for the heading
        doc.text('What needs attention?', 10, yPosition);
        yPosition += lineHeight;
    
        // Set font for the description text
        doc.setFontSize(12); // Set size for description text
        doc.setFont('helvetica', 'normal'); // Normal font for the description
        const wrappedDescription = doc.splitTextToSize(whatsneedattentionData, maxWidth);
        wrappedDescription.forEach((line) => {
            if (yPosition + lineHeight > pageHeight - margin) {
                doc.addPage(); // Add a new page if necessary
                yPosition = 10; // Reset yPosition for the new page
            }
    
            doc.text(line, 10, yPosition);
            yPosition += lineHeight;
        });
    }
    
    if (moreInformation) {
      doc.addPage(); // Add a new page for the description
      yPosition = 10; // Reset yPosition for the new page
      
      // Set font for the heading
      doc.setFontSize(16); // Adjust size if necessary
      doc.setFont('helvetica', 'bold'); // Bold font for the heading
      doc.text('More Information', 10, yPosition);
      yPosition += lineHeight;
  
      // Set font for the description text
      doc.setFontSize(12); // Set size for description text
      doc.setFont('helvetica', 'normal'); // Normal font for the description
      const wrappedDescription = doc.splitTextToSize(moreInformation, maxWidth);
      wrappedDescription.forEach((line) => {
        if (yPosition + lineHeight > pageHeight - margin) {
          doc.addPage(); // Add a new page if necessary
          yPosition = 10; // Reset yPosition for the new page
        }
        
        doc.text(line, 10, yPosition);
        yPosition += lineHeight;
      });
    }

      // Generate Blob and create Blob URL
      const pdfBlob = doc.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      newTab.location.href = pdfBlobUrl;
    };

    img.onerror = function () {
      console.error('Error loading the logo.');
    };

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};







  return (
    <div ref={cardRef} className="pdf-container">
      <div ref={buttonsRef} className="md:full md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
        {!exporting && (
          <>
            <Button variant="contained" onClick={onClick} className="export-button">
              <span className={CustomStyle.containedButtonStyle}>Back</span>
            </Button>
            <Button variant="contained" onClick={generatePDF} className="export-button" >
              <span className={CustomStyle.containedButtonStyle}>Export PDF
              </span>
            </Button>
          </>
        )}
      </div>
      <div className="flex flex-col md:flex-row">
        <Typography>
          <span className="font-bold text-lg">
            {title}
          </span>
        </Typography>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

export default CustomViewHeading;
