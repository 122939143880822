import {
    Delete,
    DeleteOutline,
    Edit,
    EditNoteOutlined,
    ListAlt,
} from "@mui/icons-material";
import {
    DialogActions,
    DialogContent,
    InputLabel,
    MenuItem,
    Typography,
    Select,
    FormControl,
    FormHelperText,
    Autocomplete,
    TextField,
    Tooltip, Card, CardContent, IconButton,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import { download, generateCsv, mkConfig } from "export-to-csv";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import {
    EMAIL_INVALID_MESSAGE,
    EMAIL_REQUIRED_MESSAGE,
    EXTERNAL_ROLE_REQUIRED_MESSAGE,
    FIRST_NAME_REQUIRED_MESSAGE,
    FIRST_NAME_INVALID_MESSAGE,
    LAST_NAME_INVALID_MESSAGE,
    LAST_NAME_REQUIRED_MESSAGE,
    MIN_LENGTH_VALIDATE_MESSAGE,
    MAX_LENGTH_VALIDATE_MESSAGE,
    CONTACT_REQUIRED_MESSAGE,
    PHONE_REQUIRED_MESSAGE,
    HOME_ADDRESS_REQUIRED_MESSAGE,
    JOB_CATEGORY_INVAILD_MESSAGE,
    JOB_CATEGORY_REQUIRED_MESSAGE,
    MIN_LENGTH_ONE_CHARACTERS, FAX_INVALID_MESSAGE,MIN_LENGTH_ONE_CHARACTERS_FOR_ALL,
    MAX_LENGTH_VALIDATE_MESSAGE_TEN
} from "../utills/ApplicationConstants";
import {
    EMAIL_REGEX_PATTERN,
    ONLY_ALPHABETIC, FAX_REGEX_PATTERN,EMAIL_REGEX_PATTERNS,
} from "../utills/ApplicationRegex";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomAutoComplete from "../components/CustomAutoComplete";
import { useSelector } from "react-redux";
import axios from "axios";
import {
    BASE_URL,
    EXTERNAL_USER,
    JOB_CATEGORY,
} from "../utills/ApplicationRouting";
import CustomToastContainer from "../components/CustomToastContainer";
import { request } from "../services/AxiosConfig";
import CustomPhoneInput from "../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomLoading from "../components/CustomLoading";

function AddExternalUser() {
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [userDetails, setUserDetails] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [externalUserDetails, setExternalUserDetails] = useState([]);
    const [occupationDetails, setOccupationDetails] = useState("");
    const [occupationId, setOccupationId] = useState("");
    const { token } = useSelector((state) => state.hybridhoa);
    const [type, setType] = useState("");
   
    const [homeOwnerLoginId, setHomeOwnerLoginId] = useState("");
    const [homeOwnerLoginEditId, setHomeOwnerLoginEditId] = useState("");

    const [jobCategoryId, setJobCategoryId] = useState(null);
    const handleDeleteClickOpen = (homeOwnerLoginId) => {
        setHomeOwnerLoginId(homeOwnerLoginId);
        console.log(homeOwnerLoginId);
        setDeleteOpen(true);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const [age, setAge] = React.useState("");
    const [jobCategoryDetails, setJobCategoryDetails] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        clearErrors,
        formState,
        setError,
        formState: { errors },
    } = useForm();
    const handleEditClickOpen = (homeOwnerLoginId) => {
        setHomeOwnerLoginEditId(homeOwnerLoginId);
        setEditOpen(true);
        // navigate(`/edit/externaluser/${selectedRow.original.homeOwnerLoginId}`)
    };

    console.log("object", errors);

    const getJobCategoryDetails = async () => {
        let isMounted = true;
        try {
            setIsLoading(true);
            const storedToken = token;
            const response = await axios.get(`${BASE_URL}${JOB_CATEGORY}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response.data);
            if (response.status == 200) {
                const data = response.data;
                const responseData = data;
                setJobCategoryDetails(data);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        return () => {
            isMounted = false;
        };
    };

    useEffect(() => {
        getJobCategoryDetails();
    }, []);

    const renderedCategory = Array.isArray(jobCategoryDetails)
    ? jobCategoryDetails.map((category, index) => ({
        category: category.occupation,
        value: category.externalOccupationId,
        key: index,
    }))
    : [];


    const handleCategoryChange = (event, newValue) => {
        console.log("insided ");
        
        if (newValue) {
            const selectedCategoryId = newValue.value;
            setValue("externalOccupation", selectedCategoryId);
            // console.log(newValue, "newvalue");
            // const matchingUsers = externalUser.filter(user => user.loginData.externalOccupationId === selectedCategoryId);
            // const exterUserName = matchingUsers.map((user, index) => ({
            //   category: `${user.userDetails.firstName} ${user.userDetails.lastName}`,
            //   value: user.loginData.homeOwnerLoginId,
            //   key: index
            const selectedHome = jobCategoryDetails.find(
                (job) => job.externalOccupationId === selectedCategoryId
            );
            const homeOwner = selectedHome.externalOccupationId;
            console.log(selectedHome.externalOccupationId, "Test Id");
            setJobCategoryId(homeOwner);
            // }));

            // setExternalUserName(exterUserName)

            // setIsJobCategorySelected(true);
        } else {
            // console.log("false");
            // console.log(externalUserName);
            // setExternalUserName([]);
            // setExternalUserName([]);
            setValue("externalOccupation", null);
            setValue("externalUser", null);
            // setSelectedExternalUser(null);
            // setIsJobCategorySelected(false);
        }
    };
    const getExternalUserDetails = async () => {
        try {
            setIsLoading(true);
            const storedToken = token;

            console.log(storedToken);

            const response = await axios.get(`${BASE_URL}${EXTERNAL_USER}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response.data);

            if (response.status === 200) {
                const data = response.data;
                console.log(data);
                setExternalUserDetails(data);

                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };
    const onSubmit = async (data) => {
        setIsLoading(true);
        const storedToken = token;

        const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);

        let occupation =""
        if(type == "ROLE_GU"){

        occupation=jobCategoryId

        }else{
            occupation=""
        }
        

        if (!isValidPhoneNumberForCountry) {
            // Display an error message or set an error state
            // and prevent form submission
            setIsLoading(false);
            return;
        }
        const postData = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            contact: data.phoneNumber,
            externalOccupationId: occupation,
            home: data.home,
            work: data.work,
            fax: data.fax,
            roles: [type],
        };


        request({
            url: EXTERNAL_USER,
            method: "post",
            data: postData,
        })
            .then((res) => {
                console.log(res);
                if (res.status == 200) {
                    setTimeout(() => {

                        setIsLoading(false); 
                        navigate(`/externaluser`);
                      }, 3000);
                    setIsLoading(false);
                    setType("");
                } else {
                    setIsLoading(false);
                    setOpen(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                reset();
                setOpen(false);
                console.log("gopal " + error.response);
                if (error.response) {
                    console.log("error.response.status" + error.response.status);
                }
            });
    };

    const navigate = useNavigate();

    useEffect(() => {
        getExternalUserDetails();
    }, []);



    const csvConfig = mkConfig({
        fieldSeparator: ",",
        decimalSeparator: ".",
        useKeysAsHeaders: true,
    });


    const handleClick = () => {
        navigate(-1);
    };





    return (
        <div>
            <div class="flex flex-col md:flex-row">
                <CustomToastContainer />
                {isLoading && <CustomLoading />}

                <CustomHeading title="Add External User" />
                <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
                    <CustomButton
                        fieldType="button"
                        buttonName="Back"
                        click={handleClick}
                    />
                </div>

            </div>
            <Card>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="space-y-4 md:space-y-6 "
                >
                    <CardContent>

                        <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
                            <CustomHeading title="External User Details" />
                            <CustomInputField
                                requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                                fieldPattern={ONLY_ALPHABETIC}
                                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                                minLength={2}
                                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                                maxLength={60}
                                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                                fieldName="firstName"
                                fieldId="firstName"
                                fieldType="text"
                                fieldLabel="First Name"
                                fieldControl={control}
                                fieldError={errors}
                                fieldRequiredIcon="*"
                                tooltipMessage="Enter the externaluser first name"
                            />

                            <CustomInputField
                                fieldName="lastName"
                                requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                                fieldPattern={ONLY_ALPHABETIC}
                                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                                minLength={1}
                                minLengthErrorMessage={MIN_LENGTH_ONE_CHARACTERS_FOR_ALL}
                                maxLength={60}
                                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                                fieldId="lastName"
                                fieldType="text"
                                fieldLabel="Last Name"
                                fieldControl={control}
                                fieldError={errors}
                                fieldRequiredIcon="*"
                                tooltipMessage="Enter the externaluser last name"
                            />
                        </section>
                        <br />
                        <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
                            <CustomHeading title="Contact Details " />
                            <CustomInputField
                                fieldName="email"
                                requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                                fieldPattern={EMAIL_REGEX_PATTERNS}
                                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                                validator={false}
                                fieldId="email"
                                fieldType="text"
                                fieldLabel="Email"
                                fieldControl={control}
                                fieldError={errors}
                                tooltipMessage=" Enter your current email address. This is how we'll communicate with you and keep you informed about important updates and notifications"
                                fieldRequiredIcon="*"
                            />



                            <Controller
                                name="phoneNumber"
                                control={control}
                                rules={{
                                    required: CONTACT_REQUIRED_MESSAGE,
                                    validate: {
                                        isValidPhoneNumber: (valueadd) =>
                                            isValidPhoneNumber(valueadd) || "Please provide a valid Contact number",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <CustomPhoneInput
                                            fieldControl={control}
                                            clearErrors={clearErrors}
                                            country="US"
                                            label="Cell"
                                            tooltipMessage="Enter the personal phone number"
                                            requiredIcon="*"
                                            value={field.valueadd}
                                            onChange={(valueadd) => field.onChange(valueadd)}
                                            error={fieldState.invalid ? fieldState.error : null}
                                            helperText={
                                                fieldState.invalid ? fieldState.error?.message : ""
                                            }
                                        />
                                    </>
                                )}
                            />



                            <div>
                                <Controller
                                    name="home"
                                    control={control}
                                    rules={{
                                        validate: {
                                            isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <CustomPhoneInput
                                                isRequiredIcon={false}
                                                isRequired={false}
                                                fieldControl={control}
                                                label="Home"
                                                clearErrors={clearErrors}
                                                country="US"
                                                fieldError={errors}
                                                value={field.value}
                                                tooltipMessage="Enter the phone number for contact purposes"
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                                
                                            />
                                        </>
                                    )}
                                />
                                {errors.home && (
                                    <FormHelperText style={{ color: "#D32F2F" }}>
                                        {errors.home.message}
                                    </FormHelperText>
                                )}
                            </div>
                            <div>
                                <Controller
                                    name="work"
                                    control={control}
                                    rules={{
                                        validate: {
                                            isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <CustomPhoneInput
                                                isRequiredIcon={false}
                                                isRequired={false}
                                                fieldControl={control}
                                                label="Work"
                                                clearErrors={clearErrors}
                                                country="US"
                                                tooltipMessage="Enter the phone number for contact purposes"
                                                fieldError={errors}
                                                value={field.value}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                {errors.work && (
                                    <FormHelperText style={{ color: "#D32F2F" }}>
                                        {errors.work.message}
                                    </FormHelperText>
                                )}
                            </div>

                            <CustomInputField
                                fieldName="fax"
                                // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                                fieldPattern={FAX_REGEX_PATTERN}
                                patternErrorMessage={FAX_INVALID_MESSAGE}
                                maxLength={10}
                                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_TEN}
                                validator={false}
                                fieldId="fax"
                                fieldType="text"
                                fieldLabel="Fax"
                                fieldControl={control}
                                fieldError={errors}
                                tooltipMessage="Enter the Fax number for contact purposes"
                            // fieldRequiredIcon="*"
                            />



                        </section>
                        <br />

                        <section>
                            <CustomHeading title="Assign Role" />
                            <div className="flex items-center md:w-1/2 w-full">
                                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Controller
                                        name="dueFrequency"
                                        defaultValue=""
                                        control={control}
                                        rules={{ required: EXTERNAL_ROLE_REQUIRED_MESSAGE }}
                                        render={({ field, fieldState }) => (
                                            <FormControl variant="standard" fullWidth error={fieldState.invalid}>
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Select Role
                                                    <span style={{ color: "#D32F2F" }}> * </span>
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={type}
                                                    sx={{
                                                        width: '100%' 
                                                      }}

                                                    onChange={(e) => {
                                                        setType(e.target.value);
                                                        field.onChange(e.target.value);
                                                    }}
                                                    label="Select type"
                                                >
                                                    <MenuItem value="ROLE_MC">Management Company</MenuItem>
                                                    <MenuItem value="ROLE_GU">General User</MenuItem>
                                                    <MenuItem value="ROLE_BM">Board Member</MenuItem>
                                                    <MenuItem value="ROLE_ARC">ARC</MenuItem>
                                                </Select>
                                                <FormHelperText style={{ color: "#D32F2F" }}>
                                                    {fieldState.invalid ? fieldState.error?.message : ""}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                    <Tooltip
                                        title={
                                            <Typography sx={{ fontSize: "14px" }}>You can assign the role for externaluser </Typography>
                                        }
                                        placement="right"
                                        arrow
                                    >
                                        <IconButton size="small">
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </section>
                        <br />

                        <section>
                            {type == "ROLE_GU" && (
                                <><CustomHeading title="Assign Job-Category" />
                                    <div className="flex items-center md:w-1/2 w-full">
                                        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>


                                            <><Controller
                                                name="externalOccupation"
                                                control={control}
                                                rules={{ required: JOB_CATEGORY_REQUIRED_MESSAGE }}
                                                render={({ field, fieldState: { error } }) => (
                                                    <FormControl fullWidth error={Boolean(error)}>
                                                        <Autocomplete
                                                            fullWidth
                                                            {...field}
                                                            id="combo-box-demo"
                                                            options={renderedCategory || []}
                                                            getOptionLabel={(option) => {
                                                                if (typeof option === "string") {
                                                                    // Handle cases where the value is a string (e.g., "e98232d8-3270-4384-8527-13e20d0df339")
                                                                    const matchingOption = renderedCategory.find(
                                                                        (item) => item.value === option
                                                                    );
                                                                    return matchingOption ? matchingOption.category : option;
                                                                }
                                                                if (option && typeof option === "object" && option.category) {
                                                                    // Handle normal option objects
                                                                    return option.category;
                                                                }
                                                                return ""; // Fallback for invalid options
                                                            }}
                                                            isOptionEqualToValue={(option, value) => {
                                                                // Ensure the selected option matches correctly
                                                                return option.value === value || option === value;
                                                            }}
                                                            onChange={handleCategoryChange}
                                                            // Enable freeSolo to allow manual input
                                                            // Validate manually entered value
                                                            onInputChange={(event, newInputValue) => {
                                                                if (!newInputValue.trim()) {
                                                                    setError("externalOccupation", {
                                                                        type: "required",
                                                                        message: "Job-Category is required",
                                                                    });
                                                                } else if (!renderedCategory.some(
                                                                    (option) => option.category === newInputValue
                                                                )) {
                                                                    setError("externalOccupation", {
                                                                        type: "manualInputError",
                                                                        message: "Invalid Job-Category. Please select from the provided options.",
                                                                    });
                                                                } else {
                                                                    clearErrors("externalOccupation");
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <Tooltip
                                                                    title={renderedCategory.length === 0 ? "No Job Categories are available. If you want to add a Job Category, please do so under 'homes'" : ""}
                                                                    placement="bottom"
                                                                    disableHoverListener={renderedCategory.length > 0}
                                                                >
                                                                    <TextField
                                                                        {...params}
                                                                        label={<span>
                                                                            Select Job-Category
                                                                            <span style={{ color: "red" }}> * </span>
                                                                        </span>}
                                                                        variant="standard"
                                                                        error={Boolean(error) && !field.value}
                                                                        helperText={Boolean(error) ? error.message : ""} />
                                                                </Tooltip>
                                                            )} />
                                                    </FormControl>
                                                )} />
                                                <Tooltip
                                                    title={<Typography sx={{ fontSize: "14px" }}>You can Select the job category for the General User </Typography>}
                                                    placement="right"
                                                    arrow
                                                >
                                                    <IconButton size="small">
                                                        <InfoOutlined />
                                                    </IconButton>
                                                </Tooltip></>


                                        </div>
                                    </div></>
                            )}


                        </section>
                        <div class="text-center mt-10">
                            <CustomButton fieldType="submit" buttonName="Add" />
                        </div>
                    </CardContent>
                </form>
            </Card>
        </div>
    );
}


export default AddExternalUser;
