import React, { useEffect, useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import CustomButton from "../components/CustomButton";
import { Controller, useForm } from "react-hook-form";
import { BASE_URL, FAQS } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading";
import { COMMON_ERROR_MESSAGE } from "../utills/ApplicationConstants";
import { request } from "../services/AxiosConfig";
import CustomHeading from "../components/CustomHeading";

function FAQ() {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(""); // Initialize content state with an empty string
  const { token } = useSelector((state) => state.hybridhoa);
  const editorRef = useRef(null);
  const { setValue, setError, clearErrors, formState } = useForm();

  useEffect(() => {
    const getFaq = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;

        console.log(storedToken);

        const response = await axios.get(`${BASE_URL}${FAQS}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);

        if (response.status === 200) {
          const data = response.data;
          console.log(data.content);
          setContent(data.content || ""); // Ensure content is set to "" if response.data.content is undefined
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getFaq();
  }, [token]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const config = useMemo(
    () => ({
      readonly: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      useSearch: false,
      removeButtons: ['file', 'image', 'video', 'spellcheck', 'speechRecognize','ul','ol','paragraph','classSpan','copyformat','ai-commands','ai-assistant','source','about'],
      showPlaceholder: false,
    }),
    []
  );

  const isEmptyContent = (content) => {
    if (!content) return true; // Check for undefined or null content
    // Strip HTML tags and check if content is empty or just spaces
    console.log(content);

    const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
    const finalData = strippedContent.replace(/&nbsp;/g, "").trim(); 
    console.log(finalData.length);
    return finalData.length === 0;
  };

  const onSubmit = async (data) => {

    // const datas = content.getData();
    //     const cleanedData = datas.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    //     const finalData = cleanedData.replace(/&nbsp;/g, " "); // Replace &nbsp; with space
    //     console.log("data", finalData);
    if (isEmptyContent(content)) {
      setError("editorContent", {
        type: "manual",
        message: COMMON_ERROR_MESSAGE,
      });
      return;
    }

    setIsLoading(true);

    const payload = { content };

    try {
      const res = await request({
        url: `${FAQS}`,
        method: "put",
        data: payload,
      });
      console.log(res);
    } catch (error) {
      console.log(error.response);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomHeading title="Frequently Asked Questions" />
      <CustomToastContainer />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6">
        <Controller
          name="editorContent"
          control={control}
          rules={{ validate: (value) => !isEmptyContent(value) || COMMON_ERROR_MESSAGE }}
          render={({ field }) => (
            <>
              <JoditEditor
                ref={editorRef}
                value={content} // Ensure content state is passed to value prop
                config={config}
                onBlur={field.onBlur}
                onChange={(newContent) => {
                  handleContentChange(newContent);
                  
                  field.onChange(newContent);
                }}
              />
              {errors.editorContent && (
                <span className="text-red-500">{errors.editorContent.message}</span>
              )}
            </>
          )}
        />
        <div className="text-center mt-8 space-x-1">
          <CustomButton fieldType="submit" buttonName="Add" />
        </div>
      </form>
    </div>
  );
}

export default FAQ;
