import React from "react";
import { CustomStyle } from "./CustomStyle";
import { Button, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

function CustomButton(props) {
  const { trail } = useSelector((state) => state.hybridhoa);

  const isDisabled = trail || props.disabled;

  return (
    <Tooltip title={trail ? "You're in trail period you can access this features once trail period finish" : ""} disableHoverListener={!trail}>
      <span>
        <Button
          variant="contained"
          type={props.fieldType}
          onClick={props.click}
          disabled={isDisabled}
          title={props.title}
          autoFocus
        >
          <span className={CustomStyle.containedButtonStyle}>
            {props.buttonName}
          </span>
        </Button>
      </span>
    </Tooltip>
  );
}

export default CustomButton;
