import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../components/CustomViewPage";
import { useSelector } from "react-redux";
import { BASE_URL, DOCUMENT_ARCHIEVED } from "../utills/ApplicationRouting";
import axios from "axios";
import CustomLoading from "../components/CustomLoading";
import moment from "moment";
function ViewArchivedDocument() {
  const navigate = useNavigate();
  const { documentId } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);
  const [documentDetails, setDocumentdetails] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [firstNameData,setFirstNameData] =useState('');
  const [lastNameData,setLastNameData] = useState('');
  const handleClick = () => {
    navigate(-1);
  };

  const formatDate = (dateString) => {
    if (dateString === "null" || !dateString) {
      return "";
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const getDocumentDetailsbyId = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}${DOCUMENT_ARCHIEVED}/${documentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      console.log(response.data.renter);
      if (response.status === 200) {
        const data = response.data;
        setIsLoading(false);
        setDocumentdetails(data);
        setFileUrl(data.fileUrl);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        };
        const formattedDate = new Date(data.createdDateAndTime).toLocaleString(
          "en-us",
          options
        );
        console.log(data);
        setCreatedDateAndTime(formattedDate);
        const createdDetails = data.createdByDetails
   
        setFirstNameData(createdDetails.firstName);
        setLastNameData(createdDetails.lastName);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDocumentDetailsbyId();
  }, [documentId]);

  const fullName = firstNameData + " " + lastNameData;

  const data = {
    pageTitle: "View Archived Document",
    buttons: [
      {
        buttonTitle: "Back",
        onClick: handleClick,
      },
    ],
    content: [
      {
        topic: "Created By",
        description: fullName,
      },
      {
        topic: "Category ",
        description:
          documentDetails.category === "null" ? "" : documentDetails.category,
      },
      {
        topic: "Created Date Time ",
        description: moment(documentDetails.createdDateAndTime).format("MM/DD/YYYY"),
      },
      {
        topic: "Audit Date Time",
        description: moment(documentDetails.auditDateAndTime).format("MM/DD/YYYY"),
      },
      {
        topic: "Is Hidden",
        description:documentDetails.isHidden ? "Yes" :"No"
      },
      {
        topic: "Status",
        description:
          documentDetails.staus === "null" ? "" : documentDetails.status,
      },
      {
        topic: "Publish Date",
        description: moment(documentDetails.publishDate).format("MM/DD/YYYY"
        ),
       
      },
      {
        topic: "Description",
        description:
          documentDetails.description === "null"
            ? ""
            : documentDetails.description,
      },
      {
        topic: "Attachments",
        component: "AttachmentList", // Instead of the JSX component, provide the component name as a string
        props: {
          fileUrl: fileUrl,
          // documentName: fileUrl,
          docname: fileUrl,
          // documentnamed: fileUrl,
          uploadedTimes: createdDateAndTime,
        },
      },
    ],
  };

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewPage data={data} />
    </div>
  );
}

export default ViewArchivedDocument;
