import {
  Delete,
  DeleteOutline,
  Edit,
  EditNoteOutlined,
  ListAlt,
} from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import {
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  EXTERNAL_ROLE_REQUIRED_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  CONTACT_REQUIRED_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  JOB_CATEGORY_INVAILD_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  MIN_LENGTH_ONE_CHARACTERS,
} from "../utills/ApplicationConstants";
import {
  EMAIL_REGEX_PATTERN,
  ONLY_ALPHABETIC,
} from "../utills/ApplicationRegex";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomAutoComplete from "../components/CustomAutoComplete";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  EXTERNAL_USER,
  JOB_CATEGORY,
} from "../utills/ApplicationRouting";
import CustomToastContainer from "../components/CustomToastContainer";
import { request } from "../services/AxiosConfig";
import CustomPhoneInput from "../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomLoading from "../components/CustomLoading";

function ExternalUser() {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = React.useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [externalUserDetails, setExternalUserDetails] = useState([]);
  const [occupationDetails, setOccupationDetails] = useState("");
  const [occupationId, setOccupationId] = useState("");
  const { token } = useSelector((state) => state.hybridhoa);
  const [type, setType] = useState("");
  const handleViewClick = (selectedRow) => {
    navigate(`/external/${selectedRow.row.original.homeOwnerLoginId}`);
  };

  const handleAddClickOpen = () => {
    // setOpen(true);
    navigate(`/addexternaluser`)
  };
 
  const [homeOwnerLoginId, setHomeOwnerLoginId] = useState("");
  // const [homeOwnerLoginEditId, setHomeOwnerLoginEditId] = useState("");

  // const [jobCategoryId, setJobCategoryId] = useState(null);
  const handleDeleteClickOpen = (homeOwnerLoginId) => {
    setHomeOwnerLoginId(homeOwnerLoginId);
    console.log(homeOwnerLoginId);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const [age, setAge] = React.useState("");


  const {
    handleSubmit,
    control,
    reset,
    setValue,
    clearErrors,
    formState,
    setError,
    formState: { errors },
  } = useForm();
  const handleEditClickOpen = (homeOwnerLoginId) => {
    // setHomeOwnerLoginEditId(homeOwnerLoginId);
    // setEditOpen(true);
    navigate(`/edit/externaluser/${homeOwnerLoginId}`)
  };

  console.log("object", errors);

  const getJobCategoryDetails = async () => {
    let isMounted = true;
    try {
      setIsLoading(true);
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${JOB_CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status == 200) {
        const data = response.data;
        const responseData = data;
        setJobCategoryDetails(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    getJobCategoryDetails();
  }, []);



 
  const getExternalUserDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${EXTERNAL_USER}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        // console.log(data.loginData.homeOwnerLoginId);

        // const mappedLoginData = data.map((item) => item.loginData);
        // const mappedUserDetalis = data.map((item) => item.userDetails);
        // const mappedOccupationDetails = data.map(
        //   (item) => item.occupationDetails
        // );
        // mappedUserDetalis.forEach((singleUserDetails) => {
        //   setUserDetails(singleUserDetails.firstName);
        //   setLastName(singleUserDetails.lastName);
        // });

        console.log(data);
        setExternalUserDetails(data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
 
  const onSubmitDelete = async () => {
    console.log(token);
    request({
      url: `${EXTERNAL_USER}/${homeOwnerLoginId}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getExternalUserDetails();
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    getExternalUserDetails();
  }, []);

  const role = [
    { label: "Management Company" },
    { label: "General User" },
    { label: "Board Member" },
  ];
  // {
  //   accessorKey: "externalUserName",
  //   header: "External UserName",
  //   accessorFn: () => fullName,
  // },

  // const fullName = userDetails + " " + lastName;

  const externalUserTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "fullName",
      header: "Name",
      Cell: ({ row }) => `${row.original.fullName}`,
      sortDescFirst: true,
    },

    {
      accessorKey: "email",
      header: "Email",
      // Cell: ({ row }) => `${row.original.userDetails.email} `,
      Cell: ({ row }) => `${row.original.email} `,
    },

    // {
    //   accessorKey: "isActive",
    //   header: "Status",
    //   size: 20,
    //   // Cell: ({ row }) => (`${row.original.isActive} ` ? "Yes" : "No"),
    //   accessorFn: (row) => (row && row.isActive ? "Yes" : "No"),
    // },
    {
      accessorKey: "roles",
      header: "Roles",
      accessorFn: (row) =>
        row && row.roles == "ROLE_GU"
          ? "General User"
          : row.roles == "ROLE_MC"
          ? "Management Company"
          : row.roles == "ROLE_ARC"
          ? "ARC"
          : row.roles == "ROLE_BM"
          ? "Board Member"
          : "",
    },
  ]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(externalUserDetails);
    download(csvConfig)(csv);
  };
  const rowActions = [
    // {
    //   label: "View",
    //   icon: <ListAlt color="primary" />,
    //   click: (row) => handleViewClick(row),
    // },
    {
      label: "Edit",
      icon: <EditNoteOutlined color="primary" />,
      click: (row) => handleEditClickOpen(row.original.homeOwnerLoginId),
    },
    {
      label: "Delete",
      icon: <DeleteOutline color="error" />,
      click: (row) => handleDeleteClickOpen(row.original.homeOwnerLoginId),
    },
  ];


  const handleClick = () => {
    navigate(-1);
  };

  
 
  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
      />
    </>
  );
 
 
  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Do you want to delete this user ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <CustomHeading title="External Users" />
      
      <CustomModel
        title="Delete External User"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />

      <CustomMaterialTable
        columns={externalUserTable}
        data={externalUserDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
        onRowClick = {handleViewClick}
      />
    </div>
  );
}

export default ExternalUser;
