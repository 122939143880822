import {
    Close,
    ContentCopy,
    Delete,
    DeleteForever,
    FileDownload,
    ListAlt,
    Telegram,
  } from "@mui/icons-material";
  import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField,
    Typography,
  } from "@mui/material";
  import { download, generateCsv, mkConfig } from "export-to-csv";
  import {
    MaterialReactTable,
    useMaterialReactTable,
  } from "material-react-table";
  import React, { useEffect, useMemo, useState } from "react";
  import { useSelector } from "react-redux";
  import { setPageIndex } from "../redux/Hybridhoa";
  import { useDispatch } from "react-redux";
  import { useLocation, useNavigate } from "react-router-dom";
function CustomMaterialTableActions(props) {
  const navigate = useNavigate();
  const actions = props.rowActions;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  // const dispatch = useDispatch();
  // const location = useLocation();
  // const currentUrl = location.pathname;
  // const actions = props.rowActions;
  // const pageIndexes = useSelector((state) => state.hybridhoa.pageIndexes);
  //   const savedPageIndex = pageIndexes[currentUrl] || 0;
  //   const [pagination, setPagination] = useState({
  //     pageIndex: savedPageIndex,
  //     pageSize: 5,
  //   });
  //   useEffect(() => {
  //     const storedPageIndex = parseInt(localStorage.getItem(currentUrl), 10);
  //     if (!isNaN(storedPageIndex)) {
  //       setPagination((prev) => ({ ...prev, pageIndex: storedPageIndex }));
  //     }
  //   }, [currentUrl]);
    return (
      <MaterialReactTable
      // enableRowSelection={true} 
        columns={props.columns}
        data={props.data}
        enableColumnOrdering
        enableRowActions={actions ? true : false}
        positionActionsColumn="last"
        enableSorting={false}
        initialState={{
          pagination: pagination,
        }}
        onPaginationChange={setPagination}
        state={{
          // isLoading: (props.isLoading),
          pagination
        }}
      
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "semi-bold",
            fontSize: 14,
            fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontSize: 14,
            fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
          },
        }}
        enableColumnPinning
        // renderRowActionMenuItems={({ row }) => [
        //   actions.map((i) => {
        //     return (
        //       <MenuItem key={i.label} onClick={() => i.click(row)}>
        //         {i.icon}
        //         <Typography sx={{ pl: 1 }}> {i.label}</Typography>
        //       </MenuItem>
        //     );
        //   }),
        // ]}
        renderRowActionMenuItems={({ row }) => {
          const renderActions = Array.isArray(actions)
            ? actions.map((i) => (
                <MenuItem key={i.label} onClick={() => i.click(row)}>
                  {i.icon}
                  <Typography sx={{ pl: 1 }}> {i.label}</Typography>
                </MenuItem>
              ))
            : actions(row).map((i) => (
                <MenuItem key={i.label} onClick={i.click}>
                  {i.icon}
                  <Typography sx={{ pl: 1 }}>{i.label}</Typography>
                </MenuItem>
              ));
        
              return renderActions.length > 0 ? renderActions : (
                <MenuItem disabled>
                  <Typography sx={{ pl: 1 }}>N/A</Typography>
                </MenuItem>
              );
        }}
        renderTopToolbarCustomActions={() => (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              {props.actionButtons}
            </Box>
          </>
        )}
      />
    );
  };

export default CustomMaterialTableActions