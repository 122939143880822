import {
  Delete,
  ListAlt,
  EditNoteOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import {
  DialogActions,
  DialogContent,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import {
  BYLAW_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_TWO_FIFTY,
  MIN_LENGTH_VALIDATE_MESSAGE,
  TOPIC_INVALID_MESSAGE,
  TOPIC_REQUIRED_MESSAGE,
} from "../utills/ApplicationConstants";
import CustomCheckBox from "../components/CustomCheckBox";
import { ONLY_ALPHABETIC } from "../utills/ApplicationRegex";
import { BASE_URL, BYLAW } from "../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import { request } from "../services/AxiosConfig";
import { useSelector } from "react-redux";
import CustomAutoComplete from "../components/CustomAutoComplete";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading"

function Bylaw() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [bylawType, setBylawType] = React.useState("");
  const [editOpen, setEditOpen] = useState(false);
  const { token } = useSelector((state) => state.hybridhoa);
  const [selectedLabel, setSelectedLabel] = useState("");
    const handleBylawChange = (event) => {
    setBylawType(event.target.value);
  };
  const handleAddClickOpen = () => {
    navigate('/addbylaw');
  };
  const handleAddClose = (status) => {
    if (status == 200) {
      setType("");
      setIsNotifys("");
      setOpen(false);
    }
  };

  const handleIconClose = () => {
    setType("");
    setIsNotifys("");
    setOpen(false);
  };
  const [editId, setEditId] = useState("");

  const handleEditClick = (selectedRow) => {
    console.log("selectedRow",selectedRow);
    navigate(`/editbylaw/${selectedRow.original.byLawId}`);
  };
  const handleEditClose = (status) => {
    if (status == 200) {
      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    setIsLoading(false)
  };

  const handleDeleteClickOpen = (selectedRow) => {
    console.log("bylawid",selectedRow);
    setEditId(selectedRow.byLawId);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setIsLoading(false)
  };

  const handleViewClick = (selectedRow) => {
    navigate(`/viewbylaw/${selectedRow.row.original.byLawId}`);
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const bylaw = [
    { label: "ARC" },
    { label: "Bylaw", id: 2 },
    { label: "Covenants", id: 3 },
  ];

  const [bylawDetails, setBylawDetails] = useState([]);
  const [byLawIds, setByLawIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBylawDetails = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${BASE_URL}${BYLAW}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
       
        const data = response.data;
        setBylawDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBylawDetails();
  }, []);
  const { byLawId } = useParams();
  const getBylawById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log("ByLawId" + byLawId);
      const response = await axios.get(`${BASE_URL}${BYLAW}/${editId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        setByLawIds(data);
        setTopic(data.topic);
        setDescription(data.description);
        setIsNotify(data.isNotify);
        setEditType(data.type);
        setIsLoading(false);
        console.log("Bylaw" + data);
      } else {
           setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {   
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBylawById();
  }, [editId]);

  useEffect(() => {
    console.log("editId", editId);
  }, [editId]);

  const onSubmit = (data) => {
    setIsLoading(true);

    console.log("buttonWorking");

    console.log("Form Data:", data);
    console.log(
      "Selected Bylaw Label:",
      data.selectedOption ? data.selectedOption.label : data.selectedOption
    );
    // const type = bylaw[selectedLabel] || "";
    // console.log(type);
    const postData = {
      topic: data.topic,
      description: data.description,
      type: type,
      isNotify: data.bylawNotification,
    };
    const storedToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };

    request({
      url: BYLAW,
      method: "post",
      data: postData,
      headers: config.headers,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          handleAddClose(res.status);
          setType("");
          setIsNotifys("");
          getBylawDetails();
          reset();  setIsLoading(false);
        }      else{  
          setIsLoading(false);
        }

      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error.response);
        if (error.response) {
          setIsLoading(false)
          console.log("error.response.status" + error.response.status);
        }
      });
    console.log(data.topic);
    console.log(data.bylaw);
    console.log(data.description);
    console.log(data.bylawNotification);
  };

  const onSubmitEdit = async (data) => {
  
   
  
   

    if (topic.trim() == "" || topic == null) {
      setError("topic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (description.trim() == "" || description == null) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);


    const payload = {
      topic: topic.trim(),
      description: description.trim(),
      type: editType,
      isNotify: isNotify,
    };
    console.log(payload);
    request({
      url: `${BYLAW}/${editId}`,
      method: "put",
      data: payload,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          handleEditClose(res.status);

          setIsNotifys("");
          getBylawDetails();
          getBylawById();
          reset();
          setIsLoading(false);

        }else{
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const onSubmitDelete = async () => {
    setIsLoading(true);

    request({
      url: `${BYLAW}/${editId}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getBylawDetails();
          setIsLoading(false);

        }else{
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.res);
        if (error.res) {
          setIsLoading(false);

          console.log("error.response.status" + error.res.status);
        }
      });
    // try {
    //   const response = await axios.delete(`${BASE_URL}${BYLAW}/${editId}`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   console.log(response);
    //   if (response.status == 200) {
    //     getBylawDetails();

    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const bylawTable = useMemo((rowData, index) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "topic",
      header: "Topic",
    
      accessorFn: (row) => {
        const topic = row && row.topic;
        if (topic && topic.length > 28) {
          return topic.slice(0, 28) + "...";
        }
        return topic;
      }
    },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
      // Cell: ({ row }) => {
      //   const description = row.original.description;
      //   return description.length > 28 ? `${description.slice(0, 28)}...` : description
      // },
    },
    {
      accessorKey: "type",
      header: "Bylaw Type",
      size: 20,
    },
    {
      accessorKey: "createdBy",
      header: "Created By",
      accessorFn:(row)=>`${row && row.createdByDetails ?row.createdByDetails.firstName :""} ${row && row.createdByDetails ?row.createdByDetails.lastName :""}`
      // Cell: ({ row }) =>
      //   `${row.original.createdByDetails.firstName} ${row.original.createdByDetails.lastName}`,
    },
    // {
    //   accessorKey: "createdDateAndTime",
    //   header: "Created Date",
    //   accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    // },
  ]);

  const rowActions = [
    // {
    //   label: "View",
    //   icon: <ListAlt color="primary" />,
    //   click: (row) => handleViewClick(row),
    // },
    {
      label: "Edit",
      icon: <EditNoteOutlined color="primary" />,
      click: (row) => handleEditClick(row),
    },
    {
          label: "Delete",
      // icon: <Delete color="primary" />,
      icon: <DeleteOutline color="error" />,

      click: (row) => handleDeleteClickOpen(row.original),
    },
  ];

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
      />
    </>
  );

  const addActionButton = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose} />
  );

  const editActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    />
  );
  const [isNotifys, setIsNotifys] = useState(false);
  //** Add ByLaw */
  const addModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="topic"
              fieldId="topic"
              fieldType="text"
              fieldLabel="Topic"
              requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={250}
              maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
              fieldRequiredIcon="*"
              fieldControl={control}
              fieldError={errors}
              numberOfRows={2}
              multiline={true}
            />
            <br />
            <br />

            {/* <CustomAutoComplete
              fieldName="selectedOption"
              requiredErrorMessage={BYLAW_REQUIRED_MESSAGE}
              id="selectId"
              requiredIcon="*"
              options={bylaw}
              labelName="Select Bylaw"
              fieldControl={control}
              error={errors}
              setValue={setValue}
            /> */}
            <Controller
              name="dueFrequency"
              defaultValue=""
              control={control}
              rules={{ required: BYLAW_REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  error={fieldState.invalid}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Please select type
                    <span style={{ color: "#D32F2F" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      field.onChange(e.target.value);
                    }}
                    label="Select type"
                  >
                    <MenuItem value="ARC">ARC</MenuItem>
                    <MenuItem value="BYLAW">ByLaw</MenuItem>
                    <MenuItem value="COVENANTS">Covenants</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <br />
            <br />

            <CustomInputField
              fieldName="description"
              requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />

            <CustomCheckBox
              fieldName="bylawNotification"
              control={control}
              defaultValue={isNotifys}
              labelName="Does a bylaw notification need to be sent to homeowners? "
              onChange={(newValue) => setIsNotifys(newValue)}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{addActionButton}</DialogActions>
      </form>
    </>
  );

  const [topic, setTopic] = useState("");
  const [type, setType] = useState("");
  const [editType, setEditType] = useState("");

  const [isNotify, setIsNotify] = useState(false);
  const [description, setDescription] = useState("");
  const handletopicChange = (event) => {
    setTopic(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.type);
  };
  const handleCheckboxChange = () => {
    setIsNotify(true);
  };
  //** Edit ByLaw */
  // const editModel = (
  //   <>
  //     <form
  //       onSubmit={handleSubmit(onSubmitEdit)}
  //       className="space-y-4 md:space-y-6 "
  //     >
  //       <DialogContent dividers>
  //         <Typography gutterBottom>
  //           <CustomInputField
  //             fieldName="topic"
  //             fieldId="topic"
  //             fieldType="text"
  //             fieldLabel="Topic"
  //             fieldPattern={ONLY_ALPHABETIC}
  //             patternErrorMessage={TOPIC_INVALID_MESSAGE}
  //             minLength={2}
  //             minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
  //             maxLength={250}
  //             maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
  //             fieldRequiredIcon="*"
  //             fieldControl={control}
  //             fieldError={errors}
  //             multiline={true}
  //             value={topic}
  //             numberOfRows={2}
  //             onInput={handletopicChange}
  //           />
  //           <br />
  //           <br />

  //           <Controller
  //             name="bylawType"
  //             defaultValue=""
  //             control={control}
  //             // rules={{ required: BYLAW_REQUIRED_MESSAGE }}
  //             render={({ field, fieldState }) => (
  //               <FormControl
  //                 variant="standard"
  //                 fullWidth
  //                 error={fieldState.invalid}
  //               >
  //                 <InputLabel id="demo-simple-select-standard-label">
  //                   Please select type
  //                   <span style={{ color: "#D32F2F" }}>*</span>{" "}
  //                 </InputLabel>
  //                 <Select
  //                   labelId="demo-simple-select-standard-label"
  //                   id="demo-simple-select-standard"
  //                   value={editType}
  //                   onChange={(e) => {
  //                     setEditType(e.target.value);
  //                     field.onChange(e.target.value);
  //                   }}
  //                   label="Select type"
  //                 >
  //                   <MenuItem value="ARC">ARC</MenuItem>
  //                   <MenuItem value="BYLAW">ByLaw</MenuItem>
  //                   <MenuItem value="COVENANTS">Covenants</MenuItem>
  //                 </Select>
  //                 <FormHelperText style={{ color: "#D32F2F" }}>
  //                   {fieldState.invalid ? fieldState.error?.message : ""}
  //                 </FormHelperText>
  //               </FormControl>
  //             )}
  //           />

  //           <br />
  //           <br />

  //           <CustomInputField
  //             fieldName="description"
  //             fieldId="description"
  //             minLength={2}
  //             minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
  //             maxLength={5000}
  //             maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
  //             fieldLabel="Description"
  //             fieldControl={control}
  //             fieldError={errors}
  //             fieldRequiredIcon="*"
  //             multiline={true}
  //             numberOfRows={2}
  //             value={description}
  //             onInput={handleDescriptionChange}
  //           />

  //           <CustomCheckBox 
  //             fieldName="bylawNotification"
  //             control={control}
  //             defaultValue={isNotify}
  //             labelName="Does a bylaw notification need to be sent to homeowners? "
  //             onChange={(newValue) => setIsNotify(newValue)}
  //           />
  //         </Typography>
  //       </DialogContent>
  //       <DialogActions>{editActionButton}</DialogActions>
  //     </form>
  //   </>
  // );

  const okCancelButtonActions = (
    <>
     <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
     
    </>
  );
  //** Delete ByLaw */
  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete this bylaw ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  return (
    <div>
      <CustomToastContainer />
      <CustomHeading title="Bylaw" />
      {isLoading && <CustomLoading />}
      <CustomModel
        title=" Add Bylaw"
        submit={onSubmit}
        content={addModel}
        action={addActionButton}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      {/* <CustomModel
        title="Edit Bylaw"
        submit={onSubmitEdit}
        content={editModel}
        action={editActionButton}
        openStatus={editOpen}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        isLoading={isLoading}

      /> */}

      <CustomModel
        title=" Delete Bylaw"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}

      />

      <CustomMaterialTable
        columns={bylawTable}
        data={bylawDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
        onRowClick = {handleViewClick}
      />
    </div>
  );
}

export default Bylaw;
