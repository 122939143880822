import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL, EMAIL_VERIFY } from '../utills/ApplicationRouting';
import { useParams } from 'react-router-dom';
import ColoredCircle from '../components/coloredCircle'; // Make sure the import path is correct
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToastContainer from '../components/CustomToastContainer';
import CustomLoading from '../components/CustomLoading';
import { setToken } from '../redux/Hybridhoa';
import { useDispatch } from 'react-redux';

function EmailVerified() {
  // const { tokenId } = useParams();
  const [status, setStatus] = useState('');
  const [isLoading,setIsLoading]=useState(false);
  const [message,setMessage]=useState('')
  const { tokenId,token } = useParams();
  const dispatch = useDispatch();
  
  let navigate = useNavigate();
  useEffect(()=>{
    if (token) {
      localStorage.removeItem("token");
      localStorage.removeItem("persist:root");
      dispatch(clearAuthToken());
      dispatch(setToken(null));
    }
  },[token])
  const getEmailVerification = async () => {
    try {
      if (token) {
        localStorage.removeItem("token");
         localStorage.removeItem("persist:root");

        dispatch(clearAuthToken());
        dispatch(setToken(null));
        return
      }
      setIsLoading(true)
      const response = await axios.get(`${BASE_URL}${EMAIL_VERIFY}${tokenId}`);
      console.log(response.data);
     
      if (response.status === 200) {
       
      setIsLoading(false)
        const statuss = response.status;
        console.log(statuss)
        setStatus(statuss);
        setMessage(response.data)
        setTimeout(()=>{
                  navigate('/')
        },4000)
      } else {
        setIsLoading(false)
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching data:', error);
      setMessage(error.response.data.message)
    }
  };

  useEffect(() => {
getEmailVerification();
  }, [tokenId]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <CustomToastContainer />
      <div>
        <ColoredCircle isSuccess={status === 200} />
      </div>
     
      <div className="mt-4">
        {status === 200 ? (
          <p className='text-xl font-bold'>{message}</p>
        ) : (
          <p className='text-xl font-bold'>{message}</p>
        )}
      </div>
    </div>
  );
}

export default EmailVerified;
