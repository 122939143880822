import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

const CustomCellRenderer = ({ cell }) => {
  const [expanded, setExpanded] = useState(false);
  const text = cell.getValue();
  const isLongText = text?.length > 28;

  const handleToggle = (event) => {
    event.stopPropagation(); // Prevent routing/navigation
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Typography>
        {expanded || !isLongText ? text : `${text.substring(0, 28)}...`}
      </Typography>
      {/* {isLongText && (
        <Button size="small" onClick={handleToggle}>
          {expanded ? "Less" : "More"}
        </Button>
      )} */}
    </Box>
  );
};

export default CustomCellRenderer;
