import { ListAlt } from "@mui/icons-material";
import { Card, CardContent } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import { useForm } from "react-hook-form";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomButton from "../components/CustomButton";
import dayjs from "dayjs";
import {
  DUE_DATE_REQUIRED_MESSAGE,
  FROM_DATE_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_GREATER_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
} from "../utills/ApplicationConstants";
import { BASE_URL, REPORT_DOCUMENT } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomLoading from "../components/CustomLoading";
import CustomMaterialWithoutActons from "../components/CustomMaterialWithoutActons";

function AuditDocument() {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.hybridhoa);

  // const handleViewClick = (selectedRow) => {
  //   navigate(`/document/${selectedRow.row.original.documentId}`);
  // };
  const handleViewClick = (selectedRow) => {
    navigate(`/auditdocument/${selectedRow.row.original.documentId}`);
    console.log(selectedRow.row.original.documentId);
  };
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const minDate = dayjs().subtract(3, "year").startOf("day");
  const maxDate = dayjs().endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  // useEffect(() => {
  //   // Retrieve table data from sessionStorage when component mounts
  //   const storedData = sessionStorage.getItem("tableData");
  //   if (storedData) {
  //     setTableData(JSON.parse(storedData));
  //   }
  // }, []);

  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem('AuditDocument'));
    console.log("save3d date "+savedFormData);
    console.log("save3d date "+JSON.stringify(savedFormData));
    if (savedFormData) {
      setValue('fromDate', savedFormData.fromDate);
      setValue('toDate', savedFormData.toDate);
      // setValue('selectedType', savedFormData.selectedType); // Uncomment if you have a selectedType field
      setFromDate(dayjs(savedFormData.fromDate));
      setToDate(dayjs(savedFormData.toDate));
    }
  }, [setValue]);
  useEffect(() => {
    // Retrieve data from local storage
    const storageData = localStorage.getItem("DocumentTableData");
    if (storageData) {
      // Parse the JSON data
      const parsedData = JSON.parse(storageData);
      // Set the parsed data in the state
      setTableData(parsedData);
      console.log("parsed", parsedData);
    }
  }, []);
  const handleFromDateChange = (selectedFromDate) => {
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
  };

  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);
  };

  // const [tableData, setTableData] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const onSubmit = async (data) => {
    // Set loading state to true when submitting form
    // const fromDates = dayjs(fromDate);
    // const toDates = dayjs(toDate);

    // if (!fromDates.isValid() || !toDates.isValid()) {
    //   console.error("Invalid fromDate or toDate");
    //   return;
    // }
    if (!data.fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!data.toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (toDate < fromDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_GREATER_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    localStorage.setItem('AuditDocument', JSON.stringify(data));
    const payload = {
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      to: dayjs(toDate).format("YYYY-MM-DD"),
      type: "ALL",
    };
    try {
      const response = await axios.post(
        `${BASE_URL}${REPORT_DOCUMENT}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}}`,
          },
        }
      );
      console.log(response.data);
      if (response.status === 200) {
        // Set the data in the state variable
        localStorage.setItem("DocumentTableData", JSON.stringify(response.data.data));
        setTableData(response.data.data);
        // Store table data in sessionStorage
        // sessionStorage.setItem("tableData", JSON.stringify(response.data.data));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false); // Reset loading state regardless of success or failure
    }
  };

  const auditDocumentsTable = useMemo(
    (rowData) => [
      {
        accessorKey: "serialNo",
        header: "#",
        size: 20,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "fileUrl",
        header: "Attachments",
        accessorFn: (row) => (row.attachments[0].file.substring(12)),
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 20,
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "publishDate",
        header: "Publish Date",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "createdDateAndTime",
        header: "Created Date",
        accessorFn: (row) =>
          moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      },
    ],
    []
  );

  // const rowActions = [
  //   {
  //     label: "View",
  //     icon: <ListAlt color="primary" />,
  //     click: (row) => handleViewClick(row),
  //   },
  // ];

  return (
    <div>
      <CustomHeading title="Document List" />
      {isLoading && <CustomLoading />}
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
          <section className="flex flex-col gap-5">
              <CustomDatePicker
                fieldName="fromDate"
                label="From Date"
                control={control}
                requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                value={fromDate}
                onChange={(date) => handleFromDateChange(date)}
                fieldRequiredIcon="*"
                tooltipMessage="for from date"
              />

              <CustomDatePicker
                fieldName="toDate"
                label="To Date"
                control={control}
                //  requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                value={toDate}
                fieldRequiredIcon="*"
                onChange={handleToDateChange}
                tooltipMessage="for to date"
              />
            </section>
            <div className="text-left mt-10">
              <CustomButton fieldType="submit" buttonName="Search" />
            </div>
          </form>
        </CardContent>
      </Card>
      <br />
      <br />

      <CustomMaterialWithoutActons
        columns={auditDocumentsTable}
        data={tableData}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default AuditDocument;
