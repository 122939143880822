import { Checkbox, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { Tooltip, IconButton } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function CustomCheckBox(props) {
  const tooltipMessage = "select the check box";
  return (
    <div className="flex items-center md:w-1/2 w-full">
      <Controller
        name={props.fieldName}
        control={props.control}
        defaultValue={props.defaultValue}
        render={({ field, fieldState }) => (
          <div className="flex items-center">
            <Typography>{props.labelName}</Typography>
            <Checkbox
              {...field}
              checked={props.defaultValue} // Set checked based on defaultValue
              onChange={(e) => {
                const newValue = e.target.checked;
                field.onChange(newValue); // Update the form's value
                if (props.onChange) {
                  props.onChange(newValue); // Call external onChange if provided
                }
              }}
            />
          </div>
        )}
      />
      {tooltipMessage && (
        <Tooltip
          title={
            <Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export default CustomCheckBox;
