import { InfoOutlined } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Tooltip, IconButton } from "@mui/material";

const CustomInputField = ({
  fieldName,
  requiredErrorMessage,
  fieldPattern,
  patternErrorMessage,
  fieldId,
  fieldType,
  fieldLabel,
  fieldControl,
  fieldError,
  fieldTrigger,
  value,
  onChange,
  defaultValue,
  fieldRequiredIcon,
  disabled,
  multiline,
  numberOfRows,
  validator,
  minLength,
  minLengthErrorMessage,
  maxLength,
  maxLengthErrorMessage,
  isMinLength,
  validationRules,
  onInput,
  validate,
  tooltipMessage
}) => {
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );

  return (
    <div className={`flex items-center w-full ${tooltipMessage ? 'md:w-1/2' : 'w-auto'}`}>
      <Controller
        name={fieldName}
        control={fieldControl}
        defaultValue={defaultValue}
        rules={{
          required: requiredErrorMessage,
          pattern: {
            value: fieldPattern,
            message: patternErrorMessage,
          },
          minLength: {
            value: minLength,
            message: minLengthErrorMessage,
          },
          maxLength: {
            value: maxLength,
            message: maxLengthErrorMessage,
          },
          validate: validate && typeof validate === 'function' ? validate : undefined,
        }}
        render={({ field, fieldState }) => (
          <>
            <TextField
              fullWidth
              {...field}
              label={
                <CustomInputLabel
                  label={fieldLabel}
                  requiredIcon={fieldRequiredIcon}
                />
              }
              type={fieldType}
              id={fieldId}
              margin="dense"
              variant="standard"
              disabled={disabled}
              multiline={multiline}
              maxRows={5}
              value={value}
              onInput={onInput}
              onBlur={(e) => field.onChange(e.target.value.trim())}
              inputProps={{
                style: {
                  fontSize: 16,
                  fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
                },
                readOnly: disabled,
              }}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
                },
              }}
              
              error={fieldState.invalid}
              helperText={fieldState.invalid ? fieldState.error?.message : ""}
            />
          </>
        )}
      />
      {tooltipMessage && (
        <Tooltip
          title={
            <Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default CustomInputField;
