import React, { useEffect, useRef } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/Hybridhoa";
import { useNavigate } from "react-router-dom";
import { Crisp } from "crisp-sdk-web";


const IdleTimeout = ({ timeout = 1800000 }) => {
  const idleTimerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useIdleTimer({
    timeout,
    
    onIdle: () => {

      localStorage.removeItem("token"); 
      dispatch(setToken(null)); 
      Crisp.chat.hide()
        alert("Your session has expired due to inactivity. Please log in again to continue.")
      navigate("/"); 
    },
    ref: idleTimerRef
  });


  return null; 
};

export default IdleTimeout;
