import { FileDownload, FileUploadOutlined, ListAlt } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomButton from "../components/CustomButton";
import { Controller, useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import {
  FROM_DATE_REQUIRED_MESSAGE,
  LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_GREATER_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  TYPE_REQUIRED_MESSAGE,
} from "../utills/ApplicationConstants";
import axios from "axios";
import { useSelector } from "react-redux";
import FileUploadIcon from '@mui/icons-material/FileUpload';

import {
  BASE_URL,
  BASE_URL_IMAGE,
  GROUP_CONFIG,
  REPORT_CR_MR,
} from "../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../services/AxiosConfig";
import { useEffect } from "react";
import PDFDocumentList from "../components/Templatelistpdf";
import { pdf } from "@react-pdf/renderer";
import CustomLoading from "../components/CustomLoading"
import CustomMaterialWithoutActons from "../components/CustomMaterialWithoutActons";
import { color, fontSize } from "@mui/system";

function Audit_Cr() {
  const [type, setType] = useState("CR");
  const { token } = useSelector((state) => state.hybridhoa);
  const userRoles = useSelector((state) => state.hybridhoa.roles);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const minDate = dayjs().subtract(3, "year").startOf("day");
  const maxDate = dayjs().endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedType, setSelectedType] = useState("ALL");
  const [arrayImage, setArrayImage] = useState([]);
  const [groupConfigurationDetails, setGroupConfigurationDetails] = useState(
    []
  );
  const [logoUrl, setLogoUrl] = useState("");
  const [imageBlob, setImageBlob] = useState("");
  const[footerAdress,setFooterAdress]=useState('')
  const[imagePreview,setImagePreview]=useState("")
  const[listPreview,setListPreview]=useState("")
  const[groupAdress,setGroupAdress]=useState('')
  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data);
        const logoConfig = data.find((config) => config.topic === "Logo");
        const footerValue = data.find((config) => config.topic === "Contact Details");
        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        if (footerValue) {
          setFooterAdress(footerValue.content);
        }
        const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }
        setIsLoading(false);
      } else { 
          
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getGroupConfigurationDetails();
  }, []);
  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem('AuditCompliance'));
    if (savedFormData) {
      setValue('fromDate', savedFormData.fromDate);
      setValue('toDate', savedFormData.toDate);
      // setValue('selectedType', savedFormData.selectedType); // Uncomment if you have a selectedType field
      setFromDate(dayjs(savedFormData.fromDate));
      setToDate(dayjs(savedFormData.toDate));
    }
  }, [setValue]);
  const onSubmit = (data) => {
  
    if (!data.fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!data.toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (toDate < fromDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_GREATER_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    localStorage.setItem('AuditCompliance', JSON.stringify(data));

    const postData = {
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      to: dayjs(toDate).format("YYYY-MM-DD"),
      type: "CR",
    };
    request({ url: REPORT_CR_MR, method: "post", data: postData })
      .then((res) => {
        // setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          let filteredData = [];
          if (type === "CR") {
            filteredData = res.data.data;
            console.log(filteredData);
            localStorage.setItem("complainceReport", JSON.stringify(filteredData));
            setListData(filteredData);
            const imageURLs = filteredData.map((item,index) => item.fileUrl);
            // setImagePreview(filteredData[27].fileUrl)
            // setImagePreview(imageURLs)
            setImagePreview(filteredData.fileUrl)

            fetchImagesForCompliance(filteredData)
            const imageURL = filteredData.map((item) => item.fileUrl);
            console.log(filteredData,"ffffffffffff");

            setArrayImage(imageURL);
          } else {
            throw new Error("Failed to fetch data");
          }

          setTableData(res.data.data);
          setIsLoading(false);
          // sessionStorage.setItem(
          //   "auditCrMrTableData",
          //   JSON.stringify(res.data.data)
          // );
        }else{
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          setIsLoading(false);
          console.log("error.response.status" + error.res.status);
        }
      });
  };

  const getFile = async () => {
    try {
      // const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getFile();
  }, [logoUrl]);
  // list image 
  // const getFilePreview = async () => {
  //   console.log("prr",imagePreview);
  //   try {
  //     const storedToken = token;
  //     const response = await axios.get(`${BASE_URL_IMAGE}/${imagePreview}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //       responseType: "blob",
  //     });
  //     const blob = response.data; // Accessing the blob data from the response
  //     const fileURL = URL.createObjectURL(blob);
  //     setListPreview(fileURL);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(() => {
  //   getFilePreview();
  // }, [imagePreview]);
  // const fetchImagesForCompliance = async (complianceList) => {
  //   try {
  //     const storedToken = token;
  //     const previews = {};

  //     await Promise.all(complianceList.map(async (compliance) => {
  //       const imageUrls = compliance.imageUrls || [];
  //       const imageBlobs = await Promise.all(imageUrls.map(async (url) => {
  //         const response = await axios.get(`${BASE_URL_IMAGE}/${url}`, {
  //           headers: {
  //             Authorization: `Bearer ${storedToken}`,
  //           },
  //           responseType: "blob",
  //         });
  //         return URL.createObjectURL(response.data);
  //       }));
  //       previews[compliance.complainceId] = imageBlobs;
  //     }));

  //     setImagePreview(previews);
  //   } catch (error) {
  //     console.error("Error fetching images:", error);
  //   }
  // };
  const handleFromDateChange = (selectedFromDate) => {
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
    setToDate("");
  };
  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);
  };
  const [tableData, setTableData] = useState([]);


  useEffect(() => {
    // Retrieve data from local storage
    const storageData = localStorage.getItem("complainceReport");
    if (storageData) {
      // Parse the JSON data
      const parsedData = JSON.parse(storageData);
      // Set the parsed data in the state
      setListData(parsedData);
      console.log("parsed", parsedData);
    }
  }, []);
  
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const navigate = useNavigate();
  // const handleViewClick = (selectedRow) => {
  //   navigate(`/viewmaintenance/${selectedRow.original.maintenanceId}`);
  // };

  // const handleViewClick = (selectedRow) => {
  //   navigate(`/maintenance/${selectedRow.row.original.maintenanceId}`);
  //   console.log(selectedRow.row.original.maintenanceId);
  // };
  const handleViewClick = (selectedRow) => {
    navigate(`/auditcr/${selectedRow.row.original.complainceId}`);
    console.log(selectedRow.row.original.complainceId);
  }; const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const auditCrMrTable = useMemo(
    (rowData, index) => [
      {
        accessorKey: "crId",
        header: "Compliance Id",
      },
      // {
      //   accessorKey: "assignedToAddresss",
      //   header: "Address",
      //   accessorFn: (row) => row.createdByDetails ? row.createdByDetails.address || "" : "",
      //     sortDescFirst: true,
      // },
      // {
      //   accessorKey: "createdToName",
      //   header: "Created By",
      //   accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
      //     sortDescFirst: true,
       
      // },
      {
        accessorKey: "createdToName",
        header: "Created To",
        accessorFn: (row) => `${row.createdToDetails? row.createdToDetails.firstName : ""} ${row.createdToDetails? row.createdToDetails.lastName : ""} `,
          sortDescFirst: true,
       
      },
      {
        accessorKey: "assignedToAddress",
        header: "Created To Address",
        accessorFn: (row) => row.createdToAddress ? row.createdToAddress.address || "" : "",
          sortDescFirst: true,
      },
      {
        accessorKey: "Category",
        header: "Category",
        accessorFn: (row) => row.categoryDetails ? row.categoryDetails.category || "" : "",
          sortDescFirst: true,
      },
      // {
      //   accessorKey: "createdTo",
      //   header: "Created To",
      //   // Cell: ({ row }) =>
      //   //   `${row.original.createdByDetails.firstName} ${row.original.createdByDetails.lastName}`,
      //   Cell: ({ row }) => {
      //     if (row.original.createdToDetails === null) {
      //       return ''; // If appliedToDetails is null, return an empty string
      //     } else if (Array.isArray(row.original.createdToDetails)) {
      //       // If appliedToDetails is an array, construct full names
      //       return row.original.createdToDetails.map(detail => `${detail.firstName} ${detail.lastName}`).join(', ');
      //     } else {
      //       // If appliedToDetails is an object, construct the full name
      //       return `${row.original.createdToDetails.firstName || ''} ${row.original.createdToDetails.lastName || ''}`;
      //     }
      //   }
      // },

      // {
      //   accessorKey: "type",
      //   header: "Type",
      //   Cell: ({ row }) => {
      //     const crId = row.original.complainceId;
      //     const mrId = row.original.maintenanceId;

      //     return (
      //       <span>
      //         {type === "ALL"
      //           ? `${crId ? "Compliance Request" : ""} ${
      //               mrId ? "Maintenance Request" : ""
      //             }`.trim()
      //           : type === "CR" && crId
      //           ? "Compliance Request"
      //           : type === "MR" && mrId
      //           ? "Maintenance Request"
      //           : ""}
      //       </span>
      //     );
      //   },
      // },
      {
        accessorKey: "description",
        header: "Description",
        accessorFn: (row) => {
          const description = row && row.description;
          if (description && description.length > 28) {
            return description.slice(0, 28) + "...";
          }
          return description;
        }
      },
      {
        accessorKey: "status",
        header: "Status",
        accessorFn: (row) => convertToPascalCase(row.status),

      },

      {
        accessorKey: "createdDateAndTime",
        header: "Created Date",
        size: 20,
        accessorFn: (row) =>
          moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      },
      {
        accessorKey: "auditDateAndTime",
        header: "Audit Date",
        size: 20,
        accessorFn: (row) =>
          moment(row.auditDateAndTime).format("MM/DD/YYYY"),
      },
      
    ],
    [type]
  );
  // const rowActions = (row) => {
  //   console.log(row.original.complainceId);
  //   const actions = [];

  //   if (row.original.maintenanceId) {
  //     actions.push({
  //       label: "View",
  //       icon: <ListAlt color="primary" />,
  //       click: () => handleViewClick(row),
  //     });
  //   }
  //   if (row.original.complainceId) {
  //     actions.push({
  //       label: "View",
  //       icon: <ListAlt color="primary" />,
  //       click: () => handleCrViewClick(row),
  //     });
  //   }
  //   return actions;
  // };
  const currentDate = moment(); // Get the current date
  let dateTimeText = currentDate.format("DD MMMM YYYY");
  const handleRenderPDF = () => {
    if (!listData || listData.length === 0) {
      console.error('No data available for PDF rendering.');
      return;
    }

    const styles = {
      title: {
        fontSize: 18,
        color: "black",
        textAlign: "center",
        padding: "5px",
      },
      emptys: {
        marginTop: "3%",
      },emptyModule:{
        marginTop:"7px"
       },
      content: {
        fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
           adress:{
            fontSize: 12,
            color: "black",
            textAlign: "left",
            
            // paddingLeft:"60px",
            padding:"5px",
            // fontWeight:"bold"
          },
      description: {
        fontSize: 15,
        color: "green",
        textAlign: "left",
        paddingLeft: "60px",
        padding: "5px",
        fontWeight: "bold",
      },
      signature: {
        fontSize: 14,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
      headers: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "4px",
      },
      heading: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Oswald",
      },
      afterfrom: {
        marginTop: "8%",
      },
      from: {
        fontSize: 11,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "2px",
      },
      empty: {
        marginTop: "128%",
      },
      secondEmpty: {
        marginTop: "30%",
        fontSize:"20px",
        color:"red"
      }, headerImage:{
        marginTop:"1%",
   paddingLeft:"70px",
      paddingRight:"50px",
      alignItems:"center",
  
        width:"470px",
        height:"160px",
        // backgroundColor:"yellow"
      },
      formempty: {
        marginTop: "49%",
      },
      fromBorder: {
        border: "1px solid black",
      },
      description:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      toBorder: {
        border: "1px solid black",
      },letterPad:{
        marginTop:"30%"
      },images:{
        width:"200px",
        height:"100px"
        
            }
    };
    const renderPageContent = (details) => {
      const letterContent = [];
    
      if (details && details.complainceId ) {
        // logo:imageBlob
       
        letterContent.push(
          // Section for Compliance Request Notice
          {
            type: "text",
            text: groupAdress,
            style: styles.title,
          },

          {
            type: "text",
            text: "Compliance Request",
            style: styles.title,
          },
          { type: "text", text: " " },
          // { type: 'text', text: dateTimeText, style:styles.from },
          { style: styles.fromBorder },
          { type: "text", text: "", style: styles.formempty },
          { type: "text", text: "From", style: styles.from },
          { type: 'text', text: groupAdress, style: styles.from },
          { type: "text", text: footerAdress, style: styles.from },
          // {type:'text',text:dateTimeText,style: styles.content},
          { type: 'text',text: "",  style:styles.emptyModule},

         
          { type: "text", text: "", style: styles.afterfrom },

          // { text: " "  },
          // { text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.adress, fontSize: 15 },
          { type: "text", text: "To :", style: styles.headers },
          // { type: 'text', text: "From", style: styles.headers  },
          // { type: 'text', text: "From", style: styles.headers  },
          {
            type: "text",
            text: `${
              (details &&
               details.createdToDetails &&
               (details.createdToDetails.firstName || '') + 
               (details.createdToDetails.firstName && details.createdToDetails.lastName ? ' ' : '') +
               (details.createdToDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text:  `${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.createdToAddress?.address?? "") ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city) ||
              ""
            }${
              details && details.createdToDetails && details.createdToDetails.city && details.createdToDetails.zipcode
                ? ", "
                : ""
            } ${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },

          { type: "text", text: "", style: styles.empty },
          { type: "text", text: "", style: styles.secondEmpty },

          {type: 'image',src:imageBlob, style:styles.headerImage  },
          { type: 'text', text: groupAdress, style: styles.title },
          { type: 'text', text: "Compliance Request", style: styles.title },
          {type:'text',text:dateTimeText,style: styles.content},
          
          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: `${
              (details &&
               details.createdToDetails &&
               (details.createdToDetails.firstName || '') + 
               (details.createdToDetails.firstName && details.createdToDetails.lastName ? ' ' : '') +
               (details.createdToDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },

          {
            type: "text",
            text: `${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.createdToAddress?.address?? "") ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city) ||
              ""
            }${
              details && details.createdToDetails && details.createdToDetails.city && details.createdToDetails.zipcode
                ? ", "
                : ""
            } ${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: 'text',
            text: `RE: ${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.createdToAddress?.address?? "") ||
              " "
            }`,
            style: styles.re
          },
          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: "Dear Resident,",
            //  ${
            //   (details &&
            //     details.createdByDetails &&
            //     details.createdByDetails.firstName) ||
            //   " "
            // }`   
            
            style: styles.content,
            fontSize: 15,
          },
          // {
          //   type: "text",
          //   text: "You have previously asked to address the following matter with your property.",
          //   style: styles.content,
          // },
          {
            type: "text",
            text: `This is to inform you about the compliance request: ${
              details?.crId ?? ""
            }_${moment(details?.createdDateAndTime).format('MM/DD/YYYY hh:mm A')}`,
            style: styles.content,
          },
          {
            type: "text",
            text: `${details && details.description}`,
            style: styles.description,
            
          },
          {
            type: "text",
            text: "If you have any questions, feel that you have received this letter in error, or would like to request a variance, please feel free to contact our office.",
            style: styles.content,
          },
          {
            type: "text",
            text: "Thank you in advance for your attention to this matter.",
            style: styles.content,
          },
          { type: "text", text: "" },
          { type: "text", text: "", style: styles.content },
          { type: "text", text: "", style: styles.content },
          // {  type: 'text',text: "Sincerely,", style: styles.signature },
          // { type: 'text',text: footerContent , style: styles.adress}
          {type: 'text', text: "Sincerely,", style: styles.adress },
          { 
            type: "text", 
            text: `${footerAdress} (computer generated letter)`, 
            style: styles.adress 
          },
          
        );
        }
        if (details.attachments && Array.isArray(details.attachments) && details.attachments.length > 0) {
          letterContent.push({ type: "text", text: "Attachments:", style: styles.content });
        
          details.attachments.forEach((attachment) => {
            const fileRemoval = attachment.file.substring(12);
        
            letterContent.push(
              { type: 'text', text: fileRemoval, style: styles.content },
              {
                type: 'image',
                src: `${BASE_URL_IMAGE}/${attachment.file}`,
                style: styles.images
              }
            );
          });
        } else {
          letterContent.push({ type: "text", text: " ", style: styles.content });
        }
      // const complianceImages = listPreview[details.complainceId] || [];
      // console.log("imjj",complianceImages);
      // complianceImages.forEach((imageSrc, index) => {
      //   letterContent.push({
      //     type: "image",
      //     src: imageSrc,
      //     style: { width: '200px', height: '200px', margin: '10px' }
      //   });
      // });
    
    return letterContent;
  };

  const logo = imageBlob;
  const data = listData.map(details => ({
    letterContent: renderPageContent(details)
  }));

  return (
    <PDFDocumentList data={data} styles={styles} logo={logo} />
  );
};   
  const handleExportData = async () => {
    // console.log("test",logoUrl);
    try {
      const pdfData = handleRenderPDF();
      const blob = await pdf(pdfData).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = 'Complaince.pdf';
      // a.target = '_blank';
      // a.click();

      // URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  // const rowActions = [
  //   {
  //     label: "View",
  //     icon: <ListAlt color="primary" />,
  //     click: (row) => handleViewClick(row),
  //   },
  // ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    boolDisplay: { true: "Yes", false: "No" },
  });
  const handleExportComplianceCsv = () => {
    const excludedProperties = [
      "homeId",
      "groupId",
      "homeOwnerDuesId",
      "createdHomeId",
      "homesDetails","topic","mrId",
      "documentId","publishDate","isPublic","isHidden","externalUserDetails","isUrgent","type",
      "auditBy",
      "title",
      "categoryDetails",
      "homeOwnerDetails",
      "requestForAccess",
      "id",
      "attachments",
      "dueAmount",
      "dueDate",
      // "createdBy",
      "isOnRent",
      "fileUrl",
      "homeOwnerLoginId",
      "homeOwnerDetailsId",
     "createdHomeId",
  "dueAmount",
  "dueDate",
  "attachments",
      "complainceId",
     
     "address",
     "createdToName",
     "createdByHomeAddress",
      "reviewComments",
      
      "createdToDetails",
      "byLawDetails",
      "createdToDetails",
      "createdByName",
      "homeOwner",
      "createdByAddress",
      "createdBy",
      "bylawId",
      "parentId",
      "comments",
      "bylawDetails",
      "auditDateAndTime",
      "isAssigned","totalAmount","homeDetails","createdByDetails",
      "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
     "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
    ];
  
   
  
    const formattedData = Object.values(listData).map((item) => {
      const formattedDatetime = moment(item.createdDateAndTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
      const formattedDateAudit = moment(item.auditDateAndTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
   const categorys=item.categoryDetails?`${item.categoryDetails.category}`:""
        const createdToAddress = item.createdToAddress
        ? `${item.createdToAddress.address}`
        :  "";
        const createdTo = item.createdToDetails
        ? `${item.createdToDetails.firstName} ${item.createdToDetails.lastName}`
        : '';
        const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
      const filteredItem = {
        ...item,
        // createdBy:createdBy,
        // createdByAddress:createdByAddress,
        category:categorys,
        createdDateAndTime: formattedDatetime,
        createdToAddress:createdToAddress,
        AuditDateAndTime:formattedDateAudit,
        ...(createdTo && { createdTo }),
        type: type,
              };
  
      excludedProperties.forEach((property) => {
        delete filteredItem[property];
      });
  
      return filteredItem;
    });
  
    const csvConfigWithFilename = {
      ...csvConfig,
      filename: 'compliance Report' 
    };
  
    const csv = generateCsv(csvConfigWithFilename)(formattedData);
  download(csvConfigWithFilename)(csv);
  };
  const tableActionButtons = (
    <>
      {listData && listData.length > 0 && (
        <>
        <Button
        color="primary"
        variant="contained"
        onClick={() => handleExportComplianceCsv(listData)}
        startIcon={<FileUploadIcon/>}
      >
        <span className={CustomStyle.containedButtonStyle}>
          Export All Data
        </span>
      </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleExportData}
          startIcon={<FileUploadIcon />}
        >
          <span className={CustomStyle.containedButtonStyle}>Export As Pdf</span>
        </Button>
        </>
      )}
    </>
  );

  return (
    <div>
      <CustomHeading title="Audit" />
      {isLoading && <CustomLoading />}
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomDatePicker
                fieldName="fromDate"
                label="From Date"
                fieldRequiredIcon="*"
                control={control}
                value={fromDate}

                // requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => handleFromDateChange(date)}
                tooltipMessage="Select from date"

              />

              <CustomDatePicker
                fieldName="toDate"
                label="To Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                value={toDate}

                onChange={handleToDateChange}
                tooltipMessage="Select to date"

              />

              {/* <Controller
                name="dueFrequency"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please select type{" "}
                      <span style={{ color: "red" }}> * </span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={"CR"}
                      onChange={(e) => {
                        setType(e.target.value);
                        field.onChange(e.target.value);
                      }}
                      label="Select type"
                    >
                      <MenuItem value="CR">Compliance Request</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              /> */}
            </section>
            <div class="text-left mt-10">
              <CustomButton
                fieldType="submit"
                onClick={handleSubmit(onSubmit)}
                buttonName="Search"
              />
            </div>
          </form>
        </CardContent>
      </Card>
      <br />
      <br />

      <CustomMaterialWithoutActons
        columns={auditCrMrTable}
        data={listData}
        // rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default Audit_Cr;
