import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DateCalendar,
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomInputField from "../components/CustomInputField";
import { AMOUNT_INVALID_MESSAGE, AMOUNT_REQUIRED_MESSAGE,ADDRESS_INVALID_MESSAGE, DESCRIPTION_INVALID_MESSAGE, DESCRIPTION_REQUIRED_MESSAGE, DUE_DATE_REQUIRED_MESSAGE, DUE_FREQUENCY_REQUIRED_MESSAGE, DUE_TYPE_REQUIRED_MESSAGE, HOME_ADDRESS_REQUIRED_MESSAGE, MAX_LENGTH_HUNDRED, MAX_LENGTH_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_VALIDATE_MESSAGE, SPECIAL_ASSESSMENT_DUE_TYPE, TITLE_INVALID_MESSAGE, TITLE_REQUIRED_MESSAGE, END_DATE_REQUIRED_MESSAGE, END_DATE_REQUIRED_MESSAGES, MAX_LENGTH_FIVE_HUNDRED } from "../utills/ApplicationConstants";
import { ALPHABETIC_WITH_SPECIAL_CHARACTERS, ALPHANUMBERIC_ALL_SPECIAL_CHARACTERS, ALPHANUMERIC_PUNCHUCATION_SLASH_UNDERSCORE, FIVE_DIGITS_TWO_DECIMAL, ONLY_ALPHABETIC_REGEX_PATTERN, ALPHANUMERIC_PUNCTUATION_SLASH_UNDERSCORE_WITH_SPACE } from "../utills/ApplicationRegex";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../components/CustomButton";
import CustomRadioButton from "../components/CustomRadioButton";
import CustomDatePicker from "../components/CustomDatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { BASE_URL, PAYMENT_CONFIG_LIST, HOMES, HOMES_DETAILS } from "../utills/ApplicationRouting";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { request } from "../services/AxiosConfig";
import CustomAutoCompleteEdit from "../components/CustomAutoCompleteEdit";
import CustomDateEditPicker from "../components/CustomDateEditPicker";
import CustomToastContainer from "../components/CustomToastContainer";
import { isValidPhoneNumber } from 'react-phone-number-input';
import CustomLoading from"../components/CustomLoading";
import CustomInputFielddueandamount from "../components/CustomInputFielddueandamount";
import CustomTooltipField from "../components/CustomTooltipField"
import { InfoOutlined } from "@mui/icons-material";
import HomesAutoComplete from "../components/Homesautocomplete";
import { Title } from "chart.js";
import CustomModel from "../components/CustomModel";

function EditPaymentConfig() {
  const [dueType, setDueType] = React.useState("");
  const [dueFrequency, setDueFrequency] = React.useState("");
  const [PaymentConfigData, setPaymentConfigData] = React.useState([]);
  const { token } = useSelector(state => state.hybridhoa);
  const [dueAmount, setDueAmount] = React.useState('');
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [description, setDescription] = React.useState('');
  const [dueFrequencyData, setDueFrequencyData] = React.useState('');
  const [dueTypeData, setDueTypeData] = React.useState('');
  const [homeOwner, setHomeOwner] = React.useState('');
  const [specialAssessmentDueType, setSpecialAssessmentDueType] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState('');
  const [specialAssessmentDueTypeName, setSpecialAssessmentDueTypeName] = React.useState('');
  const [selectedHomeAddress, setSelectedHomeAddress] = useState("");
  const [title, setTitle] = useState("");
  const [dueDate, setDueDate] = React.useState('');
  const { groupDuesId } = useParams();
  const [HomeDetails, setHomeDetails] = React.useState([]);
  const [isForAllHomes, setIsForAllHomes] = React.useState(false);
  const [homeName, setHomename] = useState('');
  const navigate = useNavigate();
  const [homeOwnerDetailsId, setHomeOwnerDetailsId] = React.useState('');
  const [homeOwnerLoginId, setHomeOwnerLoginId] = React.useState('');
  const[isLoading,setIsLoading]=React.useState(false)
  const [endDate,setEndDate]=useState("")
  const [homesData, setHomesData] = useState([]);
  const [homeDetailss, setHomeDetailss] = useState("");
  const [dueDateSelected, setDueDateSelected] = useState(false);
  const [selectedValue, setSelectedValue] = useState(''); // default state
  const [hasSelected, setHasSelected] = useState(false);
  const [disableFrequency,setDisableFrequency]=useState("")
  const [showModal, setShowModal] = useState(false);

  const handleRadioChange = (event) => {
    if (dueType==="SPECIAL_ASSESSMENT_DUE"){
    const newValue = event.target.value === 'true';
    console.log("newvalue", newValue);
    setSelectedValue(newValue ? 'For All Homeowners' : 'For a single Homeowner');
    setIsForAllHomes(newValue);
    setDueDate('')
    setEndDate('')
    }else{
      setSelectedValue(false)
    }
  };

useEffect(()=>{
  if (dueType !== "SPECIAL_ASSESSMENT_DUE"){
    setIsForAllHomes(false)
  }else if(selectedValue==='For All Homeowners'){
    setIsForAllHomes(true)
  }

  console.log("selectedValue",isForAllHomes);
  console.log("dueType",dueType);
  
},[isForAllHomes,dueType])

  const handleClick = () => {
    navigate(-1);
  };


 const [hasSelection,setHasSelection]=useState(false)
  const [touched, setTouched] = useState(false);

  const handleDueChange = (event) => {
    const selectedValue = event.target.value;
    
    setDueType(selectedValue);
    setHomename('');
    setHomeOwnerDetailsId('')
setHasSelection(true)
      setDueFrequency('')
      setDueDate('')
      setEndDate('')
  };

  const [dueFrqSele,setDueFrqSele]=useState(false)
  const handleDueFrequencyChange = (event) => {
    setDueFrequency(event.target.value);
    setHasSelected(true);

    if(dueType==="PAST_DUE" || dueType==="CUSTOM_DUE"){
      setDueFrequency("ONE_TIME")
    }
  };

  const handleDisableFrequencyChange = (event) => {
    setDisableFrequency("ONE_TIME");
  };
  const handleEditDuedate = (selectedDuedateData) => {
    setDueDate(selectedDuedateData);
    setDueDateSelected(true);
    if (endDate && dayjs(selectedDuedateData).isAfter(endDate)) {
      setEndDate(""); // Clear end date if due date is after the current end date
    }
    // Re-validate the end date when due date changes
    const newMaxEndDate = dayjs(selectedDuedateData).add(1, "year").endOf("day");
    if (endDate && dayjs(endDate).isAfter(newMaxEndDate)) {
      setEndDate(""); // Clear end date if it is more than one year after the new due date
    }
};

// const minEndDate = dueDate ? dayjs(dueDate).startOf("day") : dayjs().startOf("day");
// const minEndDate = (isForAllHomes || dueType === 'REGULAR_DUE') ? dayjs().add(1, 'day') : dueDate ? dayjs(dueDate).startOf("day") : dayjs().startOf("day");

const dayjs = require('dayjs'); // Import dayjs if using Node.js

const today = dayjs().startOf("day");
const dueDateDayjs = dayjs(dueDate).startOf("day"); // Parse the due date



let minEndDate;
let maxEndDate;
// Determine the minimum end date based on the conditions
if ((isForAllHomes || dueType === 'REGULAR_DUE') && dueDateDayjs.isBefore(today)) {
    // If it's for all homes or regular due, set to tomorrow
    minEndDate = today.add(1, 'day');
} else if ((isForAllHomes || dueType === 'REGULAR_DUE') && dueDateDayjs.isAfter(today)) {
    // If due date is after today, set to due date + 1 day
    minEndDate = dueDateDayjs.add(1, 'day');
} else {
    // In case due date is today, set to tomorrow
    minEndDate = dueDate ? dayjs(dueDate).startOf(1, "day") : dayjs().startOf(1, "day"); // Optional fallback, if due date is today
}

// Output the minimum end date
console.log(minEndDate.format("YYYY-MM-DD")); // This should give you the correct minimum end date

if ((isForAllHomes || dueType === 'REGULAR_DUE') && dueDateDayjs.isBefore(today)) {
  // If it's for all homes or regular due, set to tomorrow
  maxEndDate = today.add(1, "year").endOf("day");
} else if ((isForAllHomes || dueType === 'REGULAR_DUE') && dueDateDayjs.isAfter(today)) {
  // If due date is after today, set to due date + 1 day
  maxEndDate = dueDateDayjs.add(1, "year").endOf("day");
} else {
  // In case due date is today, set to tomorrow
  maxEndDate = dayjs(dueDate).add(1, "year").endOf("day") ; // Optional fallback, if due date is today
}

// const maxEndDate = dayjs(dueDate).add(1, "year").endOf("day");

const handleEndDateChange = (selectedEndDate) => {
    const newMaxEndDate = dueDate ? dayjs(dueDate).add(1, "year").endOf("day") : null;
    if (selectedEndDate && newMaxEndDate && dayjs(selectedEndDate).isAfter(newMaxEndDate)) {
        setEndDate(""); // Clear end date if it is more than one year after the due date
    } else {
        setEndDate(selectedEndDate || ""); // Set end date or clear if null

        const currentDate = dayjs();
        const daysDifference = currentDate.diff(selectedEndDate, 'day');
        if (daysDifference >= 30) {
          setShowModal(true); // Show modal if 30 days before current date
        }
    }
};


const handleConfirmationClose = (event) => {
  if (event) {
    event.preventDefault();
  }
  setShowModal(false);
};


const handleConfirmationConfirm = () => {
  setShowModal(false);
};

const bmCardModelAction = (
  <>
    {/* <CustomButton
      buttonName="Cancel"
      click={handleConfirmationClose}

    /> */}
    <CustomButton
      fieldType="submit"
      buttonName="OK"
      click={handleConfirmationClose}
    />

  </>
);

const bmCardContent = (
  <>
    <form>
      <DialogContent dividers>
        <div className="flex mb-5 mt-5">You have selected a last date that is more than 30 days in the past. A late fee will be applied for this. Do you want to continue?</div>
      </DialogContent>


      <DialogActions>{bmCardModelAction}</DialogActions>
    </form>
  </>)

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue, setError, watch,reset
  } = useForm();
console.log("errors",errors);

  const getHomes = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${HOMES}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        // Filter out homes with null homeOwnerDetails
        
        setHomesData(data);

        

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {

    getHomes();
  }, []);

  // const homeArray = Array.isArray(HomeDetails) ? HomeDetails : [];

  // Now you can use map on homeArray
  const renderedAddresses = homesData
    .map((home, index) => ({
      address: home.address,
      value: home.homeId,
      key: index,
    }));

  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homesData.find(
        (home) => home.homeId === selectedHomeId
      );
        const homeOwner = selectedHome;
       
        if (homeOwner) {
          const { name, homeOwnerDetailsId,homeOwnerLoginId } = homeOwner;
            const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
            console.log("Selected Home Description:", selectedHomeDescription);

            // Set the selected home details to the state if selectedHomeDescription is not null
          
                setHomeDetailss(selectedHomeDescription);
           console.log("homeOwnerDetailsId",homeOwnerDetailsId);
            setValue("selectedOption", selectedHomeId);
            setHomeOwnerDetailsId(homeOwnerDetailsId)
      setHomeOwnerLoginId(homeOwnerLoginId)
            // You can also do other operations if needed
            console.log("Selected Home:", selectedHome);
            console.log("Home Details:", homeAddresses);
        } else {
            setHomeDetailss("");
            // setValue("selectedOption", null);
            setValue("selectedOption", selectedHomeId);
        }
    } else {
        // Handle the case when the selected option is removed (e.g., by pressing backspace)
        setHomeDetailss("");
        setHomename(null);
        setHomeOwnerDetailsId('');  
        // Use setValue to clear the selected homeId in the react-hook-form
        setValue("selectedOption", null);
    }
};

useEffect(()=>{
  console.log("select",selectedHomeAddress);
},[selectedHomeAddress])

  const getPaymentConfigbyId = async () => {
    console.log(groupDuesId);
    try {
      setIsLoading(true);
      const storedToken = token

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${PAYMENT_CONFIG_LIST}/${groupDuesId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        setIsLoading(false)
        const data = response.data;
        console.log(data);
        setDescription(data.description);
        setDueAmount(data.dueAmount);
        setDueFrequencyData(data.dueFrequency);
        setDueTypeData(data.dueType);
        setHomeOwner(data.homeOwner);
        setSelectedOption(data.selectedOption);
        setIsForAllHomes(data.isForAllHomes);
        setSelectedValue(data.isForAllHomes ? 'For All Homeowners' : 'For a single Homeowner');
        setSpecialAssessmentDueTypeName(data.specialAssType);
        setTitle(data.title);
        setDueType(data.dueType);
        setDueFrequency(data.dueFrequency);
        setDueDate(data.dueDate)
        setEndDate(data.endDate)
        setHomeOwnerDetailsId(data.homeOwnerDetailsId);
        setHomeOwnerLoginId(data.homeOwnerLoginId)
        // const home = data.homeDetails.address;
        // setSelectedHomeAddress(home);
        console.log(data.homeId);


        console.log('isForAllHomes set to:', data.isForAllHomes);

        const matchingHome = homesData.find(
          (home) => home.homeId === data.homeId
        );
        console.log("matchingHome", homesData.find(
          (home) => home.homeId));
         
        if (matchingHome) {
          setSelectedHomeAddress(matchingHome.address);
        setValue('selectedOption', matchingHome.homeId);
          const { name } = matchingHome;
          const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
          setHomeDetailss(selectedHomeDescription);
          console.log("address",matchingHome.address);
        } else {
          setSelectedHomeAddress(""); // Handle when matching home is not found
          setValue("selectedOption", ""); // Reset to an empty string if no matching home is found
          setHomeDetailss("");
        }


      } else {
        setIsLoading(false); 

        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      // Handle error
      setIsLoading(false); 

      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    getPaymentConfigbyId();
  }, [homesData])

  useEffect(()=>{
    console.log("isForAllHomes",isForAllHomes);
  },[isForAllHomes])

  useEffect(() => {
    const selectedHome = watch("selectedOption");
    console.log(selectedHome);

    if (!selectedHome || selectedHome === "") {
      setHomeDetailss("");
      // setIsBylawFieldEmpty(true);
    } else {
      // Find the matching byLaw from byLawDetails
      const matchingHome = homesData.find(
        (home) => home.homeId === selectedHome
      );
      console.log(matchingHome);

      if (matchingHome) {
        const {name} = matchingHome;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
        setHomeDetailss(selectedHomeDescription);

        // setIsBylawFieldEmpty(false);
      } else {
        // Handle the case where no matching byLaw is found
        setHomeDetailss("");
        // setIsBylawFieldEmpty(true);
      }
    }
  }, [watch("selectedOption"), homeDetailss]);


  const handletitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  }; 
  
  const handleAmountChange = (e) => {
    setDueAmount(e.target.value);
  };
  
  const handlespecialAssessmentDueTypeNameChange = (e) => {
    setSpecialAssessmentDueTypeName(e.target.value);
  };


  const renderedHomeIds = HomeDetails.map(address => address.homeId);

  const onSubmit = (data) => {



    if (!description || description.trim() === "") {
      // console.log("Please provide due date");
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
    
      return;
    }
    if (!dueType) {
      console.log("working")
      setHasSelection(false)
    setTouched(true)
      return;
    }


    if (!dueFrequency && (dueType === "REGULAR_DUE" || (dueType === "SPECIAL_ASSESSMENT_DUE" && isForAllHomes))){
      console.log("error")
      setHasSelected(false)
      setDueFrqSele(true)
      return;
    }
    if (!title || title.trim() === "") {
      // console.log("Please provide due date");
      setError("title", {
        type: "manual",
        message: TITLE_REQUIRED_MESSAGE,
      });

      return;
    }

    if (parseFloat(dueAmount) === 0 && !/^(0\.\d{1,2}|[1-9]\d{0,4}\.\d{1,2})$/.test(dueAmount)) {
      setError("dueAmount", {
        type: "manual",
        message: "Due Amount is invalid: It must be in the format '0.xx' or 'xxxxx.xx'. A value of '0' is not allowed.",
      });
      return;
    }

    if (!dueDate) {
      // console.log("Please provide due date");
      setError("dueDate", {
        type: "manual",
        message: DUE_DATE_REQUIRED_MESSAGE,
      });

      return;
    }
    if (!endDate) {
      // console.log("Please provide due date");
      setError("endDate", {
        type: "manual",
        message: END_DATE_REQUIRED_MESSAGES,
      });

      return;
    }
    if (!dueAmount) {
      setError("dueAmount", {
        type: "manual",
        message: AMOUNT_REQUIRED_MESSAGE,
      });
      return;
    }
    console.log("1 Selected option:", data.selectedOption);
    console.log("2 Rendered addresses:", renderedHomeIds);
    
    // Check if data.selectedOption is not empty
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

console.log("Selected option:", data.selectedOption);


console.log("3 Rendered addresses:", renderedHomeIds);

 
console.log("isForAllhomes",isForAllHomes);

    setIsLoading(true); 

    const postData = {
      description: description,
      dueAmount: dueAmount,
      dueType: dueType,
      dueFrequency: dueType === 'REGULAR_DUE' || isForAllHomes ? dueFrequency : "ONE_TIME",
      homeOwner: data.homeOwner,
      specialAssType: specialAssessmentDueTypeName,
      isForAllHomes: dueType === 'REGULAR_DUE' || (dueType === 'SPECIAL_ASSESSMENT_DUE' && isForAllHomes) ? true : false,
      title: title,
      dueDate: dueDate ? dayjs(dueDate).format("YYYY-MM-DD") : '',
      endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : '',
      homeId: data?.selectedOption || null,
      homeOwnerDetailsId: homeOwnerDetailsId,
      homeOwnerLoginId: homeOwnerLoginId,
    };
    

    request({ url: `${PAYMENT_CONFIG_LIST}/${groupDuesId}`, method: "put", data: postData }).then(res => {
      // setIsLoading(false); 
      console.log(res);
      if (res.status == 200) {
        setTimeout(() => {

          setIsLoading(false); 
          navigate(-1);
        }, 5000);

      }else{
        setIsLoading(false); 
      }
    }).catch(error => {
      console.log(error.response);
      setIsLoading(false); 
      if (error.response) {

        console.log("error.response.status" + error.response.status);


      }
    });
    //   console.log(data.firstName);
    //   console.log(data.lastName);
    //   console.log(data.email);
    //   console.log(data.phoneNumber); // Added line to log phone number
    // console.log(data.role);
  };
  const minDate = dayjs().subtract(1, "year").startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");

  const renderTooltip = (message) => (
    <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );


  return (
    <div>

<CustomModel
        title="Warning"
        content={bmCardContent}
        action={bmCardModelAction}
        openStatus={showModal}
        // reset={reset}
        closeStatus={handleConfirmationClose}
        iconCloseStatus={handleConfirmationClose}
      />
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Edit HOA Dues" />
        {isLoading && <CustomLoading />}

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomTooltipField
                fieldName="title"
                // requiredErrorMessage={TITLE_REQUIRED_MESSAGE}
                // fieldPattern={ALPHANUMERIC_PUNCTUATION_SLASH_UNDERSCORE_WITH_SPACE}
                // patternErrorMessage={TITLE_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={500}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                fieldId="title"
                fieldType="text"
                fieldLabel="Title"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={title}
                multiline={true}
                numberOfRows={1}
                onInput={handletitleChange}
                tooltipMessage={"Enter the title for the due"}
               />

              <CustomTooltipField
                fieldName="description"
                // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={1000}
                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                fieldId="description"
                fieldType="text"
                fieldLabel="Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={1}
                value={description}
                onInput={handleDescriptionChange}
                tooltipMessage={ "Enter a detailed description of the due"}
              />

              <CustomInputFielddueandamount
                fieldName="dueAmount"
                // requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
                fieldPattern={FIVE_DIGITS_TWO_DECIMAL}
                patternErrorMessage={"Please provide Valid Due Amount (i.e xxxxx.xx)"}
                fieldId="dueAmount"
                fieldType="text"
                fieldLabel="Due Amount"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                // defaultValue={10}
                value={dueAmount}
                onInput={handleAmountChange}
                tooltipMessage={"Enter the due amount. This should be a valid monetary amount formatted as xxxxx.xx"}
              />

{/* <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
              <Controller
                name="dueType"
                control={control}

                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please Select Due Type<span style={{ color: 'red' }}> * </span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      // defaultValue={dueTypeData} 
                      value={dueTypeData}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleDueChange(e);
                      }}
                      label="Please Select Due Type"
                    >
                      <MenuItem value="REGULAR_DUE">
                        Regular Due(Applies to all Homeowners)
                      </MenuItem>
                      <MenuItem value="CUSTOM_DUE">Custom Due</MenuItem>
                      <MenuItem value="PAST_DUE">Past Due</MenuItem>
                      <MenuItem value="SPECIAL_ASSESSMENT_DUE">
                        Special Assessment
                      </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              />
</div>
  <div>
    {renderTooltip("Select the type of due. Regular dues apply to all homeowners, while custom and past dues are for specific cases. Special assessment dues and Regular dues are for unique, one-time assessments")}
  </div>
  </div> */}

<div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
  
        <FormControl
          variant="standard"
          fullWidth
          error={!hasSelection && touched}
        >
          <InputLabel id="demo-simple-select-standard-label">
            Please Select Due Type <span style={{ color: 'red' }}>*</span>
          </InputLabel>

          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={dueType}
            onChange={handleDueChange}
            label="Please Select Due Type"
          >
            <MenuItem value="REGULAR_DUE">
              Regular Due(Applies to all Homeowners)
            </MenuItem>
            <MenuItem value="CUSTOM_DUE">Custom Due</MenuItem>
            <MenuItem value="PAST_DUE">Past Due</MenuItem>
            <MenuItem value="SPECIAL_ASSESSMENT_DUE">
              Special Assessment
            </MenuItem>
          </Select>
        {!hasSelection && touched && <FormHelperText style={{ color: "#D32F2F" }}>
            {DUE_TYPE_REQUIRED_MESSAGE}
          </FormHelperText>}
        </FormControl>
     
  </div>
  <div>
    {renderTooltip("Select the type of due. Regular dues apply to all homeowners, while custom and past dues are for specific cases. Special assessment dues and Regular dues are for unique, one-time assessments")}
  </div>
</div>

              <CustomDateEditPicker
                fieldName="dueDate"
                label="Due Date"
                control={control}
                // requiredErrorMessage={DUE_DATE_REQUIRED_MESSAGE},;
                minDate={minDate}
                maxDate={maxDate}
                value={dueDate}
                required
                onChange={handleEditDuedate}
                fieldRequiredIcon="*"
                tooltipMessage={"Select the due date. This is the date by which the payment should be made"}
              />
              <CustomDateEditPicker
                  fieldName="endDate"
                  label="Last Date"
                  control={control}
                  // requiredErrorMessage={END_DATE_REQUIRED_MESSAGE}
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  onChange={handleEndDateChange}
                  fieldRequiredIcon="*"
                  value={endDate}
                  tooltipMessage={"Select the last date. This is the final date by which the payment can be made without penalties"}
                  disabled={!dueDate}
              />

              {/* {dueType === "REGULAR_DUE" || (dueType === "SPECIAL_ASSESSMENT_DUE" && isForAllHomes) ?
              <div className="flex items-center md:w-1/2 ">
              <div className="w-full ">
                <Controller
                  name="dueFrequency"

                  control={control}
                  // rules={{ required: DUE_FREQUENCY_REQUIRED_MESSAGE }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={fieldState.invalid}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Please Select Due Frequency
                        <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"

                        value={dueFrequencyData}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          handleDueFrequencyChange(e);
                        }}

                        label="Please Select Due Frequency"
                      >
                        <MenuItem value="MONTHLY">Monthly</MenuItem>
                        <MenuItem value="YEARLY">Yearly</MenuItem>
                        <MenuItem value="ONE_TIME">One Time</MenuItem>
                      </Select>
                      <FormHelperText style={{ color: "#D32F2F" }}>
                        {fieldState.invalid
                          ? fieldState.error?.message
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                /> </div>
                <div>
                  {renderTooltip("Select the frequency of the due. Choose whether the due is recurring monthly, yearly, or is a one-time payment")}
                </div>
              </div> : null} */}

{dueType === "REGULAR_DUE" || (dueType === "SPECIAL_ASSESSMENT_DUE" && isForAllHomes) ? (
        <div className="flex items-center md:w-1/2">
          <div className="w-full">
                <FormControl
                  variant="standard"
                  fullWidth
                  error={!hasSelected && dueFrqSele}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Please Select Due Frequency <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={dueFrequency}
                    onChange={handleDueFrequencyChange}
                    label="Please Select Due Frequency"
                  >
                    <MenuItem value="MONTHLY">Monthly</MenuItem>
                    <MenuItem value="YEARLY">Yearly</MenuItem>
                    <MenuItem value="ONE_TIME">One Time</MenuItem>
                  </Select>
                  {!hasSelected && dueFrqSele && ( // Show validation message only if the user has interacted
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {DUE_FREQUENCY_REQUIRED_MESSAGE}
                    </FormHelperText>
                  )}
                </FormControl>
              
          </div>
          <div>{renderTooltip("Select the frequency of the due. Choose whether the due is recurring monthly, yearly, or is a one-time payment")}</div>
        </div>
      ) : null}



              {dueType == "CUSTOM_DUE" ||
                dueType == "PAST_DUE" ? (
                <>
               
               <HomesAutoComplete
            fieldName="selectedOption"
            id="selectId"
            requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
            options={renderedAddresses}
            value={selectedHomeAddress}
            fieldLabel=" Select Home Address"
            fieldControl={control}
            setValue={setValue}
            onChange={handleHomeChange}
            fieldRequiredIcon="*"
            invalidOption="Please select valid home address"
    tooltipMessage="Select the home address to assign the due. This field helps in identifying which homeowner the due is applicable to. Upon selection, the homeowner's name will be displayed in the 'HomeOwner Name' field"
          />
<CustomTooltipField
                  fieldName="homeOwnerDetails"
                  fieldId="homeOwnerDetails"
                  fieldType="text"
                  fieldLabel="HomeOwner Name"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                  value={homeDetailss}
                  tooltipMessage={"This field displays the name of the homeowner associated with the selected home address"}
                />
               
                  {/* {dueType === 'CUSTOM_DUE' || dueType === 'PAST_DUE' ? 
                  // <div className="flex items-center md:w-1/2 ">
                  // <div className="w-full ">
                  //   <Controller
                  //     name="dueFrequency"

                  //     control={control}
                  //     // rules={{ required: DUE_FREQUENCY_REQUIRED_MESSAGE }}
                  //     render={({ field, fieldState }) => (
                  //       <FormControl
                  //         variant="standard"
                  //         fullWidth
                  //         // error={fieldState.invalid}
                  //       >
                  //         <InputLabel id="demo-simple-select-standard-label">
                  //           Please Select Due Frequency
                  //         </InputLabel>
                  //         <Select
                  //           disabled
                  //           labelId="demo-simple-select-standard-label"
                  //           id="demo-simple-select-standard"

                  //           defaultValue="ONE_TIME" 
                  //           value={field.value} // Use the controlled value from form state
                            
                  //           onChange={(e) => {
                  //             field.onChange(e.target.value);
                              
                  //           }}
                            
                  //           label="Please Select Due Frequency"
                  //         >
                  //           <MenuItem value="MONTHLY">Monthly</MenuItem>
                  //           <MenuItem value="YEARLY">Yearly</MenuItem>
                  //           <MenuItem value="ONE_TIME">One Time</MenuItem>
                  //         </Select>
                  //         {/* <FormHelperText style={{ color: "#D32F2F" }}>
                  //           {fieldState.invalid
                  //             ? fieldState.error?.message
                  //             : ""}
                  //         </FormHelperText> 
                  //       </FormControl>
                  //     )}
                  //   /> </div>
                  //   <div>
                  //     {renderTooltip("The Due Frequency field is preset to One Time and is disabled for Custom Due and Past Due types")}
                  //   </div>
                  // </div> :null*/}

{dueType === 'CUSTOM_DUE' || dueType === 'PAST_DUE' ? (
 <div className="flex items-center md:w-1/2">
 <div className="w-full">
   
       <FormControl
         variant="standard"
         fullWidth
       >
         <InputLabel id="demo-simple-select-standard-label">
           Please Select Due Frequency
         </InputLabel>
         <Select
           disabled
           labelId="demo-simple-select-standard-label"
           id="demo-simple-select-standard"
           value={disableFrequency || "ONE_TIME"}// Ensure value is always controlled
           onChange={handleDisableFrequencyChange} // No-op since the field is disabled
           label="Please Select Due Frequency"
         >
          <MenuItem value="MONTHLY">Monthly</MenuItem>
                    <MenuItem value="YEARLY">Yearly</MenuItem>
                    <MenuItem value="ONE_TIME">One Time</MenuItem>
         </Select>
       </FormControl>
 </div>
 <div>{renderTooltip("The Due Frequency field is preset to One Time and is disabled for Custom Due and Past Due types")}</div>
</div>

) : null}


                 
                </>
              ) : null}

              {dueType === "SPECIAL_ASSESSMENT_DUE" ?
                <>
               { !isForAllHomes ?
                //    <div className="flex items-center md:w-1/2">
                //    <div className="w-full">
                //      <Controller
                //        name="dueFrequency"
                //        control={control}
                //        defaultValue="ONE_TIME" // Ensure default value is set
                //        render={({ field }) => (
                //          <FormControl
                //            variant="standard"
                //            fullWidth
                //          >
                //            <InputLabel id="demo-simple-select-standard-label">
                //              Please Select Due Frequency
                //            </InputLabel>
                //            <Select
                //              disabled
                //              labelId="demo-simple-select-standard-label"
                //              id="demo-simple-select-standard"
                //              value={field.value !== undefined ? field.value : "ONE_TIME"}// Ensure value is always controlled
                //              onChange={() => {}} // No-op since the field is disabled
                //              label="Please Select Due Frequency"
                //            >
                //              <MenuItem value="ONE_TIME">One Time</MenuItem>
                //            </Select>
                //          </FormControl>
                //        )}
                //      />
                //    </div>
                //    <div>{renderTooltip("The Due Frequency field is preset to One Time and is disabled for Custom Due and Past Due types")}</div>
                //  </div>
                <div className="flex items-center md:w-1/2">
                <div className="w-full">
                  
                      <FormControl
                        variant="standard"
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Please Select Due Frequency
                        </InputLabel>
                        <Select
                          disabled
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={disableFrequency || "ONE_TIME"}// Ensure value is always controlled
                          onChange={handleDisableFrequencyChange} // No-op since the field is disabled
                          label="Please Select Due Frequency"
                        >
                         <MenuItem value="MONTHLY">Monthly</MenuItem>
                                   <MenuItem value="YEARLY">Yearly</MenuItem>
                                   <MenuItem value="ONE_TIME">One Time</MenuItem>
                        </Select>
                      </FormControl>
                </div>
                <div>{renderTooltip("The Due Frequency field is preset to One Time and is disabled for Custom Due and Past Due types")}</div>
               </div>
                 :null
                  }
                 
                  <CustomTooltipField
                    fieldName="specialAssessmentDueTypeName"
                    fieldType="text"
                    fieldId="specialAssessmentDueTypeName"
                    fieldLabel="Special Assessment Due Type Name"
                    fieldControl={control}
                    value={specialAssessmentDueTypeName}
                    minLength={2}
                    minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                    maxLength={1000}
                    maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                    fieldError={errors}
                    multiline={true}
                    numberOfRows={1}
                    validator={false}
                    onInput={handlespecialAssessmentDueTypeNameChange}
                    tooltipMessage={"Provide the name for the special assessment due type. This helps in identifying the nature of the special assessment"}
                  />

                  
<div className="flex items-center md:w-1/2 ">
<div className="w-full">
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="For All Homeowners"
    name="specialAssessmentDueType"
    value={selectedValue === 'For All Homeowners' ? 'true' : 'false'}
    onChange={handleRadioChange}
  >
    <FormControlLabel
      value="true"
      control={<Radio />}
      label="For All Homeowners"
    />
    <FormControlLabel
      value="false"
      control={<Radio />}
      label="For a single Homeowner"
    />
  </RadioGroup>
</div>

  <div>
    {renderTooltip("Specify whether this special assessment applies to all homeowners or a specific homeowner")}
  </div>
</div>


                     {isForAllHomes === false && (
                      <>
                   <HomesAutoComplete
                   fieldName="selectedOption"
                   id="selectId"
                   requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
                   options={renderedAddresses}
                   fieldLabel=" Select Home Address"
                   fieldControl={control}
                   value={selectedHomeAddress}
                   setValue={setValue}
                   onChange={handleHomeChange}
                   fieldRequiredIcon="*"
                   invalidOption="Please select valid home address"
           tooltipMessage="Select the home address to assign the due. This field helps in identifying which homeowner the due is applicable to"
                 />
                 <CustomTooltipField
                  fieldName="homeOwnerDetails"
                  fieldId="homeOwnerDetails"
                  fieldType="text"
                  fieldLabel="HomeOwner Name"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                  value={homeDetailss}
                  tooltipMessage={"This field displays the name of the homeowner associated with the selected home address"}
                />
                </>
                  )}

                </> : null

              }


            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}


export default EditPaymentConfig;
