import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../components/CustomViewPage";
import { useForm } from "react-hook-form";

import CustomInputField from "../components/CustomInputField";
import CustomButton from "../components/CustomButton";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,
  CATEGORY,
  COMPLIANCE_REQUEST,
  BASE_URL_IMAGE,
  GROUP_CONFIG,
} from "../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";

import Loading from "react-loading";
import CustomLoading from "../components/CustomLoading";

import {
  GENRAL_COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  DeleteOutline,
  EditNoteOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import CustomToastContainer from "../components/CustomToastContainer";
import { request } from "../services/AxiosConfig";
import CustomHeading from "../components/CustomHeading";
import CustomModel from "../components/CustomModel";
import PDFDocument from "../components/Templatepdf";
import CustomViewPages from "../components/CustomViewExportPage";
import { pdf } from "@react-pdf/renderer";
import Tooltip from "@mui/material/Tooltip";
import CustomViewHeading from "../components/CustomViewHeading";
import CustomViewReportToolTip from "../components/CustomViewReportToolTip";
import AttachmentList from "../components/AttachmentList";
import AttachmentListsView from "../components/AttachmentListView";
import CustomViewWithTooltip from "../components/CustomViewWithTooltip";
import CustomViewWithMore from "../components/CustomViewWithMore";
import CustomViewWithTooltipexpand from "../components/CustomViewWithTooltipexpand";
function ViewCompliance() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  const { token, userDetails } = useSelector((state) => state.hybridhoa);
  const [complianceRequestData, setComplianceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { complainceId } = useParams();
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [firstNameData, setFirstNameData] = useState("");
  const [fileUrl, setFileUrl] = useState([]);
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [crId, setCrId] = useState("");
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [createdBy, setCreatedBy] = useState("")
  const groupAdminId = userDetails.groupAdminLoginId;

  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState("");
  const [commentsId, setCommentsId] = useState("");
  const [address, setAddress] = useState("");
  const [groupConfigurationDetails, setGroupConfigurationDetails] = useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob, setImageBlob] = useState('');
  const [complainceDetails, setComplainceDetails] = useState('')
  const [fileImage, setFileImage] = useState("");
  const [fineApply,setFineApply] = useState("");
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  //linked useStates
  const [homeOwnerLoginId, setHomeOwnerLoginId] = useState("");
  const [linkedlistData, setlinkedlistData] = useState({});
  const [linkedStatus,setLinkedStatus]=useState("")
  const [fileUrls, setFileUrls] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false); 

  const [linkedReview,setLinkedReview]=useState([])
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data);
        const logoConfig = data.find(config => config.topic === 'Logo');
        const footerValue = data.find(config => config.topic === 'Footer');
        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        if (footerValue) {
          setFooterContent(footerValue.content);
        }
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getGroupConfigurationDetails()
  }, [logoUrl])
  const getFile = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getFile()
  }, [logoUrl])
  const userName = userDetails.firstName + " " + userDetails.lastName;

  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data);
        console.log(data);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getComplianceRequestDetailsById = async () => {
    try {
      const storedToken = token;
      setIsLoading(true);

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${COMPLIANCE_REQUEST}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setComplainceDetails(data)
        setCreatedBy(data.createdBy);
        const createdDetails = data.createdByDetails;
        setFileUrl(data.attachments);
        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setFineApply(data.isFineApplicable);

        setFileImage(data.fileUrl);
        setCreatedDateAndTime(formattedDate);
        setFirstNameData(createdDetails.firstName);
        setLastNameData(createdDetails.lastName);
        setComplianceRequestData(data);
        setAddress(data.address.address);
        console.log("data", data.address);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getComplianceRequestDetailsById();
  }, [complainceId]);
// linked Complaince
const getComplianceRequestDetailsLink = async () => {
  try {
    const storedToken = token;
    // const details = userLoginDetails;
    // const detailsId = details.homeOwnerLoginId;
    // console.log(detailsId);
    // setHomeOwnerLoginId(detailsId);
    console.log(storedToken);

    const response = await axios.get(
      `${BASE_URL}/complaince/link/${complainceId}`,
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    );
    console.log(response);

    if (response.status === 200) {
      const data = response.data;
      setlinkedlistData(data);
      setLinkedStatus(data.status)
      setLinkedReview(data.reviewComments)
      if (data.attachments && data.attachments.length > 0) {
        setFileUrls(data.attachments)
      }else{
        setFileUrls([])
      }
      setLinkComplainceId(data.complainceId);
      setSelectedLinkStatus(data.status)
      const formattedDate = moment(data.createdDateAndTime).format(
        "MM/DD/YYYY hh:mm A"
      );
      setCreatedDate(formattedDate);
      console.log(data);
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    // Handle error
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
  getComplianceRequestDetailsLink();
}, [complainceId]);
const handleToggleDescriptionLinked = () => {
  setShowFullDescription(!showFullDescription);
};
const convertToPascalCase = (str) => {
  // Check if the input string is undefined or empty
  if (str === undefined || str === '') return '';

  // Remove underscores and convert string to Pascal case
  return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
const fileListLink = fileUrls.map(file => ({
  docname: file.file.substring(12), // Assuming file name is the document name
  fileUrl: file.file, // Assuming file location is the file URL
  documentnamed: file.file, // Assuming file name is the document name
  uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
}));
const linkedrenderDescription = () => {
  console.log("inside render ");
  if (linkedlistData.description && !showFullDescription) {
  console.log("inside render ",linkedlistData.description);

    return (
      <>
      
        {linkedlistData.description.slice(0, 250)}
        {linkedlistData.description.length > 250 && (
          <Button onClick={handleToggleDescriptionLinked} style={{fontSize:"12px"}}>Read More</Button>
        )}
      </>
    );
  } else {
  console.log("inside render else ",linkedlistData.description);

    return (
      <>
        {linkedlistData.description}
        <Button onClick={handleToggleDescriptionLinked} style={{fontSize:"12px"}}>Less</Button>
      </>
    );
  }
};
const renderRole = (role) => {
  switch (role) {
    case 'ROLE_ARC':
      return 'ARC';
    case 'ROLE_BM':
      return 'Board Member';
      case 'ROLE_MC':
        return 'Management Company';
    default:
      return role; // or return null if you don't want to display anything for other roles
  }
};
const linkedData = [
  {
    groupName: ' Compliance Request Details',
    items: [
      { label: 'Compliance Request Id', data: `${ linkedlistData?.crId || ""}`, text: 'Unique identifier for the compliance request' },
      // { label: 'Created Date Time', data: `${moment(complianceRequestData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}` ,text: 'Tooltip text 1'},
             { label: 'Category', data: `${linkedlistData.categoryDetails?.category || ""}` ,text: 'The category or subject of the compliance request'},
             { label: 'Description', data: linkedrenderDescription() ,text: 'Detailed description of the compliance issue'},
                
             { label: 'ByLaws Topic', data: `${linkedlistData.bylawDetails?.topic || ""}` ,text: 'The topic of the bylaw related to the compliance issue'},
    ],
  },
 
  {
    groupName: ' Audit and Status Details',
    items: [
             { label: 'AuditDateAndTime', data:`${moment(linkedlistData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'The date and time of the last audit.'},

              { label: 'Status', data: `${convertToPascalCase(status)}` ,text: 'Current status of the compliance request (e.g., Open, Closed)'},
             
               

    ],
  },
  // {
  //   groupName: 'Compliance Request Fine Details',
  //   items: [
  //     ...(moment().diff(moment(complianceRequestData?.createdDateAndTime), 'days') >= 30 &&
  //       HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId &&
  //       (complianceRequestData.status === "PENDING" || complianceRequestData.status === "ACKNOWLEDGED")
  //       ? [
  //         {
  //           label: 'Fine Details',
  //           data: <CustomLateFees
  //             groupId={complianceRequestData.createdToDetails?.groupId}
  //             homeOwnerDetailsId={complianceRequestData.createdToDetails?.homeOwnerDetailsId}
  //             homeOwnerLoginId={complianceRequestData.createdToDetails?.homeOwnerLoginId}
  //             homeId={complianceRequestData.address?.homeId}
  //             id={complainceId}
  //           />
  //         }
  //       ]
  //       : []
  //     ),
  //     ...(homeDues !== 0
  //       ? [
  //         {
  //           label: "Late Fees",
  //           data: `${homeDues}`,
  //           text: "The late fees are for raised compliance that has not been resolved or responded to by a particular homeowner."
  //         }
  //       ]
  //       : []
  //     ),
  //   ],
  // },
  
  {
    groupName: 'Linked Details',
    items: [
      { label: 'CreatedBy', data:`${renderRole(linkedlistData?.role)}`, text: ' The role of the person linked to the compliance '},
      { label: 'CreatedDateAndTime', data:`${moment(linkedlistData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'The date and time when the compliance request was created'},
],
  },
  {
    groupName: 'Created To Details',
    items: [
      { label: 'Firstname', data:`${linkedlistData.createdToDetails?.firstName || ""}`, text: 'First name of the person or entity to whom the compliance request was created'},
      { label: 'Lastname', data:`${linkedlistData.createdToDetails?.lastName || ""}`, text: 'Last name of the person or entity to whom the compliance request was created' },
  
      { label: 'Email', data:`${linkedlistData.createdToDetails?.email || ""}`, text: 'Email address of the person or entity to whom the compliance request was created' },
      { label: 'Address', data:`${linkedlistData.address?.address || ""}`, text: 'Home address of the person or entity to whom the compliance request was created' },
      { label: 'Contact', data:`${linkedlistData.createdToDetails?.contact || ""}`, text: 'Contact number of the person or entity to whom the compliance request was created.' },
      
    ],
  },
  {groupName:"Attachment Details",
    items:[
      { label:'Attachment', data: (
        <AttachmentListsView fileList={fileListLink} />

      ), text: 'List of Document attached to compliance request'},
    ]
  }
];
// linked complaince
  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };

  const handleCancelClick = () => {
    console.log("Cancel");
    getGeneralComments();
    setEditModes(false);
  };
  const getGeneralComments = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);

  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);


        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralCommentsById();
  }, [commentsId]);
  //pdf 
  const getComplainceAttachments = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${fileImage}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setFileImage(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getComplainceAttachments()
  }, [fileImage])
  const currentDate = moment(); // Get the current date
  let dateTimeText = currentDate.format("DD MMMM YYYY");
  const handleRenderPDF = () => {


    const styles = {
      title: {
        fontSize: 90,
        // fontWeight: "bold",
        color: "#FFFFFF",
        textAlign: "center",
      },
      empty: {
        color: "red",
        padding: "500px"
      },
      content: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding: "5px"
      },
      emptys: {
        marginTop: "3%"
      },
      empty: {
        marginTop: "120%"
      },
      secondEmpty: {
        marginTop: "40%"
      },
      from: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "2px",
      },
      headers: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "2px",
      },
      adress: {
        fontSize: 14,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding: "5px",
        // fontWeight:"bold"
      },
      description: {
        fontSize: 15,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding: "5px",
        fontWeight: "bold"
      },
      signature: {
        fontSize: 16,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      heading: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Oswald'
      },
      pageInitial: {
        marginTop: "100px"
      },
      afterfrom: {
        marginTop: "9%",
        backgroundColor: "yellow"
      },
      formempty: {
        marginTop: "53%"
      }, anotherempty: {
        marginTop: "10%"
      }, images: {
        width: "200px",
        height: "100px"

      },
      fromBorder: {
        border: "1px solid black"
      }, toBorder: {
        border: "1px solid black"
      }
    };
    const datas = {
      title: "Complaince Request Notice",
      logo: imageBlob,
      letterContent: [
        // { type: 'text',text:"Complaince Request Notice", style: { color: "black", textAlign: "center"}},
        // { type: 'text',text: "",style:styles.emptys }, // Empty line for spacing

        // { type: 'text',text: dateTimeText, style:styles.from },
        // { type: 'text',text: "",  }, // Empty line for spacing
        // { type: 'text',text: "",  },
        { style: styles.fromBorder },
        // {type:'image',src:imageBlob,style:styles.groupImage},
        { type: 'text', text: "", style: styles.formempty },
        // {type: 'text', text: "",  style:styles.anotherempty},
        // { type: 'text',text: "",  style:styles.secondEmpty},

        { type: 'text', text: "From :", style: styles.from },

        { type: 'text', text: "" + `${complainceDetails.createdByDetails?.firstName || ""}`, style: styles.from, },
        { type: 'text', text: "" + `${complainceDetails.address?.address || ""}`, style: styles.from, },
        { type: 'text', text: `${complainceDetails.createdByDetails?.city || ""}, ${complainceDetails.createdByDetails?.zipcode || ""}`, style: styles.from },

        // {style:styles.toBorder},

        { type: 'text', text: "", style: styles.afterfrom },
        { type: 'text', text: "To :", style: styles.headers },
        { type: 'text', text: "" + `${complainceDetails.createdFor?.firstName || ""}`, style: styles.headers },
        // { type: 'text',text: "To :", style: styles.headers  },
        { type: 'text', text: "" + `${complainceDetails.createdFor?.address || ""}` + "", style: styles.headers },
        { type: 'text', text: `${complainceDetails.createdFor?.city || ""}, ${complainceDetails.createdFor?.zipcode || ""}`, style: styles.headers },

        { type: 'text', text: "", style: styles.empty },
        { type: 'text', text: "", style: styles.secondEmpty },
        { type: 'text', text: "Dear " + `${(complainceDetails.createdToDetails?.firstName || "")}`, style: styles.content },
        { type: 'text', text: "You have previously asked to adress the following matter with your property.", style: styles.content },
        { type: 'text', text: "This is to inform you about the complaince request:" + complainceDetails.crId + "_" + dateTimeText + ",", style: styles.content },
        { type: 'text', text: "" + `${complainceDetails.description || ""}`, style: styles.description, },
        { type: 'text', text: "If u have any questions,feel that you have recieved this letter in error,or would  like to request a variance,please feel free to contact our office.", style: styles.content },
        { type: 'text', text: "Thank you in advance for your attention to this matter.", style: styles.content },
        { type: 'text', text: "", },
        { type: 'text', text: "", style: styles.content },
        { type: 'text', text: "", style: styles.content },

        // {type: 'text', text: "Sincerely", style: styles.adress },
        // {text: ""+ complainceDetails.createdByDetails?.firstName + "",style: styles.adress},
        // {text: ""+ complainceDetails.address?.address + "",style: styles.adress,fontSize: 15 ,},
        // { text: `${complainceDetails.createdByDetails?.city || ""}, ${complainceDetails.createdByDetails?.zipcode || ""}`, style: styles.adress, fontSize: 15 },    
        //  {type: 'text',text: footerContent , style: styles.adress}

        { type: 'text', text: "", },
        // {type: 'text', text: "",  style:styles.empty},
        // { type: 'text',text: "",  style:styles.secondEmpty},
        // if(fileImage){
        //   {type: 'image',src:fileImage,style:styles.images },
        //  },else{

        //  }

      ]

    };
    if (fileImage) {
      datas.letterContent.push({ type: 'image', src: fileImage, style: styles.images });
    }

    return (
      <PDFDocument data={datas} />
    );
  };
  const handleClickPdf = async () => {
    // console.log("test",logoUrl);
    try {
      const pdfData = handleRenderPDF();
      const blob = await pdf(pdfData).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');


      // const a = document.createElement('a');
      //   a.href = url;
      //   a.download = `${complainceDetails.crId}.pdf`;; // Set the filename here
      //   a.target = '_blank';
      //   a.click();

      //   // Clean up
      //   URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  const submitUpdate = (data) => {
    console.log("Test");
    setIsLoading(true);
    const putData = {
      comments: generalCommentsData.trim(),
      referenceId: commentsId,
    };

    if (generalCommentsData == "" || generalCommentsData == null) {
      setError("editComments", {
        type: "manual",
        message: GENRAL_COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (generalCommentsData.trim().length < 2) {
      setError("editComments", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (generalCommentsData.trim().length > 1000) {
      setError("editComments", {
        type: "manual",
        message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
    }

    request({
      url: `${GENERAL_COMMENTS}/${commentsId}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getGeneralComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />

    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  const onSubmit = (data, e) => {
    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setError("comments", {
        type: "manual",
        message: GENRAL_COMMENTS_REQUIRED_MESSAGE,
      });
    setIsLoading(false);
    return
   
  }
    const postData = {
      comments: generalCommentsDataAdd.trim(),
      referenceId: complainceId,
    };
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getGeneralComments();
          console.log("Success");
          setIsLoading(false);
          setGeneralCommentsDataAdd("")

          // setTimeout(() => {
          // //  navigate(-1);
          // }, 1000);
          e.target.reset();

          setTimeout(() => {
            //  navigate(-1);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const fullName = firstNameData + " " + lastNameData;


  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  // console.log(complianceRequestData);
  const renderDescription = () => {
    console.log("inside render ");
    if (complainceDetails.description && !showFullDescription) {
    console.log("inside render ",complainceDetails.description);
  
      return (
        <>
        
          {complainceDetails.description.slice(0, 250)}
          {complainceDetails.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",complainceDetails.description);
  
      return (
        <>
          {complainceDetails.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };
  
  const fileList = fileUrl.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
  const data = [
    {
      groupName: 'Compliance Request Details',
      items: [
        { label: 'Compliance Request Id', data: `${ complainceDetails?.crId || ""}`, text: 'Unique identifier for the compliance request' },
        { label: 'Created Date Time', data: `${moment(complainceDetails.createdDateAndTime).format(
               "MM/DD/YYYY hh:mm:ss A")}` ,text: 'The date and time when the compliance request was created'},
               { label: 'Category', data: `${complainceDetails.categoryDetails?.category || ""}` ,text: 'The category or subject of the compliance request'},
               { label: 'Description', data: renderDescription() ,text: 'Detailed description of the compliance issue'},
               { label: 'ByLaws Topic', data: `${complainceDetails.bylawDetails?.topic ?? ""}` ,text: 'The topic of the bylaw related to the compliance issue'},

              ],
     },
    //  {
    //   groupName: 'Created By Details',
    //   items: [
    //     { label: 'Firstname', data:`${complainceDetails.createdByDetails?.firstName??""}`},
    //     { label: 'Lastname', data:`${complainceDetails.createdByDetails?.lastName??""}` },
    //     { label: 'Email', data:`${complainceDetails.createdByDetails?.email??""}` },
    //     { label: 'Address', data:`${complainceDetails?.address?.address??""}` },
    //     { label: 'contact', data:`${complainceDetails.createdByDetails?.contact??""}` },
    //   ],
    // },
    
     {
      groupName: 'Audit and Status and Details',
      items: [
        { label: 'Audit Date Time', data: `${moment(complainceDetails.auditDateAndTime).format(
        "MM/DD/YYYY hh:mm:ss A")}` ,text: 'The date and time of last audit'},
           { label: 'Status', data: `${convertToPascalCase(complainceDetails.status??"")}` ,text: 'Current status of the compliance request (e.g., Open, Closed)'},
           { label: 'Is Fine Applicable', data: `${fineApply ?'Yes': 'No'}` ,text: 'Indicates if a fine is applicable for this compliance request.'},
        ],
     },
    //  { label: 'ByLaws Topic', data: `${complainceDetails.bylawDetails?.topic ?? ""}` ,text: 'Tooltip text 1'},
    //  { label: 'Audit Date Time', data: `${moment(complainceDetails.auditDateAndTime).format(
    //     "MM/DD/YYYY hh:mm:ss A")}` ,text: 'Tooltip text 1'},
    //     { label: 'Status', data: `${CustomPascalChange(complainceDetails.status??"")}` ,text: 'Tooltip text 1'},
    //     { label: 'Is Fine Applicable', data: `${fineApply ?'Yes': 'No'}` ,text: 'Tooltip text 1'},
    //     { label: 'Status', data: `${CustomPascalChange(complainceDetails.status??"")}` ,text: 'Tooltip text 1'},
       
    //     { label:'Attachment', data: (
    //      <AttachmentList
    //        fileUrl={attachmentData.props.fileUrl}
    //        documentName={attachmentData.props.documentName}
    //        docname={attachmentData.props.docname}
    //        uploadedTimes={attachmentData.props.uploadedTimes}
    //      />
    //    ), text: 'Tooltip text 1'},
    
    {
      groupName: 'Created To Details',
      items: [
        { label: 'Firstname', data:`${complainceDetails.createdToDetails?.firstName??""}`,text: 'First name of the person or entity to whom the compliance request was created'},
        { label: 'Lastname', data:`${complainceDetails.createdToDetails?.lastName??""}`,text: 'Last name of the person or entity to whom the compliance request was created' },
        { label: 'Email', data:`${complainceDetails.createdToDetails?.email??""}` ,text: 'Email of the person or entity to whom the compliance request was created'},
        { label: 'Address', data:`${complainceDetails.createdToAddress?.address?? ""}`,text: 'Address of the person or entity to whom the compliance request was created' },
        { label: 'Address For Communication ', data:`${complainceDetails.createdToDetails?.addressForCommunication??""}` ,text:"Address For Communication of the person or entity to whom the compliance request was created"},

        { label: 'contact', data:`${complainceDetails.createdToDetails?.contact??""}`,text: 'Contact of the person or entity to whom the compliance request was created' },
      ],
    },
    {
      groupName: 'AttachmentDetails',
      items: [
        { label:'Attachment', data: (
              <AttachmentListsView fileList={fileList}/>
             ), text: 'List of Document Attached to Compliance request'},   
        ],
     },
  ];
  return (
    <div>
      <div>
        <CustomToastContainer />

        {isLoading && <CustomLoading />}
        <CustomViewHeading onClick={handleClick}  title={"View Compliance Requests"} data={data} fileList={fileList}
         description={(complianceRequestData?.description || "")}>
        {/* <CustomViewReportToolTip data={data}/> */}
        <CustomViewWithMore data={data}/>
        {/* <CustomViewPages data={data} exportedBy={true} handleExportss={handleClickPdf} /> */}
      <br />

      {/* linked statement */}
      {linkedlistData && Object.keys(linkedlistData).length > 0  && (
        // <CustomViewPage data={linkedData} />
        <div class="border-sky-500">
<CustomHeading title={`Compliance Request  ${complianceRequestData?.crId || ""} linked to ${linkedlistData?.crId || ""}`} />
{/* // <CustomViewHeading onClick={handleClick}  title={"Linked Compliance Request"}> */}
      <CustomViewWithTooltipexpand data={linkedData}  />
    {/* //  </ CustomViewHeading>  */}
    </div>
        )}
      {/* untill here */}
      <CustomHeading title="General Comments" />
      <CustomModel
        title=" Delete Comment"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          <div className="flex items-center space-x-4">
            <CustomInputField
              fieldName="comments"
              // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldId="comments"
              fieldType="text"
              fieldLabel="Write a Comments"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={generalCommentsDataAdd}
              onInput={handleInputChangeAdd}
              multiline={true}
              numberOfRows={2}
            />

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Post" />
            </div>
            </div>
          </CardContent>
        </form>
        <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
                  <p>
                    <span className="text-primary" style={{ float: "left" }}>
                      @ {comment.createdByName}{" "}
                    </span>
                    <span style={{ float: "right" }}>
                      &nbsp; {/* Adding space between icons */}
                      {moment(comment.createdDateAndTime).format(
                  "MM/DD/YYYY hh:mm:ss a"
                )}
                      &nbsp; {/* Adding space between icons */}
                      {!editModes[index] && comment.createdById === groupAdminId && (
                        <>
                          {index === 0 && (
                            <>
                              <Tooltip title="Edit Comment">
                                <EditNoteOutlined
                                  color="primary"
                                  onClick={() => {
                                    handleEditClick(index, comment.generalCommentsId);
                                    console.log(comment.generalCommentsId);
                                  }}
                                />
                              </Tooltip>
                              &nbsp; {/* Adding space between icons */}
                              <Tooltip title="Delete Comment">
                                <DeleteOutline
                                  color="error"
                                  onClick={() => {
                                    handleDeleteClickOpen(comment.generalCommentsId);
                                    console.log(comment.generalCommentsId);
                                  }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </>
                      )}
                    </span>
                    {editModes[index] ? (
                      <form
                        onSubmit={handleSubmit(submitUpdate)}
                        className="space-y-4 md:space-y-6 "
                      >
                        <CardContent>
                          <CustomInputField
                            fieldName="editComments"
                            fieldId="editComments"
                            fieldType="text"
                            fieldControl={control}
                            fieldError={errors}
                            multiline={true}
                            numberOfRows={2}
                            value={generalCommentsData}
                            onInput={handleInputChange}
                          />

                          <div class="text-center mt-8 space-x-1">
                            <CustomButton
                              fieldType="submit"
                              buttonName="Save"
                            />

                            <CustomButton
                              fieldType="button"
                              buttonName="Cancel"
                              click={handleCancelClick}
                            />
                            {isLoading && <CustomLoading />}
                          </div>
                        </CardContent>
                      </form>
                    ) : (
                      <p className="mt-8 mb-8">{comment.comments}</p>
                    )}
                  </p>
                </section>

                <Divider />
              </div>
            ))}
        </CardContent>
      </Card>
      </CustomViewHeading>
    </div>
    </div>
  );
}

export default ViewCompliance;