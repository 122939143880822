import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../components/CustomViewPage";
import { BASE_URL, FILE_NAME, HOMES } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomLoading from "../components/CustomLoading";
import CustomViewWithTooltip from "../components/CustomViewWithTooltip";
import {
  Dashboard,
  DownhillSkiing,
  FileDownloadOutlined,
} from "@mui/icons-material";
import FileSaver from "file-saver";
import moment from "moment";
import CustomViewHeading from "../components/CustomViewHeading";
import CustomViewWithMore from "../components/CustomViewWithMore";




function ViewAllocatedHomes() {
  const navigate = useNavigate();
  const { email,homeId } = useParams();

  // const {homeOwnerLoginId}=useParams();
  const [homeDetails, setHomeDetails] = useState([]);
  const [renterDetails, setRenterDetails] = useState([]);
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentNameUrl, setDocumentNameUrl] = useState("");

  const [uploadedTimes, setUploadedTimes] = useState("");
  const [createdDateAndTime, setCreatedDateAndTimes] = useState("");
  const handleEditClickOpen = () => {
    console.log(email);
    navigate(`/homes/edit/${email}`);
  };
  const [homeOwnerLoginId, setHomeOwnerLoginId] = useState("");
  const handleAddClickOpen = () => {
    console.log(homeOwnerLoginId);
    navigate(`/addrenter/${homeOwnerLoginId}`);
  };
  const handleViewClickOpen = () => {
    navigate("/viewrenter");
  };

  const handleClick = () => {
    navigate(-1);
  };

  const getHomeDetailsById = async () => {
  
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}${HOMES}/${email}/${homeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
    
      if (response.status === 200) {
        const data = response.data;
        // const renter = data.renter[0]
              // console.log(response.data.renter[0]);
        console.log(data);
        console.log(response.data);
        setHomeDetails(data);
        // if (data.renter && data.renter.length > 0) {
        //   const renterData = data.renter;
        //   console.log(renterData);
        //   setRenterDetails(renterData);

        //   // ... rest of your code
        // } else {
        //   console.log("No renter data available.");
        //   // Handle the case when 'renter' data is not present or empty
        // }
        
        setIsLoading(false);
     
        setHomeOwnerLoginId(data.homeOwnerLoginId);
        console.log(data.homeOwnerLoginId);
        const sharedDocument = response.data;
        const listreqDocument = sharedDocument.renter || [];
        console.log(listreqDocument);
        if (listreqDocument.length > 0) {
          const firstDocument = listreqDocument[0];

          const documentNamesArr = [firstDocument.fileUrl];
          setDocumentName(documentNamesArr);
          console.log(documentNamesArr);

          const documentNamesArray = [
            firstDocument.fileUrl.replace(/^\d+/, ""),
          ];
          setDocumentNameUrl(documentNamesArray);

          const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          };

          const uploadedTimesArr = [
            new Date(firstDocument.createdDateAndTime).toLocaleString(
              "en-US",
              options
            ),
          ];
          setUploadedTimes(uploadedTimesArr);
        } else {
          throw new Error("Failed to fetch data");
        }
      } else {
        setIsLoading(false);
        console.log("error");
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomeDetailsById();
  }, [email]);

  // const data = {
  //   pageTitle: "Homeowner Details",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
    
  //   ],
  //   content: [
  //     {
  //       topic: "First Name ",
  //       description:
  //         homeDetails.firstName === "null" ? "" : homeDetails.firstName,
  //     },
  //     {
  //       topic: "Last Name",
  //       description:
  //         homeDetails.lastName === "null" ? "" : homeDetails.lastName,
  //     },
  //     {
  //       topic: "Contact",
  //       description: homeDetails.contact === "null" ? "" : homeDetails.contact,
  //     },
  //     {
  //       topic: "Roles",
  //       description: Array.isArray(homeDetails.roles)
  //         ? homeDetails.roles.map(role => {
  //             switch(role) {
  //               case "ROLE_HO":
  //                 return "HomeOwner";
  //               case "ROLE_ARC":
  //                 return "ARC";
  //               case "ROLE_BM":
  //                 return "Board Member";
  //               default:
  //                 return role;
  //             }
  //           }).join(", ")
  //         : homeDetails.roles,
  //     },
      
  //     {
  //       topic: "Email",
  //       description: homeDetails.email === "null" ? "" : homeDetails.email,
  //     },
  //     {
  //       topic: "Status",
  //       description: homeDetails.status == true ? "Active" : "In-Active",
  //     },
  //     {
  //       topic: "City",
  //       description: homeDetails.city === "null" ? "" : homeDetails.city,
  //     },
  //     {
  //       topic: "State",
  //       description: homeDetails.state === "null" ? "" : homeDetails.state,
  //     },
  //     {
  //       topic: "Address ",
  //       description: homeDetails.address === "null" ? "" : homeDetails.address,
  //     },
  //   ],
  // };

  const data = [
    {
      groupName: 'Home Owner Details',
      items: [
        {
          label: 'First Name',
          data: `${homeDetails.firstName === null ? "" : homeDetails.firstName}`,
          text: 'First name of the Home owner'
        },
        {
          label: 'Last Name',
          data: `${ homeDetails.lastName === null ? "" : homeDetails.lastName}`
          , text: 'Last name of the Home owner'
        }, 
        {
          label: 'Status',
          data: `${homeDetails.status == true ? "Active" : "In-Active"}`
          , text: 'Status of the Home owner'
        },
      ],
    },
{
      groupName: 'Address',
      items: [
        {
          label: 'Address',
          data: `${homeDetails.address === null ? "" : homeDetails.address}`,
          text: 'Address of the Home owner'
        },
        {
          label: 'Address For Communication',
          data: `${homeDetails.addressForCommunication === null ? "" : homeDetails.addressForCommunication}`,
          text: 'Address for communication of the Home owner'
        },
        {
          label: 'City',
          data: `${homeDetails.city === null ? "" : homeDetails.city}`
          , text: 'The name of the city where the selected homeowner is located'
        }, 
        {
          label: 'State',
          data: `${homeDetails.state === null ? "" : homeDetails.state}`
          , text: 'The name of the State where the selected homeowner is located'
        },
      ],
    },

    {
      groupName: 'Contact Details',
      
      items: [
        {
          label: 'Email',
          data: `${homeDetails.email === null ? "" : homeDetails.email}`
          , text: 'Email of the Home owner for contact purposes'
        },
        {
          label: 'Cell',
          data: `${homeDetails.contact === null ? "" : homeDetails.contact}`
          , text: 'Personal phone number of the Home owner for contact purposes'
        },
        {
          label: 'Home',
          data: `${homeDetails.home === null ? "" : homeDetails.home}`
          , text: 'Home contact number of the Home owner for contact purposes'
        },
        {
          label: 'Work',
          data: `${homeDetails.work === null ? "" : homeDetails.work}`
          , text: 'Work contact number of the Home owner for contact purposes'
        },
        {
          label: 'Fax',
          data: `${homeDetails.fax === null ? "" : homeDetails.fax}`
          , text: 'Fax number of the Home owner for contact purposes'
        },

      ],
    },
    {
      groupName: 'Role',
      items: [
        {
          label: 'Roles',
          data: Array.isArray(homeDetails.roles)
                  ? homeDetails.roles.map(role => {
                      switch(role) {
                        case "ROLE_HO":
                          return "HomeOwner";
                        case "ROLE_ARC":
                          return "ARC";
                        case "ROLE_BM":
                          return "Board Member";
                        default:
                          return role;
                      }
                    }).join(", ")
                  : homeDetails.roles
            , text: 'Roles of the Home owner'
        },
      ],
    },
   

  ];

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewHeading onClick={handleClick}  title={"View Homeowner Details"}  data={data}>
      <CustomViewWithMore data={data}/>
      </CustomViewHeading>
      {/* <CustomViewWithTooltip data={data} title={"View Homeowner Details"} onClick={handleClick} /> */}
      <br />
    
    </div>
  );
}

export default ViewAllocatedHomes;
