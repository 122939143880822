import { Button, Card, CardContent, TextField } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomViewPage from "../components/CustomViewPage";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  MAINTENANCE_REQUEST,
  REPORT_CR_MR,
} from "../utills/ApplicationRouting";
import { useParams } from "react-router-dom/dist";
import axios from "axios";

function ViewAudirCrMr() {
  const navigate = useNavigate();
  const { maintenanceId } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);
  const [auditTableData, setAuditTableData] = useState("");
  const handleClick = () => {
    navigate(-1);
  };
  const formatDate = (dateString) => {
    if (dateString === "null" || !dateString) {
      return "";
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const getAudirCrbyId = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${MAINTENANCE_REQUEST}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data;
        setAuditTableData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAudirCrbyId();
  }, [maintenanceId]);

  const dataDetails = {
    pageTitle: "View Request Report",
    buttons: [
      {
        buttonTitle: "Back",
        onClick: handleClick,
      },
      {
        buttonTitle: "Export",
        onClick: handleClick,
      },
    ],
    content: [
      {
        topic: "Created By ",
        description:
          auditTableData.createdBy === "null" ? "" : auditTableData.createdBy,
      },
      {
        topic: "Created Date Time",
        description: formatDate(auditTableData.createdDateAndTime),
      },
      {
        topic: "Category ",
        description:
          auditTableData.category === "null" ? "" : auditTableData.category,
      },
      {
        topic: "ByLaws Topic",
        description: "test group bylaw",
      },
      {
        topic: "Audit Date Time ",
        description: formatDate(auditTableData.auditDateAndTime),
      },
      {
        topic: "Status",
        description:
          auditTableData.status === "null" ? "" : auditTableData.status,
      },
      {
        topic: "Created For",
        description: "Home Req test Three",
      },
      {
        topic: "Address",
        description: " HomeReqTest5",
      },
      {
        topic: "Description",
        description:
          auditTableData.description === "null"
            ? ""
            : auditTableData.description,
      },
      {
        topic: "Address for Communication",
        description: " HomeReqTest5",
      },
      {
        topic: "Attachments ",
        description: "",
      },
    ],
  };

  const data = [
    {
      assignDateTime: "25/11/2023",
      assignTo: "testing one",
      reviewOutCome: "Compliance Request",
      reviewDateTime: "25/11/2023",
      reviewComments: "Testing",
    },
    {
      assignDateTime: "25/11/2023",
      assignTo: "testing one",
      reviewOutCome: "Compliance Request",
      reviewDateTime: "25/11/2023",
      reviewComments: "Testing",
    },
    {
      assignDateTime: "25/11/2023",
      assignTo: "testing one",
      reviewOutCome: "Compliance Request",
      reviewDateTime: "25/11/2023",
      reviewComments: "Testing",
    },
  ];

  const auditCrMrTable = useMemo((rowData) => [
    {
      accessorKey: "assignDateTime",
      header: "Assign DateTime",
      size: 20,
    },
    {
      accessorKey: "assignTo",
      header: "Assign To",
    },
    {
      accessorKey: "reviewOutCome",
      header: "Review OutCome",
    },
    {
      accessorKey: "reviewDateTime",
      header: "Review DateTime",
    },

    {
      accessorKey: "reviewComments",
      header: "Review Comments",
      size: 20,
    },
  ]);
  return (
    <div>
      <CustomViewPage data={auditCrMrTable} />

      <MaterialReactTable
        columns={auditCrMrTable}
        data={data}
        enableColumnOrdering
        positionActionsColumn="last"
        enableSorting={false}
        enableColumnPinning
      />
    </div>
  );
}

export default ViewAudirCrMr;
