import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomViewPage from "../components/CustomViewPage";
import CustomInputField from "../components/CustomInputField";
import {
  GENRAL_COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_HUNDRED,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import { useForm } from "react-hook-form";
import CustomButton from "../components/CustomButton";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,
  MAINTENANCE_REQUEST,
} from "../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import {
  DeleteOutline,
  EditNoteOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import Loading from "react-loading";
// import Attachment from "../components/Attachment";
import CustomLoading from "../components/CustomLoading";
import CustomToastContainer from "../components/CustomToastContainer";
import { request } from "../services/AxiosConfig";
import CustomHeading from "../components/CustomHeading";
import CustomModel from "../components/CustomModel";
import Tooltip from "@mui/material/Tooltip";
import CustomViewWithTooltipWithMultipleButtons from "../components/CustomViewWithTooltipWithMultipleButtons";
import CustomViewReportToolTip from "../components/CustomViewReportToolTip";
import CustomViewHeading from "../components/CustomViewHeading";
import AttachmentList from "../components/AttachmentList";
import CustomViewWithTooltip from "../components/CustomViewWithTooltip";
import AttachmentListsView from "../components/AttachmentListView";
import CustomViewWithMore from "../components/CustomViewWithMore";


function ViewMaintenance() {
  const navigate = useNavigate();
  const { maintenanceId } = useParams();
  const [maintenanceRequestData, setMaitenanceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState("");
  const [commentsId, setCommentsId] = useState("");
  const { token, userDetails } = useSelector((state) => state.hybridhoa);
  const [firstNameData, setFirstNameData] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [createdBy, setCreatedBy] = useState("")
  const [fileImage,setFileImage]=useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showTopic,setShowTopic]=useState(false)
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const handleClick = () => {
    navigate(-1);
  };
  console.log(userDetails, "Details to check");
  const groupAdminId = userDetails.groupAdminLoginId;
  console.log(userDetails.groupAdminLoginId);
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
    setValue,
  } = useForm();
  const userName = userDetails.firstName + " " + userDetails.lastName;
  const getMaintenanceRequestDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${MAINTENANCE_REQUEST}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setMaitenanceRequestData(data);
        setFileUrl(data.fileUrl);
        setCreatedBy(data.creataedBy);
        console.log(data.createdBy);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        };
        const formattedDate = new Date(data.createdDateAndTime).toLocaleString(
          "en-us",
          options
        );
        setCreatedDateAndTime(formattedDate);
        const createdDetails = data.createdByDetails;
        setFileImage(data.attachments);

        setFirstNameData(createdDetails.firstName);
        setLastNameData(createdDetails.lastName);
        console.log(data.fileUrl);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(true);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getMaintenanceRequestDetailsById();
  }, []);

  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };

  const handleCancelClick = () => {
    console.log("Cancel");
    getGeneralComments();
    setEditModes(false);
  };
  const getGeneralComments = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("general comments", data);
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);

  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);

        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      // setIsLoading(true);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralCommentsById();
  }, [commentsId]);

  const submitUpdate = (data) => {

    setIsLoading(true);
    const putData = {
      comments: generalCommentsData.trim(),
      referenceId: commentsId,
    };

    if (generalCommentsData == "" || generalCommentsData == null) {
      setError("editComments", {
        type: "manual",
        message: GENRAL_COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (generalCommentsData.trim().length < 2) {
      setError("editComments", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (generalCommentsData.trim().length > 1000) {
      setError("editComments", {
        type: "manual",
        message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
    }
    request({
      url: `${GENERAL_COMMENTS}/${commentsId}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getGeneralComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />

    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  const onSubmit = (data, e) => {
    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setError("comments", {
        type: "manual",
        message: GENRAL_COMMENTS_REQUIRED_MESSAGE,
      });
    setIsLoading(false);
    return
   
  }
    const postData = {
      comments: generalCommentsDataAdd.trim(),
      referenceId: maintenanceId,
    };
    // if (data.comments == "" || data.comments == null) {
    //   setError("comments", {
    //     type: "manual",
    //     message: GENRAL_COMMENTS_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
     
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getGeneralComments();
          console.log("Success");
          setIsLoading(false);
          setGeneralCommentsDataAdd("")

          e.target.reset();

          setTimeout(() => {
            //  navigate(-1);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

const attachmentData = {
  props: {
    fileUrl: fileUrl,
        documentName: fileUrl,
        docname: fileUrl,
        uploadedTimes: createdDateAndTime,
  }
};

const convertToPascalCase = (str) => {
  // Check if the input string is undefined or empty
  if (str === undefined || str === '' || str === null) return '';

  // Remove underscores and convert string to Pascal case
  return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
const handleToggleDescription = () => {
  setShowFullDescription(!showFullDescription);
};
const handleToggleTopic = () => {
  setShowTopic(!showTopic);
};
const renderDescription = () => {
  const description = maintenanceRequestData.description || ''; // Default to an empty string if null or undefined

  if (!showFullDescription) {
    return (
      <>
        {description.slice(0, 250)}
        {description.length > 250 && (
          <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Read More</Button>
        )}
      </>
    );
  } else {
    return (
      <>
        {description}
        <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Less</Button>
      </>
    );
  }
};
const renderTopic = () => {
  console.log("inside render ");
  if (maintenanceRequestData.topic && !showTopic) {
  console.log("inside render ",maintenanceRequestData.topic);

    return (
      <>
      
        {maintenanceRequestData.topic.slice(0, 250)}
        {maintenanceRequestData.topic.length > 250 && (
          <Button onClick={handleToggleTopic} style={{fontSize:"12px"}}>Read More</Button>
        )}
      </>
    );
  } else {
  console.log("inside render else ",maintenanceRequestData.topic);

    return (
      <>
        {maintenanceRequestData.topic}
        <Button onClick={handleToggleTopic} style={{fontSize:"12px"}}>Less</Button>
      </>
    );
  }
};
//  const renderDescription = () => {
//   console.log("inside render ");
//   if (maintenanceRequestData.description && !showFullDescription) {
//   console.log("inside render ",maintenanceRequestData.description);

//     return (
//       <>
      
//         {maintenanceRequestData.description.slice(0, 250)}
//         {maintenanceRequestData.description.length > 250 && (
//           <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
//         )}
//       </>
//     );
//   } else {
//   console.log("inside render else ",maintenanceRequestData.description);

//     return (
//       <>
//         {maintenanceRequestData.description}
//         <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
//       </>
//     );
//   }
// };
const fileList = fileImage.map(file => ({
  docname: file.file.substring(12), // Assuming file name is the document name
  fileUrl: file.file, // Assuming file location is the file URL
  documentnamed: file.file, // Assuming file name is the document name
  uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
}));
const data = [
  {
    groupName: 'Maintenance  Details',
    items: [
      { label: 'Maintenance Request ID', data: `${ maintenanceRequestData?.mrId || ""}`, text: 'Unique identifier for the maintenance request.' },
      { label: 'What needs attention?', data:renderTopic()  ,text: 'Description of the issue or topic that needs attention.'},

       
  { label: 'More Information?', data: renderDescription() ,text: 'Additional information or details about the maintenance request.'},
    { label: 'Is this issue Urgent?', data:  `${maintenanceRequestData.isUrgent ?'Yes':'No'}` ,text: 'Indicates whether the issue requires immediate attention (Yes/No).'},
     { label: 'Is this issue  causing a threat to personal safety??', data:  `${maintenanceRequestData.isSafty ?'Yes':'No'}` ,text: 'Indicates whether the issue poses a threat to personal safety (Yes/No).'},
       {
              label: 'Status',
              data: convertToPascalCase(maintenanceRequestData.status ?? ""),
              text: 'Current status of the maintenance request.'
            },
{ label: 'Request Type', data:  `${maintenanceRequestData?.type || "" }` ,text: 'Type or category of the maintenance request.'},

       
            ],
   },
   {
    groupName: 'Date Details',
    items: [
     
      {
        label: 'Start Date',
        data: maintenanceRequestData.startDate 
        ? moment(maintenanceRequestData.startDate).isValid() 
          ? moment(maintenanceRequestData.startDate).format("MM/DD/YYYY ") 
          : "" 
        : ""  ,      text: 'Date and time when the maintenance request was initiated.'
        },
        {
        label: 'End Date',
        data: maintenanceRequestData.endDate 
        ? moment(maintenanceRequestData.endDate).isValid() 
          ? moment(maintenanceRequestData.endDate).format("MM/DD/YYYY") 
          : "" 
        : ""  ,  
       text: 'Date and time when the maintenance request is expected to be completed.'
        },
        // { label: 'Requested Date Time', data: `${moment(maintenanceRequestData.createdDateAndTime).format(
        //   "MM/DD/YYYY hh:mm:ss A")}` ,text: 'Date and time when the maintenance request was submitted.'},
        //   { label: 'Updated Date Time', data: `${moment(maintenanceRequestData.auditDateAndTime).format(
        //    "MM/DD/YYYY hh:mm:ss A")}` ,text: 'Date and time when the maintenance request was last updated.'}, 
    ],
  },
   {
    groupName: 'Created By Details',
    items: [
      { label: 'Firstname', data:`${maintenanceRequestData.createdByDetails?.firstName??""}`,text: 'First name of the person of maintenance request was created'},
      { label: 'Lastname', data:`${maintenanceRequestData.createdByDetails?.lastName??""}`,text: 'Last name of the person of maintenance request was created' },
      { label: 'Email', data:`${maintenanceRequestData.createdByDetails?.email??""}` ,text: 'Email name of the person of maintenance request was created'},
      { label: 'Address', data: maintenanceRequestData?.createdByDetails?.addressForCommunication || maintenanceRequestData?.homeDetails?.address || "",text: 'HomeAddress  of the person of maintenance request was created' },
      { label: 'contact', data:`${maintenanceRequestData.createdByDetails?.contact??""}`,text: 'Contact of the person of maintenance request was created' },
    ],
  },
  {
    groupName: 'AttachmentDetails',
items:[
  
 { label:'Attachment', data: (
  <AttachmentListsView fileList={fileList} />

), text: 'List of attached files/documents related to the maintenance request.'},   , 
]  
  }
  ];
  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      {/* <CustomViewPage data={data} /> */}
      <CustomViewHeading onClick={handleClick}  title={"View Maintenance Request"} data={data} fileList={fileList} 
       information={(maintenanceRequestData?.description || "")} attention={maintenanceRequestData.topic}>
        {/* <CustomViewReportToolTip data={data}/> */}
        <CustomViewWithMore data={data}/>
      <br />
      <CustomHeading title="General Comments" />
      <CustomModel
        title=" Delete Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          <div className="flex items-center space-x-4">
            <CustomInputField
              fieldName="comments"
              // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
              // minLength={2}
              // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              // maxLength={1000}
              // maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldId="comments"
              fieldType="text"
              fieldLabel="Write a Comments"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              value={generalCommentsDataAdd}
              onInput={handleInputChangeAdd}
              maxLength={1000}
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={3}
            />

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Post" />
            </div>
            </div>
          </CardContent>
        </form>
        <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
                  <p>
                    <span className="text-primary" style={{ float: "left" }}>
                      @ {comment.createdByName}{" "}
                    </span>
                    <span style={{ float: "right" }}>
                      &nbsp; {/* Adding space between icons */}
                      {/* Adding space between icons */}
                      {!editModes[index] && (
                        <>
                          {index === 0 && comment.createdById === groupAdminId && (
                            <>
                              <Tooltip title="Edit Comment">
                                <EditNoteOutlined
                                  color="primary"
                                  onClick={() => {
                                    handleEditClick(index, comment.generalCommentsId);
                                    console.log(comment.generalCommentsId);
                                  }}
                                />
                              </Tooltip>
                              &nbsp; {/* Adding space between icons */}
                              <Tooltip title="Delete Comment">
                                <DeleteOutline
                                  color="error"
                                  onClick={() => {
                                    handleDeleteClickOpen(comment.generalCommentsId);
                                    console.log(comment.generalCommentsId);
                                  }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </>
                      )}
                      &nbsp;
                      {moment(comment.createdDateAndTime).format(
                  "MM/DD/YYYY hh:mm:ss a"
                )}
                    </span>

                    {editModes[index] ? (
                      <form
                        onSubmit={handleSubmit(submitUpdate)}
                        className="space-y-4 md:space-y-6 "
                      >
                        <CardContent>
                          <CustomInputField
                            fieldName="editComments"
                            fieldId="editComments"
                            fieldType="text"
                            fieldControl={control}
                            fieldError={errors}
                            multiline={true}
                            numberOfRows={2}
                            value={generalCommentsData}
                            onInput={handleInputChange}
                          />

                          <div class="text-center mt-8 space-x-1">
                            <CustomButton
                              fieldType="button"
                              buttonName="Cancel"
                              click={handleCancelClick}
                            />
                            <CustomButton
                              fieldType="submit"
                              buttonName="Save"
                            />

                            {isLoading && <CustomLoading />}
                          </div>
                        </CardContent>
                      </form>
                    ) : (
                      <p className="mt-8 mb-8">{comment.comments}</p>
                    )}
                  </p>
                </section>

                <Divider />
              </div>
            ))}
        </CardContent>
      </Card>
      </CustomViewHeading>
    </div>
  );
}
export default ViewMaintenance;
