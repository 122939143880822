import React from 'react'
import { useNavigate } from 'react-router-dom';
import CustomViewPage from '../components/CustomViewPage';

function PaymentGateway() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(-1);
      };

      const handleEditClick = () =>{
        navigate('/editpaymentgateway')
      }
    const data = {
        pageTitle: "View Payment Gateway",
        buttons: [
        //   {
        //     buttonTitle: "Back",
        //     onClick: handleClick,
        //   },
          {
            buttonTitle: "Edit",
            onClick: handleEditClick,
          },
        ],
        content: [
          {
            topic: "Organization Type",
            description: "Nonprofit Organization",
          },
          {
            topic: "First Name ",
            description:"Aishwaryaa",
          },
          {
            topic: "Last Name",
            description: "Balu"
           
          },
          {
            topic: "Date of Birth",
            description: "10/11/1999",
          },
          {
            topic: "Social Security Number ",
            description:"4455",
          },
          {
            topic: "Role",
            description: "CEO",
          },
          {
            topic: "Business Website",
            description: "",
          },
          {
            topic: "HOA Description",
            description: "TEST",
          },
          {
            topic: "Routing Number",
            description: "987878786",
         
          },
          {
            topic: "Account Number",
            component: "97878786654444444",
          },
        ],
      };
  return (
    <div>
        <CustomViewPage data={data} />
    </div>
  )
}

export default PaymentGateway