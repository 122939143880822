import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import ApartmentIcon from '@mui/icons-material/Apartment';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import HandymanOutlined from '@mui/icons-material/HandymanOutlined';
import DvrIcon from '@mui/icons-material/Dvr';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import "../../index.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, DASHBOARD_PRESIST } from "../../utills/ApplicationRouting"

const Card = ({ cardData, index }) => {
  const renderIcon = () => {
    switch (cardData.name) {
      case "Allocated Homes/Total Homes":
        return <ApartmentIcon />;
      case "Subscription Details":
        return <ShoppingCartCheckoutIcon />;
      case "Maintenance Requests":
        return <HandymanOutlined />;
      case "Compliance Requests":
        return <DvrIcon />;
      case "Total Outstanding Dues":
        return <MonetizationOnIcon />;
      case "Total Dues Received":
        return <CurrencyExchangeIcon />;
      default:
        return null;
    }
  };

  return (
    <Draggable draggableId={`card-${index}`} index={index}>
      {(provided, snapshot) => (
        <Link to={cardData?.linkTo} title={cardData?.tooltip}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`draggable-container ${snapshot.isDragging ? "dragging" : ""} w-auto h-auto  `}
          >
            <div className="flex items-center p-6 bg-white rounded-lg shadow-lg w-full h-full">
              <div
                className={`inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-${cardData?.color}-600 bg-${cardData?.color}-100 rounded-full mr-4 relative`}
                style={{ backgroundColor: cardData?.color }}
              >
                <span style={{ fontSize: '24px', color: 'inherit' }}>
                  {renderIcon()}
                  {cardData?.badgeContent !== 0 && cardData?.badgeContent && (
                    <span
                      style={{
                        display: 'inline-block',
                        fontSize: '11px',
                        top: '11px',
                        right: '10px',
                        backgroundColor: '#cc0000',
                        color: 'white',
                        borderRadius: '50%',
                        width: '17px',
                        height: '17px',
                        textAlign: 'center',
                        lineHeight: '17px',
                        position: 'absolute',
                      }}
                    >
                      {cardData?.badgeContent > 9 ? '9+' : cardData?.badgeContent}
                    </span>
                  )}
                </span>
              </div>
              <div>
                <span className="block text-2xl font-bold">{cardData?.count}</span>
                  <span className="block font-bold text-xl ml-2">{cardData?.startDate}</span>
                  <span className="block font-bold text-xl ml-2">{cardData?.endDate}</span>
                <span className="block text-gray-500 text-xs mt-2">{cardData?.name}</span>
              </div>
            </div>
          </div>
        </Link>
      )}
    </Draggable>
  );
};

const CardLayout = ({ cardData }) => {
  const [order, setOrder] = useState([]);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const { token, userLoginDetails } = useSelector((state) => state.hybridhoa);
  const groupId = userLoginDetails.groupId;

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(`${BASE_URL}${DASHBOARD_PRESIST}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.length > 0) {
          const parsedValue = JSON.parse(response.data[0].value); // Parse the value string
          setOrder(parsedValue);
          setIsNewOrder(false);
        } else {
          // If data is empty or not valid, initialize order with default values
          const defaultOrder = cardData.map((_, index) => index);
          setOrder(defaultOrder);
          setIsNewOrder(true);
        }
      } catch (error) {
        console.error('Error fetching card order:', error);
        // If there's an error, initialize order with default values
        const defaultOrder = cardData.map((_, index) => index);
        setOrder(defaultOrder);
        setIsNewOrder(true);
      }
    };

    fetchOrder();
  }, [ token, cardData]);

  const saveCardOrder = (updatedOrder) => {
    const payload = {
      groupId,
      value: JSON.stringify(updatedOrder)  // Convert the array to a JSON string
    };

    const url = isNewOrder ? `${BASE_URL}${DASHBOARD_PRESIST}` : `${BASE_URL}${DASHBOARD_PRESIST}/${groupId}`;
    const requestMethod = isNewOrder ? axios.post : axios.put;

    requestMethod(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('Card order saved successfully:', response.data);
      setIsNewOrder(false);
    })
    .catch(error => {
      console.error('Error saving card order:', error);
    });
  };

  const moveCard = (result) => {
    if (!result.destination) return;

    const updatedOrder = [...order];
    const [movedCard] = updatedOrder.splice(result.source.index, 1);
    updatedOrder.splice(result.destination.index, 0, movedCard);

    setOrder(updatedOrder);
    saveCardOrder(updatedOrder);
  };

  const orderedCards = order.length > 0 ? order.map((index) => cardData[index]) : cardData;

  return (
    <DragDropContext onDragEnd={moveCard}>
      <Droppable droppableId="cardLayout" direction="horizontal">
        {(provided) => (
          <section
            className="grid md:grid-cols-2 xl:grid-cols-3 gap-6"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {orderedCards.map((card, index) => (
              <Card key={index} index={index} cardData={card} />
            ))}
            {provided.placeholder}
          </section>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CardLayout;
