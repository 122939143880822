import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Tooltip, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const Offlinepaymentauto = ({
  fieldName,
  requiredErrorMessage,
  fieldId,
  fieldLabel,
  fieldControl,
  fieldError,
  fieldTrigger,
  value,
  onChange,
  defaultValue,
  fieldRequiredIcon,
  disabled,
  options,
  getOptionLabel,
  validator,
  isRequired = true,
  invalidOption,
  tooltipMessage,
}) => {
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );

  const { setValue, control, trigger, setError, clearErrors } = useForm({
    defaultValues: {
      [fieldName]: defaultValue || "",
    },
  });

  useEffect(() => {
    setValue(fieldName, defaultValue || "");
  }, [defaultValue, setValue, fieldName]);

  const [isEmpty, setIsEmpty] = useState(defaultValue === undefined || defaultValue === "");
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await setValue(fieldName, value || "");
      setIsEmpty(value === null || value === "" || value === undefined);
    };
    fetchData();
  }, [value, setValue, fieldName]);

  const validateAutoComplete = (value) => {
    if (!isRequired && (value === undefined || value === "")) {
      return true;
    }
  
    const startsWithSpace = /^\s/.test(value);
    if ((value !== null && value !== "" && !options.some((option) => option.value === value)) || startsWithSpace) {
      return invalidOption;
    }
  
    if (isRequired && (value === undefined || value === "")) {
      return requiredErrorMessage;
    }
  
    return true;
  };
  

  useEffect(() => {
    if (touched) {
      trigger(fieldName);
    }
  }, [value, trigger, fieldName, touched]);

  const handleKeyDown = (event) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      setValue(fieldName, "", { shouldDirty: true });
      setIsEmpty(true);
      if (onChange) {
        onChange(event, null);
      }
    }
  };

  return (
    <div className="flex items-center w-full md:w-1/2">
      <div className="flex-1">
        <Controller
          name={fieldName}
          control={fieldControl}
          rules={{
            required: isRequired ? requiredErrorMessage : '',
            validate: validateAutoComplete,
          }}
          disabled={disabled}
          render={({ field, fieldState }) => (
            <>
              {options && (
                <Autocomplete
                  {...field}
                  options={options}
                  getOptionLabel={(option) => option.address || option.topic || option.category || ""}
                  value={options.find((option) => option.value === field.value) || ""}
                  onChange={(event, newValue) => {
                    setValue(fieldName, newValue ? newValue.value : "", { shouldDirty: true });
                    if (newValue) {
                      clearErrors(fieldName);
                      setIsEmpty(false);
                    } else {
                      setIsEmpty(true);
                    }
                    if (onChange) {
                      onChange(event, newValue);
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    const isInputInOptions = options.some((option) => option.value === newInputValue);
                    if (!isInputInOptions) {
                      setError(fieldName, { type: "manual", message: invalidOption }); // Set error manually
                      setIsEmpty(true);
                    } else {
                      clearErrors(fieldName); // Clear error if the input value is in options
                      setIsEmpty(false);
                    }
                  }}
                  onBlur={() => {
                    if (field.value) {
                      setTouched(false);
                    } else {
                      setTouched(true);
                      trigger(fieldName);
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      label={
                        <CustomInputLabel label={fieldLabel} requiredIcon={fieldRequiredIcon} />
                      }
                      margin="dense"
                      variant="standard"
                      error={fieldState.invalid && isEmpty}
                      helperText={
                        fieldState.invalid && isEmpty
                          ? fieldState.error?.message || requiredErrorMessage
                          : ""
                      }
                      onKeyDown={handleKeyDown}
                    />
                  )}
                  clearOnBlur={false}
                />
              )}
            </>
          )}
        />
      </div>
      {tooltipMessage && (
        <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>} placement="right" arrow>
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default Offlinepaymentauto;
