import React from 'react'
import ForgetChangePassword from '../../components/forgotpassword/ForgetChangePassword'


function ForgotChange() {
  return (
    <div>
      <ForgetChangePassword/>
    </div>
  )
}

export default ForgotChange

