import { ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, DOCUMENT_ARCHIEVED } from "../utills/ApplicationRouting";
import moment from "moment";
import CustomLoading from "../components/CustomLoading";
import CustomMaterialWithoutActons from "../components/CustomMaterialWithoutActons";

function ArchivedDocuments() {
  const navigate = useNavigate();
  const handleViewClick = (selectedRow) => {
    navigate(`/viewarchiveddocument/${selectedRow.row.original.documentId}`);
  };

  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state) => state.hybridhoa);
  const getAllocatedHomeDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${DOCUMENT_ARCHIEVED}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setDocuments(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllocatedHomeDetails();
  }, []);
  const archivedDocuments = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 2,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "fileUrl",
      header: "Attachments",
    },
    {
      accessorKey: "category",
      header: "Category",
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 20,
    },
    {
      accessorKey: "publishDate",
      header: "Publish Date",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "createdDate",
      header: "Created Date",
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
  ]);

  return (
    <div>
      <CustomHeading title="Archived Documents" />
      {isLoading && <CustomLoading />}
      <CustomMaterialWithoutActons
        columns={archivedDocuments}
        data={documents}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default ArchivedDocuments;
