// import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
// import { Info } from "@mui/icons-material";
import { InfoOutlined } from "@mui/icons-material";
import { TextField,Tooltip, IconButton,Typography } from "@mui/material";


const CustomInputFieldForhome = ({
  fieldName,
  requiredErrorMessage,
  fieldPattern,
  patternErrorMessage,
  fieldId,
  fieldType,
  fieldLabel,
  fieldControl,
  fieldError,
  fieldTrigger,
  value,
  onChange,
  defaultValue,
  fieldRequiredIcon,
  disabled,
  multiline,
  numberOfRows,
  validator,
  minLength,
  minLengthErrorMessage,
  maxLength,
  maxLengthErrorMessage,
  isMinLength,
  validationRules,
  onInput,
  tooltipMessage // New prop for tooltip text
}) => {
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );

  return (
    <div className={`flex items-center w-full`}>
      <Controller
        name={fieldName}
        control={fieldControl}
        defaultValue={defaultValue}
        rules={{
          required: requiredErrorMessage,
          pattern: {
            value: fieldPattern,
            message: patternErrorMessage,
          },
          minLength: {
            value: minLength,
            message: minLengthErrorMessage,
          },
          maxLength: {
            value: maxLength,
            message: maxLengthErrorMessage,
          },
        }}
        render={({ field, fieldState }) => (
          <>
            <TextField
              fullWidth
              {...field}
              label={
                <CustomInputLabel
                  label={fieldLabel}
                  requiredIcon={fieldRequiredIcon}
                />
              }
              type={fieldType}
              id={fieldId}
              margin="dense"
              variant="standard"
              disabled={disabled}
              multiline={multiline}
              maxRows={5}
              value={value}
              onInput={onInput}
              onBlur={(e) => field.onChange(e.target.value.trim())}
              inputProps={{
                style: {
                  fontSize: 16,
                  fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,

                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
                },
              }}
              error={fieldState.invalid}
              helperText={fieldState.invalid ? fieldState.error?.message : ""}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </>
        )}
      />
      {/* Adding Tooltip */}
      {tooltipMessage && (
        <Tooltip
        title={
          <Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>
        }
        placement="right"
        arrow
      >
        <IconButton size="small">
          <InfoOutlined/>
        </IconButton>
      </Tooltip>
      )}
    </div>
  );
};

export default CustomInputFieldForhome;