import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { CustomStyle } from "../components/CustomStyle";

const CustomModel = (props) => {

  const iconCloseStatus = () => {
    if (props.reset) {
      props.reset();
    }
    props.iconCloseStatus();
  };

  return (
    <Dialog open={props.openStatus} onClose={props.handleAddClose} fullWidth sx={props.modeltyle}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <span className={CustomStyle.mainHeading}>{props.title}</span>
        <IconButton
          aria-label="close"
          onClick={iconCloseStatus}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      
      {props.content}
    </Dialog>
  );
};

export default CustomModel;
