import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../components/CustomViewPage";
import axios from "axios";
import { BASE_URL, DOCUMENT } from "../utills/ApplicationRouting";
import { useSelector } from "react-redux";
// import Attachment from "../components/Attachment";
import CustomLoading from "../components/CustomLoading";
import moment from "moment";
import CustomViewWithTooltip from "../components/CustomViewWithTooltip";
import CustomViewHeading from "../components/CustomViewHeading";
import AttachmentListsView from "../components/AttachmentListView";

function AuditDocument_View() {
  const navigate = useNavigate();
  const { documentId } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);
  const [documentDetails, setDocumentdetails] = useState("");
  const [fileUrl, setFileUrl] = useState([]);
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [firstNameData,setFirstNameData] =useState('');
  const [lastNameData,setLastNameData] = useState('');
  const handleClick = () => {
    navigate(-1);
  };

  const formatDate = (dateString) => {
    if (dateString === "null" || !dateString) {
      return "";
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const fileList = fileUrl.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
    }));
  const getDocumentDetailsbyId = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}${DOCUMENT}/${documentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      console.log(response.data.renter);
      if (response.status === 200) {
        const data = response.data;
        setIsLoading(false);
        setDocumentdetails(data);
        setFileUrl(data.attachments);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        };
        const formattedDate = new Date(data.createdDateAndTime).toLocaleString(
          "en-us",
          options
        );
        console.log(data);
        setCreatedDateAndTime(formattedDate);
        const createdDetails = data.createdByDetails
   
        setFirstNameData(createdDetails.firstName);
        setLastNameData(createdDetails.lastName);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDocumentDetailsbyId();
  }, [documentId]);

  const fullName = firstNameData + " " + lastNameData;

  const data = [
    {
      groupName:'View Document',
    items: [
      {
        label: "Created By",
        data: fullName,
        text: 'name of the person of document was created' 
      },
      {
        label: "Category ",
        data:
          documentDetails.category === "null" ? "" : documentDetails.category,
          text: 'Category of the document'
      },
      {
        label: "Created Date Time ",
        data: moment(documentDetails.createdDateAndTime).format("MM/DD/YYYY"),
        text: 'Created Date and Time of the document'
      },
      {
        label: "Document Type",
        data: (documentDetails.category === "CONFIDENTIAL" 
        ? "Confidential"
        : documentDetails.isPublic 
          ? "General Document - This will be Published General Document to Homeowners" 
          : "Need 51% Approval from Board Member"),
          text: 'Type of the Document'

      },
      // {
      //   label: "Is Hidden",
      //   data:documentDetails.isHidden ? "Yes" :"No"
      // },
      {
        label: "Status",
        data:
          documentDetails.staus === "null" ? "" : documentDetails.status,
          text: 'Current status of the document'
      },
      {
        label: "Publish Date",
        data: moment(documentDetails.publishDate).format("MM/DD/YYYY"
        ),
        text: 'Publish Date of the document'
       
      },
      {
        label: "Description",
        data:
          documentDetails.description === "null"
            ? ""
            : documentDetails.description,
            text: 'Description of the document'
      },
      {
        label: "Attachments",
        data:(
          <AttachmentListsView fileList={fileList} />
        ),
        text: 'List of attached files/documents related to the documents.'
      },
    ]
  }
];

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewHeading onClick={handleClick} title={"Document"} data={data} description={documentDetails?.description || ""}  fileName={`Document_${documentDetails.documentId}.pdf`} fileList={fileList} >
      {/* <CustomViewHeading onClick={handleClick} > */}
      <CustomViewWithTooltip data={data}  />
      </CustomViewHeading>
    </div>
  );
}

export default AuditDocument_View;
