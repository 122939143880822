import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Tooltip,
  Typography,
  Select,
  FormControl,
  OutlinedInput,
  Menu, Checkbox
} from "@mui/material";
import {
  Close,
  Delete,
  DeleteOutline,
  EditNoteOutlined,
  ListAlt,
} from "@mui/icons-material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { MaterialReactTable } from "material-react-table";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import { ROLES_REQUIRED_MESSAGE } from "../utills/ApplicationConstants";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";
import CustomStyle from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomModel from "../components/CustomModel";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import {
  ASSIGN_ROLES,
  BASE_URL,
  HOME_OWNERS,
  HOME_OWNERS_EDIT,
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomLoading from "../components/CustomLoading";
import { request } from "../services/AxiosConfig";

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import TextField from '@mui/material/TextField';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function HomeOwners() {
  const { email } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedRowEmail, setSelectedRowEmail] = useState(null);
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);
  const [homeOwnerDetails, setHomeOwnerDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editedHomeOwnerDetails, setEditedHomeOwnerDetails] = useState([]);
  const [homeOwnerloginid, setHomeOwnerloginId] = useState("");
  const [role, setRole] = useState([]);
  const [homeId, setHomeId] = useState("");

  const handleEditClickOpen = (row) => {
    // setEditOpen(true);
    navigate(`/homeowner/roles/${row.original.email}/${row.original.homeId}`)
    setSelectedRowEmail(row.original.email);
    setHomeOwnerloginId(row.original.homeOwnerLoginId)
    console.log("orinal row", row.original);
    console.log(row.original.email);
    setRole(row.original.roles);
    console.log(row.original.roles); // Call getHomeOwnerDetailsEdit after setting edit model to open
    setSelectedRoles(row.original.roles);
  };

  useEffect(() => {
    console.log("roles", role);
  }, [role])


  // chip icon
  // const [fieldChips, setFieldChips] = React.useState([
  //   { key: 'ROLE_HO', label: 'HOME OWNER' },
  //   { key: 'ROLE_BM', label: 'BOARD MEMBER' },
  //   { key: 'ROLE_ARC', label: 'ARC' },
  // ]);

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleDelete = (chipToDelete) => () => {
  //   setFieldChips((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  // };

  // const handleMenuItemClick = (menuItem) => () => {
  //   if (!fieldChips.some((chip) => chip.label === menuItem.label)) {
  //     setFieldChips([...fieldChips, menuItem]);
  //   }
  //   handleClose();
  // };

  // const menuItems = [
  //   { key: 'ROLE_HO', label: 'HOME OWNER' },
  //   { key: 'ROLE_BM', label: 'BOARD MEMBER' },
  //   { key: 'ROLE_ARC', label: 'ARC' },
  // ];

  // useEffect(() => {
  //   // Filter fieldChips based on keys present in role
  //   const selectedChips = [
  //     { key: 'ROLE_HO', label: 'HOME OWNER' },
  //     { key: 'ROLE_BM', label: 'BOARD MEMBER' },
  //     { key: 'ROLE_ARC', label: 'ARC' },
  //   ].filter(chip => role.includes(chip.key));
  //   setFieldChips(selectedChips);
  // }, [role]);

  const [fieldChips, setFieldChips] = useState([]);

  const [selectedRoles, setSelectedRoles] = useState(['ROLE_HO']);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked && !selectedRoles.includes(value)) {
      setSelectedRoles([...selectedRoles, value]);
    } else {
      setSelectedRoles(selectedRoles.filter((role) => role !== value));
    }
  };

  useEffect(() => {
    // Filter fieldChips based on keys present in selectedRoles
    const selectedChips = [
      { key: 'ROLE_HO', label: 'HOME OWNER' },
      { key: 'ROLE_BM', label: 'BOARD MEMBER' },
      { key: 'ROLE_ARC', label: 'ARC' },
    ].filter((chip) => selectedRoles.includes(chip.key));
    setFieldChips(selectedChips);
  }, [selectedRoles]);

  useEffect(() => {
    // Automatically check the checkboxes corresponding to the roles from the backend
    ['ROLE_HO', 'ROLE_BM', 'ROLE_ARC'].forEach((roleKey) => {
      if (role.includes(roleKey)) {
        setSelectedRoles((prevSelectedRoles) =>
          prevSelectedRoles.includes(roleKey) ? prevSelectedRoles : [...prevSelectedRoles, roleKey]
        );
      }
    });
  }, [role]);

  // Rest of your code...


  const handleDelete = (chipToDelete) => () => {
    setFieldChips((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    setSelectedRoles(selectedRoles.filter((role) => role !== chipToDelete.key));
  };



  //
  const handleEditClose = (status) => {
    if (status == 200) {
      setEditOpen(false);
    }

  };

  const handleEditIconClose = () => {
    setEditOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleViewClick = (selectedRow) => {
    console.log(selectedRow.row.original.homeId);
    navigate(`/homeowner/${selectedRow.row.original.email}/${selectedRow.row.original.homeId}`);
   
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const roles = ["ROLE_ARC", "ROLE_BM", "ROLE_HO"];
  const [personName, setPersonName] = useState([]);

  const getStyles = (name, personName, theme) => { };

  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (!role || role.length === 0) {
      // Display an alert if roles are empty
      alert("Please select at least any one role.");
      return; // Exit the function without submitting
    }

    // If roles are entered, print the selected roles to the console
    console.log("Selected Roles:", data.roles);

    // Call the assignRoles function with selected roles
    // assignRoles({ roles: data.roles });
  };

  const onSubmitDelete = () => {
    console.log("Deleted Successfully");
  };

  const data = [
    {
      serialNo: 1,
      homeOwner: "Testing home1",
      email: "testmail1@gmail.com",
      status: true,
      roles: "HOME OWNER,ARC MEMBER,BOARD MEMBER",
    },
    // ... (other data entries)
  ];

  const getHomeOwnerDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}/${HOME_OWNERS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setHomeOwnerDetails(data);
        setHomeId(data.homeId);
        console.log("home owners list ", data);
        // if (data.length > 0) {
        //   setHomeOwnerloginId(data[0].homeOwnerLoginId);
        // }

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomeOwnerDetails();
  }, []);

  // Edit
  const handleChangeRoles = (event) => {
    setRole(event.target.value);
  };

  useEffect(() => {
    console.log("roles", role);
    console.log("rlen", role.length);
  }, [role]);

  const getHomeOwnerDetailsEdit = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      console.log("HomeId" + selectedRowEmail);

      const response = await axios.get(
        `${BASE_URL}${HOME_OWNERS_EDIT}/${selectedRowEmail}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        const data = response.data;
        setEditedHomeOwnerDetails(data); // Use a separate state for edited data
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomeOwnerDetailsEdit();
  }, [selectedRowEmail]);
  // roles

  const onSubmitEdit = async (data) => {
    const storedToken = token;
    setIsLoading(true);

    // Extracting keys of selected chips
    const selectedChipKeys = fieldChips.map(chip => chip.key);

    const postData = {
      roles: selectedChipKeys,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };

    if (!selectedChipKeys || selectedChipKeys.length === 0) {
      // Display an alert if roles are empty
      alert("Please select at least any one role.");
      setIsLoading(false);
      return; // Exit the function without submitting
    }

    try {
      const res = await request({
        url: `${ASSIGN_ROLES}${homeOwnerloginid}`,
        method: "post",
        data: postData,
        headers: config.headers,
      });

      console.log(res);
      if (res.status == 200) {
        handleEditClose(res.status);
        getHomeOwnerDetails();
        setIsLoading(false);
        reset();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.response);
      setIsLoading(false);
      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    }
  };

  const roleMappings = {
    ROLE_HO: 'HomeOwner',
    ROLE_BM: 'Board Member',
    ROLE_ARC: 'ARC',
  };

  const homeOwnerTable = useMemo(
    () => [
      {
        accessorKey: "serialNo",
        header: "#",
        size: 2,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "homeOwner",
        header: "Name",
        size: 1,
        // accessorFn: (row) => `${row && row.firstName ? row.firstName : " "} ${row && row.lastName ? row.lastName : " "}`,
         accessorFn: (row) => {
        const fullName = `${row && row.firstName ? row.firstName : " "} ${row && row.lastName ? row.lastName : " "}`;
        if (fullName && fullName.length > 28) {
          return fullName.slice(0, 28) + "...";
        }
        return fullName;
      },
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 2,
        accessorFn: (row) => `${row && row.address} `,
        accessorFn: (row) => {
          const address = row.address?row.address:"";
          if (address && address.length > 28) {
            return address.slice(0, 28) + "...";
          }
          return address;
        },

      },
      {
        accessorKey: "email",
        header: "Email",
        size: 2,
        accessorFn: (row) => `${row && row.email} `,
      },
      {
        accessorKey: "isOnRent",
        header: "Is Onrent",
        size: 2,
        accessorFn: (row) => (row && row.isOnRent ? "Yes" : "No"),
      },
      {
        accessorKey: "roles",
        header: "Roles",

        accessorFn: (row) => {
          if (row && row.roles) {
            return row.roles.map((role) => {
              const roleName = roleMappings[role];
              return roleName ? roleName : role.replace("ROLE_", "");
            }).join(", ");
          } else {
            return "";
          }
        }
      },

      {
        accessorKey: "status",
        header: "Status",
        size: 2,
        accessorFn: (row) => (row && row.status ? "Active" : "Inactive"),
      },


    ],
    []
  );

  const rowActions = [
    // {
    //   label: "View",
    //   icon: <ListAlt color="primary" />,
    //   click: (row) => handleViewClick(row),
    // },
    {
      label: "Roles",
      icon: <EditNoteOutlined color="primary" />,
      click: handleEditClickOpen,
    },
    // {
    //   label: "Delete",
    //   icon: <DeleteOutline color="error" />,

    //   click: handleDeleteClickOpen,
    // },
  ];

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>Do you want to delete this Homeowner?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  const editAction = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    />
  );

  const editModel = (
    <form
      onSubmit={handleSubmit(onSubmitEdit)}
      defaultValue={[""]}
      className="space-y-4 md:space-y-6"
    >
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            fieldName="homeOwner"
            fieldId="homeOwner"
            fieldLabel="Name"
            fieldControl={control}
            fieldError={errors}
            disabled={true}
            value={`${editedHomeOwnerDetails.firstName} ${editedHomeOwnerDetails.lastName}`}
          />

          <br />


          <br />

          {/* <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-multiple-chip-label">Roles</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={role}
              onChange={handleChangeRoles}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {roles.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {/* <Typography className="text-12" gutterBottom>
        Roles
      </Typography>
      <Paper
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 0,
        }}
        component="ul"
        onClick={handleClick}
      >
        {fieldChips.map((data) => {
          const deleteIcon = data.label !== 'HOME OWNER' ? <CloseIcon /> : null;

          return (
            <ListItem key={data.key}>
              {data.label === 'HOME OWNER' ? (
                <Tooltip title="You cannot remove HOME OWNER" arrow placement="right">
                  <div>
                    <Chip
                      label={data.label}
                      onDelete={deleteIcon ? handleDelete(data) : undefined}
                      color={'primary'}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Chip
                  label={data.label}
                  onDelete={deleteIcon ? handleDelete(data) : undefined}
                  color={'primary'}
                />
              )}
            </ListItem>
          );
        })}
        <ListItem sx={{ justifyContent: 'flex-end', marginLeft: 'auto' }}>
          <div style={{ cursor: 'pointer' }} onClick={handleClick}>
            {/* Dropdown Icon 
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"
                fill="#000000"
              />
            </svg>
          </div>
        </ListItem>
      </Paper>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((data) => (
          <MenuItem key={data.key} onClick={handleMenuItemClick(data)}>
            {data.label}
          </MenuItem>
        ))}
      </Menu>
          {errors.selectedRoles && (
            <span style={{ color: "red" }}>{errors.selectedRoles.message}</span>
          )} */}
          <Typography className="text-12" gutterBottom>
            Roles
          </Typography>
          {/* <Paper
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {fieldChips.map((data) => {
          const deleteIcon = data.label !== 'HOME OWNER' ? <CloseIcon /> : null;

          return (
            <ListItem key={data.key}>
              {data.label === 'HOME OWNER' ? (
                <Tooltip title="You cannot remove HOME OWNER" arrow placement="right">
                  <div>
                    <Chip
                      label={data.label}
                      onDelete={deleteIcon ? handleDelete(data) : undefined}
                      color={'primary'}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Chip
                  label={data.label}
                  onDelete={deleteIcon ? handleDelete(data) : undefined}
                  color={'primary'}
                />
              )}
            </ListItem>
          );
        })}
      </Paper> */}
          <div class="flex flex-row items-center mt-2">
            {['ROLE_HO', 'ROLE_BM', 'ROLE_ARC'].map((roleKey) => (
              <div key={roleKey} className="ml-2">
                <Checkbox
                  checked={roleKey === 'ROLE_HO' || selectedRoles.includes(roleKey)}
                  onChange={handleCheckboxChange}
                  value={roleKey}
                  disabled={roleKey === 'ROLE_HO'}
                />
                {roleKey === 'ROLE_HO' ? (
                  <span className="items-center justify-center">HOME OWNER</span>
                ) : roleKey === 'ROLE_BM' ? (
                  <span className="items-center justify-center">BOARD MEMBER</span>
                ) : (
                  <span className="items-center justify-center">ARC</span>
                )}
              </div>
            ))}
          </div>
          <div class="bg-yellow-200 text-yellow-800 p-2 rounded flex items-center mt-5">
            <svg class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <span class="text-sm">Note: Select a role from the checkbox and assign it to a particular homeowner.</span>
          </div>
        </Typography>
      </DialogContent>
      <DialogActions>{editAction}</DialogActions>
    </form>
  );

  return (
    <div>
      <ToastContainer limit={1} position="top-center" />
      <CustomHeading title="Home Owners" />
      {isLoading && <CustomLoading />}

      <CustomModel
        title="Role Assignment"
        submit={onSubmit}
        content={editModel}
        action={editAction}
        openStatus={editOpen}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        value={role}
        isLoading={isLoading}
      />

      <CustomModel
        title="Delete Home Owner"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        isLoading={isLoading}
      />
      <CustomMaterialTable
        columns={homeOwnerTable}
        data={homeOwnerDetails}
        rowActions={rowActions}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default HomeOwners;
