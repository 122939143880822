import {
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInputFieldFullWidth from "../components/CustomInputFieldFullWidth";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import {
  AMOUNT_EXCEEDED,
  AMOUNT_INVALID_MESSAGE,
  AMOUNT_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REIMBURSE_COMMENT,
} from "../utills/ApplicationConstants";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  HO,
  HOMEOWNERDUES_BY_ID,
  REIMBURSED_DUES,
} from "../utills/ApplicationRouting";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading";
import CustomInputFielddueandamountFullWidth from "../components/CustomInputFielddueandamountFullWidth";
import CustomMaterialTableForReimbursed from "../components/CustomMaterialTableForReimbursed";
import CustomInputField from "../components/CustomInputField";

function ApplyEditDueReimburse() {
  const { token, homeId } = useSelector((state) => state.hybridhoa);

  const [totalAmount, setTotalAmount] = useState("");
  const [comment, setComment] = useState("");
  const [waivedAmount, setWaivedAmount] = useState("");
  const [reimburseHomeOwnerDuesId, setReimburseHomeOwnerDuesId] = useState("");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const { homeOwnerDuesId, reimbursedDueId } = useParams();
  const [appliedTo, setAppliedTo] = useState("");


  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue, setError, clearErrors, watch, reset
  } = useForm();

  const [isLoading, setIsLoading] = useState(true);


  const getWaivedamount = async () => {

    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(
        `${BASE_URL}${REIMBURSED_DUES}/${reimbursedDueId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        setIsLoading(false);
        const data = response.data;
        setWaivedAmount(data.waivedAmount);
        setComment(data.comment);
        setAppliedTo(data.appliedTo);
        setTotalAmount(data.totalAmount);
        console.log(data.totalAmount);
        setReimburseHomeOwnerDuesId(data.homeOwnerDuesId);
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!reimbursedDueId) {
      // If not present, just return and don't call getWaivedamount
      console.log("reimbursedDueId is required to fetch waived amount.");
      return;
    }
    getWaivedamount();
  }, [reimbursedDueId]);


  const onSubmit = async (data) => {

    const parsedWaivedAmount = parseFloat(waivedAmount);
    const parsedDueAmount = parseFloat(totalAmount);
    if (parsedWaivedAmount > parsedDueAmount) {
      console.log("kjsdfhdsfajkhadsfhjasdfhadsfjk");
      
      setError("waivedOffAmount", {
        type: "manual",
        message: AMOUNT_EXCEEDED
      });

      setIsLoading(false);
      return;
    }
    if (!comment || !comment.trim()) {
      setError("reimburseComment", {
        type: "manual",
        message: REIMBURSE_COMMENT
      });
      setIsLoading(false);
      return;
    }
    if (!waivedAmount || !waivedAmount.trim()) {
      setError("waivedOffAmount", {
        type: "manual",
        message: AMOUNT_INVALID_MESSAGE
      });

      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    const payload = {
      totalAmount: totalAmount,
      comment: comment,
      waivedAmount: waivedAmount.trim(),
      appliedTo: appliedTo,
      homeOwnerDuesId: reimburseHomeOwnerDuesId,
    };
    request({
      url: `${REIMBURSED_DUES}/${reimbursedDueId}`,
      method: "put",
      data: payload,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setTimeout(() => {
            setIsLoading(false);
            navigate("/reimbursedue")
          }, 4000)
          setIsLoading(false);

        } else {
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
    // try {
    //   const response = await axios.put(
    //     `${BASE_URL}${REIMBURSED_DUES}/${reimbursedDueId}`,
    //     payload,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },

    //     }
    //   );
    //   if (response.status === 200) {

    //     setTimeout(() => {
    //       setIsLoading(false);
    //       navigate("/reimbursedue")
    //     }, 4000)

    //   } else {
    //     setIsLoading(false);
    //     throw new Error('Failed to fetch data');
    //   }
    //   console.log(response);
    // } catch (error) {
    //   if (error.response && error.response.data) {
    //     setIsLoading(false);

    //     console.log("error.response.status" + error.response.status);
    //   }
    // }
  };

  const handleWaivedChange = (event) => {
    setWaivedAmount(event.target.value);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Edit Waived Off" />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputField
                fieldName="waivedOffAmount"
                fieldId="waivedOffAmount"
                fieldType="text"
                fieldLabel="Waived Off Amount"
                // requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                onInput={handleWaivedChange}
                value={waivedAmount}
                tooltipMessage="Enter the Amount to be waived off"
              />
             

              <CustomInputField
                fieldName="reimburseComment"
                fieldType="text"
                fieldId="reimburseComment"
                fieldLabel="Waived Off Comment"
                // requiredErrorMessage={REIMBURSE_COMMENT}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={500}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                fieldControl={control}
                fieldError={errors}
                multiline={true}
                numberOfRows={1}
                fieldRequiredIcon="*"
                onInput={handleCommentChange}
                value={comment}
                tooltipMessage="Enter your comment here"

              />


            </section>
            {/* </div> */}
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Edit" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default ApplyEditDueReimburse;
