import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Tooltip,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import {
  DESCRIPTION_REQUIRED_MESSAGE,
  DUE_DATE_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MAX_LENGTH_TWO_FIFTY,
  MIN_LENGTH_VALIDATE_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  DATE_OF_BIRTH_REQUIRED_MESSAGE,
  ORGANIZATION_REQUIRED_MESSAGE,
  ROLE_REQUIRED_MESSAGE,
  ROLE_INVALID_MESSAGE,
  WEBSITE_REQUIRED_MESSAGE,
  SSN_REQUIRED_MESSAGE,
  ROUTING_NUMBER_REQUIRED_MESSAGE,
  ACCOUNT_NUMBER_REQUIRED_MESSAGE,
  SSN_INVALID_MESSAGE,
  ROUTING_NUMBER_INVALID_MESSAGE,
  ACCOUNT_NUMBER_INVALID_MESSAGE,
  ACCOUNT_NUMBER_MINIMUM_MESSAGE,
  WEBSITE_INVALID_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  ALPHANUMERIC_PUNCTUATION_SLASH_UNDERSCORE_WITH_SPACE,
  ONLY_ALPHABETIC,
  EMAIL_REGEX_PATTERN,
  ONLY_DIGITS,
  WEBSITE,
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
} from "../utills/ApplicationRegex";
import CustomInputField from "../components/CustomInputFieldWithHelperText";
import CustomButton from "../components/CustomButton";
import { Controller, useForm } from "react-hook-form";
import CustomDatePicker from "../components/CustomDatePicker";
import dayjs from "dayjs";
import { request } from "../services/AxiosConfig";
import { BASE_URL, PAYMENT_DETAILS } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomInputFieldPreventPaste from "../components/CustomInputFieldWithHelperTextPastePrevent";
import CustomDateEditPicker from "../components/CustomDateEditPicker";
import { InfoOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function AddPaymentGateway() {
  const [publishDate, setPublishDate] = React.useState("");
  const { token } = useSelector((state) => state.hybridhoa);
  const [groupId, setGroupId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState("true");
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState(false);
  const [confirmAccount, setConfirmAccount] = useState(false);
  const [routing, setRouting] = useState(false);

  const maskAccountNumber = (accountNumber) => {
    if (!accountNumber) return "";
    if (accountNumber.length <= 4) return accountNumber;
    return accountNumber.slice(-4).padStart(accountNumber.length, "*");
  };
  const ACCOUNT_NUMBER = (value) =>
    /^\d+$/.test(value) || "Account number must contain only digits";

  const ROUTING_NUMBER = (value) =>
    /^\d+$/.test(value) || "Routing number must contain only digits";

  useEffect(() => {
    const tes = () => {
      const persistRoot = JSON.parse(localStorage.getItem("persist:root"));

      if (
        persistRoot &&
        persistRoot.userGroupDetails &&
        typeof persistRoot.userGroupDetails === "string"
      ) {
        const userGroupDetailsObject = JSON.parse(persistRoot.userGroupDetails);
        // console.log(userGroupDetailsObject);
        if (userGroupDetailsObject && userGroupDetailsObject.groupId) {
          const groupId = userGroupDetailsObject.groupId;
          console.log("groupId:", groupId);

          setGroupId(groupId);
        } else {
          console.log("groupId not found in userGroupDetails.");
        }
      }
    };
    tes();
  }, [groupId]);

  const handleDueChange = (event) => {};

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const handleConfirmAccountNumberPaste = (e) => {
    e.preventDefault(); // Prevent pasting into the confirm account number field
  };

  const onSubmit = (data) => {
    console.log("inside submit ");
    if (!publishDate) {
      setError("dueDate", {
        type: "manual",
        message: DUE_DATE_REQUIRED_MESSAGE,
      });

      return;
    }

    if (data.accountNumber != data.confirmaccountNumber) {
      setError("confirmaccountNumber", {
        type: "manual",
        message:
          "The confirm account number entered does not match the account number. Please ensure both account numbers match",
      });

      return;
    } else {
      setIsLoading(true);
      const postData = {
        legalFirstName: data.firstName,
        legalLastName: data.lastName,
        orgType: data.organizationType,
        legalRole: data.role,
        hoaDesc: data.description,
        ssn: data.ssn,
        routingNumber: data.routingNumber,
        dob: publishDate ? dayjs(publishDate).format("YYYY-MM-DD") : "",
        businessEmail: data.businessEmail,
        accountNumber: data.accountNumber,
      };

      console.log(postData);
      // return;
      request({
        url: `${PAYMENT_DETAILS}/${groupId}`,
        method: "put",
        data: postData,
      })
        .then((res) => {
          if (res.status == 200) {
            setTimeout(() => {
              setIsLoading(false);

              navigate(`/paymentgatewayview`);
            }, 4000);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.response);
          if (error.response) {
          }
        });
    }
  };
  const dobDate = dayjs().subtract(18, "year").endOf("day");
  const handlePublishDateChange = (selectedPublishDate) => {
    setPublishDate(selectedPublishDate);
    clearErrors("dueDate");
  };
  const renderTooltip = (message) => (
    <Tooltip
      title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>}
      placement="right"
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Add Payment Gateway" />
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Basic Details" />
              <CustomInputField
                fieldName="firstName"
                requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                helperText="Enter your First name exactly as it is recorded with the IRS."
                tooltipMessage={
                  "Enter your First name exactly as it is recorded with the IRS."
                }
              />
              <CustomInputField
                fieldName="lastName"
                requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                helperText="Enter your Last name exactly as it is recorded with the IRS."
                tooltipMessage={
                  "Enter your Last name exactly as it is recorded with the IRS."
                }
              />
              <CustomDateEditPicker
                fieldName="dateofBirth"
                label="Date of Birth"
                control={control}
                requiredErrorMessage={DATE_OF_BIRTH_REQUIRED_MESSAGE}
                maxDate={dobDate}
                onChange={handlePublishDateChange}
                fieldRequiredIcon="*"
                tooltipMessage={"Enter your Date of Birth"}
              />
              <CustomInputField
                fieldName="ssn"
                requiredErrorMessage={SSN_REQUIRED_MESSAGE}
                minLength={4}
                minLengthErrorMessage="SSN must be 4 digits"
                maxLength={4}
                maxLengthErrorMessage="SSN must be 4 digits"
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={SSN_INVALID_MESSAGE}
                fieldId="ssn"
                fieldType="text"
                fieldLabel="SSN"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                helperText="Last 4 digits of Social Security number"
                tooltipMessage={
                  "Enter your social security number. It should be secured"
                }
              />
              <CustomHeading title="Organization Details" />
              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <Controller
                    name="organizationType"
                    control={control}
                    rules={{ required: ORGANIZATION_REQUIRED_MESSAGE }}
                    fieldRequiredIcon="*"
                    render={({ field, fieldState }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={fieldState.invalid}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Please Select Organization Type <span style={{ color: "red" }}> * </span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            handleDueChange(e);
                          }}
                          label="Please Select Organization Type"
                        >
                          <MenuItem value="Non-Profit Organization">
                            Non-Profit Organization
                          </MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {fieldState.invalid ? fieldState.error?.message : ""}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div>{renderTooltip("Select one of Organization")}</div>
              </div>

              <CustomInputField
                fieldName="description"
                requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={1000}
                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                fieldId="description"
                fieldType="text"
                fieldLabel="HOA Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={3}
                tooltipMessage={"Enter description"}
              />
              <CustomHeading title="Role" />
              <CustomInputField
                fieldName="role"
                requiredErrorMessage={ROLE_REQUIRED_MESSAGE}
                fieldPattern={
                  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN
                }
                patternErrorMessage={ROLE_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="role"
                fieldType="text"
                fieldLabel="Role"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage={"Enter your Role"}
              />
              <CustomHeading title="Business website" />
              <CustomInputField
                fieldName="businessEmail"
                fieldPattern={WEBSITE}
                patternErrorMessage={WEBSITE_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="businessEmail"
                fieldType="text"
                fieldLabel="Business Website"
                fieldControl={control}
                fieldError={errors}
                tooltipMessage={"Enter your business website url"}
              />

              <CustomHeading title="Banking Details" />
              <div className="flex items-center md:w-1/2 w-full">
                <Controller
                  name="accountNumber"
                  control={control}
                  rules={{
                    required: ACCOUNT_NUMBER_REQUIRED_MESSAGE,
                    validate: ACCOUNT_NUMBER,
                    minLength: {
                      value: 2,
                      message: ACCOUNT_NUMBER_MINIMUM_MESSAGE,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type={newPassword ? "text" : "password"}
                      variant="standard"
                      label={<span>
                        Account Number
                        <span style={{ color: "red" }}> * </span>
                    </span>}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setNewPassword(!newPassword)}
                              edge="end"
                            >
                              {newPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "14px" }}>
                      Provide vaild account number for adding a payment . It
                      should be secured{" "}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="flex items-center md:w-1/2 w-full">
                <Controller
                  name="confirmaccountNumber"
                  control={control}
                  rules={{
                    required: ACCOUNT_NUMBER_INVALID_MESSAGE,
                    validate: ACCOUNT_NUMBER,
                    minLength: {
                      value: 2,
                      message: ACCOUNT_NUMBER_MINIMUM_MESSAGE,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type={confirmAccount ? "text" : "password"}
                      variant="standard"
                    
                      label={<span>
                       Confirm Account Number
                        <span style={{ color: "red" }}> * </span>
                    </span>}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      sx={{ width: "100%" }}
                      onPaste={(e) => e.preventDefault()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setConfirmAccount(!confirmAccount)}
                              edge="end"
                            >
                              {confirmAccount ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "14px" }}>
                      Confirm your account number{" "}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </div>

              <div className="flex items-center md:w-1/2 w-full">
                <Controller
                  name="routingNumber"
                  control={control}
                  rules={{
                    required: ROUTING_NUMBER_REQUIRED_MESSAGE,
                    validate: ROUTING_NUMBER,
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type={routing ? "text" : "password"}
                      variant="standard"
                     
                      label={<span>
                       Routing Number
                         <span style={{ color: "red" }}> * </span>
                     </span>}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setRouting(!routing)}
                              edge="end"
                            >
                              {routing ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "14px" }}>
                      Enter your routing number it should be secured{" "}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Add" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default AddPaymentGateway;
