import React, { useEffect, useState } from "react";
import CustomHeading from "../components/CustomHeading";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Card, CardContent, FormHelperText } from "@mui/material";
import {
  CONTACT_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_ONE_CHARACTERS,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PHONE_REQUIRED_MESSAGE,

} from "../utills/ApplicationConstants";
import { ONLY_ALPHABETIC } from "../utills/ApplicationRegex";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomInputField from "../components/CustomInputField";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL, PROFILE } from "../utills/ApplicationRouting";
import CustomLoading from "../components/CustomLoading";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomPhoneInput from "../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomBackButtonWithoutDisable from "../components/CustomBackButtonWithoutDisable";

function Profile() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.hybridhoa);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [houseCount, setHouseCount] = useState("");

  const handleClick = () => {
    navigate(-1);
  };
  const handleChangePassword = () => {
    navigate("/changepassword");
  };
  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState,
    setValue,
    formState: { errors },
  } = useForm();
  console.log("first", errors);
  const handleInputChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const getProfileDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${PROFILE}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(data);

        setFirstName(data.firstName);
        setLastName(data.lastName);

        setAddress(data.address);
        setEmail(data.email)
        setContact(data.contact);
        setHouseCount(data.houseCount);
        setValue("phoneNumber", data.contact);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);


    // if (!formState.isValid) {
    //   setIsLoading(false);
    //   return;
    // }

    const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);
    if (typeof firstName !== 'string' || firstName.trim() === '') {
      setError("firstName", {
        type: "manual",
        message: FIRST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof lastName !== 'string' || lastName.trim() === '') {
      setError("lastName", {
        type: "manual",
        message: LAST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }


    if (!isValidPhoneNumberForCountry) {
      setIsLoading(false);
      return;
    }
    const postData = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email,
      contact: data.phoneNumber,
      houseCount: houseCount,
    };

    request({ url: PROFILE, method: "post", data: postData })
      .then((res) => {
        console.log(res);

        if (res.status == 200) {
          console.log("Success");
          getProfileDetails();

          setIsLoading(false);
          setTimeout(() => {
            navigate("/")

          }, 5000)
        }

      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />

        <CustomHeading title="Edit Profile" />

        <div class="md:full md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 space-x-2">
          <CustomBackButtonWithoutDisable
            fieldType="button"
            buttonName="Change Password"
            click={handleChangePassword}
          />
          <CustomBackButtonWithoutDisable
            fieldType="button"
            buttonName="Back"
            click={handleClick}

          />

        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          {isLoading && <CustomLoading />}
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputField
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={firstName}
                multiline={true}
                onInput={handleInputChange}
                tooltipMessage=" Update your First name here. This is how we'll address you in communications and personalize your experience on our platform"

              />

              <CustomInputField
                fieldName="lastName"
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_ONE_CHARACTERS}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={lastName}
                multiline={true}
                onInput={handleLastNameChange}
                tooltipMessage=" Update your last name here. This is how we'll address you in communications and personalize your experience on our platform"

              />
              <CustomInputField
                disabled={true}
                fieldName="email"
                validator={false}
                fieldId="email"
                fieldType="text"
                fieldLabel="email"
                fieldControl={control}
                fieldError={errors}
                value={email}
                multiline={true}
                tooltipMessage="your current email address. This is how we'll communicate with you and keep you informed about important updates and notifications"

              />
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: PHONE_REQUIRED_MESSAGE,
                  validate: {
                    isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
                  }
                }}
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      label="Phone Number"
                      clearErrors={clearErrors}
                      country="US"
                      value={field.value}
                      tooltipMessage="Please provide your current phone number. This allows us to reach you for account verification, important notifications, and customer support purposes"

                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={
                        fieldState.invalid ? fieldState.error?.message : ""
                      }
                    />
                  </>
                )}
              />
            </section>
            <br />
            <br />
            {/* <Controller
              name="phoneNumber"
              defaultValue="+917010406926"
              control={control}
              rules={{ required: CONTACT_REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    error={fieldState.invalid}
                    country={"us"}
                    value={field.value}
                    onInput={(value) => field.onChange(value)}
                  />
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </>
              )}
            /> */}

            <div class="text-center mt-10">
              <CustomBackButtonWithoutDisable fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default Profile;
