import React, { useEffect, useMemo, useState } from "react";
import { BYLAW_REQUIRED_MESSAGE, DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_FIVE_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_VALIDATE_MESSAGE, TOPIC_REQUIRED_MESSAGE } from '../utills/ApplicationConstants'
import CustomHeading from '../components/CustomHeading'
import {
    DialogActions,
    DialogContent,
    InputLabel,
    MenuItem,
    Typography,
    Select,
    FormControl,
    FormHelperText,
    Card,
    CardContent,
    Tooltip,
    IconButton,
} from "@mui/material";
import CustomLoading from "../components/CustomLoading";
import {  Controller, useForm } from "react-hook-form";
import CustomTooltipField from "../components/CustomTooltipField";
import CustomCheckBox from "../components/CustomCheckBox";
import CustomButton from "../components/CustomButton";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, BYLAW } from "../utills/ApplicationRouting";
import axios from "axios";
import { request } from "../services/AxiosConfig";
import { InfoOutlined } from "@mui/icons-material";
import CustomToastContainer from "../../../group-portal/src/components/CustomToastContainer";

function AddBylaw() {
  const navigate = useNavigate();
  const [bylawType, setBylawType] = React.useState("");
  const [isNotifys, setIsNotifys] = useState(false);
  const { token } = useSelector((state) => state.hybridhoa);
  const {
    handleSubmit,
    control,
    
    reset,
    setValue,
    setError,
    formState: { errors },
  
  } = useForm();

  const bylaw = [
    { label: "ARC" },
    { label: "Bylaw", id: 2 },
    { label: "Covenants", id: 3 },
  ];

  const [bylawDetails, setBylawDetails] = useState([]);
  const [byLawIds, setByLawIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const handleViewClick = () => {
    navigate(-1);
  };
  const onSubmit = (data) => {
    setIsLoading(true);

    console.log("buttonWorking");

    console.log("Form Data:", data);
    console.log(
      "Selected Bylaw Label:",
      data.selectedOption ? data.selectedOption.label : data.selectedOption
    );
    // const type = bylaw[selectedLabel] || "";
    // console.log(type);
    const postData = {
      topic: data.topic,
      description: data.description,
      type: type,
      isNotify: data.bylawNotification,
    };
    const storedToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };

    request({
      url: BYLAW,
      method: "post",
      data: postData,
      headers: config.headers,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setTimeout(() => {
            navigate(-1);
          }, 4000);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error.response);
        if (error.response) {
          setIsLoading(false)
          console.log("error.response.status" + error.response.status);
        }
      });
    console.log(data.topic);
    console.log(data.bylaw);
    console.log(data.description);
    console.log(data.bylawNotification);
  };


  const renderTooltip = (message) => (
    <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );

  return (
    <div>
      {isLoading && <CustomLoading />}
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Bylaw" />
          
        
          <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6">
          <CardContent>
      <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-2">
              <CustomTooltipField
                fieldName="topic"
                fieldId="topic"
                fieldType="text"
                fieldLabel="Topic"
                requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldRequiredIcon="*"
                fieldControl={control}
                fieldError={errors}
                numberOfRows={2}
                multiline={true}
                tooltipMessage={"Please enter the Topic"}
              />
            

            {/* <CustomAutoComplete
              fieldName="selectedOption"
              requiredErrorMessage={BYLAW_REQUIRED_MESSAGE}
              id="selectId"
              requiredIcon="*"
              options={bylaw}
              labelName="Select Bylaw"
              fieldControl={control}
              error={errors}
              setValue={setValue}
            /> */}
            <div className="flex items-center md:w-1/2 ">
<div className="w-full ">
                  <Controller
                    name="dueFrequency"
                    defaultValue=""
                    control={control}
                    rules={{ required: BYLAW_REQUIRED_MESSAGE }}
                    render={({ field, fieldState }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={fieldState.invalid}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Please select type
                          <span style={{ color: "#D32F2F" }}>*</span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={type}
                          onChange={(e) => {
                            setType(e.target.value);
                            field.onChange(e.target.value);
                          }}
                          label="Select type"
                        >
                          <MenuItem value="ARC">ARC</MenuItem>
                          <MenuItem value="BYLAW">ByLaw</MenuItem>
                          <MenuItem value="COVENANTS">Covenants</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {fieldState.invalid ? fieldState.error?.message : ""}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div>
                  {renderTooltip("Please select the bylaw type")}
                </div>
              </div>
           

              <CustomTooltipField
                fieldName="description"
                requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                fieldId="description"
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={5000}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                fieldLabel="Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={2}
                tooltipMessage={"Please enter the Description"}
              />
            
            {/* <CustomCheckBox
              fieldName="bylawNotification"
              control={control}
              defaultValue={isNotifys}
              labelName="Does a bylaw notification need to be sent to homeowners? "
              onChange={(newValue) => setIsNotifys(newValue)}
            /> */}
            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Add" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  )
}

export default AddBylaw
