import {
    Button,
    Card,
    CardContent,
    DialogActions,
    DialogContent,
    Divider,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import { useNavigate, useParams } from "react-router-dom";
  import CustomViewPage from "../components/CustomViewPage";
  import { useForm } from "react-hook-form";
  
  import CustomInputField from "../components/CustomInputField";
  import CustomButton from "../components/CustomButton";
  import { useSelector } from "react-redux";
  import {
    BASE_URL,
    GENERAL_COMMENTS,
    GET_ALL_GENERAL_COMMENTS,
    CATEGORY,
    COMPLIANCE_REQUEST,
    BASE_URL_IMAGE,
    GROUP_CONFIG,
  } from "../utills/ApplicationRouting";
  import axios from "axios";
  import moment from "moment";
  
  import Loading from "react-loading";
  import CustomLoading from "../components/CustomLoading";
  
  import CustomToastContainer from "../components/CustomToastContainer";
import CustomViewPages from "../components/CustomViewExportPage";
import PDFDocument from "../components/Templatepdf";
import { pdf } from "@react-pdf/renderer";
import CustomHeading from "../components/CustomHeading";
import CustomViewReportHeading from "../components/CustomViewReportHeading";
import CustomViewReportToolTip from "../components/CustomViewReportToolTip";
import AttachmentList from "../components/AttachmentList";
import AttachmentListsView from "../components/AttachmentListView";

// import PDFBLANKPAGE from "../components/PdfBlankPage";




function Audit_Cr_View() {
    const navigate = useNavigate();
    const handleClick = () => {
      navigate(-1);
    };
  
    const {
      handleSubmit,
      control,
      reset,
      setError,
      formState: { errors },
    } = useForm();
    const { token, userDetails } = useSelector((state) => state.hybridhoa);
    const [complianceRequestData, setComplianceRequestData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { complainceId } = useParams();
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [firstNameData, setFirstNameData] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [createdDateAndTime, setCreatedDateAndTime] = useState("");
    const [lastNameData, setLastNameData] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [crId, setCrId] = useState("");
    const [address,setAddress] = useState("");
    const [complainceDetails,setComplainceDetails]=useState('')
    const [includeComments, setIncludeComments] = useState(false);
    const handleCheckboxChange = (event) => {
      setIncludeComments(event.target.checked);
    };
    const userName = userDetails.firstName + " " + userDetails.lastName;
    const [fineApply,setFineApply] = useState("");
    const [showFullDescription, setShowFullDescription] = useState(false);
    // groupConfiGuration logo
    const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const [fileImage,setFileImage]=useState([]);
  const [fileImages,setFileImages]=useState("");
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [filePdf,setFilePdf]=useState("");
const[viewImage,setViewImage]=useState("")
  const [footerContent,setFooterContent]=useState('');
  const[footerAdress,setFooterAdress]=useState('')
  const[groupAdress,setGroupAdress]=useState('')
  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data);
        const logoConfig = data.find(config => config.topic === 'Logo');
        const footerValue =data.find(config => config.topic === 'Contact Details');

        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        if(footerValue){
          setFooterAdress(footerValue.content);
        } const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
     getGroupConfigurationDetails()
  },[])
  const getFile=async () => {
        try {
          const storedToken = token;
          const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
            // headers: {
            //   Authorization: `Bearer ${storedToken}`,
            // },
            responseType: "blob",
          });
          const blob = response.data; // Accessing the blob data from the response
          const fileURL = URL.createObjectURL(blob);
          setImageBlob(fileURL);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(()=>{
        getFile()
      },[logoUrl])
      
    const getCategory = async () => {
      try {
        const storedToken = token;
  
        const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
  
        if (response.status === 200) {
          const data = response.data;
          setCategoryDetails(data);
          console.log(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        setIsLoading(false);
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getCategory();
    }, []);
    const getGeneralComments = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;
    
        console.log(storedToken);
    
        const response = await axios.get(
          `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${complainceId}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
    
        if (response.status === 200) {
          const data = response.data;
          setGeneralCommentDetails(data);
          // setEditModes(Array(data.length).fill(false));
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        setIsLoading(false);
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
    
    useEffect(() => {
      getGeneralComments();
    }, [complainceId]);
    const getComplianceRequestDetailsById = async () => {
      try {
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(
          `${BASE_URL}${COMPLIANCE_REQUEST}/${complainceId}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          console.log(response.data);
          setComplainceDetails(data);
          const createdDetails = data.createdByDetails;
          setFileUrl(data.fileUrl);
          const formattedDate = moment(data.createdDateAndTime).format(
            "MM/DD/YYYY hh:mm A"
          );
          setFileImage(data.attachments);
          setCreatedDateAndTime(formattedDate);
          setFirstNameData(createdDetails.firstName);
          setLastNameData(createdDetails.lastName);
          setComplianceRequestData(data);
          setAddress(data.address.address);
          setFilePdf(data.fileUrl)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getComplianceRequestDetailsById();
    }, [complainceId]);
    
    
    const currentDate = moment(); // Get the current date
    let dateTimeText = currentDate.format("DD MMMM YYYY");
    const handleRenderPDF = () => {
    
      const styles = {
        title: {
          fontSize: 12,
          color: "black",
          textAlign: "center",
          padding:"5px",
          fontWeight: "bold" 
        },
      empty:{
        color:"red",
      padding:"500px"
      },
      content: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px"
      },
      comments:{
        fontSize:12,
        color:"black",
        marginTop:"2%"
      },
      emptys:{
          marginTop:"3%"
      },
      empty:{
      marginTop:"120%"
      },
      secondEmpty:{
       marginTop:"31%"
      },
      from:{
        fontSize: 12,
        color: "black",
        textAlign: "left",
        alignItems:"center",
        padding:"2px",
        paddingRight: "10px"
       },
     headers:{
      fontSize: 12,
      color: "black",
      textAlign: "left",
      alignItems:"center",
      padding:"4px",
      marginRight:"4px"
      },
     emptyModule:{
      marginTop:"7px"
     },
     re:{
  fontSize:12,
  color:"black",
  textAlign:"left",
  padding:"5px"
     },
      adress:{
        fontSize: 12,
        color: "black",
        textAlign: "left",
        
        // paddingLeft:"60px",
        padding:"5px",
        // fontWeight:"bold"
      },
      toDate:{
          marginTop:"1%",
      },
      description:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      dear:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      signature: {
        fontSize: 16,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      heading: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Oswald'
      },
      pageInitial:{
        marginTop:"100px"
      },
      afterfrom:{
        marginTop:"6%"
      },
      formempty:{
        marginTop:"45%"
      },anotherempty:{
        marginTop:"8%"
      },images:{
  width:"200px",
  height:"100px"
  
      },
      fromBorder:{
         border:"1px solid black"
      },toBorder:{
        border:"1px solid black"
  
      },
      headerImage:{
        marginTop:"1%",
   paddingLeft:"70px",
      paddingRight:"50px",
      alignItems:"center",
  
        width:"470px",
        height:"160px",
        // backgroundColor:"yellow"
      }
    };
    const datas = {
      // title:"Complaince Request Notice",
       logo: imageBlob,
            letterContent: [
        // { type: 'text',text: "",style:styles.emptys }, // Empty line for spacing
        { type: 'text', text: groupAdress, style: styles.title },
       
        { type: 'text', text: "Compliance Request", style: styles.title },
        { type: 'text', text: " "  }, 
        // { type: 'text',text: dateTimeText, style:styles.from },
        // { type: 'text',text: "",  }, // Empty line for spacing
        // { type: 'text',text: "",  },
        
        {style:styles.fromBorder},
        
        {type: 'text', text: "",  style:styles.formempty},
        // {type: 'text', text: "",  style:styles.anotherempty},
        // { type: 'text',text: "",  style:styles.secondEmpty},
            
        { type: 'text',text: "From :", style: styles.from  } ,
        { type: 'text', text: groupAdress, style: styles.from },

        { type: "text", text: footerAdress, style: styles.from },
  
        // {type: 'text',text: ""+ `${complainceDetails.createdByDetails?.firstName ||""}`,style: styles.from, },
        // {type: 'text',text: ""+ `${complainceDetails.address?.address || ""}`,style: styles.from,},
        // { type: 'text',text: `${complainceDetails.createdByDetails?.city || ""}, ${complainceDetails.createdByDetails?.zipcode || ""}`, style: styles.from },
       
               // {style:styles.toBorder},
  
        { type: 'text',text: "",  style:styles.afterfrom},
        { type: 'text',text: "To :", style: styles.headers  },
        {
          type: 'text',
          text: `${complainceDetails?.createdToDetails?.firstName || ""}${complainceDetails?.createdToDetails?.lastName || ""}`,
          style: styles.headers
        },
        { 
          type: 'text',
          text:`${complainceDetails?.createdToDetails?.addressForCommunication ?? complainceDetails?.createdToAddress?.address ?? ""}`,
          style: styles.headers
        },
        {
          type: 'text',
          text: `${complainceDetails?.createdToDetails?.city || ""}${complainceDetails?.createdToDetails?.zipcode ? `, ${complainceDetails?.createdToDetails?.zipcode}` : ""}`,
          style: styles.headers
        },
        
              
        {type: 'text', text: "",  style:styles.empty},
        { type: 'text',text: "",  style:styles.secondEmpty},
        { type: 'text',text: "",  style:styles.toDate},
        ...(imageBlob ? 
          [{ type: 'image', src: imageBlob, style: styles.headerImage }] : 
          [{ type: 'text', text: "", style: styles.headerImage }]
        ),
        // {type: 'image',src:imageBlob, style:styles.headerImage  },
        { type: 'text', text: groupAdress, style: styles.title },
       
        { type: 'text', text: "Compliance Request", style: styles.title },
        {type:'text',text:dateTimeText,style: styles.content},
        { type: 'text',text: "",  style:styles.emptyModule},
  
        // {type:'text',text:"To",style: styles.content},
        {
          type: 'text',
          text: `${complainceDetails?.createdToDetails?.firstName || ""}${complainceDetails?.createdToDetails?.lastName || ""}`,
          style: styles.headers
        },
        { 
          type: 'text',
          text:`${complainceDetails?.createdToDetails?.addressForCommunication ?? complainceDetails?.createdToAddress?.address ?? ""}`,
          style: styles.headers
        },
        {
          type: 'text',
          text: `${complainceDetails?.createdToDetails?.city || ""}${complainceDetails?.createdToDetails?.zipcode ? `, ${complainceDetails?.createdToDetails?.zipcode}` : ""}`,
          style: styles.headers
        },
        { type: 'text',text: "",  style:styles.emptyModule},
  
  
        {
          type: 'text',
          text: `RE: ${complainceDetails?.createdToDetails?.addressForCommunication ?? complainceDetails?.createdToAddress?.address ?? ""}`,
          style: styles.re
        },
        { type: 'text',text: "",  style:styles.emptyModule},
  
  
        {type: 'text', text: "Dear Resident,",style: styles.dear},
  
        // {type: 'text', text: "Dear "  + `${(complainceDetails.createdToDetails?.firstName || "")}`, style: styles.content },
        // {type: 'text',text:"You have previously asked to adress the following matter with your property.",style: styles.content},
        { 
          type: 'text', 
          text: `This is to inform you about the compliance request: ${complainceDetails.crId}_${moment(complainceDetails.createdDateAndTime).format('MM/DD/YYYY hh:mm A')},`, 
          style: styles.content 
        },
            { type: 'text',text: "" + `${complainceDetails.description || ""}`+".", style: styles.description,},
      { type: 'text',text: "If u have any questions,feel that you have recieved this letter in error,or would  like to request a variance,please feel free to contact our office.", style: styles.content},
     {type: 'text',text:"Thank you in advance for your attention to this matter.", style: styles.content},
      {type: 'text', text: "",  }, 
      {type: 'text', text: "", style: styles.content },
      {type: 'text', text: "", style: styles.content },
        
        {type: 'text', text: "Sincerely,", style: styles.adress },
        { 
          type: "text", 
          text: `${footerAdress} (computer generated letter)`, 
          style: styles.adress 
        },
              // {text: ""+ complainceDetails.address?.address + "",style: styles.adress,fontSize: 15 ,},
        // { text: `${complainceDetails.createdByDetails?.city || ""}, ${complainceDetails.createdByDetails?.zipcode || ""}`, style: styles.adress, fontSize: 15 },    
        //  {type: 'text',text: footerContent , style: styles.adress}
          
        {type: 'text', text: "", },
        // {type: 'text', text: "",  style:styles.empty},
        // { type: 'text',text: "",  style:styles.secondEmpty},
        // {type: 'image',src:fileImage,style:styles.images },
  
      ]
    };
    if (Array.isArray(fileImage) && fileImage.length > 0) {
      datas.letterContent.push({ type: 'text', text: "Attachments :", style: styles.content });
  
      fileImage.forEach((fileImages, index) => {
        const trimmedFileName = fileImages.file.substring(12);
        datas.letterContent.push({ type: 'text', text: trimmedFileName, style: styles.content });
        datas.letterContent.push({ type: 'image', src: `${BASE_URL_IMAGE}/${fileImages.file}`, style: styles.images, alt: `Attachment ${index + 1}` });
      });
    } else {
      datas.letterContent.push({ type: 'text', text: "", style: styles.content });
    }
    if (includeComments && generalCommentDetails.length > 0) {
      // Add the "Comments" heading before looping through comments
      datas.letterContent.push({ type: 'text', text: "Comments :", style: styles.content });
  
      generalCommentDetails.forEach((commentDetail) => {
        // Combine the name and comment into a single line
        const combinedText = `@${commentDetail.createdByName} : ${commentDetail.comments}`;
        datas.letterContent.push({ type: 'text', text: combinedText, style: styles.content });
    });
  }
    return (
      <PDFDocument data={datas} />
    );
  };
  const handleClickPdf = async () => {
    // console.log("test",logoUrl);
    try {
      const pdfData = handleRenderPDF();
      const blob = await pdf(pdfData).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    //   const a = document.createElement('a');
    // a.href = url;
    // a.download = `${complainceDetails.crId}.pdf`; // Set the filename here
    // a.target = '_blank';
    // a.click();
    
    // // Clean up
    // URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';

    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
  
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  // console.log(complianceRequestData);
  const renderDescription = () => {
    console.log("inside render ");
    if (complainceDetails.description && !showFullDescription) {
    console.log("inside render ",complainceDetails.description);
  
      return (
        <>
        
          {complainceDetails.description.slice(0, 250)}
          {complainceDetails.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",complainceDetails.description);
  
      return (
        <>
          {complainceDetails.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };

  const fileList = fileImage.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
    const fullName = firstNameData + " " + lastNameData;
  
    const data = [
      {
        groupName: 'Compliance Request Details',
        items: [
          { label: 'Compliance Request Id ', data: `${ complainceDetails?.crId || ""}`, text: 'Unique identifier for the compliance request' },
          { label: 'Created Date Time ', data: `${moment(complainceDetails.createdDateAndTime).format(
                 "MM/DD/YYYY hh:mm:ss A")}` ,text: 'The date and time when the compliance request was created'},
                 { label: 'Category ', data: `${complainceDetails.categoryDetails?.category || ""}` ,text: 'The category or subject of the compliance request'},
                 { label: 'Description ', data: renderDescription() ,text: 'Detailed description of the compliance issue'},
                 { label: 'ByLaws Topic ', data: `${complainceDetails.bylawDetails?.topic ?? ""}` ,text: 'The topic of the bylaw related to the compliance issue'},

          ],
       },
      //  {
      //   groupName: 'Created By Details',
      //   items: [
      //     { label: 'Firstname', data:`${complainceDetails.createdByDetails?.firstName??""}`:text:"First name of the person  whom created compliance request"},
      //     { label: 'Lastname', data:`${complainceDetails.createdByDetails?.lastName??""}`:text:"Last name of the person who created compliance request" },
      //     { label: 'Email', data:`${complainceDetails.createdByDetails?.email??""}`:text:"Email address of the person who created compliance request " },
      //     { label: 'Address', data:`${complainceDetails?.address?.address??""}`:text:"Home address of the person who created compliance request" },
      //     { label: 'contact', data:`${complainceDetails.createdByDetails?.contact??""}`:text:"Contact number of the person Who created compliance request " },
      //   ],
      // },
      
       {
        groupName: 'Audit and Status Details',
        items: [
          { label: 'Audit Date Time ', data: `${moment(complainceDetails.auditDateAndTime).format(
          "MM/DD/YYYY hh:mm:ss A")}` ,text: 'The date and time of the last audit.'},
             { label: 'Status', data: `${convertToPascalCase(complainceDetails.status??"")}` ,text: 'Current status of the compliance request (e.g., Open, Closed)'},
             { label: 'Is Fine Applicable ', data: `${fineApply ?'Yes': 'No'}` ,text: 'Indicates if a fine is applicable for this compliance request'},
          ],
       },
      
      
      {
        groupName: 'Created To Details',
        items: [
          { label: 'Firstname ', data:`${complainceDetails.createdToDetails?.firstName??""}`,text:"FirstName of the person or entity to whom the compliance request was created"},
          { label: 'Lastname ', data:`${complainceDetails.createdToDetails?.lastName??""}`,text:"LastName of the person or entity to whom the compliance request was created" },
          { label: 'Email', data:`${complainceDetails.createdToDetails?.email??""}`,text:"Email address of the person or entity to whom the compliance request was created" },
          { label: 'Address ', data:`${complainceDetails.createdToAddress?.address??""}` ,text:"Home address of the person or entity to whom the compliance request was created"},
          { label: 'Address For Communication ', data:`${complainceDetails.createdToDetails?.addressForCommunication??""}` ,text:"Address For Communication of the person or entity to whom the compliance request was created"},

          { label: 'contact ', data:`${complainceDetails.createdToDetails?.contact??""}`,text:"Contact number of the person or entity to whom the compliance request was created" },
        ],
      },
      {
        groupName: 'AttachmentDetails',
        items: [
          { label:'Attachment', data: (
            <AttachmentListsView fileList={fileList} />

               ), text: 'List of Document attached to compliance request'},   
          ],
       },
    ];
    return (
      <div>
        <div>
          <CustomToastContainer />

          {/* <PDFBLANKPAGE/> */}

          {isLoading && <CustomLoading />}
          {/* <CustomViewPages data={data} exportedBy={true} handleExportss={handleClickPdf}
           checked={includeComments}
           handleCheckBoxChange={handleCheckboxChange}/> */}
           <CustomViewReportHeading onClick={handleClick} 
      handleExportss={handleClickPdf} 
       title={"View Compliance Request"} checked={includeComments}
       handleCheckBoxChange={handleCheckboxChange} exportedBy={true}>
                    <CustomViewReportToolTip data={data}  />
 
        <br />
        <>
      <CustomHeading title="General Comments" />

  <Card>
  
  <CardContent>
    {generalCommentDetails && generalCommentDetails.length > 0 ? (
      generalCommentDetails.map((comment, index) => (
        <div key={comment.generalCommentsId}>
          <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
            <p>
              <span className="text-primary" style={{ float: "left" }}>
                @ {comment.createdByName}{" "}
              </span>
              <span style={{ float: "right" }}>


               
                {moment(comment.createdDateAndTime).format(
                  "MM/DD/YYYY hh:mm:ss a"
                )}
              </span>
              
                <p className="mt-8 mb-8">{comment.comments}</p>
            </p>
          </section>

          <Divider />
        </div>
      ))) : (
        <div className="text-center mt-10">
          <p>No comments to display</p>
        </div>
      )}
  </CardContent>
</Card>
</>  
</CustomViewReportHeading>
</div>
      </div>
    );
    }
export default Audit_Cr_View