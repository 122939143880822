import { Button, Card, CardContent, TextField } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { CustomStyle } from '../components/CustomStyle';
import CustomHeading from '../components/CustomHeading';
import CustomViewPage from '../components/CustomViewPage';

function ViewAuditDocument() {
    const navigate = useNavigate();
    const handleClick =() =>{
        navigate(-1)
    }
    const data = {
        pageTitle:"View Document",
        buttons :[
          {
            buttonTitle:"Back",
            onClick : handleClick
          }
        ],
        content : [
          {
            topic :'Created By',
            description :'Home Req Test Four'
          },
          {
            topic :'Category ',
            description :'Public'
          },
          {
            topic :'Created Date Time ',
            description :'11/23/2023 10:27:19 AM'
          },
          {
            topic :'Audit Date Time',
            description :'11/23/2023 10:27:19 AM'
          },
          {
            topic :'Is Hidden',
            description :'false'
          },
          {
            topic :'Status',
            description :'UNDERREVIEW'
          },
          {
            topic :'Publish Date',
            description :'08/30/2023'
          },
          {
            topic :'Description',
            description :' test'
          },
          {
            topic :'Attachments ',
            description :''
          },
        ]
      }
    return (
        <div>
       
        <CustomViewPage data={data} />
                       
    
    </div>
    )
    }

export default ViewAuditDocument