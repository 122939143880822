import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomViewPage from '../components/CustomViewPage';
import { useSelector } from 'react-redux';
import { BASE_URL, HOMES } from '../utills/ApplicationRouting';
import axios from 'axios';
import CustomLoading from '../components/CustomLoading';
import moment from 'moment';
import CustomViewWithTooltip from '../components/CustomViewWithTooltip';
import CustomViewWithTooltipWithMultipleButtons from '../components/CustomViewWithTooltipWithMultipleButtons';
import AttachmentList from '../components/AttachmentList';
import AttachmentListsView from "../components/AttachmentListView"

function ViewHomeowners() {
  const { email } = useParams();
  const { token } = useSelector(state => state.hybridhoa);
  const [homeDetails, setHomeDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [renterDetails, setRenterDetails] = useState([]);
  const [uploadedTimes, setUploadedTimes] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentNameUrl, setDocumentNameUrl] = useState("");
  const [homeOwnerLoginId, setHomeOwnerLoginId] = useState("");
  // const [homeIdRenter, setHomeIdRenter] = useState("");
  const [fileUrls, setFileUrls] = useState([]);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const handleEditClickOpen = () => {
    console.log(email);
   navigate(`/edithomeowner/${email}/${homeId}`)
  };
  // const handleEditClickOpen=()=>{
  //   navigate(`/edithomeowner`)
  // }
  const handleAddClickOpen = () => {
    console.log(homeOwnerLoginId);
    navigate(`/addrenter/${homeOwnerLoginId}/${homeId}`);
  };
  const {homeId}=useParams();
  const getHomeDetailsById = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${HOMES}/${email}/${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      if (response.status === 200) {
        const data = response.data;
        setHomeDetails(data);
        setHomeOwnerLoginId(data.homeOwnerLoginId);
        // setHomeIdRenter(data.homeId)
        console.log(data.homeOwnerLoginId);
        console.log(data.homeId);
        console.log(homeId);
        setFileUrls(data.renter.attachments);
        if (data.renter) {
          const renterData = data.renter;
          console.log(renterData);
          setRenterDetails(renterData);

          // ... rest of your code
        } else {
          console.log("No renter data available.");
          // Handle the case when 'renter' data is not present or empty
        }
        setIsLoading(false);
       
        const sharedDocument = response.data;
        const listreqDocument = sharedDocument.renter || [];
        console.log(listreqDocument);
        if (listreqDocument.length > 0) {
          const firstDocument = listreqDocument[0];

          const documentNamesArr = [firstDocument.fileUrl];
          setDocumentName(documentNamesArr);
          console.log(documentNamesArr);

          const documentNamesArray = [
            firstDocument.fileUrl.replace(/^\d+/, ""),
          ];
          setDocumentNameUrl(documentNamesArray);

          const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          };

          const uploadedTimesArr = [
            new Date(firstDocument.createdDateAndTime).toLocaleString(
              "en-US",
              options
            ),
          ];
          setUploadedTimes(uploadedTimesArr);

        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } else {
        setIsLoading(false);
        console.log("error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomeDetailsById();
  }, [email]);

  const data1 = [
    {
      groupName: 'Home Owner Details',
      items: [
        {
          label: 'First Name',
          data: `${homeDetails.firstName === null ? "" : homeDetails.firstName}`,
          text: 'First Name of the Home owner'
        },
        {
          label: 'Last Name',
          data: `${ homeDetails.lastName === null ? "" : homeDetails.lastName}`
          , text: 'Last Name of the Home owner'
        }, 
        {
          label: 'Status',
          data: `${homeDetails.status == true ? "Active" : "In-Active"}`
          , text: 'Status of the Home owner'
        },
      ],
    },
{
      groupName: 'Address',
      items: [
        {
          label: 'Address',
          data: `${homeDetails.address === null ? "" : homeDetails.address}`,
          text: 'Address of the Home owner'
        },
        {
          label: 'Address For Communication',
          data: `${homeDetails.addressForCommunication === null ? "" : homeDetails.addressForCommunication}`,
          text: 'Address for communication of the Home owner'
        },
        {
          label: 'City',
          data: `${homeDetails.city === null ? "" : homeDetails.city}`
          , text: 'The name of the city where the  home owner is located'
        }, 
        {
          label: 'State',
          data: `${homeDetails.state === null ? "" : homeDetails.state}`
          , text: 'The name of the State where the  home owner is located'
        }, 
        {
          label: 'Zipcode',
          data: `${homeDetails.zipcode === null ? "" : homeDetails.zipcode}`
          , text: 'The Zipcode of the place where the home owner is located'
        },
      ],
    },

    {
      groupName: 'Contact Details',
      
      items: [
        {
          label: 'Email',
          data: `${homeDetails.email === null ? "" : homeDetails.email}`
          , text: 'Email for the contact purposes'
        },
        {
          label: 'Cell',
          data: `${homeDetails.contact === null ? "" : homeDetails.contact}`
          , text: 'Personal contact number for the contact purposes'
        },
        {
          label: 'Home',
          data: `${homeDetails.home === null ? "" : homeDetails.home}`
          , text: 'Home contact number for the contact purposes'
        },
        {
          label: 'Work',
          data: `${homeDetails.work === null ? "" : homeDetails.work}`
          , text: 'Work contact number for the contact purposes'
        },
        {
          label: 'Fax',
          data: `${homeDetails.fax === null ? "" : homeDetails.fax}`
          , text: 'Fax number for the contact purposes'
        },

      ],
    },
    {
      groupName: 'Role',
      items: [
        {
          label: 'Roles',
          data: Array.isArray(homeDetails.roles)
                  ? homeDetails.roles.map(role => {
                      switch(role) {
                        case "ROLE_HO":
                          return "HomeOwner";
                        case "ROLE_ARC":
                          return "ARC";
                        case "ROLE_BM":
                          return "Board Member";
                        default:
                          return role;
                      }
                    }).join(", ")
                  : homeDetails.roles
            , text: 'Roles of the Home Owner'
        },
      ],
    },
   

  ];


  const fileListLink = fileUrls.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
  const renterData = [
    {
      groupName: 'Renter Details',
      items: [
        {
          label: 'First Name',
          data: `${renterDetails.firstName === null ? "" : renterDetails.firstName}`,
          text: 'First name of the renter'
        },
        {
          label: 'Last Name',
          data: `${ renterDetails.lastName === null ? "" : renterDetails.lastName}`
          , text: 'Last name of the renter'
        }, 
        {
          label: 'Address',
          data: `${ renterDetails.address === null ? "" : renterDetails.address}`
          , text: 'Address of the renter'
        }, 
          {
          label: 'Date Of Birth',
          data:  renterDetails.dateOfBirth 
          ? moment(renterDetails.dateOfBirth).isValid() 
            ? moment(renterDetails.dateOfBirth).format("MM/DD/YYYY ") 
            : "" 
          : ""  ,
           text: 'Date Of Birth of the renter'
        }, 
        
      ],
    },
{
      groupName: 'Renter Information Details',
      items: [
        {
          label: 'Lease start date',
          data:  renterDetails.leaseStartDate 
          ? moment(renterDetails.leaseStartDate).isValid() 
            ? moment(renterDetails.leaseStartDate).format("MM/DD/YYYY ") 
            : "" 
          : ""  ,
          text: ' Lease Start Date of the Renter'
        }, 
        {
          label: 'Lease End date',
          data:  renterDetails.leaseEndDate 
          ? moment(renterDetails.leaseEndDate).isValid() 
            ? moment(renterDetails.leaseEndDate).format("MM/DD/YYYY ") 
            : "" 
          : ""  ,
          text: ' Lease end Date of the Renter'
        },
      ],
    },
    // renterDetails.leaseStartDate 
    // ? moment(renterDetails.leaseStartDate).isValid() 
    //   ? moment(renterDetails.leaseStartDate).format("MM/DD/YYYY ") 
    //   : "" 
    // : ""  
    {
      groupName: 'Contact Details',
      
      items: [
        {
          label: 'Email',
          data: `${renterDetails.email === null ? "" : renterDetails.email}`
          , text: 'Email for contact purposes'
        },
        {
          label: 'Contact',
          data: `${renterDetails.contact === null ? "" : renterDetails.contact}`
          , text: 'Phone number for the contact purposes'
        },
       

      ],
    },
    {groupName:"Attachment Details",
    items:[
      { label:'Attachment', data: (
        <AttachmentListsView fileList={fileListLink} />

      ), text: 'List of Document attached to Renter'},
    ]
  }

  ];

  const buttons = [
    { label: 'Back', onClick: handleClick },
    { label: 'Edit', onClick: handleEditClickOpen },
    { label: 'Add A Renter', onClick: handleAddClickOpen },
  ];

  const buttons1 = [
    
  ];
  return (
    <div>
      {isLoading&&<CustomLoading/>}

      {/* {<CustomViewPage data={data} />} */}
      {/* {<CustomViewWithTooltip data={data1}  title={"View Homeowner Details"} onClick={handleClick} />} */}
      
      <CustomViewWithTooltipWithMultipleButtons
      data={data1}
      title="View Homeowner Details"
      buttons={buttons}
    />

      {renterDetails.length !=0 ?
      
          <CustomViewWithTooltipWithMultipleButtons  data={renterData}  buttons={buttons1} title="Active Renter Details" />:""
      }
    </div>
  );
}

export default ViewHomeowners;
