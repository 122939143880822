import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormLabel, RadioGroup, FormControlLabel, Radio,
  IconButton,
  Typography,
  Modal,
  DialogContent,
  DialogActions
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import {
  AMOUNT_INVALID_MESSAGE,
  AMOUNT_REQUIRED_MESSAGE,
  DESCRIPTION_INVALID_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  DUE_DATE_REQUIRED_MESSAGE,
  DUE_FREQUENCY_REQUIRED_MESSAGE,
  DUE_TYPE_REQUIRED_MESSAGE,
  END_DATE_REQUIRED_MESSAGE,
  END_DATE_REQUIRED_MESSAGES,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_HUNDRED,
  MAX_LENGTH_THOUSAND,
  MAX_LENGTH_TWO_FIFTY,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  SPECIAL_ASSESSMENT_DUE_TYPE,
  TITLE_INVALID_MESSAGE,
  TITLE_REQUIRED_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  ALPHANUMBERIC_ALL_SPECIAL_CHARACTERS,
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  ALPHANUMERIC_PUNCHUCATION_SLASH_UNDERSCORE,
  ALPHANUMERIC_PUNCTUATION_SLASH_UNDERSCORE_WITH_SPACE,
  FIVE_DIGITS_TWO_DECIMAL,
  NO_DIGITS_REGEX,
  ONLY_ALPHABETIC,
  ONLY_ALPHABETIC_REGEX_PATTERN,
} from "../utills/ApplicationRegex";
import CustomInputField from "../components/CustomInputField";
import CustomButton from "../components/CustomButton";
import { Controller, useForm } from "react-hook-form";
import CustomDatePicker from "../components/CustomDatePicker";
import dayjs from "dayjs";
import CustomRadioButton from "../components/CustomRadioButton";
import { request } from "../services/AxiosConfig";
import { BASE_URL, PAYMENT_CONFIG_LIST, HOMES, HOMES_DETAILS } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomAutoComplete from "../components/CustomAutoComplete";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from"../components/CustomLoading"
import CustomInputFielddueandamount from "../components/CustomInputFielddueandamount";
import CustomDateEditPicker from "../components/CustomDateEditPicker";
import { InfoOutlined } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CustomTooltipField from "../components/CustomTooltipField";
import HomesAutoComplete from "../components/Homesautocomplete";
import CustomModel from "../components/CustomModel";

function AddPaymentConfig() {
  const [dueType, setDueType] =useState("");
  const [dueFrequency, setDueFrequency] = React.useState("ONE_TIME");
  const [publishDate, setPublishDate] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState('For All Homeowners');
  const [isForAllHomes, setIsForAllHomes] = React.useState(false);
  const [homeDetails, setHomeDetails] = useState("");
  const { token } = useSelector(state => state.hybridhoa);
  const [homeName, setHomename] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [endDate,setEndDate]=useState("")
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [homeOwnerDetailsId, setHomeOwnerDetailsId] = useState('');
  const [selectedOwner, setSelectedOwner] = useState('');
  const [homeOwnerLoginId, setHomeOwnerLoginId] = useState('');
  const [homesData, setHomesData] = useState([]);
  const [dueDateSelected, setDueDateSelected] = useState(false);
  const [homeId, setHomeId] = useState("");
  const[isLoading,setIsLoading]=useState(null)
  const [hasSelected, setHasSelected] = useState(false);
  const [disableFrequency,setDisableFrequency]=useState("")
  const [showModal, setShowModal] = useState(false);

  
  const handleRadioChange = (event) => {
    const newValue = event.target.value;
    // const updatedIsForAllHomes = newValue === 'For All Homeowners';

  setSelectedValue(newValue);
    // console.log('isForAllHomes:', updatedIsForAllHomes);
    setValue('selectedOption', "");

    setPublishDate('')
    setEndDate('')
  };
  useEffect(() => {
    setSelectedOption(null);
    if(selectedValue === 'For All Homeowners'){
        setIsForAllHomes(true);
    }else{
        setIsForAllHomes(false);
    }
  }, [dueType,selectedValue]);


  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const [hasSelection,setHasSelection]=useState(false)
  const [touched, setTouched] = useState(false);

  const handleDueChange = (event) => {
    const selectedValue = event.target.value;
    
    setDueType(selectedValue);
    setHomename('');
    setHomeOwnerDetailsId('')
setHasSelection(true)
      setDueFrequency('')
      setPublishDate('')
      setEndDate('')
  };
  


const [dueFrqSele,setDueFrqSele]=useState(false)
  const handleDueFrequencyChange = (event) => {
    setDueFrequency(event.target.value);
    setHasSelected(true);

    if(dueType==="PAST_DUE" || dueType==="CUSTOM_DUE"){
      setDueFrequency("ONE_TIME")
    }
  };

  const handleDisableFrequencyChange = (event) => {
    setDisableFrequency("ONE_TIME");
  };

  const {
    handleSubmit,
    control,
    formState: { errors }, reset,
    setValue, setError, clearErrors, watch,
  } = useForm();
console.log("errors",errors);

  const HomeName = watch("selectedOption");
  const getHomes = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${HOMES}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        // Filter out homes with null homeOwnerDetails
        
        setHomesData(data);

        

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);
 


  const renderedAddresses = homesData
    .map((home, index) => ({
      address: home.address,
      value: home.homeId,
      key: index,
    }));

    const handleHomeChange = (event, newValue) => {
      if (newValue && newValue.value !== undefined && newValue.value !== null) {
        const selectedHomeId = newValue.value;
        const selectedHome = homesData.find(
          (home) => home.homeId === selectedHomeId
        );
          const homeOwner = selectedHome;
         
          if (homeOwner) {
            const { name, homeOwnerDetailsId,homeOwnerLoginId } = homeOwner;
              const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
              console.log("Selected Home Description:", selectedHomeDescription);
  
              // Set the selected home details to the state if selectedHomeDescription is not null
            
                  setHomeDetails(selectedHomeDescription);
             console.log("homeOwnerDetailsId",homeOwnerDetailsId);
              setValue("selectedOption", selectedHomeId);
              setHomeOwnerDetailsId(homeOwnerDetailsId)
        setHomeOwnerLoginId(homeOwnerLoginId)
              // You can also do other operations if needed
              console.log("Selected Home:", selectedHome);
              console.log("Home Details:", homeAddresses);
          } else {
              setHomeDetails("");
              // setValue("selectedOption", null);
              setValue("selectedOption", selectedHomeId);
          }
      } else {
          // Handle the case when the selected option is removed (e.g., by pressing backspace)
          setHomeDetails("");
          setHomename(null);
          setHomeOwnerDetailsId('');  
          // Use setValue to clear the selected homeId in the react-hook-form
          setValue("selectedOption", null);
      }
  };
  

  useEffect(() => {
    const selectedHome = watch("selectedOption");
    console.log(selectedHome);

    if (!selectedHome || selectedHome === "") {
      setHomeDetails("");
      // setIsBylawFieldEmpty(true);
    } else {
      // Find the matching byLaw from byLawDetails
      const matchingHome = homesData.find(
        (home) => home.homeId === selectedHome
      );
      console.log(matchingHome);

      if (matchingHome) {
        const {name} = matchingHome;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
        setHomeDetails(selectedHomeDescription);

        // setIsBylawFieldEmpty(false);
      } else {
        // Handle the case where no matching byLaw is found
        setHomeDetails("");
        // setIsBylawFieldEmpty(true);
      }
    }
  }, [watch("selectedOption"), homeDetails]);


  const onSubmit = (data) => {

    if (!publishDate) {
      setError("dueDate", {
        type: "manual",
        message: DUE_DATE_REQUIRED_MESSAGE,
      });

      return;
    }
     
    if (!endDate) {
      setError("endDate", {
        type: "manual",
        message: END_DATE_REQUIRED_MESSAGES
      });

      return;
    }

    if (parseFloat(data.dueAmount) === 0 && !/^(0\.\d{1,2}|[1-9]\d{0,4}\.\d{1,2})$/.test(data.dueAmount)) {
      setError("dueAmount", {
        type: "manual",
        message: "Due Amount is invalid: It must be in the format '0.xx' or 'xxxxx.xx'. A value of '0' is not allowed.",
      });
      return;
    }

  
    if (!dueType) {
      console.log("working")
      setHasSelection(false)
    setTouched(true)
      return;
    }


    if (!dueFrequency && (dueType === "REGULAR_DUE" || (dueType === "SPECIAL_ASSESSMENT_DUE" && isForAllHomes))){
      console.log("error")
      setHasSelected(false)
      setDueFrqSele(true)
      return;
    }
   

    setIsLoading(true);
    const postData = {
      description: data.description,
      dueAmount: data.dueAmount,
      dueType: dueType,
      dueFrequency: dueType === 'REGULAR_DUE' || (dueType === 'SPECIAL_ASSESSMENT_DUE' && isForAllHomes) ? dueFrequency : "ONE_TIME",
      homeOwner: data.homeOwner,
      specialAssType: data.specialAssessmentDueTypeName,
      isForAllHomes: dueType === 'REGULAR_DUE' || (dueType === 'SPECIAL_ASSESSMENT_DUE' && isForAllHomes) ? true : false,
      title: data.title,
      dueDate: publishDate ? dayjs(publishDate).format("YYYY-MM-DD") : '',
      endDate:endDate ? dayjs(endDate).format("YYYY-MM-DD") : '',
      homeId: data?.selectedOption || null,
      homeOwnerDetailsId: homeOwnerDetailsId ?homeOwnerDetailsId : null,
      homeOwnerLoginId:homeOwnerLoginId?homeOwnerLoginId:null,
    };

    console.log("Selected Option:", selectedOption);
    console.log("HomeOwnerDetailsID:", postData.homeOwnerDetailsId);

    request({ url: PAYMENT_CONFIG_LIST, method: "post", data: postData }).then(res => {
      // setIsLoading(false); 
      // console.log(res);
      if (res.status == 200) {
        setTimeout(() => {

          setIsLoading(false); 
          navigate(-1);
        }, 5000);
      }else{
        setIsLoading(false); 

      }
    }).catch(error => {
      console.log(error.response);
      setIsLoading(false); 
      if (error.response) {

        // console.log("error.response.status" + error.response.status);


      }
    });



  };

 
  const minDate = dayjs().subtract(1, "year").startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const handlePublishDateChange = (selectedPublishDate) => {
    setPublishDate(selectedPublishDate);
    setDueDateSelected(true);
    if (endDate && dayjs(selectedPublishDate).isAfter(endDate)) {
      setEndDate(""); // Clear end date
    }

    const newMaxEndDate = dayjs(selectedPublishDate).add(1, "year").endOf("day");
    if (endDate && dayjs(endDate).isAfter(newMaxEndDate)) {
      setEndDate(""); // Clear end date if it is more than one year after the new due date
    }
  };

  // const minEndDate = dayjs(publishDate);
  const minEndDate = (!isForAllHomes || dueType === 'CUSTOM_DUE' || dueType === 'PAST_DUE') 
  ?dayjs(publishDate)  
  : (dayjs(publishDate).isBefore(dayjs()) 
    ? dayjs().add(1, 'day')  // Tomorrow's date
    : dayjs(publishDate).add(1, 'day')); // Next date of publishDate
 

    const maxEndDate = (!isForAllHomes || dueType === 'CUSTOM_DUE' || dueType === 'PAST_DUE') 
  ?dayjs(publishDate).add(1, "year").endOf("day")
  : (dayjs(publishDate).isBefore(dayjs()) 
    ? dayjs().add(1, "year").endOf("day")  // Tomorrow's date
    : dayjs(publishDate).add(1, "year").endOf("day"));
  // const maxEndDate = dayjs(publishDate).add(1, "year").endOf("day");
  const handleEndDateChange = (selectedEndDate) => {
    if (selectedEndDate) {
      setEndDate(selectedEndDate);

      const currentDate = dayjs();
      const daysDifference = currentDate.diff(selectedEndDate, 'day');

      if (daysDifference >= 30) {
        setShowModal(true); // Show modal if 30 days before current date
      }
    } else {
      setEndDate("");
    }
  };

  const handleConfirmationClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShowModal(false);
  };
  

  const handleConfirmationConfirm = () => {
    setShowModal(false);
  };

  const bmCardModelAction = (
    <>
      {/* <CustomButton
        buttonName="Cancel"
        click={handleConfirmationClose}

      /> */}
      <CustomButton
        fieldType="submit"
        buttonName="OK"
        click={handleConfirmationClose}
      />

    </>
  );

  const bmCardContent = (
    <>
      <form>
        <DialogContent dividers>
          <div className="flex mb-5 mt-5">You have selected a last date that is more than 30 days in the past. A late fee will be applied for this. Do you want to continue?</div>
        </DialogContent>


        <DialogActions>{bmCardModelAction}</DialogActions>
      </form>
    </>)


  // const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     maxWidth: 'none',
  //   },
  // });
  
  const renderTooltip = (message) => (
    <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Add HOA Dues" />
<CustomModel
        title="Warning"
        content={bmCardContent}
        action={bmCardModelAction}
        openStatus={showModal}
        // reset={reset}
        closeStatus={handleConfirmationClose}
        iconCloseStatus={handleConfirmationClose}
      />
        {isLoading && <CustomLoading />}
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            
    
      <CustomTooltipField
        fieldName="title"
        requiredErrorMessage={TITLE_REQUIRED_MESSAGE}
        minLength={2}
        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
        maxLength={500}
        maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
        fieldId="title"
        fieldType="text"
        fieldLabel="Title"
        fieldControl={control}
        fieldError={errors}
        multiline={true}
        numberOfRows={1}
        fieldRequiredIcon="*"
        tooltipMessage={"Enter the title for the due"}
      />
   
 

  
              <CustomTooltipField
                fieldName="description"
                requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                // fieldPattern={NO_DIGITS_REGEX}
                // patternErrorMessage={DESCRIPTION_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={1000}
                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                fieldId="description"
                fieldType="text"
                fieldLabel="Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={1}
                tooltipMessage={"Enter a detailed description about the due"}
              />
 

 
              <CustomInputFielddueandamount
                fieldName="dueAmount"
                requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
                fieldPattern={FIVE_DIGITS_TWO_DECIMAL}
                patternErrorMessage={"Please provide Valid Due Amount (i.e xxxxx.xx)"}
                fieldId="dueAmount"
                fieldType="text"
                fieldLabel="Due Amount"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage={"Enter the due amount. This should be a valid monetary amount formatted as xxxxx.xx"}
              />
             

             <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
  
        <FormControl
          variant="standard"
          fullWidth
          error={!hasSelection && touched}
        >
          <InputLabel id="demo-simple-select-standard-label">
            Please Select Due Type <span style={{ color: 'red' }}>*</span>
          </InputLabel>

          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={dueType}
            onChange={handleDueChange}
            label="Please Select Due Type"
          >
            <MenuItem value="REGULAR_DUE">
              Regular Due(Applies to all Homeowners)
            </MenuItem>
            <MenuItem value="CUSTOM_DUE">Custom Due</MenuItem>
            <MenuItem value="PAST_DUE">Past Due</MenuItem>
            <MenuItem value="SPECIAL_ASSESSMENT_DUE">
              Special Assessment
            </MenuItem>
          </Select>
        {!hasSelection && touched && <FormHelperText style={{ color: "#D32F2F" }}>
            {DUE_TYPE_REQUIRED_MESSAGE}
          </FormHelperText>}
        </FormControl>
     
  </div>
  <div>
    {renderTooltip("Select the type of due. Regular dues apply to all homeowners, while custom and past dues are for specific cases. Special assessment dues and Regular dues are for unique, one-time assessments")}
  </div>
</div>

  
                <CustomDateEditPicker
                  fieldName="dueDate"
                  label="Due Date"
                  control={control}
                  requiredErrorMessage={DUE_DATE_REQUIRED_MESSAGE}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={handlePublishDateChange}
                  fieldRequiredIcon="*"
                  tooltipMessage={"Select the due date. This is the date by which the payment should be made"}
                  value={publishDate}

                />
             

                <CustomDateEditPicker
                  fieldName="endDate"
                  label="Last Date"
                  control={control}
                  disabled={!dueDateSelected}
                  requiredErrorMessage={END_DATE_REQUIRED_MESSAGES}
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  onChange={handleEndDateChange}
                  fieldRequiredIcon="*"
                  value={endDate}
                  tooltipMessage={"Select the last date. This is the final date by which the payment can be made without penalties"}
                />


  
{dueType === "REGULAR_DUE" || (dueType === "SPECIAL_ASSESSMENT_DUE" && isForAllHomes) ? (
        <div className="flex items-center md:w-1/2">
          <div className="w-full">
                <FormControl
                  variant="standard"
                  fullWidth
                  error={!hasSelected && dueFrqSele}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Please Select Due Frequency <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={dueFrequency}
                    onChange={handleDueFrequencyChange}
                    label="Please Select Due Frequency"
                  >
                    <MenuItem value="MONTHLY">Monthly</MenuItem>
                    <MenuItem value="YEARLY">Yearly</MenuItem>
                    <MenuItem value="ONE_TIME">One Time</MenuItem>
                  </Select>
                  {!hasSelected && dueFrqSele && ( // Show validation message only if the user has interacted
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {DUE_FREQUENCY_REQUIRED_MESSAGE}
                    </FormHelperText>
                  )}
                </FormControl>
              
          </div>
          <div>{renderTooltip("Select the frequency of the due. Choose whether the due is recurring monthly, yearly, or is a one-time payment")}</div>
        </div>
      ) : null}



 

              {dueType == "CUSTOM_DUE" ||
                dueType == "PAST_DUE" ? (
                <>            
<HomesAutoComplete
            fieldName="selectedOption"
            id="selectId"
            requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
            options={renderedAddresses}
            fieldLabel=" Select Home Address"
            fieldControl={control}
            setValue={setValue}
            onChange={handleHomeChange}
            fieldRequiredIcon="*"
            invalidOption="Please select valid home address"
    tooltipMessage="Select the home address to assign the due. This field helps in identifying which homeowner the due is applicable to. Upon selection, the homeowner's name will be displayed in the 'HomeOwner Name' field"
          />
<CustomTooltipField
                  fieldName="homeOwnerDetails"
                  fieldId="homeOwnerDetails"
                  fieldType="text"
                  fieldLabel="HomeOwner Name"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                  value={homeDetails}
                  tooltipMessage={"This field displays the name of the homeowner associated with the selected home address"}
                />
                 
                </>
              ) : null}

              
{dueType === 'CUSTOM_DUE' || dueType === 'PAST_DUE' ? (
 <div className="flex items-center md:w-1/2">
 <div className="w-full">
   
       <FormControl
         variant="standard"
         fullWidth
       >
         <InputLabel id="demo-simple-select-standard-label">
           Please Select Due Frequency
         </InputLabel>
         <Select
           disabled
           labelId="demo-simple-select-standard-label"
           id="demo-simple-select-standard"
           value={disableFrequency || "ONE_TIME"}// Ensure value is always controlled
           onChange={handleDisableFrequencyChange} // No-op since the field is disabled
           label="Please Select Due Frequency"
         >
          <MenuItem value="MONTHLY">Monthly</MenuItem>
                    <MenuItem value="YEARLY">Yearly</MenuItem>
                    <MenuItem value="ONE_TIME">One Time</MenuItem>
         </Select>
       </FormControl>
 </div>
 <div>{renderTooltip("The Due Frequency field is preset to One Time and is disabled for Custom Due and Past Due types")}</div>
</div>

) : null}






              {dueType === "SPECIAL_ASSESSMENT_DUE" ?
                <>
                 { !isForAllHomes ?
                //    <div className="flex items-center md:w-1/2">
                //    <div className="w-full">
                //      <Controller
                //        name="dueFrequency"
                //        control={control}
                //        defaultValue="ONE_TIME" // Ensure default value is set
                //        render={({ field }) => (
                //          <FormControl
                //            variant="standard"
                //            fullWidth
                //          >
                //            <InputLabel id="demo-simple-select-standard-label">
                //              Please Select Due Frequency
                //            </InputLabel>
                //            <Select
                //              disabled
                //              labelId="demo-simple-select-standard-label"
                //              id="demo-simple-select-standard"
                //              value={field.value !== undefined ? field.value : "ONE_TIME"}// Ensure value is always controlled
                //              onChange={() => {}} // No-op since the field is disabled
                //              label="Please Select Due Frequency"
                //            >
                //              <MenuItem value="ONE_TIME">One Time</MenuItem>
                //            </Select>
                //          </FormControl>
                //        )}
                //      />
                //    </div>
                //    <div>{renderTooltip("The Due Frequency field is preset to One Time and is disabled for Custom Due and Past Due types")}</div>
                //  </div>
                <div className="flex items-center md:w-1/2">
 <div className="w-full">
   
       <FormControl
         variant="standard"
         fullWidth
       >
         <InputLabel id="demo-simple-select-standard-label">
           Please Select Due Frequency
         </InputLabel>
         <Select
           disabled
           labelId="demo-simple-select-standard-label"
           id="demo-simple-select-standard"
           value={disableFrequency || "ONE_TIME"}// Ensure value is always controlled
           onChange={handleDisableFrequencyChange} // No-op since the field is disabled
           label="Please Select Due Frequency"
         >
          <MenuItem value="MONTHLY">Monthly</MenuItem>
                    <MenuItem value="YEARLY">Yearly</MenuItem>
                    <MenuItem value="ONE_TIME">One Time</MenuItem>
         </Select>
       </FormControl>
 </div>
 <div>{renderTooltip("The Due Frequency field is preset to One Time and is disabled for Custom Due and Past Due types")}</div>
</div>
                 :null
                  }
                 
                  <CustomTooltipField
                    fieldName="specialAssessmentDueTypeName"
                    fieldType="text"
                    fieldId="specialAssessmentDueTypeName"
                    fieldLabel="Special Assessment Due Type Name"
                    fieldControl={control}
                    maxLength={1000}
                    maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                    fieldError={errors}
                    multiline={true}
                    numberOfRows={1}
                    validator={false}
                    tooltipMessage={"Provide the name for the special assessment due type. This helps in identifying the nature of the special assessment"}
                  />
 
                  
   <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Special Assessment Due Type</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="For All Homeowners"
                      fieldName="specialAssessmentDueType"
                      value={selectedValue}
                      onChange={handleRadioChange}
                      // requiredErrorMessage={SPECIAL_ASSESSMENT_DUE_TYPE}
                      fieldControl={control}
                      fieldError={errors}
                      
                    >
                      <FormControlLabel
                        value="For All Homeowners"
                        control={<Radio />}
                        label="For All Homeowners"
                      />
                      <FormControlLabel
                        value="For a single Homeowner"
                        control={<Radio />}
                        label="For a single Homeowner"
                      />
                    </RadioGroup>
                  </FormControl>
                  </div>
    <div>{renderTooltip("Specify whether this special assessment applies to all homeowners or a specific homeowner")}</div>
  </div>
                  {selectedValue === 'For a single Homeowner' && (
                   <>
                  <HomesAutoComplete
                  fieldName="selectedOption"
                  id="selectId"
                  requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
                  options={renderedAddresses}
                  fieldLabel=" Select Home Address"
                  fieldControl={control}
                  setValue={setValue}
                  onChange={handleHomeChange}
                  fieldRequiredIcon="*"
                  invalidOption="Please select valid home address"
          tooltipMessage="Select the home address to assign the due. This field helps in identifying which homeowner the due is applicable to"
                />
                <CustomTooltipField
                  fieldName="homeOwnerDetails"
                  fieldId="homeOwnerDetails"
                  fieldType="text"
                  fieldLabel="HomeOwner Name"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                  value={homeDetails}
                  tooltipMessage={"This field displays the name of the homeowner associated with the selected home address"}
                />
                </>
                  )}
                </>  : null

              }
  

            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Submit" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default AddPaymentConfig;
