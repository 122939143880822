import React, { useEffect, useState } from 'react'
import { BASE_URL, PAYMENT_DETAILS } from '../utills/ApplicationRouting';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import CustomInputField from '../components/CustomInputField';
import CustomButton from '../components/CustomButton';
import { Button, Tooltip } from "@mui/material";
import moment from 'moment';


function PrintEmailSrvice() {
    const { token, userGroupDetails } = useSelector((state) => state.hybridhoa);
  const groupDetailsId = userGroupDetails.groupId
  const [planTerm, setPlanTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [lastDigits, setLastDigits] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYr, setExpYr] = useState("");
  const [pasueDue, setPauseDue] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [showEditCard, setShowEditCard] = useState(false);
  const [publishKey, setPublishKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [pmId, setPmId] = useState("");
  const [isTrail, setIsTrail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resumeCollection, setResumeCollection] = useState(" ");
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [endPeriod, setEndPeriod] = useState("");
  const [cardNumber, setCardNumber] = useState('');
  const [cvvNumber, setCvvNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [expiration, setExpiration] = useState('');
  const [paymentTypeValue, setPaymentTypeValue] = useState('');
  const [pricePayable, setPricePayable] = useState('');
  const [price, setPrice] = useState('');
  const [startPeriod,setStartPeriod] = useState("");
  const [checkIsActive,setCheckIsActive] = useState('');
  const [dueDateShow,setDueDateShow] = useState("");
  const [isEmailPrint,setIsEmailPrint]=useState(false)
  const [amounts,setAmounts]=useState(0)

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
    clearErrors
  } = useForm();
  const auditMr=localStorage.getItem("AuditMaintainence");
  console.log(auditMr,"jhbv");
  const fromDatemaintenance = JSON.parse(auditMr);
  console.log(fromDatemaintenance,"jhbv");
const fromDateString = fromDatemaintenance.fromDate;
  const fromDateM = moment(fromDateString)
  const fromFormattedDateM = fromDateM.format('M/D/YYYY');
  console.log(fromFormattedDateM, "jhbv")
  const toDatemaintenance = JSON.parse(auditMr);
  console.log(toDatemaintenance,"jhbv");
const fromDateStringT = toDatemaintenance.toDate;
  const toDateM = moment(fromDateStringT)
  const toFormattedDateM = toDateM.format('M/D/YYYY');
  console.log(toFormattedDateM, "jhbv")
    const getPaymentDetails = async () => {
    console.log("inside if");
    try {

      const storedToken = token;
      const response = await axios.get(
        `${BASE_URL}${PAYMENT_DETAILS}/${groupDetailsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);
      setIsLoading(true)

      if (response.status === 200) {
        const data = response.data;
        // setDedicatedPhone(data.dedicatedPhone);
        // setArc(data.virtualArc);
        // setVirtualManager(data.virtualManager);
        // setWebHosting(data.webHosting);
        // setDomain(data.domainRegistration);
        // setPlanType(data.planType);
        // setPricePayable(data.pricePayable);
        // setPrice(data.price);
        // setPlanTerm(data.planTerm);
        // setStartDate(data.startDate);
        // setEndDate(data.endDate);
        // setIsTrail(data.isTrial);
        // setEndPeriod(data.endPeriod);
        // setStartPeriod(data.startPeriod);
        // setCheckIsActive(data.isActive);
        // setDueDateShow(data.dueDate);
        setIsLoading(false);
        const startDateString = data.startDateAsString;
        console.log("original startDateString",startDateString);

        const startDate = new Date(startDateString + ' UTC');
console.log("original startDatesss",startDate);
        // Format the date to YYYY-MM-DD
        const formattedDate = startDate.toISOString().split('T')[0];
        
        console.log("startdate", formattedDate);
        console.log("original startDate", data.startDate);
        
        // Set the formatted date
        setStartDate(formattedDate);
        const endDateString = data.endDateAsString;
const endDates = new Date(endDateString + ' UTC');
// const formattedEndDate = endDates.toISOString().split('T')[0];
const formattedEndDate = endDates.toISOString().split('T')[0];
setEndDate(formattedEndDate)

        // console.log("checking price type of", typeof JSON.stringify(price));
        const dueDateString = data.dueDateAsString;
const dueDates = new Date(dueDateString + ' UTC');
// const formattedEndDate = endDates.toISOString().split('T')[0];
const formattedDueDate = dueDates.toISOString().split('T')[0];
setDueDateShow(formattedDueDate)

        // const invoiceDetails = JSON.parse(data.invoiceDetails);
        // console.log(invoiceDetails);
        // const createdTimestamp = invoiceDetails.created;
        // const createdDate = new Date(createdTimestamp * 1000);
        // setDueDate(createdDate);
        // const dueAmountConvert = invoiceDetails.amount_due;
        // const estimatedAmount = dueAmountConvert / 100;
        // setAmountDue(estimatedAmount);
        // setPaymentType(invoiceDetails.lines.data[0].price.type);
        const cardDetails = JSON.parse(data.cardDetails);
        const cardBrandDetails = cardDetails.data[0].card.brand;
        const cardFourDigit = cardDetails.data[0].card.last4;
        const cardMonth = cardDetails.data[0].card.exp_month;
        const cardYear = cardDetails.data[0].card.exp_year;
        const cardPmId = cardDetails.data[0].id;
        setCardBrand(cardBrandDetails);
        setLastDigits(cardFourDigit);
        setExpMonth(cardMonth);
        setExpYr(cardYear);
        setPmId(cardPmId);
        const subscriptionDetails = JSON.parse(data.subscriptionDetails);
        const dueSubscriptionDetails = subscriptionDetails.pause_collection;

        setPauseDue(dueSubscriptionDetails);
        // getTypeDetails();
        console.log("card Details", cardDetails.data[0].card.last4);

        // exp_month
        // exp_year
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [groupDetailsId]);
  const getDetailsByDate = async () => {
    console.log("inside if");
    try {
        // printandpay?fromDate=${fromDate}&toDate=${toDate}

      const storedToken = token;
      const response = await axios.get(
        `${BASE_URL}/printandpay?fromDate=${fromFormattedDateM}&toDate=${toFormattedDateM}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);
      setIsLoading(true)

      if (response.status === 200) {
        const data = response.data;
        
        setIsLoading(false);
       
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getDetailsByDate();
  }, [fromFormattedDateM,toFormattedDateM]);
  const onSubmit=()=>{}
  return (
    <div>
      <div class="p-8 bg-white  rounded-lg  shadow-lg ">
          <span class="block text-black text-base font-semibold">
            Payment Information
          </span>
          <br />

          <div class="grid md:grid-cols-1 xl:grid-cols-1">
            <span class="block text-black text-sm">
              <b>{cardBrand.toUpperCase()} Ending in :</b> XXXX XXXX XXXX{" "}
              {lastDigits ? lastDigits : "XXXX"}
            </span>
            {/* <span class="block text-black text-sm mt-1">
              <b>Payment Type : </b>{" "}
              {paymentType == "recurring" ? "Recurring" : paymentType}
            </span> */}
            <span class="block text-black text-sm">
              <b>Exp Date : </b> {expMonth ? expMonth : "MM"}/{" "}
              {expYr ? expYr : "YYYY"}
            </span>
            
          </div>
          {/* <span style={{ float: "right" }}>
            {lastDigits ? (
              <>
                <CustomButton
                  fieldType="submit"
                  buttonName="Edit"
                  click={handleUpdateClickOpen}
                />
                &nbsp;
                <CustomButton
                  fieldType="submit"
                  buttonName="Delete"
                  click={handleDeleteClick}
                />
              </>
            ) : (
              <CustomButton
                fieldType="submit"
                buttonName="Add"
                click={handleAddClickOpen}
              // click={handleClickCardOpen}
              />
            )}
          </span> */}
          {/* <span class="block text-black text-base font-semibold mt-4 mb-4">
            Billing Details
          </span>
          <span class="block text-black text-sm">
            <b>Due Date : </b>{" "}
            {/* {endPeriod ? moment(endPeriod).format("MM/DD/YYYY") : " "} */}

            {/* {dueDateShow ? moment(dueDateShow).format("MM/DD/YYYY") : " "} */}
           
            {/* {startPeriod ? moment(startPeriod).format("MM/DD/YYYY") : " "} */}
          {/* </span> */}
          
          {/* <span class="block text-black text-sm mt-1">
            <b>Current Due  :</b> $ {pricePayable ? pricePayable : " "}
          </span> */}
          {/* <span class="block text-black text-sm mt-1">
            <b>Estimated Due  :</b> $ {price ? price : " "}  (Tax and Processing fee will be added to the subscription amount.)
          </span> */} 
         
           
          {/* <span>
            <b>
              Note
            </b>
            : Tax included
          </span> */}
        </div>
        <div class= "grid md:grid-cols-3 xl:grid-cols-3 ml-5 mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              fieldName="amount"
            //   fieldPattern={TWO_DECIMAL}
            //   patternErrorMessage={"Please provide vaild amount. i.e ##.##"}
              // requiredErrorMessage={"required field"}
              // minLength={2}
              // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              // maxLength={5000}
              // maxLengthErrorMessage={"amount is lesser than waived amount"}
              fieldId="amount"
              fieldType="text"
              fieldLabel="Amount"
              fieldControl={control}
              fieldError={errors}
            //   fieldRequiredIcon="*"

              value={amounts}
              disabled={true}
            />
            {amounts === 0 ?
              <Tooltip arrow title="There are no outstanding dues or no dues have been selected for processing...">
                <span>
                  <CustomButton
                    fieldType="submit"
                    buttonName="Pay"
                    disabled={amounts === 0}
                  />
                </span>

              </Tooltip>
              :

              <span>
                <CustomButton
                  fieldType="submit"
                  buttonName="Pay"

                />
              </span>


            }

          </form>
          </div>
    </div>
  )
}

export default PrintEmailSrvice
