import {createSlice} from '@reduxjs/toolkit';

export const hybridhoa = createSlice({

    name:'hybridhoa',
    initialState: {
        token:null,
        sideNavState:true,
        userLoginDetails:null,
        userDetails:null,
        userGroupDetails: null,
        tableData:null,
        homeAddress:"",
        pageIndexes: {},
        trail : null
              
      },
      reducers: {
       
        setToken: (state, action) => {
          state.token = action.payload
        },
        setSideNavState: (state, action) => {
          state.sideNavState = action.payload
        },
        setUserLoginDetails :(state, action) => {
          state.userLoginDetails = action.payload
        },
        setUserDetails :(state, action) => {
          state.userDetails = action.payload
        },
        setUserGroupDetails:(state,action) =>{
          state.userGroupDetails = action.payload
        },
        setTableData:(state,action) =>{
          state.tableData = action.payload
        },
        setHomeAddress:(state,action) =>{
          state.homeAddress = action.payload
        },
        setTrail:(state,action) =>{
          state.trail = action.payload
        },
        setPageIndex: (state, action) => { 
          const { url, pageIndex } = action.payload;
          state.pageIndexes[url] = pageIndex; 
        },
        clearAuthToken: (state) => {
          state.token = null;
        },
       
                 
      }
    })
    
    // Action creators are generated for each case reducer function
    export const { setToken,setSideNavState,clearAuthToken,setHomeAddress,setUserLoginDetails,setUserDetails,setUserGroupDetails,setTableData,setPageIndex,setTrail } = hybridhoa.actions
    
    export default hybridhoa.reducer