import React, { useState, useEffect } from "react";
import CustomHeading from "../components/CustomHeading";
import CardLayout from "../components/dashboard/CardLayout";
import CustomToastContainer from "../components/CustomToastContainer";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { Download, InfoOutlined } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, FormControl, IconButton, Tooltip } from "@mui/material";
import CustomModel from "../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../components/CustomButton";
import { request } from "../services/AxiosConfig";
import {
  BASE_URL,
  CARD_DELETE,
  CARD_DETAILS,
  CARD_DETAILS_REDIRECT,
  CARD_PAYMENT_MANUAL,
  PAUSE_SUBSCRIPTION,
  PAYMENT_DETAILS,
  PAYMENT_TYPE,
  PK_KEY,
  RESUME_SUBSCRIPTION,
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { FormControlLabel, Switch } from "@mui/material";
import moment from "moment";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CustomCardElement from "./CustomCardElement";
import { toast } from "react-toastify";
import CustomEditCardElement from "./CustomEditCardElement";
import CustomLoading from "../components/CustomLoading";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import CustomInputField from '../components/CustomInputField'
import { CARD_MAX_LENGTH, CARD_MIN_LENGTH, CARD_NUMBER_INVALID_MESSAGE, CARD_NUMBER_REQUIRED, CVV_INVALID_MESSAGE, CVV_MAX_LENGTH, CVV_MIN_LENGTH, CVV_NUMBER_REQUIRED, FIRST_NAME_INVALID_MESSAGE, FIRST_NAME_REQUIRED_MESSAGE, MAX_LENGTH_VALIDATE_MESSAGE, MIN_LENGTH_VALIDATE_MESSAGE } from "../utills/ApplicationConstants";
import { CARD_VALIDATOR, CVV_VALIDATOR, ONLY_ALPHABETIC } from "../utills/ApplicationRegex";
import { DatePicker, LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomBackButtonWithoutDisable from "../components/CustomBackButtonWithoutDisable";
var stripePromise = null;
var options = null;
function Subscription() {

  const [openModal, setOpenModal] = useState(false);
  const [openModalForUpgrade, setOpenModalForUpgrade] = useState(false);
  const [dedicatedPhone, setDedicatedPhone] = useState(false);
  const [domain, setDomain] = useState(false);
  const [arc, setArc] = useState(false);
  const [virtualManager, setVirtualManager] = useState(false);
  const [webHosting, setWebHosting] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [groupId, setGroupId] = useState("");
  const [planType, setPlanType] = useState("");
  const { token, userGroupDetails } = useSelector((state) => state.hybridhoa);
  const groupDetailsId = userGroupDetails.groupId
  const [planTerm, setPlanTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [lastDigits, setLastDigits] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYr, setExpYr] = useState("");
  const [pasueDue, setPauseDue] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [showEditCard, setShowEditCard] = useState(false);
  const [publishKey, setPublishKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [pmId, setPmId] = useState("");
  const [isTrail, setIsTrail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { addcard } = useParams();
  const [resumeCollection, setResumeCollection] = useState(" ");
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [endPeriod, setEndPeriod] = useState("");
  const [cardNumber, setCardNumber] = useState('');
  const [cvvNumber, setCvvNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [expiration, setExpiration] = useState('');
  const [paymentTypeValue, setPaymentTypeValue] = useState('');
  const [pricePayable, setPricePayable] = useState('');
  const [price, setPrice] = useState('');
  const [startPeriod,setStartPeriod] = useState("");
  const [checkIsActive,setCheckIsActive] = useState('');
  const [dueDateShow,setDueDateShow] = useState("");
  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(50, "year").endOf("year");
  useEffect(() => {
    if (addcard === "success") {

      navigate.replace("/#/subscription");
    }
  }, [addcard, navigate]);
  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };
  const handleCvvNumber = (e) => {
    setCvvNumber(e.target.value);
  };
  const handleHolderName = (e) => {
    setCardName(e.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleExpirationChange = (selectedFromDate) => {
    setExpiration(selectedFromDate);
  };


  const { handleSubmit, reset, errors, control } = useForm();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleIconClose = () => {
    setOpenModal(false);
  };

  const handleClickCardOpen = () => {
    setOpen(true);
  }

  const handleAddClickOpen = () => {
    setOpen(true);
    request({
      url: `${CARD_DETAILS}/${groupId}`,
      method: "get",
    })
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          console.log(data);
          setSecretKey(data.clientSecret);
          setPublishKey(data.publishKey);
          stripePromise = loadStripe(PK_KEY);

          options = {
            // passing the client secret obtained from the server
            clientSecret: data.clientSecret,
          };

          setShowCard(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error.res) {
        }
      });
  };

  const handleUpdateClickOpen = () => {
    setOpenEdit(true);
    request({
      url: `${CARD_DETAILS}/${groupId}`,
      method: "get",
    })
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          console.log(data);
          setSecretKey(data.clientSecret);

          stripePromise = loadStripe(PK_KEY);

          options = {
            // passing the client secret obtained from the server
            clientSecret: data.clientSecret,
          };

          setShowEditCard(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.res) {
        }
      });
  };

  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
    }
  };

  const handleOpenModalForUpgrade = () => {
    setOpenModalForUpgrade(true);
  };

  const handleCloseModalForUpgrade = () => {
    setOpenModalForUpgrade(false);
  };
  const handleIconCloseForUpgrade = () => {
    setOpenModalForUpgrade(false);
  };

  const handleCloseCardAdd = () => {
    setShowCard(false);
  };

  const handleCardClose = (status) => {
    if (status == 200) {
      setOpen(false);
    }

  };

  const handleCardIconClose = () => {
    setOpen(false);
    setCardNumber('');
    setCvvNumber('')
  }
  const handleCloseCardEdit = () => {
    setShowEditCard(false);
  };

  useEffect(() => {
    const tes = () => {
      const persistRoot = JSON.parse(localStorage.getItem("persist:root"));
      // console.log(persistRoot);
      if (
        persistRoot &&
        persistRoot.userGroupDetails &&
        typeof persistRoot.userGroupDetails === "string"
      ) {
        const userGroupDetailsObject = JSON.parse(persistRoot.userGroupDetails);
        // console.log(userGroupDetailsObject);
        if (userGroupDetailsObject && userGroupDetailsObject.groupId) {
          const groupId = userGroupDetailsObject.groupId;
          console.log("groupId:", groupId);

          setGroupId(groupId);
        } else {
          console.log("groupId not found in userGroupDetails.");
        }
      }
    };
    tes();
  }, [groupId]);

  const getPaymentDetails = async () => {
    console.log("inside if");
    console.log(groupId);
    try {

      const storedToken = token;
      const response = await axios.get(
        `${BASE_URL}${PAYMENT_DETAILS}/${groupDetailsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);
      setIsLoading(true)

      if (response.status === 200) {
        const data = response.data;
        setDedicatedPhone(data.dedicatedPhone);
        setArc(data.virtualArc);
        setVirtualManager(data.virtualManager);
        setWebHosting(data.webHosting);
        setDomain(data.domainRegistration);
        setPlanType(data.planType);
        setPricePayable(data.pricePayable);
        setPrice(data.price);
        setPlanTerm(data.planTerm);
        // setStartDate(data.startDate);
        // setEndDate(data.endDate);
        setIsTrail(data.isTrial);
        setEndPeriod(data.endPeriod);
        setStartPeriod(data.startPeriod);
        setCheckIsActive(data.isActive);
        // setDueDateShow(data.dueDate);
        setIsLoading(false);
        const startDateString = data.startDate;
        console.log("original startDateString",startDateString);

        // const startDate = new Date(startDateString + ' UTC');
console.log("original startDatesss",startDateString);
        // Format the date to YYYY-MM-DD
        const formattedDate = startDateString.split('T')[0];
        
        console.log("startdate", formattedDate);
        console.log("original startDate", data.startDate);
        
        // Set the formatted date
        setStartDate(formattedDate);
        const endDateString = data.endDate;
// const endDates = new Date(endDateString + ' UTC');
// const formattedEndDate = endDates.toISOString().split('T')[0];
const formattedEndDate = endDateString.split('T')[0];
setEndDate(formattedEndDate)

        console.log("checking price type of", typeof JSON.stringify(price));
        const dueDateString = data.dueDate;
// const dueDates = new Date(dueDateString + ' UTC');
// const formattedEndDate = endDates.toISOString().split('T')[0];
const formattedDueDate = dueDateString.split('T')[0];
setDueDateShow(formattedDueDate)

        // const invoiceDetails = JSON.parse(data.invoiceDetails);
        // console.log(invoiceDetails);
        // const createdTimestamp = invoiceDetails.created;
        // const createdDate = new Date(createdTimestamp * 1000);
        // setDueDate(createdDate);
        // const dueAmountConvert = invoiceDetails.amount_due;
        // const estimatedAmount = dueAmountConvert / 100;
        // setAmountDue(estimatedAmount);
        // setPaymentType(invoiceDetails.lines.data[0].price.type);
        const cardDetails = JSON.parse(data.cardDetails);
        const cardBrandDetails = cardDetails.data[0].card.brand;
        const cardFourDigit = cardDetails.data[0].card.last4;
        const cardMonth = cardDetails.data[0].card.exp_month;
        const cardYear = cardDetails.data[0].card.exp_year;
        const cardPmId = cardDetails.data[0].id;
        setCardBrand(cardBrandDetails);
        setLastDigits(cardFourDigit);
        setExpMonth(cardMonth);
        setExpYr(cardYear);
        setPmId(cardPmId);
        const subscriptionDetails = JSON.parse(data.subscriptionDetails);
        const dueSubscriptionDetails = subscriptionDetails.pause_collection;

        setPauseDue(dueSubscriptionDetails);
        // getTypeDetails();
        console.log("card Details", cardDetails.data[0].card.last4);

        // exp_month
        // exp_year
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [groupDetailsId]);


  const getTypeDetails = async () => {
    console.log("inside if");

    try {
      setIsLoading(true);
      const storedToken = token;

      // console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${PAYMENT_TYPE}/${planType}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);


      if (response.status === 200) {
        const data = response.data;
        setPaymentTypeValue(data)
        console.log("payment type check", data);

      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTypeDetails();
  }, [planType]);

  const CardRedirect = async () => {
    if (window.location.search.includes("setup_intent")) {
      try {

        const storedToken = token;
        const response = await axios.get(
          `${BASE_URL}${CARD_DETAILS_REDIRECT}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
        setIsLoading(true)
        console.log("check stauts", response.status);
        if (response.status === 202) {

          toast.success("Payment card added successfully!");
          setTimeout(() => {
            window.location.href = "/#/subscription";
          }, 4000);

        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }

      } catch (error) {
        setIsLoading(false);

        console.error("Error fetching data:", error);
      }


    }

  }

  useEffect(() => {
    CardRedirect();
  }, []);




  const handleDeleteClick = (selectedRow) => {
    setIsActivateModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsActivateModalOpen(false); // Close the deactivate modal
  };
  const handleCardDelete = () => {
    setIsLoading(true)
    request({
      url: `${CARD_DELETE}/${pmId}`,
      method: "get",
    })
   
      .then((res) => {
        if (res.status === 200) {
          
          getPaymentDetails();
          setIsActivateModalOpen(false);
          setTimeout(() => {
            window.location.reload();
            setIsLoading(false);
          }, 3000);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
        }
      });
  };

  const deleteModel = (
    <form
      onSubmit={handleSubmit(handleCardDelete)}
      className="space-y-4 md:space-y-6"
    >
      <div>
        <DialogContent dividers>
          <Typography>Are you sure you want to delete your card?</Typography>
        </DialogContent>
        {/* <DialogActions>{activateButtons}</DialogActions> */}
        <DialogActions>
          <a className="flex justify-end  space-x-2">
            <CustomBackButtonWithoutDisable
              fieldType="button"
              buttonName="Cancel"
              click={handleDeleteModalClose} // Assuming you have a function to handle cancel action
            />
            <CustomBackButtonWithoutDisable fieldType="submit" buttonName="Delete" />
          </a>
        </DialogActions>
      </div>
    </form>
  );

  const onSubmitCard = () => {
    const postData = {
      card: cardNumber,
      exp: expiration,
      cvv: cvvNumber
    }
    console.log("cardNumber", cardNumber);
    console.log("cvvNumber", cvvNumber);
    console.log("expirationDate", expiration);
    request({
      url: `${CARD_PAYMENT_MANUAL}`,
      method: "post",
      data: postData,
    })
      .then((res) => {
        if (res.status == 200) {
          handleCardClose(res.status)
          getPaymentDetails();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
        }
      });
  }

  const handlePauseSub = () => {
    setIsLoading(true);
    const postData ={
      isActive : false
    }
    request({
      url: `${PAYMENT_DETAILS}/${groupId}`,
      method: "put",
      data: postData,
    })
      .then((res) => {
        if (res.status == 200) {
          getPaymentDetails();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
        }
      });
  };

  const handleResume = () => {
    setIsLoading(true);
    const postData ={
      isActive : true
    }
    request({
      url: `${PAYMENT_DETAILS}/${groupId}`,
      method: "put",
      data: postData,
    })
      .then((res) => {
        if (res.status == 200) {
          getPaymentDetails();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
        }
      });
  };

  const handleAdd = () => { };
  const editActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleCloseModal}
    ></CustomButton>
  );

  const UpgradePlan = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleCloseModalForUpgrade}
    ></CustomButton>
  );

  const onSubmitEdit = (name, value) => {
    let postData = {};
    if (name == "dedicatedPhone") {
      const dep = paymentTypeValue.dedicatedPhone
      const depStringTofloat = parseFloat(dep);
      const depPrice = parseFloat(price);


      let addPricePayable = null;
      let roundPricePayable = null;

      if (value) {

        addPricePayable = depPrice + depStringTofloat
        roundPricePayable = addPricePayable.toFixed(2);
        console.log("dedicated phone number", addPricePayable);
        console.log("dep price", depPrice);
        console.log("dep", dep);
      }
      else {

        addPricePayable = depPrice - depStringTofloat
        roundPricePayable = addPricePayable.toFixed(2);
        console.log("dedicated phone number", addPricePayable);
        console.log("dep price", depPrice);
        console.log("dep", dep);
      }


      postData = {
        dedicatedPhone: value,
        price: Math.abs(roundPricePayable)
      };
    }
    if (name == "domainRegistration") {
      postData = {
        domainRegistration: value,
      };
    }
    if (name == "webHosting") {
      const webhost = paymentTypeValue.webHosting
      const webhostStringTofloat = parseFloat(webhost);
      const webhostPrice = parseFloat(price);
      let addPricePayableFrWebhost = null;
      let roundWebPricePayable = null;
      if (value) {

        addPricePayableFrWebhost = webhostPrice + webhostStringTofloat
        roundWebPricePayable = addPricePayableFrWebhost.toFixed(2);
        console.log("Webhost payable", addPricePayableFrWebhost);
        console.log("webhost price", webhostPrice);
        console.log("webhost", webhost);
      }
      else {
        addPricePayableFrWebhost = webhostPrice - webhostStringTofloat
        roundWebPricePayable = addPricePayableFrWebhost.toFixed(2);
        console.log("Webhost payable", addPricePayableFrWebhost);
        console.log("webhost price", webhostPrice);
        console.log("webhost", webhost);
      }
      postData = {
        webHosting: value,
        price: Math.abs(roundWebPricePayable)
      };
    }
    if (name == "virtualManager") {
      const virtManager = paymentTypeValue.virtualManager
      const vmStringTofloat = parseFloat(virtManager);
      const vmPrice = parseFloat(price);
      let addPricePayableFrVm = null;
      let roundVmPricePayable = null;

      if (value) {

        addPricePayableFrVm = vmPrice + vmStringTofloat
        roundVmPricePayable = addPricePayableFrVm.toFixed(2);
        console.log("virtual manager", addPricePayableFrVm);
        console.log("vm price", vmPrice);
        console.log("virtManager", virtManager);
      }
      else {
        addPricePayableFrVm = vmPrice - vmStringTofloat
        roundVmPricePayable = addPricePayableFrVm.toFixed(2);
        console.log("virtual manager", addPricePayableFrVm);
        console.log("vm price", vmPrice);
        console.log("dep", virtManager);
      }
      postData = {
        virtualManager: value,
        price: Math.abs(roundVmPricePayable)
      };
    }

    if (name == "arc") {
      const arc = paymentTypeValue.virtualarc
      const arcStringTofloat = parseFloat(arc);
      const arcPrice = parseFloat(price);
      let addPricePayableFrArc = null;
      let roundArcPricePayable = null;
      if (value) {
        addPricePayableFrArc = arcPrice + arcStringTofloat
        roundArcPricePayable = addPricePayableFrArc.toFixed(2);
        console.log("arc payable", addPricePayableFrArc);
        console.log("arc price", arcPrice);
        console.log("arc", arc);
      }
      else {
        addPricePayableFrArc = arcPrice - arcStringTofloat
        roundArcPricePayable = addPricePayableFrArc.toFixed(2);
        console.log("arc payabvle", addPricePayableFrArc);
        console.log("arc price", arcPrice);
        console.log("arc", arc);
      }
      postData = {
        virtualArc: value,
        price: Math.abs(roundArcPricePayable)
      };
    }
     setIsLoading(true);
    request({
      url: `${PAYMENT_DETAILS}/${groupId}`,
      method: "put",
      data: postData,
    })
      .then((res) => {
        if (res.status == 200) {
          getPaymentDetails();

          setTimeout(() => {
            setIsLoading(false);
          }, 4000);          
        }     
         
           else{
            getPaymentDetails();

            setTimeout(() => {
              setIsLoading(false);
            }, 4000);  
           }
      })
      .catch((error) => {
        getPaymentDetails();

          setTimeout(() => {
            setIsLoading(false);
          }, 4000);   
        console.log(error.response);
      
      });
  };

  const radioButtonStyle = {
    marginRight: "20px",
    transform: "scale(1.7)",
  };
  const containerStyle = {
    border: "3px solid #ccc",
    padding: "20px", // Padding for spacing inside the box
  };
  const UpgradePlanTerm = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitEdit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <div>
              <div style={containerStyle}>
                <label>
                  <input
                    type="radio"
                    value="option1"
                    checked={selectedOption === "option1"}
                    onChange={handleOptionChange}
                    style={radioButtonStyle}
                  />
                  Option 1
                </label>
                <div>Helper text for Option 1</div>
              </div>
              <br />
              <div style={containerStyle}>
                <label>
                  <input
                    type="radio"
                    value="option2"
                    checked={selectedOption === "option2"}
                    onChange={handleOptionChange}
                    style={radioButtonStyle}
                  />
                  Option 2
                </label>
                <div>Helper text for Option 3</div>
              </div>
              <br />
              <div style={containerStyle}>
                <label>
                  <input
                    type="radio"
                    value="option3"
                    checked={selectedOption === "option3"}
                    onChange={handleOptionChange}
                    style={radioButtonStyle}
                  />
                  Option 3
                </label>
                <div>Helper text for Option 3</div>
              </div>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>{editActionButton}</DialogActions>
      </form>
    </>
  );

  const addCardModel = (
    <>
      <DialogContent dividers>
        <Elements stripe={stripePromise} options={options}>
          <CustomCardElement />
        </Elements>
      </DialogContent>
    </>
  );


  const cardAddButton = (
   
    <CustomBackButtonWithoutDisable
    fieldType="submit"
    buttonName="Add"
    click={handleCardClose}
  ></CustomBackButtonWithoutDisable>
  );

  const cardAddModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitCard)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <div>
              <Cards
                cvc={cvvNumber}
                expiry={""}
                name={"HybridHOA"}
                number={cardNumber}
              />
            </div>
            <CustomInputField
              requiredErrorMessage={CARD_NUMBER_REQUIRED}
              fieldPattern={CARD_VALIDATOR}
              patternErrorMessage={CARD_NUMBER_INVALID_MESSAGE}
              minLength={16}
              minLengthErrorMessage={CARD_MIN_LENGTH}
              maxLength={16}
              maxLengthErrorMessage={CARD_MAX_LENGTH}
              fieldName="cardNumber"
              fieldId="cardNumber"
              fieldType="text"
              fieldLabel="Card Number"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={cardNumber}
              onInput={handleCardNumberChange}
            />

            {/* <CustomInputField
              requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
              fieldPattern={ONLY_ALPHABETIC}
              patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={60}
              maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
              fieldName="firstName"
              fieldId="firstName"
              fieldType="text"
              fieldLabel="Card holder name"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={cardName}
              onInput={handleHolderName}
            /> */}

            <CustomInputField
              requiredErrorMessage={CVV_NUMBER_REQUIRED}
              fieldPattern={CVV_VALIDATOR}
              patternErrorMessage={CVV_INVALID_MESSAGE}
              minLength={3}
              minLengthErrorMessage={CVV_MIN_LENGTH}
              maxLength={3}
              maxLengthErrorMessage={CVV_MAX_LENGTH}
              fieldName="cvcNumber"
              fieldId="cvcNumber"
              fieldType="text"
              fieldLabel="Cvc Number"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={cvvNumber}
              onInput={handleCvvNumber}
            />
            <Controller
              control={control}
              name="selectedDate"
              rules={{ required: 'Date & time is required' }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...field}
                      views={["year", "month"]}
                      minDate={minDate}
                      maxDate={maxDate}
                      format="MM/YYYY"
                      onChange={(date) => {
                        handleExpirationChange(date);
                        field.onChange(date);
                      }}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          label: (
                            <span>
                              Expiration
                              <span style={{ color: "red" }}> *</span>
                            </span>
                          ),
                          // error: !!errors.selectedDate,
                          // helperText: errors.selectedDate ? errors.selectedDate.message : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}

            />
          </Typography>
        </DialogContent>
        <DialogActions>{cardAddButton}</DialogActions>
      </form>
    </>
  )


  const editCardModel = (
    <>
      <DialogContent dividers>
        <Elements stripe={stripePromise} options={options}>
          <CustomEditCardElement />
        </Elements>
      </DialogContent>
    </>
  );
  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <CustomHeading title="Subscription" />
      <section class="grid md:grid-cols-1 xl:grid-cols-2 gap-1">
        <div class="p-8 bg-white  rounded-lg  shadow-lg ">
          <span class="block text-black text-base font-semibold">
            Subscription Details
          </span>
          <br />

          <div class="grid md:grid-cols-2 xl:grid-cols-2">
            <span class="block text-black text-sm">
              <b>Plan Type : </b> {planType}
            </span>
            <span class="block text-black text-sm mt-1">
              <b>Start Date :</b>{" "}
              {startDate ? moment(startDate).format("MM/DD/YYYY") : " "}
            </span>
            <span class="block text-black text-sm mt-1">
              <b>Plan Term :</b> {planTerm}
            </span>

            <span class="block text-black text-sm mt-1">
              <b>End Date :</b>{" "}
              {endDate ? moment(endDate).format("MM/DD/YYYY") : " "}
            </span>
            {
              lastDigits ?
                <span class="block text-black text-sm mt-1">
                  <b>Status :</b> {pasueDue ? "In-Active" : "Active"}
                </span>
                :
                ""
            }

            <span class="block text-black text-sm mt-1">
              <b>Is Trial Period:</b> {isTrail ? "Yes" : "No"}
            </span>
          </div>
          <span class="block text-black text-base font-semibold mt-4 mb-4">
            Add on Features
          </span>
          <form
            onSubmit={handleSubmit(onSubmitEdit)}
            className="space-y-4 md:space-y-6 "
          >
            {planType === "BASIC" ? (
              <>
                <b>Features included in this plan</b>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Basic</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />

                      <Tooltip
                        title="Basic included your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <b>Add on Features</b>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      Dedicated Phone Number
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={false} disabled={true} />}
                      />
                      <Tooltip
                        title="Dedicated phone number not included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Domain Registration</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={false} disabled={true} />}
                      />
                      <Tooltip
                        title="Domain registration not included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Web Hosting</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={false} disabled={true} />}
                      />
                      <Tooltip
                        title="Web hosting not included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Virtual Manager</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={false} disabled={true} />}
                      />
                      <Tooltip
                        title="Virtual manager not included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">ARC</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={false} disabled={true} />}
                      />
                      <Tooltip
                        title="ARC not included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </>
            ) : planType === "EXTENDED" ? (
              <>
                <b>Features included in this plan</b>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">IT Services</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />
                      <Tooltip
                        title="IT Services includes in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Accounting</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />
                      <Tooltip
                        title="Accounting included your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <b>Add on Features</b>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      Dedicated Phone Number
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={dedicatedPhone}
                            onChange={(event) => {
                              setDedicatedPhone(event.target.checked);
                              onSubmitEdit(
                                "dedicatedPhone",
                                event.target.checked
                              );
                            }}
                          />
                        }
                      />
                      <Tooltip
                        title="If this dedicated phone number enabled that amount should be added into your subscription"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Domain Registration</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={domain}
                            onChange={(event) => {
                              setDomain(event.target.checked);
                              onSubmitEdit(
                                "domainRegistration",
                                event.target.checked
                              );
                            }}
                          />
                        }
                      />
                      <Tooltip
                        title="If this domain registration number enabled that amount should be added into your subscription"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Web Hosting</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={webHosting}
                            onChange={(event) => {
                              setWebHosting(event.target.checked);
                              onSubmitEdit("webHosting", event.target.checked);
                            }}
                          />
                        }
                      />
                      <Tooltip
                        title="If this web hosting number enabled that amount should be added into your subscription"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Virtual Manager</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={virtualManager}
                            onChange={(event) => {
                              setVirtualManager(event.target.checked);
                              onSubmitEdit(
                                "virtualManager",
                                event.target.checked
                              );
                            }}
                          />
                        }
                      />
                      <Tooltip
                        title="If this virtual manager number enabled that amount should be added into your subscription"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">ARC</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={arc}
                            onChange={(event) => {
                              setArc(event.target.checked);
                              onSubmitEdit("arc", event.target.checked);
                            }}
                          />
                        }
                      />
                      <Tooltip
                        title="If this ARC enabled that amount should be added into your subscription"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <b>Features included in this plan</b>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">IT Services</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />
                      <Tooltip
                        title="IT Services included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Accounting</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />
                      <Tooltip
                        title="Accounting included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      Dedicated Phone Number
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />
                      <Tooltip
                        title="Dedicated phone number included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Domain Registration</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />
                      <Tooltip
                        title="Domain Registration included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Web Hosting</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />
                      <Tooltip
                        title="Web hosting included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Virtual Manager</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Switch checked={true} disabled={true} />}
                      />
                      <Tooltip
                        title="Virtual manager included in your plan"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <b>Add on Features</b>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">ARC</Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={arc}
                            onChange={(event) => {
                              setArc(event.target.checked);
                              onSubmitEdit("arc", event.target.checked);
                            }}
                          />
                        }
                      />
                      <Tooltip
                        title="If this ARC enabled that amount should be added into your subscription"
                        placement="right"
                        arrow
                      >
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>

          <br />
          <span style={{ float: "right" }}>
            {lastDigits && checkIsActive  && (
              <CustomBackButtonWithoutDisable
                fieldType="submit"
                buttonName="Pause Subscription"
                click={handlePauseSub}
              />
            )}
            {lastDigits && !checkIsActive && (
              <CustomBackButtonWithoutDisable
                fieldType="submit"
                buttonName="Resume Subscription"
                click={handleResume}
              />
            )}

            {!lastDigits && (
              <>
                <Tooltip arrow title="Add card to enable the button">
                  <span>
                    <CustomBackButtonWithoutDisable
                      fieldType="submit"
                      buttonName="Pause Subscription"
                      disabled={true}
                    />
                  </span>

                </Tooltip>

              </>


            )}
          </span>
        </div>

        <div class="p-8 bg-white  rounded-lg  shadow-lg ">
          <span class="block text-black text-base font-semibold">
            Payment Information
          </span>
          <br />

          <div class="grid md:grid-cols-1 xl:grid-cols-1">
            <span class="block text-black text-sm">
              <b>{cardBrand.toUpperCase()} Ending in :</b> XXXX XXXX XXXX{" "}
              {lastDigits ? lastDigits : "XXXX"}
            </span>
            {/* <span class="block text-black text-sm mt-1">
              <b>Payment Type : </b>{" "}
              {paymentType == "recurring" ? "Recurring" : paymentType}
            </span> */}
            <span class="block text-black text-sm">
              <b>Exp Date : </b> {expMonth ? expMonth : "MM"}/{" "}
              {expYr ? expYr : "YYYY"}
            </span>
            
          </div>
          <span style={{ float: "right" }}>
            {lastDigits ? (
              <>
                <CustomBackButtonWithoutDisable
                  fieldType="submit"
                  buttonName="Edit"
                  click={handleUpdateClickOpen}
                />
                &nbsp;
                <CustomBackButtonWithoutDisable
                  fieldType="submit"
                  buttonName="Delete"
                  click={handleDeleteClick}
                />
              </>
            ) : (
              <CustomBackButtonWithoutDisable
                fieldType="submit"
                buttonName="Add"
                click={handleAddClickOpen}
              // click={handleClickCardOpen}
              />
            )}
          </span>
          <span class="block text-black text-base font-semibold mt-4 mb-4">
            Billing Details
          </span>
          <span class="block text-black text-sm">
            <b>Due Date : </b>{" "}
            {/* {endPeriod ? moment(endPeriod).format("MM/DD/YYYY") : " "} */}

            {dueDateShow ? moment(dueDateShow).format("MM/DD/YYYY") : " "}
           
            {/* {startPeriod ? moment(startPeriod).format("MM/DD/YYYY") : " "} */}
          </span>
          
          {/* <span class="block text-black text-sm mt-1">
            <b>Current Due  :</b> $ {pricePayable ? pricePayable : " "}
          </span> */}
          <span class="block text-black text-sm mt-1">
            <b>Estimated Due  :</b> $ {price ? price : " "}  (Tax and Processing fee will be added to the subscription amount.)
          </span>
         
           
          {/* <span>
            <b>
              Note
            </b>
            : Tax included
          </span> */}
        </div>

        <CustomModel
          title="Plan Term"
          content={UpgradePlanTerm}
          action={UpgradePlan}
          openStatus={openModalForUpgrade}
          closeStatus={handleCloseModalForUpgrade}
          iconCloseStatus={handleIconCloseForUpgrade}
          reset={reset}
          isLoading={isLoading}
        />

        <CustomModel
          title="Delete Card"
          content={deleteModel}
          submit={handleDeleteClick}
          openStatus={isActivateModalOpen}
          closeStatus={handleDeleteModalClose}
          iconCloseStatus={handleDeleteModalClose}
          reset={reset}
          isLoading={isLoading}
        />

        <CustomModel
          title="Add Card"
          content={addCardModel}
          openStatus={showCard}
          closeStatus={handleCloseCardAdd}
          iconCloseStatus={handleCloseCardAdd}
          reset={reset}
          isLoading={isLoading}
        />

        <CustomModel
          title="Card Add"
          content={cardAddModel}
          // openStatus={open}
          action={cardAddButton}
          closeStatus={handleCardClose}
          iconCloseStatus={handleCardIconClose}
          reset={reset}
          isLoading={isLoading}
        />
        <CustomModel
          title="Update Card"
          content={editCardModel}
          openStatus={showEditCard}
          closeStatus={handleCloseCardEdit}
          iconCloseStatus={handleCloseCardEdit}
          reset={reset}
          isLoading={isLoading}
        />
      </section>
    </div>
  );
}

export default Subscription;
