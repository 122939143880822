import { Delete, DeleteOutline, ListAlt, EditNoteOutlined } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import {
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import { request } from "../services/AxiosConfig";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, HOMES, PAYMENT_CONFIG_LIST } from "../utills/ApplicationRouting";
import moment from "moment";
import CustomToastContainer from '../components/CustomToastContainer';
import CustomLoading from "../components/CustomLoading";
import CustomMaterialTableMoreData from "../components/CustomMaterialTableMoreData";

function PaymentConfig() {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [PaymentconfigDetails, setPaymentconfigDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector(state => state.hybridhoa);
  const [selectedRow, setSelectedRow] = useState(null);
  const [homes, setHomes] = useState([]);

  const navigate = useNavigate();

  const getHomes = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${HOMES}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setHomes(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);

  const handleViewClick = (selectedRow) => {
    navigate(`/payment/${selectedRow.row.original.groupDuesId}`);
    console.log(selectedRow.row.original.groupDuesId);
  };

  const handleEditClick = (selectedRow) => {
    navigate(`/payment/edit/${selectedRow.original.groupDuesId}`);
  };

  const handleAddClick = () => {
    if (homes.length === 0) {
      setOpen(true);
    } else {
      navigate("/payment/add");
    }
  };

  const handleAddClickOpen = () => {
    if (homes === null || (Array.isArray(homes) && homes.length === 0)) {
      setOpen(true);
    }
  };

  const handleAddClose = () => {
    setOpen(false);
  };

  const handleDeleteClickOpen = (selectedRow) => {
    setDeleteOpen(true);
    console.log(selectedRow);
    setSelectedRow(selectedRow.original.groupDuesId);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const { handleSubmit, reset } = useForm();

  const getPaymentConfig = async () => {
    try {
      const storedToken = token;
      setIsLoading(true);

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${PAYMENT_CONFIG_LIST}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data + 'test');

      if (response.status === 200) {
        const data = response.data;
        setPaymentconfigDetails(data);
        setIsLoading(false);
        data.forEach((obj, index) => {
          console.log(`Object ${index + 1}:`, obj);
        });
      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPaymentConfig();
  }, []);

  const convertToPascalCase = (str) => {
    if (str === undefined || str === '' || str === null) return '';
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const onSubmit = () => {
    setIsLoading(true);
    request({ url: `${PAYMENT_CONFIG_LIST}/${selectedRow}`, method: "delete" }).then(res => {
      console.log(res);
      getPaymentConfig();
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
    });
  };

  const paymentConfigTable = useMemo(() => [
    {
      accessorKey: "description",
      header: "Due Description",
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
      accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "endDate",
      header: "Last Date",
      accessorFn: (row) => moment(row.endDate).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "title",
      header: "Due Title",
    },
    {
      accessorKey: "dueAmount",
      header: "Amount",
      size: 20,
      accessorFn: (row) => {
        const amount = parseFloat(row.dueAmount);
        if (isNaN(amount)) return '0';
        return amount % 1 === 0 ? amount.toFixed(0) : amount.toFixed(2);
      },
    },
    {
      accessorKey: "dueType",
      header: "Due Type",
      accessorFn: (row) => convertToPascalCase(row.dueType),
    },
    {
      accessorKey: "dueFrequency",
      header: "Due Frequency",
      accessorFn: (row) => convertToPascalCase(row.dueFrequency),
    },
    {
      accessorKey: "status",
      header: "Status",
      accessorFn: (row) => convertToPascalCase(row.status),
    },
  ], []);
  
  
  

  const rowActions = (row) => {
    const actions = [];
    console.log("Row data:", row.original);
  
    if (row.original.status === "PENDING" && row.original.isAnyOnePaid === false) {
      actions.push(
        {
          label: "Edit",
          icon: <EditNoteOutlined color="primary" />,
          click: () => handleEditClick(row),
        },
        {
          label: "Delete",
          icon: <DeleteOutline color="error" />,
          click: () => handleDeleteClickOpen(row),
        }
      );
    }
  
    if (actions.length === 0) {
      actions.push({
        label: "N/A",
        icon: null, // You can add an icon if you want, or keep it null
        click: () => {}, // No action on click
      });
    }
  
    return actions;
  };
  
  

  

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClick}
      />
    </>
  );

  const addButtonActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleAddClose}
      />
    </>
  );

  const onSubmitResend = (data) => {
    handleAddClose()
  };

  const addModel = (
    <form className="space-y-4 md:space-y-6"  onSubmit={handleSubmit(onSubmitResend)}>
      <DialogContent dividers>
        <Typography>
        You don't have any homes to add dues. Please add homes first, then you will be able to add dues.
        </Typography>
      </DialogContent>
      <DialogActions>{addButtonActions}</DialogActions>
    </form>
  );

  const okCancelAction = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-4 md:space-y-6"
    >
      <DialogContent dividers>
        <Typography>
          Are you sure you want to delete this Due?
        </Typography>
      </DialogContent>
      <DialogActions>
        {okCancelAction}
      </DialogActions>
    </form>
  );

  return (
    <div>
      <CustomToastContainer />
      <CustomHeading title="HOA Dues" />
      {isLoading && <CustomLoading />}
      <CustomModel
        title="Delete Due"
        submit={onSubmit}
        content={deleteModel}
        action={okCancelAction}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Warning"
        submit={handleAddClose}
        content={addModel}
        action={addButtonActions}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleAddClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomMaterialTableMoreData
        columns={paymentConfigTable}
        data={PaymentconfigDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default PaymentConfig;
