import {
  Close,
  EditNote,
  FileDownload,
  FileUpload,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from "react-hook-form";
import { CustomStyle } from "../components/CustomStyle";
import CustomInputField from "../components/CustomInputField";
import JoditEditor from "jodit-react";
import {
  COMMON_ERROR_MESSAGE,
  COMMON_INVALID_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  JOB_CATEGORY_INVAILD_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  MAX_LENGTH_SIX,
  HEADER_REQUIRED_MESSAGE,
  FOOTER_REQUIRED_MESSAGE,
  LATE_FEE_CHARGE_REQUIRED_MESSAGE,
  PERCENTAGE_REQUIRED_MESSAGE,
  CR_FINE_REQUIRED_MESSAGE,
  ONLY_DIGITS_ERROR_MESSAGE,
  MAX_LENGTH_TWO_FIFTY_VALIDATE_MESSAGE,
  MAX_PERCENTAGE_VALIDATE_MESSAGE,
  CONTACT_DETAILS_REQUIRED_MESSAGE,
  MAX_LENGTH_TWO_FIFTY,
  MAX_LENGTH_VALIDATE_MESSAGE_FIFTY,
} from "../utills/ApplicationConstants";
import {
  EMAIL_REGEX_PATTERN,
  NINETY_PERCENTANGE,
  ONLY_ALPHABETIC_REGEX_PATTERN,
  ONLY_DIGITS,
} from "../utills/ApplicationRegex";
import CustomHeading from "../components/CustomHeading";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  GROUP_CONFIG,
  GROUP_CONFIGURATION,
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import CustomToastContainer from "../components/CustomToastContainer";
import { request } from "../services/AxiosConfig";
import CustomButton from "../components/CustomButton";
import CustomLoading from "../components/CustomLoading";
import { useDispatch } from "react-redux";
import { setPageIndex } from "../redux/Hybridhoa";
import ImageUpload from "../components/imageUpload";

function GroupConfiguration() {
  const fileTypes = ["JPG", "PNG"];
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [file, setFile] = useState(null);
  const editorRef = useRef(null);
  const [jobCategoryValue, setJobCategoryValue] = useState("");
  const [editorContentModify, setEditorContentModify] = useState("");
  const [groupConfigurationDetails, setGroupConfigurationDetails] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { groupConfigId } = useParams();
  const { token, pageIndexes } = useSelector((state) => state.hybridhoa);

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const savedPageIndex = pageIndexes[currentUrl] || 0;
  const [pagination, setPagination] = useState({
    pageIndex: savedPageIndex,
    pageSize: 5,
  });
  useEffect(() => {
    const storedPageIndex = parseInt(localStorage.getItem(currentUrl), 10);
    if (!isNaN(storedPageIndex)) {
      setPagination((prev) => ({ ...prev, pageIndex: storedPageIndex }));
    }
  }, [currentUrl]);

  const handleEditorContentChange = (newContent) => {
    setEditorContentModify(newContent);
  };

  const config = useMemo(
    () => ({
      readonly: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      toolbar: false,
      // disablePlugins: "add-new-line",
      showPlaceholder: false,
    }),
    []
  );

  const isEmptyContent = (content) => {
    if (!content) return true;

    const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
    const finalData = strippedContent.replace(/&nbsp;/g, "").trim();

    return finalData.length === 0;
  };

  const isContentTooLong = (content) => {
    const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
    const finalData = strippedContent.replace(/&nbsp;/g, "").trim();

    return finalData.length > 250;
  };
  useEffect(() => {
    localStorage.setItem(currentUrl, pagination.pageIndex);
    dispatch(setPageIndex({ url: currentUrl, pageIndex: pagination.pageIndex }));
  }, [pagination.pageIndex, dispatch, currentUrl]);
  // const [iconClose,setIconClose]

  const removeFirst24Chars = (str) => {
    return str.length > 12 ? str.substring(12) : str;
  };

  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIGURATION}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        if (Array.isArray(data)) {
          const modifiedData = data.map(item => {
            // Check if the topic is 'logo'
            if (item && item.topic === 'Logo' && item.content) {
              item.content = removeFirst24Chars(item.content);
            }
            return item;
          });

          // Update state with modified data
          setGroupConfigurationDetails(modifiedData);
          // Update state with modified data
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGroupConfigurationDetails();
  }, []);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [content, setContent] = useState("");
  const [topic, setTopic] = useState("");
  //Get details by id
  const getGroupConfigurationDetailsData = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GROUP_CONFIGURATION}/${selectedRowId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        setTopic(response.data.topic);
        setContent(response.data.content);
        // setEditorContentModify(response.data.content);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getGroupConfigurationDetailsData();
  }, [selectedRowId]);
  // post the details by id



  const onSubmitEdit = async (data) => {
    const payload = {
      topic: topic,
      // content: topic === "Header" || topic === "Contact Details" ? editorContentModify : content,
      content: content.trim(),
    };

    if (content == "" || content == null) {
      let errorMessage = '';


      switch (payload.topic) {
        case "Header":
          errorMessage = HEADER_REQUIRED_MESSAGE;
          break;
        case "Contact Details":
          errorMessage = CONTACT_DETAILS_REQUIRED_MESSAGE;
          break;
        case "Late Fee Charge":
          errorMessage = LATE_FEE_CHARGE_REQUIRED_MESSAGE;
          break;
        case "Minimum Amount In Percentage":
          errorMessage = PERCENTAGE_REQUIRED_MESSAGE;
          break;
        case "CR Fine":
          errorMessage = CR_FINE_REQUIRED_MESSAGE;
          break;

        default:
          errorMessage = COMMON_ERROR_MESSAGE;
          break;
      }

      console.log(errorMessage);
      setError("value", {
        type: "manual",
        message: errorMessage,
      });

      return;
    }

    if (content.trim().length < 1 || content == null) {
      let errorMessage = '';

      switch (payload.topic) {
        case "Header":
          errorMessage = HEADER_REQUIRED_MESSAGE;
          break;
        case "Contact Details":
          errorMessage = CONTACT_DETAILS_REQUIRED_MESSAGE;
          break;

        case "Late Fee Charge":
          errorMessage = LATE_FEE_CHARGE_REQUIRED_MESSAGE;
          break;
        case "Minimum Amount In Percentage":
          errorMessage = PERCENTAGE_REQUIRED_MESSAGE;
          break;
        case "CR Fine":
          errorMessage = CR_FINE_REQUIRED_MESSAGE;
          break;

        default:
          errorMessage = COMMON_ERROR_MESSAGE;
          break;
      }

      console.log(errorMessage);
      setError("value", {
        type: "manual",
        message: errorMessage,
      });

      return;
    }

    console.log("content:", content.trim(), "end");


    if (!/^\d+$/.test(content.trim()) && ((topic == "Late Fee Charge") || (topic == "Minimum Amount In Percentage") || (topic == "CR Fine"))) {
      setError("value", {
        type: "manual",
        message: ONLY_DIGITS_ERROR_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (content.trim().length > 50 && (topic =="Header")) {
      setError("value", {
        type: "manual",
        message: MAX_LENGTH_VALIDATE_MESSAGE_FIFTY,
      });
      setIsLoading(false);
      return;
    }
    if (content.trim().length > 250 && (topic =="Contact Details")) {
      setError("value", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (content.trim().length > 6 && (topic === "Late Fee Charge")) {
      setError("value", {
        type: "manual",
        message: MAX_LENGTH_SIX,
      });
      setIsLoading(false);
      return;
    }
    if (content.trim().length > 6 && (topic == "CR Fine")) {
      setError("value", {
        type: "manual",
        message: MAX_LENGTH_SIX,
      });
      setIsLoading(false);
      return;
    }
    if (content.trim() > 99 && (topic == "Minimum Amount In Percentage")) {
      setError("value", {
        type: "manual",
        message: MAX_PERCENTAGE_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (content.trim() < 1 && (topic == "Minimum Amount In Percentage")) {
      setError("value", {
        type: "manual",
        message: COMMON_INVALID_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (content.trim() < 1 && (topic == "CR Fine")) {
      setError("value", {
        type: "manual",
        message: COMMON_INVALID_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (content.trim() < 1 && (topic == "Late Fee Charge")) {
      setError("value", {
        type: "manual",
        message: COMMON_INVALID_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    
    // if (!editorContentModify || editorContentModify.trim().length === 0) {
    //   setError("editorContentEdit", {
    //     type: "manual",
    //     message: "This field is required",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
  
    // if (editorContentModify.trim().length > 250) {
    //   setError("editorContentEdit", {
    //     type: "manual",
    //     message: "Please provide a maximum of 250 characters",
    //   });
    //   setIsLoading(false);
    //   return;
    // }

   

    request({ url: `${GROUP_CONFIGURATION}/${selectedRowId}`, method: "put", data: payload })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");

          handleEditClose(res.status);
          getGroupConfigurationDetails();

          setIsLoading(false);
        } else if (res.status != 200) {
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });

  };
  const handleEditClickOpen = (row) => {
    setEditOpen(true);
    setSelectedRowId(row.row.original.groupConfigId);
    console.log(row.row.original.groupConfigId);
    getGroupConfigurationDetailsData()
  };
  const handleEditClose = (status) => {
    if (status == 200) {
      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
  };

  const [imageData, setImageData] = useState(null);
  const [errorss, setErrorss] = useState('');

  const handleImageUpload = (data) => {
    setImageData(data);
    setErrorss('');
  };
  const [previewUrl, setPreviewUrl] = useState(null);
  const handleChange = (selectedFile) => {
    if (selectedFile.size > 10 * 1024 * 1024) { // Check if file size is larger than 10MB
      setErrorMessage("File size exceeds the maximum limit of 10MB.");
      setFile(null); // Clear the selected file
      setPreviewUrl(null); // Clear the preview
      return; // Exit function
    }
    setErrorMessage('');
    setFile(selectedFile);

    if (selectedFile.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Clear preview for non-image files
      setPreviewUrl(null);
    }
  };

  const handleAddClickOpen = () => {
    setAddOpen(true);
  };
  const handleAddClose = (status) => {
    if (status == 200) {
      setAddOpen(false);
    }
  };

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: true, // Add this option
  });

  const onSubmit = async () => {


    if (!imageData || !imageData.file) {
      setErrorss('Logo is required');
      console.log("Please Upload any Image"); // Debugging log
      return;
    }

    const file = imageData.file;
    const fileType = file.type;
    const validFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!validFileTypes.includes(fileType)) {
      setErrorss('Please provide a valid file format');
      return;
    }

    if (file.size > 10 * 1024 * 1024) { // 10MB size limit
      setErrorss('File size exceeds 10MB');
      return;
    }


    const formData = new FormData();
    if (imageData) {
      formData.append('file', imageData.file);
      console.log("file", imageData);
    }


    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({ url: GROUP_CONFIG, method: "post", data: formData, headers })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setFile('');
          setPreviewUrl("");
          setImageData(null)
          handleAddClose(res.status);
          getGroupConfigurationDetails();

          setIsLoading(false);
          setAddOpen(false);
        } else {
          setFile('');
          setImageData(null)
          setPreviewUrl("");
          setIsLoading(false);

        }
      })
      .catch((error) => {
        console.log(error.response);
        setFile('');
        setPreviewUrl("");
        setImageData(null)
        setIsLoading(false);

        if (error.response) {
          setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
  };

  // const onSubmit = async (data) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   console.log("file", file);

  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}${GROUP_CONFIG}`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response.status == 200) {
  //       const data = response.data;
  //       setIsLoading(false);
  //       // setGroupConfigurationDetails();
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  };
  const handleContentChange = (event) => {
    setContent(event.target.value);

  };
  const handleIconClose = () => {
    setErrorMessage('');
    setAddOpen(false);
    setFile('');
    setImageData(null)
    setPreviewUrl("");
    setErrorss('')
  };
  const groupConfigurationTable = useMemo((rowData) => [
    {
      accessorKey: "topic",
      header: "Key",
      accessorFn: (row) => (row && row.topic === "CR Fine" ? "Compliance Request Fine" : row.topic),
    },
    {
      accessorKey: "content",
      header: "value",
      size: 2,
      accessorFn: (row) => stripHtml(row.content),
    },
  ]);

  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <CustomHeading title="Group Configuration" />

      <Dialog open={addOpen} onClose={handleAddClose} fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <span style={{ fontSize: '16px' }}>Upload Logo {" "}
            <InfoOutlined titleAccess="Upload logo size should be within the range of a maximum of 10MB" /></span>
          <IconButton
            aria-label="close"
            onClick={handleIconClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <DialogContent dividers>
            {/* <Typography class="py-5">
              Upload Logo{" "}
              <InfoOutlined titleAccess="Upload logo size should be within the range of a maximum of 10MB" />
              :{" "}
            </Typography> */}
            {/* <h1>Image Upload and Preview</h1> */}
            <ImageUpload onImageUpload={handleImageUpload} />
            {imageData?.base64Image && (
              <div>
                <h2>Uploaded Image:</h2>
                <img src={imageData.base64Image} alt="Uploaded" style={{ maxWidth: '300px', maxHeight: '300px' }} />
              </div>
            )}
            {errorss && <p style={{ color: 'red' }}>{errorss}</p>}
            {/* <FileUploader
        className="py-5"
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        required
      /> */}

            {/* {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>} */}
            {previewUrl && <img src={previewUrl} alt="Preview" style={{ maxWidth: "100%" }} />}
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <Typography className="py-2">
              Note: Upload logo size should be within the range of a maximum of 10MB
            </Typography>
          </DialogContent>
          <DialogActions>
            <CustomButton
              buttonName="Upload"
              onClick={handleAddClose}
              variant="contained"
              fieldType="submit"
            />


          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={editOpen} onClose={handleEditClose} fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <span style={{ fontSize: '16px' }}>Edit Group Configuration</span>
          <IconButton
            aria-label="close"
            onClick={handleEditIconClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmitEdit)}
          className="space-y-4 md:space-y-6 "
        >
          <DialogContent dividers>
            <Typography gutterBottom>
              {isLoading && <CustomLoading />}
              <CustomInputField
                fieldName="key"
                fieldId="key"
                fieldType="text"
                fieldLabel="Key"
                fieldControl={control}
                fieldError={errors}
                multiline={5}
                value={topic == "CR Fine" ? "Compliance Request Fine" : topic}
                disabled={true}
                onInput={handleTopicChange}
                reset={reset}
              />
              {topic === "Contact Details" || topic === "Header" ? (
                <>
                 <CustomInputField
                  fieldName="value"
                  fieldId="value"
                  fieldType="text"
                  fieldLabel="Value"
                  multiline={5}
                  fieldControl={control}
                  fieldError={errors}
                  value={content}
                  onInput={handleContentChange}
                  reset={reset}
                />
                  {/* <FormLabel>Value <span style={{ color: 'red' }}>*</span></FormLabel>
                  <Controller
                    name="editorContentEdit"
                    control={control}
                    render={({ field }) => (
                      <>
                        <JoditEditor
                          ref={editorRef}
                          value={editorContentModify}
                          config={config}
                          onBlur={field.onBlur}
                          onChange={(newContent) => {
                            handleEditorContentChange(newContent);

                            field.onChange(newContent);
                          }}
                        />
                        {errors.editorContentEdit && (
                          <span className="text-red-500">{errors.editorContentEdit.message}</span>
                        )}
                      </>
                    )}
                  /> */}
                  {/* <CustomInputField
                  fieldName="value"
                  fieldId="value"
                  fieldType="text"
                  fieldLabel="Value"
                  multiline={5}
                  fieldControl={control}
                  fieldError={errors}
                  value={content}
                  onInput={handleContentChange}
                  reset={reset}
                /> */}
                </>

              ) : topic === "Late Fee Charge" || topic === "CR Fine" ? (
                <CustomInputField
                  fieldName="value"
                  fieldId="value"
                  fieldType="text"
                  fieldLabel="Value"
                  fieldControl={control}
                  fieldError={errors}
                  value={content}
                  onInput={handleContentChange}
                  reset={reset}
                />
              ) : topic === "Minimum Amount In Percentage" ? (
                <CustomInputField
                  fieldName="value"
                  fieldId="value"
                  fieldType="text"
                  fieldLabel="Value %"
                  fieldControl={control}
                  fieldError={errors}
                  value={content}

                  onInput={handleContentChange}
                  reset={reset}
                />

              )



                : topic === "Publish a notification to homeowners with 51% approval" || topic === "Allow board members, ARC users, and MC users to publish general notifications to homeowners" ? (
                  <Controller
                    name="Value"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={fieldState.invalid}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Select Value<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={content}
                          onChange={(e) => {
                            handleContentChange(e);
                          }}
                          label="Please Select Category"
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {fieldState.invalid ? fieldState.error?.message : ""}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                )
                  : topic === "Publish a document to homeowners with 51% approval" || topic === "Allow board members, ARC users, and MC users to publish general documents to homeowners" ? (
                    <Controller
                      name="Value"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl
                          variant="standard"
                          fullWidth
                          error={fieldState.invalid}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Select Value<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={content}
                            onChange={(e) => {
                              handleContentChange(e);
                            }}
                            label="Please Select Category"
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                          <FormHelperText style={{ color: "#D32F2F" }}>
                            {fieldState.invalid ? fieldState.error?.message : ""}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  )
                    : null}
            </Typography>
          </DialogContent>
          <DialogActions>
            {/* <Button
              autoFocus
              onClick={handleEditClose}
              variant="contained"
              type="submit"
            >
              Update
            </Button> */}
            <CustomButton
              buttonName="Update"
              onClick={handleEditClose}
              variant="contained"
              fieldType="submit"
            />

          </DialogActions>
        </form>
      </Dialog>

      <MaterialReactTable
        columns={groupConfigurationTable}
        data={groupConfigurationDetails}
        enableColumnOrdering
        positionActionsColumn="last"
        enableSorting={false}
        enableColumnPinning
        enableRowActions
        initialState={{
          pagination: pagination,
        }}
        onPaginationChange={setPagination}
        state={{
          pagination,
          isLoading: isLoading,
        }}

        renderTopToolbarCustomActions={() => (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddClickOpen}
                startIcon={<FileUpload />}
              >
                <span class="text-sm font-sans capitalize ">Upload Logo</span>
              </Button>
            </Box>
          </>
        )}
        renderRowActions={(row) => (
          <>
            {console.log("Row Data:", row.row.original.topic)}
            <Box sx={{ display: "flex", gap: "1rem" }}>
              {row.row.original.topic !== "Group Name" &&
                row.row.original.topic !== "Logo" && (
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEditClickOpen(row)}>
                      <EditNote color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
              {row.row.original.topic !== "Group Name" && (

                <Tooltip
                  title={getTooltipMessage(row.row.original.topic)}
                >


                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </>
        )}
      />
    </div>
  );
}
const getTooltipMessage = (topic) => {
  console.log(topic);
  switch (topic) {
    case "Publish a notification to homeowners with 51% approval":
      return "if value = Yes - it allows Board Members to publish notification to homeowners with 51% approval of the Board...... if value = No - it disables Board's access to send notification to home owners";

    case "Allow board members, ARC users, and MC users to publish general notifications to homeowners":
      return ` if value = Yes - it allows Board Members to publish notification to homeowners......      
               if value = No - it disables Board's access to send notification to home owners`;

    case "Publish a document to homeowners with 51% approval":
      return "if value = Yes - it allows Board Members to publish document to homeowners with 51% approval of the Board...... if value = No - it disables Board's access to send document to home owners";

    case "Allow board members, ARC users, and MC users to publish general documents to homeowners":
      return ` if value = Yes - it allows Board Members to publish document to homeowners......      
               if value = No - it disables Board's access to send document to home owners`;

    // Add more cases for other topics if needed
    default:
      return `Update this value to display ${(topic)} to HomeOwner portal`;
  }
};
export default GroupConfiguration;
