import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import React, { useState ,useEffect } from "react";
import {
  DateCalendar,
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiTelInput } from "mui-tel-input";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomButton from "../components/CustomButton";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomDateEditPicker from "../components/CustomDateEditPicker";
import {
  CONTACT_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FROM_DATE_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
  LEASE_END_DATE_REQUIRED_MESSAGE,
  LEASE_START_DATE_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,MAX_LENGTH_TWO_FIFTY,
} from "../utills/ApplicationConstants";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import {
  EMAIL_REGEX_PATTERN,EMAIL_REGEX_PATTERNS,
  ONLY_ALPHABETIC,
} from "../utills/ApplicationRegex";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL, RENTER } from "../utills/ApplicationRouting";
import { request } from '../services/AxiosConfig';
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading";
import CustomPhoneInput from "../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomAttachmentFile from "../components/CustomAttachmentFile";

function AddRenter() {
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [leaseStartDate, setLeaseStartDate] = useState("");
  const [leaseEndDate, setLeaseEndDate] = useState("");
  const [dob, setDob] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };
  const { token } = useSelector(state => state.hybridhoa);
  const [fileError, setFileError] = useState("");

  const handleClick = () => {
    navigate(-1);
  };
  const {
    handleSubmit,
    control, formState,
    formState: { errors },
    setError, clearErrors,
  } = useForm();
  const { homeOwnerLoginId } = useParams();
  const {homeId}=useParams();

  const minDate = dayjs().subtract(2, "year").startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const dobDate = dayjs().subtract(18, "year").endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  const [uploadedFiles, setUploadedFiles] = useState([])


  const handleFileChange = (newFiles) => {
    setFile(newFiles);
  };
  const handleFromDateChange = (selectedFromDate) => {
    const newToDateMaxDate = dayjs(selectedFromDate).add(100, "year").endOf("day");
    setToDateMaxDate(newToDateMaxDate);
    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setLeaseStartDate(selectedFromDate);
  };

  const handleLeaseEndDateChange = (selectedEndDate) => {
    setLeaseEndDate(selectedEndDate);
  };

  const handleDobChange = (selectedDob) => {
    setDob(selectedDob);
  };
  useEffect(() => {
    // Reset fileError when a valid file is selected
    if (file && file.size <= 10 * 1024 * 1024) {
      console.log("File:", file);
      const fileType = file.type && file.type.split("/")[1]; // Check if file.type exists
      console.log("File type:", fileType);
      if (fileType) {
        const fileExtension = fileType.toUpperCase().replace(/[^\w]/g, ""); // Remove special characters
        console.log("File extension:", fileExtension);
        if (fileTypes.includes(fileExtension)) {
          setFileError("");
        }
      }
    }
  }, [file, fileTypes]);

  const onSubmit = (data) => {
    setErrorMessage("");
    setIsLoading(true);

    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      setIsLoading(false);
            return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      setIsLoading(false);
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }

    
    if (!data.leaseStartDate) {
      setError("leaseStartDate", {
        type: "manual",
        message: LEASE_START_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!data.leaseEndDate) {
      setError("leaseEndDate", {
        type: "manual",
        message: LEASE_END_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (leaseEndDate < leaseStartDate) {
      setError("leaseEndDate", {
        type: "manual",
        message: LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,

      });
      setIsLoading(false);
      return;
    }
    // if(file){
    //   console.log("file1",file);
    //   if (!fileTypes.includes(file.name.split('.').pop().toUpperCase())) {
    //     console.log("file2",file);
    //     alert("Please provide a valid file format (JPG, PNG, JPEG, or PDF)");
    //     setIsLoading(false);
    //     return;
    // }
    // }
    // if(data.phoneNumber){
    //   const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);
    //   if (!isValidPhoneNumberForCountry) {
    //     setIsLoading(false);
    //     return;
    //   }
    // }
    // const uploaded = [...uploadedFiles];
    // files.some((file) => {
    //     uploaded.push(file);
    // })
    // setUploadedFiles(uploaded)

    console.log('Form data:', data);
    const formData = new FormData();
    formData.append('leaseStartDate', dayjs(leaseStartDate).format("YYYY-MM-DD"));
    formData.append('leaseEndDate', dayjs(leaseEndDate).format("YYYY-MM-DD"));
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('dateOfBirth', dob ? dayjs(dob).format("YYYY-MM-DD") : '');
    let arrayOfYourFiles=file
    arrayOfYourFiles.forEach(file=>{
      formData.append("files", file);
    });

    formData.append('email', data.email);
    formData.append('contact', data.phoneNumber || "");
    formData.append('address',data.address)
   formData.append('homeId', homeId);
    console.log('leaseStartDate:', dayjs(data.leaseStartDate).format("YYYY-MM-DD"));
    console.log('leaseEndDate', dayjs(data.leaseEndDate).format("YYYY-MM-DD"));
    console.log('firstName', data.firstName);
    console.log('lastName', data.lastName);
    // console.log('file:', file);
    console.log('dateOfBirth', dayjs(dob).format("YYYY-MM-DD"));
    console.log('email', data.email);

    console.log('formData:', formData);


    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    request({ url: `${RENTER}/${homeOwnerLoginId}`, method: "post", data: formData, headers }).then(res => {
      setIsLoading(false);
      console.log(res);
      if (res.status == 200) {

        setTimeout(() => {
          setIsLoading(false);
          // navigate("/homeowners");
          navigate(-1);
        }, 4000);

      }
      setIsLoading(false);
    }).catch(error => {
      console.log(error.res);
      setIsLoading(false);
      if (error.response) {

        console.log("error.response.status" + error.response.status);


      }
    });
  };


  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Renter" />
        <CustomToastContainer />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Renter Details" />
              <CustomInputField
                requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
               
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage="Enter the first name "
              />

              <CustomInputField
                fieldName="lastName"
                requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
              
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                tooltipMessage="Enter the last name"
                fieldError={errors}
                fieldRequiredIcon="*"
              />
              <CustomInputField
                fieldName="address"
                requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
                // fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                // patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                // minLength={2}
                // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="address"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage={"Enter the Address of the renter."}
              />
               <CustomDatePicker
                fieldName="dob"
                label="Date of Birth"
                control={control}
                maxDate={dobDate}
                onChange={handleDobChange}
                tooltipMessage={"Select the date of birth of the renter."}
              />
            </section>
            <br/>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Renter Information Details" />
              <CustomDateEditPicker
                fieldName="leaseStartDate"
                label="Lease Start Date"
                control={control}
                // requiredErrorMessage={LEASE_START_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => handleFromDateChange(date)}
                fieldRequiredIcon="*"
                tooltipMessage="Select the lease start date "
              />

              <CustomDateEditPicker
                fieldName="leaseEndDate"
                label="Lease End Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={LEASE_END_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                onChange={handleLeaseEndDateChange}
                tooltipMessage="Select the lease end date "
              />

            </section>
            <br/>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Contact Details" />
              <CustomInputField
                fieldName="email"
                requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                validator={false}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage=" Enter your current email address. This is how we'll communicate with you and keep you informed about important updates and notifications"
              />


              <div>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: {
                      isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid phone number"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <CustomPhoneInput
                        isRequiredIcon={false}
                        isRequired={false}
                        fieldControl={control}
                        label="Phone Number"
                        clearErrors={clearErrors}
                        tooltipMessage="Enter the phone number for contact purposes "
                        country="US"
                        fieldError={errors}
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    </>
                  )}
                />
                {errors.phoneNumber && (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {errors.phoneNumber.message}
                  </FormHelperText>
                )}
              </div>


            </section>
            <br />
            <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Attachments" />
              <CustomAttachmentFile
        handleFileChange={handleFileChange}
        token={token}
        fileError={fileError}
        file={file}
        
      />
            </section>
            <br />
            <br />
            <div className="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Submit" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default AddRenter;
