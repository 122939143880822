import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Card,
  CardContent,
  FormHelperText,
} from "@mui/material";

import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import {
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  EXTERNAL_ROLE_REQUIRED_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  CONTACT_REQUIRED_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  MIN_LENGTH_ONE_CHARACTERS, FAX_INVALID_MESSAGE,MIN_LENGTH_ONE_CHARACTERS_FOR_ALL,
  MAX_LENGTH_VALIDATE_MESSAGE_TEN
} from "../utills/ApplicationConstants";
import {
  EMAIL_REGEX_PATTERNS,
  ONLY_ALPHABETIC, FAX_REGEX_PATTERN,
} from "../utills/ApplicationRegex";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomButton from "../components/CustomButton";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  EXTERNAL_USER,
  GET_EXTERNAL_USER,
  PUT_EXTERNAL_USER,
} from "../utills/ApplicationRouting";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomPhoneInput from "../components/CustomPhoneInput";
import { isValidPhoneNumber } from 'react-phone-number-input';
import CustomLoading from "../components/CustomLoading"


function EditExternalUser() {
  const [phone, setPhone] = React.useState("");
  const { homeOwnerLoginId } = useParams();

  const { token } = useSelector((state) => state.hybridhoa);
  const [externalUser, setExternalUser] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [fax, setFax] = useState("");
  const [home, setHome] = useState("");
  const [work, setWork] = useState("");
  const [occupation, setOccupation] = useState("");
  const [role, setRole] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const handleClick = () => {
    navigate(-1);
  };

  const getExternalUserId = async () => {
    try {
      const storedToken = token;
      setIsLoading(true);

      console.log("HomeId" + homeOwnerLoginId);
      const response = await axios.get(
        `${BASE_URL}${EXTERNAL_USER}/${homeOwnerLoginId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {

        const data = response.data;
        const mappedUserDetalis = data.userDetails;
        const occupationDetails = data.occupationDetails;
        const loginData = data.loginData;
        setExternalUser(mappedUserDetalis);
        setFirstName(mappedUserDetalis.firstName);
        setLastName(mappedUserDetalis.lastName);
        setEmail(mappedUserDetalis.email);
        setContact(mappedUserDetalis.contact);
        setHome(mappedUserDetalis.home);
        setWork(mappedUserDetalis.work);
        setFax(mappedUserDetalis.fax);
        setValue("phoneNumber", mappedUserDetalis.contact)
        setRole(loginData.roles);
        setOccupation(occupationDetails.occupation);

        setIsLoading(false);

        console.log(occupationDetails.occupation);
        console.log(data.loginData.roles);
        console.log("Renter" + data.renter);
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getExternalUserId();
  }, []);

  const onSubmit = async (data) => {


    const storedToken = token;
    const jsonFormData = {
      firstName: firstName,
      lastName: lastName,
      contact: data.phoneNumber,
      email: email,
      work: data.work,
      home: data.home,
      fax: fax
    };
    if (firstName == "" || firstName == null) {
      setError("firstName", {
        type: "manual",
        message: FIRST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (firstName.trim().length < 2) {
      setError("firstName", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (firstName.trim().length > 60) {
      setError("firstName", {
        type: "manual",
        message: MAX_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (lastName == "" || lastName == null) {
      setError("lastName", {
        type: "manual",
        message: LAST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (lastName.trim().length < 1) {
      setError("lastName", {
        type: "manual",
        message: MIN_LENGTH_ONE_CHARACTERS_FOR_ALL,
      });
      setIsLoading(false);
      return;
    }

    if (lastName.trim().length > 60) {
      setError("lastName", {
        type: "manual",
        message: MAX_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (email == "" || email == null) {
      setError("email", {
        type: "manual",
        message: EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);

    if (!isValidPhoneNumberForCountry) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    request({
      url: `${EXTERNAL_USER}/${homeOwnerLoginId}`,
      method: "put",
      data: jsonFormData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setTimeout(() => {
            navigate(-1);
          }, 4000);
          setIsLoading(false);
        }
        else {
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };
  const [age, setAge] = React.useState("");

  const handleChangeSelect = (event) => {
    setAge(event.target.value);
  };

  const {
    handleSubmit,
    control,
    setError, clearErrors, formState, setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Edit External User" />

        {isLoading && <CustomLoading />}
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>

            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="External User Details" />

              <CustomInputField
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                tooltipMessage="You can edit your first Name here"
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={firstName}
                onInput={(event) => setFirstName(event.target.value)}
              />
              <CustomInputField
                fieldName="lastName"
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage="You can edit your last Name here"
                value={lastName}
                onInput={(event) => setLastName(event.target.value)}
              />

            </section>
            <br />
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Contact Details " />

              <CustomInputField
                fieldName="email"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                validator={false}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage="You can edit your email here"
                value={email}
                onInput={(event) => setEmail(event.target.value)}
              />


              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: CONTACT_REQUIRED_MESSAGE,
                  validate: {
                    isValidPhoneNumber: (value) =>
                      isValidPhoneNumber(value) || "Please provide a valid Contact number",
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      clearErrors={clearErrors}
                      country="US"
                      label="Cell"
                      tooltipMessage="You can edit your personal phone number here"
                      requiredIcon="*"
                      value={contact}
                      onChange={(value) => field.onChange(value)}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={
                        fieldState.invalid ? fieldState.error?.message : ""
                      }
                    />
                  </>
                )}
              />



              <div>
                <Controller
                  name="home"
                  control={control}
                  rules={{
                    validate: {
                      isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <CustomPhoneInput
                        isRequiredIcon={false}
                        isRequired={false}
                        fieldControl={control}
                        label="Home"
                        clearErrors={clearErrors}
                        country="US"
                        fieldError={errors}
                        value={home}
                        tooltipMessage="You can edit your home contact here"
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    </>
                  )}
                />
                {errors.home && (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {errors.home.message}
                  </FormHelperText>
                )}
              </div>
              <div>
                <Controller
                  name="work"
                  control={control}
                  rules={{
                    validate: {
                      isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <CustomPhoneInput
                        isRequiredIcon={false}
                        isRequired={false}
                        fieldControl={control}
                        label="Work"
                        clearErrors={clearErrors}
                        country="US"
                        tooltipMessage="You can edit your work contact here"
                        fieldError={errors}
                        value={work}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    </>
                  )}
                />
                {errors.work && (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {errors.work.message}
                  </FormHelperText>
                )}
              </div>

              <CustomInputField
                fieldName="fax"
                // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={FAX_REGEX_PATTERN}
                patternErrorMessage={FAX_INVALID_MESSAGE}
                maxLength={10}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_TEN}
                validator={false}
                fieldId="fax"
                fieldType="text"
                fieldLabel="Fax"
                fieldControl={control}
                fieldError={errors}
                value={fax}
                tooltipMessage="You can edit your fax number here"
                onInput={(event) => setFax(event.target.value)}
              // fieldRequiredIcon="*"
              />



            </section>
            <br />

            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Assign Role" />
            

                <CustomInputField
                  fieldName="role"
                  fieldId="role"
                  fieldType="text"
                  fieldLabel="Role"
                  fieldControl={control}
                  fieldError={errors}
                  fieldRequiredIcon="*"
                  value={role == "ROLE_GU" ? "General User" : role == "ROLE_MC" ? "Management Company" : role == "ROLE_ARC" ? "ARC" : role == "ROLE_BM" ? "Board Member" : ""}
                  disabled={true}
                  tooltipMessage="You can edit the role of the external user here "
                />
             
            </section>
            <br />

            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              {occupation && (
                <><CustomHeading title="Assign Job-Category" />
                


                      {occupation ?
                        <CustomInputField
                          fieldName="Job"
                          fieldId="Job"
                          fieldType="text"
                          fieldLabel="Job"
                          fieldControl={control}
                          fieldError={errors}
                          fieldRequiredIcon="*"
                          value={occupation}
                          disabled={true}
                          tooltipMessage="You can change the job catergory of the externaluser here"
                        />
                        :
                        null
                      }


                 </>
              )}


            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default EditExternalUser;
