// import React, { useEffect } from 'react';
// import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
// import moment from 'moment';
// import { BASE_URL_IMAGE } from '../utills/ApplicationRouting';
// import { useSelector } from 'react-redux';

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#F6F8FA',
//     padding:"50px"
//  },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//     color: 'white'
//   },
//   logo: {
//     width: 70,
//     height: 60,
//     // backgroundColor:"red",
//     alignItems:"center",
//     justifyContent:"center"
//     // color:'blue',
//   },
//   header: {
//     color: 'blue',
//     fontSize: 32
//   },
// });
// const { token, userDetails } = useSelector((state) => state.hybridhoa);

// const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
//   const [logoUrl, setLogoUrl] = useState('');
//   const [imageBlob,setImageBlob]=useState('')
//   const getGroupConfigurationDetails = async () => {
//     try {
//       setIsLoading(true);
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response);

//       if (response.status === 200) {
//         const data = response.data;
//         setGroupConfigurationDetails(data);
//         const logoConfig = data.find(config => config.topic === 'Logo');
//         console.log(logoConfig);
//         if (logoConfig) {
//           setLogoUrl(logoConfig.content);
//           console.log(logoConfig.content);
//         }
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   useEffect(()=>{
//      getGroupConfigurationDetails()
//   },[])
//   const getFile=async () => {
//     try {
//       const storedToken = token;
//       const response = await axios.get(`${BASE_URL_IMAGE}/${image}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//         responseType: "blob",
//       });
//       const blob = response.data; // Accessing the blob data from the response
//       const fileURL = URL.createObjectURL(blob);
//       setImageBlob(fileURL);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
  
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`,{
//             headers: {
//                 Authorization: `Bearer ${storedToken}`,
//               },
//               responseType: "blob",
//         });
//         const blob = response.data; // Accessing the blob data from the response
//       const fileURL = URL.createObjectURL(blob);
//       setImageBlob(fileURL);
//         // Handle response data here
//       } catch (error) {
//         // Handle error here
//       }
//     };
  
//     fetchData(); // Call the fetchData function inside useEffect
  
//   }, []); // Empty dependency array indicates that this effect runs only once, similar to componentDidMount
  
// const PDFDocument = ({ data }) => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Image src={imageBlob} style={styles.logo} />
//         {data.letterContent.map((item, index) => (
//           <Text key={index} style={item.style}>{item.text}</Text>
//         ))}
//         {/* <Text>From, Group Admin</Text> */}
//       </View>
//     </Page>
//   </Document>
// );

// export default PDFDocument;
import React from 'react';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 35,
    backgroundColor: '#F6F8FA',
 },
  section: {
    margin: 8,
    padding: 5,
    flexGrow: 1,
    color: 'white'
  },
  logo: {
    width: 70,
    height: 60,
    marginTop:"300px",
    // backgroundColor:"red",
    alignItems:"center",
    justifyContent:"center",
    // paddingLeft:"150px",
    flexDirection:"column"
    // color:'blue',  
  },
  header: {
    marginBottom: 0,
    alignItems: 'center',
    justifyContent:'center',
    color: 'grey',
    width:"60%",
    height:"20%",
    marginLeft:"21%"
  },
  title:{
    textAlign:"center",
    fontSize:"12px",
    color: 'black',
    marginTop:"8%",
    fontWeight
    :"bold"
},headerImage:{
    marginTop:"15%",
    paddingLeft:"70px",
    paddingRight:"50px",

    alignContent:"center",
    justifyContent:"center",
    width:"470px",
    height:"200px",
    // backgroundColor:"yellow"
  }
});

const PDFDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Assuming data.logo is a base64 string or a URL to the image */}
      <Text  style={styles.title}>{data.hoaName}</Text>
      {data.logo ? ( <Image src={data.logo} 
      style={styles.headerImage} />):(
        <Text style={styles.headerImage}/>
      )}
      {data.letterContent.map((item, index) => {
        switch (item.type) {
          case 'text':
            return <Text key={index} style={{ ...styles.text, ...item.style }}>{item.text}</Text>;
          case 'image':
            // Ensure your image source (item.src) is a valid base64 string or a URL
            return <Image key={index} src={item.src} style={{ ...styles.image, ...item.style }}/> ;
          default:
            return null;
        }
      })}
    </Page>
  </Document>
);

export default PDFDocument;