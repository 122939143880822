import React, { useState, useEffect, useRef } from "react";
import SideMenuItem from "./SideMenuItem";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ArrowDownward, ArrowDropDown } from "@mui/icons-material";

const SideMenuDropdown = (props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", closeDropdown);
        return () => {
            document.removeEventListener("click", closeDropdown);
        };
    }, []);

    const child = props.childItem;
    console.log(child);

    return (
      <>
      <li className="group relative dropdown text-base tracking-wide">
          <button
              type="button"
              className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group "
              aria-controls={props.id}
              data-collapse-toggle={props.id}
              onClick={toggleDropdown}
          >
              <span className="text-primary">{props.icon}</span>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-regular text-sm ">
                  {props.parrentName}
              </span>
              {isDropdownOpen ? <ArrowDropDown /> : <ArrowRightIcon />}
          </button>
          <ul className={`py-2 space-y-2 ml-4 ${isDropdownOpen ? 'block' : 'hidden'} dropdown-menu `} >
              {child.map((i) => (
                  <SideMenuItem
                      key={i.navLink} // Make sure to use a unique key for each item
                      navigationLink={i.navLink}
                      itemName={i.name}
                      icon={i.icon}
            onClick={props.onClick}

                  />
              ))}
          </ul>
      </li>
  </>
    );
};

export default SideMenuDropdown;
