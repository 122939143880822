import React, { useEffect } from "react";
import CustomButton from "../components/CustomButton";
import CustomHeading from "../components/CustomHeading";
import CustomToastContainer from "../components/CustomToastContainer";
import { Card, CardContent, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { request } from '../services/AxiosConfig';
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL, CONTACT_VERIFICATION } from "../utills/ApplicationRouting";
import logo from "../assets/img/logo.png";
import { clearAuthToken } from "../redux/Hybridhoa";
import { useNavigate } from "react-router-dom";
import CustomBackButtonWithoutDisable from "../components/CustomBackButtonWithoutDisable";



function PhoneOtpVerification() {
    const { token, userDetails } = useSelector((state) => state.hybridhoa);
    console.log(userDetails.contact);
    const {
        handleSubmit,
    } = useForm();
    let navigate = useNavigate();
    // contact verification not routing to dashboard fixed by mohanraj start
    const dispatch = useDispatch();

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            window.history.pushState(null, "", window.location.href);
        };

        window.history.pushState(null, "", window.location.href);
        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);
    // contact verification not routing to dashboard fixed by mohanraj end
    

    const maskPhoneNumber = (phoneNumber) => {
        if (phoneNumber && phoneNumber.length >= 6) {
            if (phoneNumber.startsWith('+')) {
            // If the phone number starts with '+', assume it's an international number
                const countryCode = phoneNumber.substring(0, 3);
                const lastTwo = phoneNumber.substring(phoneNumber.length - 2);
                const middleDigits = "X".repeat(phoneNumber.length - 7);
                return `${countryCode} ${middleDigits}${lastTwo}`;
            } else {
            // If the phone number doesn't start with '+', assume it's a local number
                const firstFour = phoneNumber.substring(0, 2);
                const lastTwo = phoneNumber.substring(phoneNumber.length - 2);
                const middleDigits = "X".repeat(phoneNumber.length - 4);
                return `${firstFour}${middleDigits}${lastTwo}`;
            }
        } else {
          return phoneNumber; // handle cases where the phone number is not long enough
        }
    };
      
    
  

    const handleVerificationLink = (data) => {

        // const postData = {
        //     //   firstName: firstName,
        //     //   lastName: lastName,
        //       email: userDetails.email,
        //       contact: userDetails.contact,
        //     //   houseCount: houseCount,
        // };
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };


        request({ url: `${BASE_URL}${CONTACT_VERIFICATION}`, method: "get",headers: config.headers, })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    console.log("Success");
                    setTimeout(() => {
                        localStorage.removeItem("token");
                        dispatch(clearAuthToken());
                        navigate('/');
                    }, 5000);
                }
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response) {
                    console.log("error.response.status" + error.response.status);
                }
            });
    };

    const handleSignIn = (e) => {
      // Prevent the default behavior of the anchor tag
        e.preventDefault();
  
      // Clear token from local storage
        localStorage.removeItem("token");
  
      // Dispatch an action to clear token in Redux state
        dispatch(clearAuthToken());
  
      // Navigate to the login page
        navigate("/");
    };

    return (
        <div>
            <CustomToastContainer />
            <div className="flex items-center justify-center min-h-screen">
                <div className="w-full lg:w-1/2">
        <section >
                        <div className="flex flex-col items-center justify-center mx-auto md:h-screen lg:py-0">
            <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <div
                  className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
                >
                                        <img className="w-44 h-14" src={logo} alt="logo" />
                                    </div>
                <div class="flex items-center justify-center font-bold">
                                        <Typography sx={{ fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`, fontWeight: 'bold' }}>
                                            Phone Verification
                                        </Typography>
                                    </div>

<form onSubmit={handleSubmit(handleVerificationLink)} className="space-y-4 md:space-y-6 ">
    <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5 text-center">
                                            <Typography sx={{ fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
            Your Phone Number is not yet verified,Please Verify your Phone Number.
                                            </Typography>
                                            <Typography sx={{ fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
                                                {maskPhoneNumber(userDetails.contact)}
                                            </Typography>
                                        </section>
        
                            
                            {/* <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5 text-center">
                              
                                <Controller
                                    name="phoneNumber"
                                    defaultValue="+917010406926"
                                    control={control}
                                    rules={{ required: CONTACT_REQUIRED_MESSAGE }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <PhoneInput
                                                error={fieldState.invalid}
                                                country={"us"}
                                                value={field.value}
                                                onInput={(value) => field.onChange(value)}
                                            />
                                            <FormHelperText style={{ color: "#D32F2F" }}>
                                                {fieldState.invalid ? fieldState.error?.message : ""}
                                            </FormHelperText>
                                        </>
                                    )}
                                />
                            </section> */}

                        


                        <div class="text-center mt-10 ">
                                            <CustomBackButtonWithoutDisable fieldType="submit" buttonName="Send Verification Link" /> &nbsp;
                          

                                        </div>
                        <br/>
                                        <div className="flex items-center justify-center">
                                            <p className="text-sm mr-2"></p>
                                            <span>
                                                <a
                                                    href="/"
                                                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500 text-blue-600"
                                                    onClick={handleSignIn}
                                                >

                                                    Sign in
                                                </a>
                                            </span>
                                        </div>
                 
                                    </form>
          
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default PhoneOtpVerification;
