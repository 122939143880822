import { Button, Card, CardContent, TextField, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomViewPage from "../components/CustomViewPage";
import { BASE_URL, BYLAW } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Loading from "react-loading";
import CustomLoading from "../components/CustomLoading";
import CustomViewWithTooltip from "../../../group-portal/src/components/CustomViewWithTooltip";
import CustomViewHeading from "../components/CustomViewHeading";

function ViewBylaw() {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.hybridhoa);
  const [bylawDetails, setBylawDetails] = useState([]);
  const [topic, setTopic] = useState([]);
  const [description, setDescription] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const[createdByEmail,setcreatedByEmail]=useState("")
  const[contact,setContact]=useState("");
  const [showFullDescription, setShowFullDescription] = useState(false); 
  const handleClick = () => {
    navigate(-1);
  };
  const { byLawId } = useParams();
  let fileList = [];
  const getBylawById = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      const storedToken = token;

      console.log("ByLawId" + byLawId);
      const response = await axios.get(`${BASE_URL}${BYLAW}/${byLawId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        const createdByDetails = data.createdByDetails;
        setFirstName(createdByDetails.firstName);
        setLastName(createdByDetails.lastName);
        setContact(createdByDetails.contact)
        setcreatedByEmail(data.createdByEmail)
        setBylawDetails(data);
        setIsLoading(false);
        console.log("Bylaw" + data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBylawById();
  }, [byLawId]);

  const fullName = firstName + " " + lastName;

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderDescription = () => {
    if (bylawDetails.description && !showFullDescription) {
      return (
        <>
          {bylawDetails.description.slice(0, 250)}
          {bylawDetails.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
      return (
        <>
          {bylawDetails.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };

  // const data = [
    // {
    //   groupName: 'Page Details',
    //   items: [
    //     {
    //       label: 'Page Title',
    //       data: 'View ByLaw',
    //       text: 'Title of the page',
    //     },
    //     {
    //       label: 'Back Button',
    //       data: 'Back',
    //       onClick: handleClick,
    //       text: 'Button to go back to the previous page',
    //     },
    //   ],
    // },
    const data = [
      {
        groupName: 'ByLaw Details',
        items: [
          {
            label: 'Topic',
            data: `${bylawDetails.topic || ""}`,
            text: 'Topic of the bylaw',
          },
          {
            label: 'Description',
            data: `${bylawDetails.description || ""}`,
            text: 'Description of the bylaw',
          },
          {
            label: 'ByLaws Type',
            data: `${bylawDetails.type || ""}`,
            text: 'Type of the bylaw ',
          },
          {
            label: 'Created Date',
            data: `${moment(bylawDetails.createdDateAndTime).format("MM/DD/YYYY") || ""}`,
            text: 'Date when the bylaw was created',
          },
        ],
      },
      {
        groupName: 'Created By Details',
        items: [
          {
            label: 'First Name',
            data: `${firstName || ""}`,
            text: 'First name of the person who created the bylaw',
          },
          {
            label: 'Last Name',
            data: `${lastName || ""}`,
            text: 'Last name of the person who created the bylaw',
          },
          {
            label: 'Email',
            data: `${createdByEmail || ""}`,
            text: 'Email of the person who created the bylaw',
          },
          {
            label: 'Contact',
            data: `${contact  || ""}`,
            text: 'Contact number of the person who created the bylaw',
          },
        ],
      },
    ];
  
  return (
    <div>
      {isLoading && <CustomLoading />}
      {/* <CustomViewPage data={data} /> */}
      <CustomViewHeading onClick={handleClick} title={"View ByLaw"} data={data}
      fileList={fileList}
       description={(bylawDetails?.description || "")}> 
      {/* <CustomViewHeading onClick={handleClick}  title={"View Compliance Requests"} data={data} fileList={fileList}
         description={(complianceRequestData?.description || "")}> */}
      <CustomViewWithTooltip  data={data} />
      
      </CustomViewHeading>

    </div>
  );
}

export default ViewBylaw;
