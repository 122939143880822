import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react'
import CustomButton from '../components/CustomButton';
import CustomLoading from '../components/CustomLoading';
import { CARD_URL } from '../utills/ApplicationRouting';
import CustomBackButtonWithoutDisable from '../components/CustomBackButtonWithoutDisable';

function CustomEditCardElement() {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading,setIsLoading]=useState(false)
  
    const handleCardEdit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      console.log("entered payment card");
      setIsLoading(true)
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
    
      const result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: CARD_URL,
        },
      });
     
  
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000);
      }
    };
  
  return (
    <form onSubmit={handleCardEdit}>
{isLoading && <CustomLoading />}
         {/* <CardExpiryElement/>
         <CardNumberElement/>
         <CardCvcElement/> */}
         {/* <CardElement/> */}
         <PaymentElement/>
         <span style={{float:'right'}} >
         <CustomBackButtonWithoutDisable
         fieldType="submit"
         buttonName="Update"
        />
         </span>
        

   
          {/* <button>Update</button> */}

       
        
        </form>
  )
}

export default CustomEditCardElement