import {
  Close,
  Delete,
  DeleteForever,
  DeleteOutline,
  EditNoteOutlined,
  EditOutlined,
  EmailOutlined,
  FileDownload,
  FileUploadOutlined,
  ListAlt,
  Telegram,
  InfoOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  Card,
  CardContent,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import CustomInputField from "../components/CustomInputField";
import {
  ADDRESS_INVALID_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  JOB_CATEGORY_INVAILD_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE_FIFTY,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  EMAIL_REGEX_PATTERN,
  ONLY_ALPHABETIC,EMAIL_REGEX_PATTERNS,
} from "../utills/ApplicationRegex";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomModel from "../components/CustomModel";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import {
  BASE_URL,
  HOMES,
  HOME,
  UPLOADS,
  HOME_UPLOAD,
  HOMES_DETAILS,
  ADD_SINGLE_HO,
  SINGLE_HOME_ADD,
  JOB_CATEGORY,
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomAutoComplete from "../components/CustomAutoComplete";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import moment from "moment";
import CustomLoading from "../components/CustomLoading";
import HomesAutoComplete from "../components/Homesautocomplete";
import CustomInputFieldForhome from "../components/CustomInputFieldForHome";


const SidebarMenu = () => {
  const [currentSection, setCurrentSection] = useState('Single Homeowner');
  const fileTypes = ["xlsx"];
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHomeOption, setSelectedHomeOption] = useState("option1");
  const navigate = useNavigate();


  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const sections = {
    SingleHomeowner: 'Single Homeowner',
    MultipleHomeowners: 'Multiple Homeowners',
  };

  const handleSectionClick = (section) => {
    setCurrentSection(section);
  };

  const downloadSampleTemplate = () => {
    const sampleTemplateUrl = `${process.env.PUBLIC_URL}/SampleTemplate.xlsx`;

    const anchor = document.createElement("a");
    anchor.href = sampleTemplateUrl;
    anchor.download = "SampleTemplate.xlsx";
    anchor.click();
  };

  const handleFileChange = (file) => {
    if (file.size > 10 * 1024 * 1024) {
      setErrorMessage("File size exceeds the maximum limit of 10MB.");
      setFile(null); 
      return; 
    }
    setErrorMessage("");
    setFile(file);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    console.log("file", file);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({ url: HOME_UPLOAD, method: "post", data: formData, headers })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setTimeout(() => {
            setFile("");
            setIsLoading(false);
            navigate(`/homes`)
            
          }, 2000);
          
          console.log("Success");
        
        
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const onSubmitSingleHome = async (data) => {
    const putData = {
      homeAddress: data.address,
      email: data.email ? data.email : null,
    };

    request({ url: SINGLE_HOME_ADD, method: "post", data: putData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setTimeout(() => {
            setIsLoading(false);
            setFile("");
            // getHomes();
            navigate(`/homes`)
          }, 2000);
       
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleClick = () => {
    navigate(-1);
  };
  const addModelAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Add"
      
      />
    </>
  );
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Add Homes" />

        {isLoading && <CustomLoading />}
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>

      <div>
      
        <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row" style={{ border: '2px solid rgba(204, 204, 204, 0.5)' }}>
          <nav className="w-full md:w-64 bg-[#e3e9f7] flex md:block">
            <ul className="flex md:block m-0 p-0">
            <li className="my-6">
                {/* Top space */}
                <div className="h-8"></div>
              </li>
              {Object.keys(sections).map((section, index) => (
                <li key={`${section}-radio`} className="list-none w-full">
                  <label
                    className={`block pl-2 md:pl-10 py-3 md:py-6 relative transition w-full duration-300 ${currentSection === sections[section]
                      ? 'text-[#2b2626] bg-white  sm:w-full'
                      : 'text-black hover:text-[#2b2626]'
                      }`}
                  >
                    <input
                      type="radio"
                      name="options"
                      className="mr-2"
                      onClick={() => handleSectionClick(sections[section])}
                      checked={currentSection === sections[section]}
                      readOnly
                    />
                    {sections[section]}
                  </label>
                </li>
              ))}
              <li className="my-6 md:hidden">
                <div className="h-8"></div>
              </li>
            </ul>
          </nav>

          <div className="flex-1 flex items-left justify-left p-8">
            <div className="text-center w-full md:w-3/4">
              {currentSection === 'Multiple Homeowners' && (
                 <form onSubmit={handleSubmit(onSubmit)} class="mt-[-20px]">
                  <DialogContent>
                  <p class="text-sm leading-relaxed text-gray-900 text-left">
                    Supported Documents (Excel):{" "}
                    <span style={{ color: "red" }}>*</span>
                    <InfoOutlined
                      style={{ fontSize: "19px" }}
                      titleAccess="File size should be within the range of a maximum of 10MB" />
                  </p>
                  <br />
                  <p class="text-sm leading-relaxed text-gray-900 text-left">
                    {" "}
                    Please upload Excel document that contains HomeOwners details
                  </p>
                  <br />
                  <div>
                    <FileUploader
                      handleChange={handleFileChange}
                      name="file"
                      types={fileTypes}
                      required />
                    {file ? (
                      <p style={{ fontSize: "14px" }}>Selected File: {file.name}</p>
                    ) : (
                      <p class="text-sm leading-relaxed text-gray-900 text-left">
                        No file selected
                      </p>
                    )}
                  </div>
                  {errorMessage && (
                    <p className="text-red-500" style={{ fontSize: "14px" }}>
                      {errorMessage}
                    </p>
                  )}
                  <div className="flex-1 flex items-left justify-left p-8">
                    <Typography class="py-2" style={{ fontSize: "14px" }}>
                      Note : File size should be within the range of a maximum of 10MB
                    </Typography>
                  </div>

                  <p class="text-sm leading-relaxed text-gray-900 text-left">
                    Sample Template to add Homes(It is recommended that you download
                    the sample template for adding homes each time.)
                    <span
                      style={{ cursor: "pointer", color: "blue", marginLeft: "5px" }}
                      onClick={() => downloadSampleTemplate()}
                    >
                      SampleTemplate.xlsx <FileDownload />
                    </span>
                  </p>
                  {/* <p class="text-sm leading-relaxed text-gray-900 text-left">
<b>Note:-</b>If the Homeowner (HO) fails to complete the email
verification process within 24 hours, the email associated with
their Homeowner account will be automatically unassigned for that
particular home.
</p> */}

                </DialogContent>
                <div class="flex-1 flex items-center justify-center p-8">
                <DialogActions>{addModelAction}</DialogActions>
                </div>
                </form>
              
                 
              )}
              {currentSection === 'Single Homeowner' && (
                <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
                 <form
                    onSubmit={handleSubmit(onSubmitSingleHome)}
                    class="mt-[-25px]"
                  >
                    <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
                      <br/>
                      <br/>
                      <CustomInputFieldForhome
                        fieldName="address"
                        requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
                        fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                        patternErrorMessage={ADDRESS_INVALID_MESSAGE}
                        minLength={2}
                        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                        maxLength={50}
                        maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_FIFTY}
                        fieldId="address"
                        fieldType="text"
                        fieldLabel="Enter a Home Address"
                        fieldRequiredIcon="*"
                        fieldControl={control}
                        fieldError={errors}
                        tooltipMessage="Enter Home address for adding single Home "
                      />
                      <CustomInputFieldForhome
                        fieldName="email"
                        // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                        fieldPattern={EMAIL_REGEX_PATTERNS}
                        patternErrorMessage={EMAIL_INVALID_MESSAGE}
                        fieldId="email"
                        fieldType="text"
                        fieldLabel="Email"
                        // fieldRequiredIcon="*"
                        fieldControl={control}
                        fieldError={errors}
                        tooltipMessage="Enter Email for the home address "
                      />
                   </section>

                    <div class="text-center mt-10">
                      <CustomButton fieldType="submit" buttonName="Add" />
                    </div>

                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
