import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import CustomHeader from "./CustomHeader";
import CustomFooter from "./CustomFooter";
import logo from "../assets/img/logo.png";
import {
  GET_ACTIVE_GIF,
  BASE_URL,
  BASE_URL_IMAGE,

} from "../../src/utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";

function CustomLayout({ children }) {
  const routeNameMap = {
    '/': { name: 'Dashboard', parent: null },
    'profile': { name: 'Profile', parent: '/' },
    'bylaw': { name: 'Bylaw', parent: '/' },
    'viewbylaw': { name: 'View Bylaw', parent: 'bylaw' },
    'editbylaw': { name: 'Edit Bylaw', parent: 'bylaw' },
    'reimbursedue': { name: 'Waived Off Dues', parent: '/' },
    'viewreimbursedue': { name: 'View Waived Off Dues', parent: 'reimbursedue' },
    'applyreimbursedue': { name: 'Apply Waived Off Dues', parent: 'reimbursedue' },
    'subscriptions': { name: 'Subscription', parent: '/' },
    'faq': { name: 'Frequently Asked Questions', parent: '/' },
    'homes': { name: 'Homes', parent: '/' },
    'addhomes': { name: 'Add Homes', parent: 'homes' },
    'assignhome': { name: 'Assign Home', parent: 'homes' },
    'subscription': { name: 'Subscription', parent: 'subscriptions' },
    'Documents': { name: 'Document', parent: '/' },
    'documents': { name: 'Documents', parent: 'Documents' },
    'document': { name: 'View Documents', parent: 'documents' },
    'archiveddocuments': { name: 'Archived Document', parent: 'Documents' },
    'viewarchiveddocument': { name: 'View Archived Document', parent: 'archiveddocuments' },
    'auditdocument': { name: 'Audit Document', parent: 'Documents' },
    'paymentgatewayview': { name: 'Payment Gateway View', parent: 'subscriptions' },
    'paymentgatewayadd': { name: 'Add Payment Gateway', parent: 'subscriptions' },
    'paymentgatewayedit': { name: 'Payment Gateway Edit', parent: 'paymentgatewayview' },
    'billing': { name: 'Billing History', parent: 'subscriptions' },
    'Payments': { name: 'Payments', parent: '/' },
    'payment': { name: 'Dues', parent: 'Payments' },
    'offlinepayment': { name: 'Offline Payment', parent: 'Payments' },
    'offlinepayments': { name: 'Offline Payment', parent: 'offlinepayment' },
    'auditlist': { name: 'Audit', parent: 'Payments' },
    'Compliance': { name: 'Compliance Request', parent: '/' },
    'compliance': { name: 'Compliance', parent: 'Compliance' },
    'auditcr': { name: 'Audit Compliance', parent: 'Compliance' },
    'compliance': { name: 'Compliance', parent: 'Compliance' },
    'Maintenance': { name: 'Maintenance Request', parent: '/' },
    'maintenance': { name: 'Maintenance', parent: 'Maintenance' },
    'auditmr': { name: 'Audit Maintenance', parent: 'Maintenance' },
    'configuration': { name: 'Group Configuration', parent: '/' },
    'accountconfig': { name: 'Account Configuration', parent: '/' },

    'jobcategory': { name: 'Job Category', parent: '/' },
    'editjobcategory': { name: 'Edit Job Category', parent: 'jobcategory' },
    'externaluser': { name: 'External Users', parent: '/' },
    'external': { name: 'View External User', parent: 'externaluser' },

    'changepassword': { name: 'Change Password', parent: 'profile' },
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const forbiddenUrls = ["/contactverification"];

  const shouldShowContent = !forbiddenUrls.includes(location.pathname);


  const [gifPreview, setGifPreview] = useState(null);
  const { token } = useSelector((state) => state.hybridhoa);
  // const { token } = useSelector((state) => state.hybridhoa);
  const [gifPreviewOld, setGifPreviewOld] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);


  const getNotificationDetailsById = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${GET_ACTIVE_GIF}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        console.log(response.data);
        console.log("lasfkosfkulhsafIOUHASEFOIUAEFJKLADFJLKADSFJJDLKAFJKLASDFLKJDKJLKLJDFJKFSDJLFK")

        const data = response.data;

        if (data) {
          const imageUrls = response.data.map(item => item.imageUrl);
          console.log(imageUrls.length);

          setGifPreviewOld(imageUrls); // Show old GIF preview initially
        }


      } else {

        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getNotificationDetailsById();
  }, []);
  useEffect(() => {
    if (gifPreviewOld.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % gifPreviewOld.length);
      }, 5000); // Change slide every 5 seconds

      return () => clearInterval(interval);
    }
  }, [gifPreviewOld]);

  

  const getFile = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${gifPreviewOld}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data;
      const fileURL = URL.createObjectURL(blob);
      setGifPreview(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (gifPreviewOld) {
      getFile();
    }
  }, [gifPreviewOld]);

  const generateBreadcrumbs = () => {
    const pathname = location.pathname || ''; // Ensure pathname is defined
    const pathnames = pathname.split('/').filter((x) => x);
    const breadcrumbs = [];

    let currentPath = '';
    pathnames.forEach((pathname, index) => {
      const cleanPathname = pathname.split(':')[0]; // Remove dynamic segments
      currentPath += `/${pathname}`;
      const routeConfig = routeNameMap[cleanPathname];
      if (routeConfig) {
        breadcrumbs.push({
          path: currentPath,
          name: routeConfig.name,
        });
      }
    });

    // Add parent paths for each breadcrumb
    let completeBreadcrumbs = [];
    breadcrumbs.forEach((breadcrumb, index) => {
      let parentPath = routeNameMap[breadcrumb.path.slice(1)]?.parent;
      const pathTrail = [breadcrumb];
      while (parentPath) {
        const routeConfig = routeNameMap[parentPath];
        pathTrail.unshift({
          path: `/${parentPath}`,
          name: routeConfig.name,
        });
        parentPath = routeConfig.parent;
      }
      completeBreadcrumbs = completeBreadcrumbs.concat(pathTrail);
    });

    // Remove duplicate breadcrumbs
    const uniqueBreadcrumbs = Array.from(new Set(completeBreadcrumbs.map(JSON.stringify))).map(JSON.parse);

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" separator=">">
          {uniqueBreadcrumbs.map((breadcrumb, index) => {
            const last = index === uniqueBreadcrumbs.length - 1;
            const isDisabled = breadcrumb.name === 'Subscription' || breadcrumb.name === 'Document' || breadcrumb.name === 'Payments' || breadcrumb.name === 'Compliance Request' || breadcrumb.name === 'Maintenance Request' || breadcrumb.name === 'Home';
            return last ? (
              <Typography color="primary" key={breadcrumb.path}>
                {breadcrumb.name}
              </Typography>
            ) : isDisabled ? (
              <Typography key={breadcrumb.path} style={{ color: 'gray' }}>
                {breadcrumb.name}
              </Typography>
            ) : (
              <Link component={RouterLink} to={breadcrumb.path} key={breadcrumb.path} style={{ color: 'black' }}>
                {breadcrumb.name}
              </Link>
            );
          })}
        </Breadcrumbs>

      </>
    );
  };

  return (
    <>
      {shouldShowContent && (
        <>
          <div>
            <CustomHeader />
            <div className="p-4 sm:ml-64">
              <div className="p-4 mt-14">
                {generateBreadcrumbs()}
                {children}

              </div>
             
              {gifPreviewOld.length > 0 && (
                <div className="slideshow-wrapper" style={{ display: 'flex', transition: 'transform 1s ease-in-out', width: '100%', position: 'relative' }}>
                  {gifPreviewOld.map((item, index) => (
                    <img
                      key={index}
                      src={`${BASE_URL_IMAGE}/${item}`}
                      alt={`GIF Preview ${index + 1}`}
                      style={{
                        minWidth: '100%',
                        transition: 'opacity 1s ease-in-out',
                        opacity: index === currentIndex ? 1 : 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: 'auto',
                        width: 'auto',
                        maxWidth: 'auto',
                        maxHeight: '125px',
                        borderRadius: '8px',
                      }}
                    />
                  ))}
                </div>
              )}

            </div>

          </div>

        </>
      )}
    </>
  );
}

export default CustomLayout;
