import React, { useMemo, useState, useEffect } from "react";
import CustomHeading from "../components/CustomHeading";
import CustomDatePicker from "../components/CustomDatePicker";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  FROM_DATE_REQUIRED_MESSAGE,
  GROUP_NAME_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  STATE_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  STTUS_REQUIRED_MESSAGE
} from "../utills/ApplicationConstants";
import CustomButton from "../components/CustomButton";
// import CustomMaterialTable from '../../components/CustomMaterialTable';
import { download, generateCsv, mkConfig } from "export-to-csv";
import {
  DownloadOutlined,
  FileDownload,
  FileUploadOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { CustomStyle } from "../components/CustomStyle";
// import CustomAutoComplete from '../../components/CustomAutoComplete';
import axios from "axios";
import { BASE_URL, AUDIT } from "../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import { request } from "../services/AxiosConfig";
import moment from "moment";
import CustomLoading from "../components/CustomLoading";
import CustomMaterialWithoutRoutes from "../components/CustomMaterialWithoutRoutes";
import CustomDateEditPicker from "../components/CustomDateEditPicker";
import ReadMoreTable from "../screens/ReadMoreTable";

function AuditList() {
  const { token } = useSelector((state) => state.hybridhoa);
  const [groupData, setGroupData] = useState([]);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  console.log(errors);
  // const getGroupName = async () => {
  //   try {
  //     // setIsLoading(true);
  //     const storedToken = token

  //     console.log(storedToken);

  //     const response = await axios.get(`${BASE_URL}${GET_GROUP}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });
  //     console.log(response.data);

  //     if (response.status === 200) {
  //       const data = response.data;
  //       // setIsLoading(false);
  //       setGroupData(data);

  //     } else {
  //       throw new Error('Failed to fetch data');
  //     }

  //   } catch (error) {
  //     // Handle error
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getGroupName();
  // }, [])

  // useEffect(() => {
  //   // console.log("HomeDetails", groupData);
  // }, [groupData])

  // const renderedAddresses = groupData.map((group, index) => ({
  //   label: group.groupName,
  //   homeId: group.groupId,
  //   key: index,
  // }));

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const exportCSV = (data, fields, csvConfig) => {
    // Generate CSV headers from the fields array
    const headers = fields
      .map((field) =>
        csvConfig.useKeysAsHeaders ? field.header : field.accessor
      )
      .join(csvConfig.fieldSeparator);
    // Generate CSV rows from the data
    const rows = data.map((row) => {
      return fields
        .map((field) => {
          const value = row[field.accessor];
          // If value contains the separator, wrap it in quotes
          if (value.includes(csvConfig.fieldSeparator)) {
            return `"${value}"`;
          }
          return value;
        })
        .join(csvConfig.fieldSeparator);
    });

    const csvContent = [headers, ...rows].join("\n");
    return csvContent;
  };
  const handleExportData = (data) => {
    // Step 1: Transform the data to include the additional fields
    const transformedData = data.map((row) => ({
      ...row,
      'homeOwnerDetails.firstName': row.homeOwnerDetails?.firstName || '',
      'homeOwnerDetails.lastName': row.homeOwnerDetails?.lastName || '',
      'homeOwnerDetails.email': row.homeOwnerDetails?.email || '',
      'homeOwnerDetails.contact': row.homeOwnerDetails?.contact || '',
      'homeOwnerDetails.city': row.homeOwnerDetails?.city || '',
      'homeOwnerDetails.state': row.homeOwnerDetails?.state || '',
      'homeOwnerDetails.zipcode': row.homeOwnerDetails?.zipcode || '',
      'homeDetails.address': row.homeDetails?.address || '',
    }));
  
    // Step 2: Define the static fields to export
    const staticFields = [
      { header: "Title", accessor: "title" },
      { header: "Description", accessor: "description" },
      { header: 'First Name', accessor: 'homeOwnerDetails.firstName' },
      { header: 'Last Name', accessor: 'homeOwnerDetails.lastName' },
      { header: "Address", accessor: "homeOwnerAddress" },
      { header: "Email", accessor: "homeOwnerDetails.email" },
      { header: "Contact", accessor: "homeOwnerDetails.contact" },
      { header: "City", accessor: "homeOwnerDetails.city" },
      { header: "State", accessor: "homeOwnerDetails.state" },
      { header: "Zipcode", accessor: "homeOwnerDetails.zipcode" },
      { header: "DueType", accessor: "dueType" },
      { header: "Status", accessor: "status" },
      { header: "Amount", accessor: "dueAmount" },
    ];
  
    // Step 3: Dynamically add Payment Date or Due Date fields
    const fieldsToExport = [...staticFields];
  
    // Step 4: Sanitize and filter the transformed data
    const sanitizedData = transformedData.map((item) => {
      const sanitizedItem = {};
  
      // Include static fields
      staticFields.forEach(({ accessor }) => {
        sanitizedItem[accessor] = accessor.split('.').reduce((acc, key) => acc?.[key], item) || "";
      });
  
      // Conditionally include Payment Date or Due Date
      if (item.status?.toLowerCase() === "pending") {
        fieldsToExport.push({ header: "Due Date", accessor: "dueDate" });
        sanitizedItem["dueDate"] = new Date(item.dueDate).toLocaleDateString() || "";
      }
  
      if (item.status?.toLowerCase() === "paid") {
        fieldsToExport.push({ header: "Payment Date", accessor: "createdDateAndTime" });
        sanitizedItem["createdDateAndTime"] = new Date(item.createdDateAndTime).toLocaleDateString() || "";
      }
  
      return sanitizedItem;
    });
  
    // Step 5: Remove duplicate headers from dynamic fields
    const uniqueFields = Array.from(new Map(fieldsToExport.map((field) => [field.accessor, field])).values());
  
    // Step 6: Export the CSV
    const csvConfigWithFilename = {
      ...csvConfig,
      filename: 'Audit',
    };
    const csv = exportCSV(sanitizedData, uniqueFields, csvConfigWithFilename);
    download(csvConfigWithFilename)(csv);
  };
  
  

  const minDate = dayjs().subtract(3, "year").startOf("day");
  const maxDate = dayjs().endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);

  const handleFromDateChange = (selectedFromDate) => {
    setListData([]);
    setToDate("");
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
  };

  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);
    setListData([]);
  };

  // const onSubmit=(data)=>{
  //   console.log("inside on submit" );
  // }

  const onSubmit = (data) => {
    console.log("inside submit");
    if (!data.fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    // if (!selectedOption) {
    //   console.log("inside asdfsddad");
    //   setError("selectedOption", {
    //     type: "manual",
    //     message: HOME_ADDRESS_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    console.log("inside subkmit 1 ");
    console.log("Selected Option:", selectedOption);
    setIsLoading(true);

    const postData = {
      from: dayjs(data.fromDate).format("YYYY-MM-DD"),
      to: dayjs(data.toDate).format("YYYY-MM-DD"),
      groupId: selectedOption ? selectedOption.homeId : null,
      status: status,
    };
    console.log("Post Data:", postData);

    // console.log(data);
    request({ url: AUDIT, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);

          console.log(res.data.data);
          let filteredData = [];

          filteredData = res.data.data;
          console.log(filteredData);
          if (res.data.status == "PENDING") {
            let filteredData = [];
            filteredData = res.data.pendingData;
            console.log(filteredData);
            console.log("pending data ");
            setListData(filteredData);
            setStatus("PENDING");
          } else if (res.data.status == "PAID") {
            let filteredData = [];
            filteredData = res.data.paidData;
            console.log(filteredData);
            console.log("paid data ");

            setListData(filteredData);
            setStatus("PAID");
          }
          // localStorage.setItem('mr', JSON.stringify(filteredData));
          // setListData(filteredData);
          // setTimeout(() => {
          //   navigate(`/subscribers`);
          // }, 4000);
          // setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === "") return "";

    // Remove underscores and convert string to Pascal case
    return str
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const auditListTable = useMemo(() => {
    const commonColumns = [
      {
        accessorKey: "serialNo",
        header: "#",
        size: 20,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "title",
        header: "Title",
        size: 20,
        Cell: ({ row }) => (
          <ReadMoreTable text={row.original.title || ""} maxLength={75} />
      ),
      },
      {
        accessorKey: "description",
        size: 20,
        header: "Description",
        Cell: ({ row }) => (
          <ReadMoreTable text={row.original.description || ""} maxLength={75} />
      ),
      },
      {
        accessorKey: "homeOwnerFirstName",
        size: 20,
        header: "First Name",
        accessorFn: (row) => row.homeOwnerFirstName ? row.homeOwnerFirstName : "",
      },
      {
        accessorKey: "homeOwnerLastName",
        size: 20,
        header: "Last Name",
        accessorFn: (row) => row.homeOwnerLastName ? row.homeOwnerLastName : "",
      },
      {
        accessorKey: "homeOwnerAddress",
        size: 20,
        header: "Address",
        accessorFn: (row) => row.homeOwnerAddress? row.homeOwnerAddress : "",
      },
      {
        accessorKey: "dueType",
        size: 20,
        header: "Due Type",
        accessorFn: (row) => convertToPascalCase(row.dueType),
      },
      {
        accessorKey: "status",
        size: 20,
        header: "Status",
      },

      {
        accessorKey: "dueAmount",
        header: "Amount",
        size: 20,
      },
    ];
    if (status === "PAID") {
      return [
        ...commonColumns,
        {
          accessorKey: "createdDateAndTime",
          header: "Payment Date",
          size: 20,
          accessorFn: (row) =>
            moment(row.createdDateAndTime).format("MM/DD/YYYY"),
        },
      ];
    } else {
      return commonColumns;
    }
  }, [status]);

  const tableActionButtons = (
    <>
      {listData.length > 0 && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleExportData(listData)}
          startIcon={<FileUploadOutlined />}
        >
          <span className={CustomStyle.containedButtonStyle}>Export</span>
        </Button>
      )}
    </>
  );
  return (
    <div>
      <CustomHeading title="Audit" />
      {isLoading && <CustomLoading />}
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomDateEditPicker
                fieldName="fromDate"
                label="From Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => handleFromDateChange(date)}
                tooltipMessage="Select from date"
              />

              <CustomDateEditPicker
                fieldName="toDate"
                label="To Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                value={toDate}
                onChange={handleToDateChange}
                tooltipMessage="Select to date"
              />

              <>
                <div className="flex items-center md:w-1/2 w-full">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: STTUS_REQUIRED_MESSAGE }}
                      render={({ field, fieldState }) => (
                        <FormControl
                          variant="standard"
                          fullWidth
                          error={fieldState.invalid}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Select Status
                            <span style={{ color: "#D32F2F" }}> * </span>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                              field.onChange(e.target.value);
                            }}
                            label="Select Status"
                          >
                            {/* <MenuItem value="free">Free</MenuItem> */}
                            <MenuItem value="PAID">Paid</MenuItem>
                            <MenuItem value="PENDING">Pending</MenuItem>
                          </Select>
                          <FormHelperText style={{ color: "#D32F2F" }}>
                            {fieldState.invalid
                              ? fieldState.error?.message
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>
                          Select status Pending/Paid{" "}
                        </Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </>
            </section>
            <div class="text-left mt-10">
              <CustomButton fieldType="submit" buttonName="Search" />
            </div>
          </form>
        </CardContent>
      </Card>
      <br />
      <br />

      <CustomMaterialWithoutRoutes
        columns={auditListTable}
        data={listData}
        actionButtons={tableActionButtons}
      />
    </div>
  );
}

export default AuditList;
