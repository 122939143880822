import { Button, Card, CardContent, TextField } from '@mui/material';
import React from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import { CustomStyle } from '../components/CustomStyle';
import CustomViewPage from '../components/CustomViewPage';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL,PAYMENT_CONFIG_LIST } from "../utills/ApplicationRouting";
import moment from 'moment';
import CustomLoading from '../components/CustomLoading';
import CustomHeading from '../components/CustomHeading';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Info } from "@mui/icons-material";
import CustomViewWithTooltip from '../components/CustomViewWithTooltip';
import CustomViewHeading from '../components/CustomViewHeading';

function ViewPaymentConfig() {

    const navigate = useNavigate();
    const [PaymentConfigData,setPaymentConfigData] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const {token} = useSelector(state=>state.hybridhoa);
    const {groupDuesId} = useParams();
    const [createdByFullName, setCreatedByFullName] = useState("");
    const [GroupDuesId,setGroupDuesId]=useState('');
    const [address,setAddress] = useState('');
    const [createdToDetails,setCreatedToDetails]=useState('')
    const handleClick =() =>{
        navigate(-1)    
    }
   
    // const handleEditClick = () => {
    //   navigate(`/payment/edit/${GroupDuesId}`);
    //   console.log(GroupDuesId);
    // };


    const getPaymentConfigbyId = async () => {
      setIsLoading(true);
   
      try {
        const storedToken = token;
        
        console.log("groupDuesId"+groupDuesId);
        const response = await axios.get(`${BASE_URL}${PAYMENT_CONFIG_LIST}/${groupDuesId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
        if (response.status === 200) {
          const data = response.data;
          

    setGroupDuesId(groupDuesId)
    console.log(groupDuesId);
    
          
              setCreatedByFullName(createdByFullName)
              setCreatedToDetails(data.createdToDetails)
          setPaymentConfigData(data);
          setIsLoading(false);
        } else { 
           setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) { 
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    
    useEffect(()=>{
      getPaymentConfigbyId();
    },[groupDuesId])

  //   function convertToPascalCase(inputString) {
  //     // Check if inputString is defined before attempting to call replace
  //     if (inputString) {
  //         return inputString.replace(/[-_](\w)/g, function(match, group1) {
  //             return group1.toUpperCase();
  //         });
  //     } else {
  //         return ''; // Or handle the case where inputString is undefined/null
  //     }
  // }

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
  

    // const data = {
    //     pageTitle:"View HOA Due",
    //     buttons :[
    //       {
    //         buttonTitle:"Back",
    //         onClick : handleClick
    //       },
    //       // {
    //       //   buttonTitle:"Edit",
    //       //    onClick: (row)=>handleEditClick(row),
    //       // }
    //     ],

    //     content : [
    //         {
    //             topic :'Title ',
    //             description :PaymentConfigData.title
    //           },
    //           {
    //             topic :'Description',
    //             description :PaymentConfigData.description
    //           },
    //           {
    //             topic :'Due Type',
    //             description :convertToPascalCase(PaymentConfigData.dueType)
    //           },
    //           {
    //             topic :'Due Date',
    //             description :
    //             moment(PaymentConfigData.dueDate).format("MM/DD/YYYY ") 
    //           },
    //       {
    //         topic :'Due Frequency ',
    //         description :convertToPascalCase(PaymentConfigData.dueFrequency)
    //       },
    //       {
    //         topic :'Due Amount',
    //         description :PaymentConfigData.dueAmount
    //       },
         
    //       {
    //         topic :'Created By',
    //         description :createdByFullName
    //       },
        
    //       {
    //         topic :'Created Date Time',
    //         description :
    //         moment(PaymentConfigData.createdDateAndTime).format("MM/DD/YYYY hh:MM:ss a") 
    //       },
    //       {
    //         topic :'Audit Date Time',
    //         description :
    //         moment(PaymentConfigData.auditDateAndTime).format("MM/DD/YYYY hh:MM:ss a") 
    //       },
        
    //          {
    //         topic :'Special Assessment Due Type',
    //         description :
    //         PaymentConfigData.specialAssType,
    //       },  
    //        {
    //         topic :'For All Homes',
    //         description :
    //         PaymentConfigData.isForAllHomes ? "Yes" : "No",  
    //       },
    //       {
    //         topic :'Address',
    //         description : address
    //       },
    //     ]
    //   }



    const data = [
      {
          groupName: 'Payment Details',
          items: [
              { label: 'Title', data: `${PaymentConfigData?.title || ""}`, text: 'Title of the payment group' },
              { label: 'Description', data: `${PaymentConfigData?.description || ""}`, text: 'Description of the payment group' },
              // { label: 'For All Homes', data: `${PaymentConfigData?.isForAllHomes ? "Yes" : "No" || ""}`, text: 'Indicates if the due is for all homes' },
              { label: 'Status', data: `${convertToPascalCase(PaymentConfigData?.status) || ""}`, text: 'Current status of the due (e.g., Pending, Paid)' },
              { label: 'Due Type', data: `${convertToPascalCase(PaymentConfigData?.dueType) || ""}`, text: 'Type of due (e.g., Regular, Special)' },
              { label: 'Due Frequency', data: `${convertToPascalCase(PaymentConfigData?.dueFrequency) || ""}`, text: 'Frequency of the due (e.g., Monthly, Quarterly)' },
              { label: 'Due Amount', data: `${PaymentConfigData?.dueAmount || ""}`, text: 'Amount to be paid' },
          ]
      },
      {
          groupName: 'Date And Time Details',
          items: [
              { label: 'Due Date', data: `${moment(PaymentConfigData?.dueDate).format("MM/DD/YYYY ") || ""}`, text: 'The date when the payment is due' },
              { label: 'Last Date', data: `${moment(PaymentConfigData?.endDate).format("MM/DD/YYYY ") || ""}`, text: 'The last date for payment without penalties' },
              { label: 'Created Date & Time', data: `${moment(PaymentConfigData?.createdDateAndTime || "").format("MM/DD/YYYY hh:MM:ss a")}`, text: 'Date and time when the payment configuration was created' },
              { label: 'Audit Date & Time', data: `${moment(PaymentConfigData?.auditDateAndTime).format("MM/DD/YYYY hh:MM:ss a") || ""}`, text: 'Date and time of the last audit' }
          ]
      },
  ];
  
  // Check if due type is "Special Assessment" and add the item accordingly
  if (PaymentConfigData?.dueType === "SPECIAL_ASSESSMENT_DUE") {
      data[0].items.push({ label: 'Special Assessment Due Type', data: `${PaymentConfigData?.specialAssType || ""}`, text: 'Type of special assessment due' });
  }
  
  if (PaymentConfigData?.dueType === "REGULAR_DUE" || PaymentConfigData?.isForAllHomes == true) {
      data.push({
          groupName: 'Created To Details',
          items: [
              { label: 'For All Homeowners', data: 'Yes', text: 'This due is for all homeowners' }
          ]
      });
  } else {
      data.push({
          groupName: 'Created To Details',
          items: [
              {
                  label: 'Firstname',
                  label2: 'Lastname',
                  data: `${createdToDetails?.firstName || ""}`,
                  text: 'First name of the person or entity to whom the configuration was created',
                  data2: `${createdToDetails?.lastName || ""}`
              },
              { label: 'Address', data: `${PaymentConfigData?.homeDetails?.address || ""}`, text: 'Address of the person or entity to whom the configuration was created' },
              { label: 'Email', data: `${createdToDetails?.email || ""}`, text: 'Email of the person or entity to whom the configuration was created' },
              { label: 'Contact', data: `${createdToDetails?.contact || ""}`, text: 'Contact number of the person or entity to whom the configuration was created' },
          ]
      });
  }
  
    
  //   const renderFieldWithTooltip = (fieldName, fieldValue, tooltipText) => (
  //     <div className="flex items-center mb-3">
  //         <div className="w-1/2 pr-4">
  //             <h3 className="font-bold">{fieldName}:</h3>
  //         </div>
  //         <div className="w-1/2">
  //             <Tooltip title={tooltipText}>
  //                 <span>{fieldValue}</span>
  //             </Tooltip>
  //         </div>
  //     </div>
  // );

  return (
    <div>
      {isLoading && <CustomLoading/>}

    {/* <CustomViewPage data = {data} /> */}
    {/* <div>
      <div className="flex flex-col md:flex-row">
        <CustomHeading
          title={"View PaymentHOA's Payment Gateway Informations"}
        />

        <div className="md:full md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
          <Button variant="contained">
            <span className={CustomStyle.containedButtonStyle}>Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <CardContent>
          <CustomHeading title="Basic Details" />
          <div >
                        <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
                          <div className='flex items-center'>
                            <div className={`${CustomStyle.borderedDiv} mb-3 w-full md:w-1/2`}>
                                <div>
                                <h3 className="viewPageText">
                                    <b>Title:</b> {PaymentConfigData.title} 
                                </h3>
                            </div>
                            
                            </div>
                            <div>
                            <Tooltip title={"text"}>
                  <Info />
                </Tooltip>
                            </div>
                            </div>


                            
                            
                        </section>
                    </div>

   </CardContent>
      </Card>
    </div> */}
<CustomViewHeading onClick={handleClick} title={"HOA Due"} data={data} 
         description={(PaymentConfigData?.description || "")}>
<CustomViewWithTooltip   data={data} />
</CustomViewHeading>

</div>
)
}

export default ViewPaymentConfig