import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  FormHelperText,
  TextField,
  Tooltip,
  Typography,Accordion,AccordionSummary,AccordionDetails,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import CustomInputFieldFullWidth from "../components/CustomInputFieldFullWidth";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import CustomHeading from "../components/CustomHeading";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTooltipField from "../components/CustomTooltipField";
import {
  AMOUNT_EXCEEDED,
  AMOUNT_INVALID_MESSAGE,
  AMOUNT_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REIMBURSE_COMMENT,
} from "../utills/ApplicationConstants";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import CustomAutoComplete from "../components/CustomAutoComplete";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  HO,
  HOMES,
  REIMBURSED_DUES,
} from "../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../services/AxiosConfig";
import CustomReimbursedAuto from "../components/CustomReimbursedAuto";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading";
import CustomInputFielddueandamount from "../components/CustomInputFielddueandamount";
import CustomInputFielddueandamountFullWidth from "../components/CustomInputFielddueandamountFullWidth";
import CustomPhoneInput from "../components/CustomPhoneInput";
import HomesAutoComplete from "../components/Homesautocomplete"
import OfflinePaymentAuto from "../components/Offlinepaymentauto";
import ReadMoreTable from "../../src/screens/ReadMoreTable"
import { Crisp } from "crisp-sdk-web";
import CustomMaterialTableForReimbursed from "../components/CustomMaterialTableForReimbursed";
function ApplyReimburseDue() {
  const { token,homeId } = useSelector((state) => state.hybridhoa);

  const [applyOpen, setApplyOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [comment, setComment] = useState("");
  const [waivedAmount, setWaivedAmount] = useState("");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const [selectedDueAmount, setSelectedDueAmount] = useState("");
  const [selectedHomeOwnerLoginId, setSelectedHomeOwnerLoginId] = useState("");
  const [selectedHomeOwnerDuesId, setSelectedHomeOwnerDuesId] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [title, setTitle] = useState("");
  const [dueType, setDueType] = useState("");
  const [dueAmount, setDueAmount] = useState(""); 
  const [firstName,setFirstName]=useState("");
  const [lastName,setLastName]=useState("");
  const [email,setEmail]=useState("");
  const [contact,setContact]=useState("");
  const [address,setAddress]=useState("");
   const [selectedNewHomeId,setSelectedNewHomeId]=useState("");


   const handleApply = (row) => {
    console.log("Row data:", row.original || row); // Adjust according to your row structure
    const data = row.original || row; // Fallback to `row` if `row.original` is not available
    navigate(`/applyeaddreimburse/${data.homeOwnerDuesId}`)
    setSelectedRowId(data.homeOwnerDuesId);
    setDueHomeId(data.homeId);
    setSelectedDueAmount(data.dueAmount);
    setSelectedHomeOwnerLoginId(data.homeOwnerLoginId);
    setTitle(data.title);
    setDueType(data.dueType);
    setDueAmount(data.dueAmount);
    console.log("Due Amount:", data.dueAmount);

 
    
    // Open a modal or dialog
    // setApplyOpen(true);
};
  const getDataRow = async () => {
    try {
      const storedToken = token;
      setIsLoading(false);

      const response = await axios.get(
        `${BASE_URL}${REIMBURSED_DUES}/${selectedRowId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);
      if (response.status == 200) {
        const data = response.data;
        // const datas = response.data.homeOwnerDetails;
        setIsLoading(false);

        // setType(data);
        console.log(data);
        // setHomeAddressOptions(data);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => { }, [getDataRow]);
  const handleApplyClose = (status) => {
    if (status == 200) {
      setApplyOpen(false);
      resetModal();
      setWaivedAmount('');
      setComment('');
    }
  };
  const handleApplyIconClose = () => {
    setApplyOpen(false);
    setWaivedAmount('');
    setComment('');
    resetModal();
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    reset,
    watch,clearErrors
  } = useForm();
  const {
    handleSubmit: handleSubmitModel,
    control: controlModal,
    formState: { errors: errorsModal },
    setValue: setValueModal,
    setError: setErrorModal,
    reset: resetModal,
    watch: watchModal,
  } = useForm();
  const [homeAddressOptions, setHomeAddressOptions] = useState([]);
  const [selectedHomeAddress, setSelectedHomeAddress] = useState("");
  const [type, setType] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [homeOwnerDetailsId, setHomeOwnerDetailsId] = useState("");
  const [selectedFirstname, setSelectedFirstname] = useState("");
  const [selectedLastname, setSelectedLastname] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dueData, setDueData] = useState([]);
  const [dueHomeId,setDueHomeId]=useState("")
  const [homeOwnerLoginId,setHomeOwnerLoginId]=useState("")
  const [homeAddresses, setHomeAddresses] = useState([]);
  const getHomes = async () => {
    try {
      const storedToken = token;
      setIsLoading(true);

      const persistRoot = JSON.parse(localStorage.getItem("persist:root"));

      // Parse the Redux state to get the user details
      const userDetails = JSON.parse(persistRoot?.userDetails);

      // Extract the homeOwnerLoginId
      const storedHomeOwnerLoginId = userDetails?.homeOwnerLoginId;

      console.log("storedHomeOwnerLoginId", storedHomeOwnerLoginId);
      const response = await axios.get(`${BASE_URL}${HOMES}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      const data = response.data;
      if (response.status === 200) {
        setIsLoading(false);


        setHomeAddresses(data);
      } else {
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);
  const [homeDetails, setHomeDetails] = useState('');
  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      console.log("home id",newValue.value);
      const selectedHomeId = newValue.value;
      const selectedHome = homeAddresses.find((home) => home.homeId === selectedHomeId);
      // const homeOwner = selectedHome.homeOwnerDetails;

      if (selectedHome) {
        const { name } = selectedHome;
        const selectedHomeDescription = `${name}`;
        console.log("Selected Home Description:", selectedHomeDescription);
        setHomeOwnerDetailsId(selectedHomeId);
        setFirstName(selectedHome.firstName);
        setLastName(selectedHome.lastName);
        setEmail(selectedHome.email);
        setContact(selectedHome.contact);
        setAddress(selectedHome.address);
        setValue("selectedOption", selectedHomeId);
        setHomeDetails(selectedHomeDescription);
        setHomeOwnerDetailsId(selectedHome.homeOwnerDetailsId);
        setHomeOwnerLoginId(selectedHome.homeOwnerLoginId);
        setSelectedNewHomeId(selectedHome.homeId)
        console.log(selectedHomeId, "homeId");
        console.log("inside if");
      
      } else {
        setHomeDetails("");
        setValue("selectedOption", "");
        setHomeOwnerDetailsId("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setContact("");
        setAddress("");
        console.log("inside if else");

      }
    } else {
      setHomeDetails("");
      setHomeOwnerDetailsId("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setContact("");
      setAddress("");
      setValue("selectedOption", "");
      console.log("inside else");

    }
  };



  // useEffect(() => {
  //   const selectedHome = watch('selectedOption');


  //   if (!selectedHome || selectedHome === "") {
  //     setHomeDetails('');
  //     // setIsBylawFieldEmpty(true);
  //   } else {
  //     // Find the matching byLaw from byLawDetails
  //     const matchingHome = homeAddresses.find((home) => home.homeOwnerDetailsId === selectedHome);
  //     console.log(matchingHome, "check");
  //     if (matchingHome) {
  //       const { name } = matchingHome;
  //       setHomeDetails(`${name}`);
  //       // setIsBylawFieldEmpty(false);
  //     } else {
  //       // Handle the case where no matching byLaw is found
  //       setHomeDetails('');
  //       // setIsBylawFieldEmpty(true);
  //     }
  //   }

  // }, [watch('selectedOption'), homeDetails]);
  const renderedAddresses = homeAddresses.map((home, index) => {
    const parts = [
      home.address,
      home.firstName,
      home.lastName,
      home.email,
      home.contact,
    ].filter(part => part); // Filter out any null or undefined parts
  
    const address = parts.join(', '); // Join the parts with a comma and a space
  
    return {
      address: address,
      value: home.homeId,
      key: index,
    };
  });

  // const renderedAddresses = homeAddresses.map((home, index) => ({
  //   address: `${home.address}, ${home.firstName}, ${home.lastName}, ${home.email}, ${home.contact}`,
  //   value: home.homeOwnerDetailsId,
  //   key: index,
  // }));
  const onSubmits = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BASE_URL}${REIMBURSED_DUES}/${HO}?homeId=${selectedNewHomeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data;
        setDueData(response.data);
        // console.log("testee",response.data);
        setTotalAmount(data.dueAmount);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  // const  homeOwnerPaymentUpdate=async()=>{
  //  await onSubmits()
  // }
  const onSubmit = async (data) => {
console.log("homeIdff",homeOwnerDetailsId);
    const parsedWaivedAmount = parseFloat(waivedAmount);
    const parsedDueAmount = parseFloat(dueAmount);

    let waivedAmount1 = waivedAmount;

    // Check if dueAmount is a string and its length is greater than 1
    if (typeof waivedAmount1 === 'string' && waivedAmount1.length > 1) {
      // Trim leading zeros
      waivedAmount1 = waivedAmount1.replace(/^0+/, '');
    }

    if (parsedWaivedAmount > parsedDueAmount) {
      setErrorModal("waivedOffAmount", {
        type: "manual",
        message: AMOUNT_EXCEEDED
      });

      setIsLoading(false);
      return;
    }
    if (!waivedAmount || parseFloat(waivedAmount) === 0) {
      setErrorModal("waivedOffAmount", {
        type: "manual",
        message: "Please provide Valid Due Amount (i.e xxxxx.xx)",
      });
      return;
    }

    setIsLoading(true)
    console.log("selected");
    const payload = {
      totalAmount: selectedDueAmount,
      waivedAmount: waivedAmount1,
      comment: comment,
      appliedTo: homeOwnerLoginId,
      homeOwnerDuesId: selectedRowId,
      title: title,
      dueType: dueType,
      appliedToHomeId:dueHomeId,
      // handleApplyClickOpenomeOwnerLoginId:homeOwnerLoginId;
    };

    request({
      url: `${REIMBURSED_DUES}`,
      method: "post",
      data: payload,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          handleApplyClose(res.status);
          setWaivedAmount('');
          setComment('');
          setTimeout(() => {
            onSubmits();
            setIsLoading(false);
          }, 4000)
          setIsLoading(false);

        } else {
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
    // try {
    //   setIsLoading(true);
    //   const response = await axios.post(
    //     `${BASE_URL}${REIMBURSED_DUES}`,
    //     payload,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   console.log(response);
    //   if (response.status == 200) {
    //     const data = response.data;
    //     // getGroupConfigurationDetailsData();
    //     setDueData(response.data);
    //     setIsLoading(false);
    //   } else {
    //     throw new Error("Failed to fetch data");
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';

    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleViewClick = (selectedRow) => {
    
  };



  const reimburseDueTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 2,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "homeOwnerDetails.firstName",
      size: 20,
      header: "First Name",
      cell: ({ row }) => row.original.homeOwnerDetails?.firstName || "",
    },
    {
      accessorKey: "homeOwnerDetails.lastName",
      size: 20,
      header: "Last Name",
      cell: ({ row }) => row.original.homeOwnerDetails?.lastName || "",
    },
    {
      accessorKey: "homeDetails.address",
      size: 20,
      header: "Address",
      cell: ({ row }) => row.original.homeDetails?.address || "",
    },
    {
      accessorKey: "title",
      header: "Title",
      Cell: ({ row }) => (
          <ReadMoreTable text={row.original.title || ""} maxLength={75} />
      ),
  },
    {
      accessorKey: "description",
      header: "Description",
      Cell: ({ row }) => (
        <ReadMoreTable text={row.original.description || ""} maxLength={75} />
    ),
    },
    {
      accessorKey: "dueType",
      header: "Due Type",
      accessorFn: (row) => convertToPascalCase(row.dueType),
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
      accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "dueAmount",
      header: "Amount",
    },


  ]);

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleApplyIconClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleApplyClose}
      />

    </>
  );
  const rowActions = [
   
  ];
  const handleWaivedChange = (event) => {
    setWaivedAmount(event.target.value);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const applyModel = (
    <form onSubmit={handleSubmitModel(onSubmit)} className="space-y-4 md:space-y-6 ">
      <DialogContent dividers>
        <Typography gutterBottom>
        {isLoading && <CustomLoading />}
          <CustomInputFielddueandamountFullWidth
            fieldName="waivedOffAmount"
            fieldId="waivedOffAmount"
            fieldType="text"
            fieldLabel="Waived Off Amount"
            requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
            fieldControl={controlModal}
            fieldError={errorsModal}
            fieldRequiredIcon="*"
            onInput={handleWaivedChange}
            value={waivedAmount}
            invalidOption="invalid home address"
             tooltipMessage="Enter the Amount to be waved off"
          />
          <br />
          <br />

          <CustomInputFieldFullWidth
            fieldName="reimburseComment"
            fieldType="text"
            fieldId="reimburseComment"
            fieldLabel="Waived Off Comment"
            requiredErrorMessage={REIMBURSE_COMMENT}
            minLength={2}
            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            maxLength={500}
            maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
            fieldControl={controlModal}
            fieldError={errorsModal}
            multiline={true}
            numberOfRows={1}
            fieldRequiredIcon="*"
            onInput={handleCommentChange}
            value={comment}
             tooltipMessage="Enter your comment here"
           
          />
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Apply Waived Off Dues" />
        <CustomToastContainer />
        {isLoading && <CustomLoading />}

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <CardContent>
          <form>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <HomesAutoComplete
                fieldName="selectedOption"
                requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedAddresses}
                fieldLabel="Search By (address, firstname, lastname, email, contact)"
                fieldControl={control}
                error={errors}
                setValue={setValue}
                fieldRequiredIcon="*"
                onChange={handleHomeChange}
                invalidOption= "Please select valid home address"
                tooltipMessage="You can search by (address, firstname, lastname, email, contact) it shows description below"
              />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Home Owner Information
                </AccordionSummary>
                <AccordionDetails>
                  <CustomTooltipField
                    fieldName="firstName"
                    fieldId="firstName"
                    fieldType="text"
                    fieldLabel="First Name"
                    fieldControl={control}
                    fieldError={errors}
                    fieldRequiredIcon="*"
                    value={firstName}
                    disabled
                    onInput={(e) => setFirstName(e.target.value)}
                    tooltipMessage={"First name of the HomeOwner"}
                  />

                  <CustomTooltipField
                    fieldName="lastName"

                    fieldId="lastName"
                    fieldType="text"
                    fieldLabel="Last Name"
                    fieldControl={control}
                    fieldError={errors}
                    fieldRequiredIcon="*"
                    value={lastName}
                    disabled
                    onInput={(e) => setLastName(e.target.value)}
                    tooltipMessage={"Last name of the HomeOwner"}
                  />
                  <CustomTooltipField
                    fieldName="email"
                    disabled
                    fieldId="email"
                    fieldType="text"
                    fieldLabel="Email"
                    fieldControl={control}
                    fieldError={errors}
                    fieldRequiredIcon="*"
                    value={email}
                    onInput={(e) => setEmail(e.target.value)}
                    tooltipMessage={"Email of the HomeOwner"}
                  />
                  <CustomTooltipField
                    fieldName="address"
                    disabled
                    fieldId="address"
                    fieldType="text"
                    fieldLabel="Address"
                    fieldControl={control}
                    fieldError={errors}
                    fieldRequiredIcon="*"
                    value={address}
                    onInput={(e) => setAddress(e.target.value)}
                    tooltipMessage={"Address of the HomeOwner"}
                  />

                  <Controller
                    name="phoneNumber"
                    control={control}
                    // rules={{ required: PHONE_REQUIRED_MESSAGE,
                    //   validate: {
                    //     isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
                    //   } }}
                    render={({ field, fieldState }) => (
                      <>
                        <CustomPhoneInput
                          fieldControl={control}
                          label="Phone Number"
                          clearErrors={clearErrors}
                          country="US"
                          value={contact}
                          onChange={(e) => {
                            setContact(e.target.value);
                          }}
                          error={fieldState.invalid ? fieldState.error : null}
                          helperText={
                            fieldState.invalid ? fieldState.error?.message : ""
                          }
                          disabled={true}
                          tooltipMessage={"Phone Number of the HomeOwner"}
                        />
                      </>
                    )}
                  />
                </AccordionDetails>
              </Accordion>
            </section>
            <div class="text-left mt-10">
              <CustomButton fieldType="submit" buttonName="Search" click={handleSubmit(onSubmits)} />
            </div>
          </form>
        </CardContent>
      </Card>
      <br />
      <br />

      <CustomModel
        title="Apply Waived Off"
        submit={onSubmit}
        content={applyModel}
        action={okCancelButtonActions}
        openStatus={applyOpen}
        closeStatus={handleApplyClose}
        iconCloseStatus={handleApplyIconClose}
        reset={reset}
      />
       
      <CustomMaterialTableForReimbursed 
  columns={reimburseDueTable}
  data={dueData}
rowActions={rowActions}
onApply={handleApply}
/>
    
      {/* <MaterialReactTable
        columns={reimburseDueTable}
        data={dueData}
        enableColumnOrdering
        positionActionsColumn="last"
        enableSorting={false}
        enableColumnPinning
        enableRowActions
        renderRowActions={(row, table) => (
          <>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip style={{ cursor: "pointer" }}>
                <span onClick={() => handleApplyClickOpen(row)}>
                  Apply Waived Off
                </span>
              </Tooltip>
            </Box>
          </>
        )}
      /> */}
    </div>
  );
}

export default ApplyReimburseDue;
