import { EditNoteOutlined } from "@mui/icons-material";
import { DialogActions, DialogContent } from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import React, { useEffect, useMemo } from "react";
import CustomInputField from "../components/CustomInputField";
import {
  CURRENT_AND_NEW_PASSWORD_CHECKING,
  JOB_CATEGORY_INVAILD_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE_FIFTY,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  ONLY_ALPHABETIC,
  ONLY_ALPHABETIC_REGEX_PATTERN,
} from "../utills/ApplicationRegex";
import { useForm } from "react-hook-form";
import { useState } from "react";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import { useSelector } from "react-redux";
import { BASE_URL, JOB_CATEGORY } from "../utills/ApplicationRouting";
import axios from "axios";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading";
import { Navigate } from "react-router-dom";
import {  useNavigate } from "react-router-dom";


function JobCategory() {
  const [jobCategoryDetails, setJobCategoryDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.hybridhoa);
const navigate = useNavigate();


  const handleAddClickOpen = () => {
    // setOpen(true);
    navigate(`/addjobcategory`)
  };

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  // get all details code start

  const getJobCategoryDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${JOB_CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status == 200) {
        const data = response.data;
        setJobCategoryDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getJobCategoryDetails();
  }, []);



  const handleEditClickOpen = (selectedRow) => {
    console.log("Occupation Id " + selectedRow);
    navigate(`/editjobcategory/${ selectedRow.original.externalOccupationId}`);
    // setEditOpen(true);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.externalOccupationId);
    // setSelectedRowId(selectedRow.original.externalOccupationId);
    // getJobCategoryById(selectedRow.original.externalOccupationId);
  };



  const jobCategoryTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 2,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "occupation",
      header: "Job-Category",
      size: 2,
      enableClickToCopy: true,
      filterVariant: "text",
    },
  ]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(jobCategoryDetails);
    download(csvConfig)(csv);
  };

  const rowActions = [
    {
      label: "Edit",
      icon: <EditNoteOutlined color="primary" />,
      click: (row) => handleEditClickOpen(row),
    },
  ];


  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
      />
    </>
  );



  

 

 
  return (
    <div>
      <CustomToastContainer/>
      
      <CustomHeading title="Job-Category" />
      {isLoading && <CustomLoading />}
     

     

      <CustomMaterialTable
        columns={jobCategoryTable}
        data={jobCategoryDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
      />
    </div>
  );
}

export default JobCategory;
