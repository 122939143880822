
// export const BASE_URL="http://localhost:3031/api/v1";
// export const BASE_URL="http://10.64.26.73:3031/api/v1";
// export const BASE_URL = "https://apilivegpprod.hybridhoaonline.com/hhoa-group-portal/api/v1";
export const BASE_URL = "https://groupapi.hybridhoa.com/hhoa-group-portal/api/v1";


// export const BASE_URL_IMAGE = "http://localhost:3031/api/v1/file";
// export const BASE_URL="http://10.64.26.139:3031/api/v1";


// export const BASE_URL_IMAGE = "https://apilivegpprod.hybridhoaonline.com/hhoa-group-portal/api/v1/file";
export const BASE_URL_IMAGE = "https://groupapi.hybridhoa.com/hhoa-group-portal/api/v1/file";


// export const BASE_URL_IMAGE = "http://10.64.26.139:3031/api/v1/file";
// export const CARD_URL = "http://localhost:4041/#/subscription?addcard=success"
// export const CARD_URL = "https://groupliveprod.hybridhoaonline.com/#/subscription?addcard=success"
export const CARD_URL = "https://group.hybridhoa.com/#/subscription?addcard=success"


  

export const ADMIN_LOGIN = "/auth/adminlogin";
export const HOMES = "/homes";
export const HOME = "/home";
export const BYLAW = "/bylaw";  
export const UPLOADS="/upload";
export const EMAIL_VERIFY="/verifyEmail/" ;
export const COMPLIANCE_REQUEST = "/complaince";
export const MAINTENANCE_REQUEST = "/maintenance";
export const JOB_CATEGORY = "/externaloccupation";
export const EXTERNAL_USER = "/externaluser";
export const GROUP_CONFIGURATION = "/groupconfig";
export const HOME_OWNERS = "homes/homeowners";
export const HOME_OWNERS_EDIT="/homes";
export const  ASSIGN_ROLES="/homeowner/assign/"
export const FILE_NAME = "/fileName";
export const FAQS = "/faq";
export const DOCUMENT = "/document";
export const DOCUMENT_ARCHIEVED="/document/archived"
export const GROUP_CONFIG = "/groupconfig";
export const REPORT_CR_MR="/report/crmr";
export const REPORT_DOCUMENT="/report/document"
export const PROFILE="/profile";
export const RENTER="/renter";
export const REIMBURSED_DUES="/reimburseddue";
export const PAYMENT_CONFIG_LIST="/groupdues";
export const DASHBOARD="/dashboard";
export const CHART="/chart";
export const HO="ho";

export const HOME_UPLOAD = "/homes/upload";

export const CATEGORY = "/groupconfig/category";

export const GENERAL_COMMENTS = "/generalcomments";

export const GET_ALL_GENERAL_COMMENTS = "/generalcomments/all";

export const HOMES_DETAILS = "/homes/details";

export const ADD_SINGLE_HO = "/homes/assignsinglehomeowner";

export const CHANGE_PASSWORD = "/changepassword";

export const HOME_EDIT_ALLOCATEDHOMES ="/homeowner/edit/";

export const PK_KEY = "pk_test_51OWI9QKPmkkpH9DkafAqFjuhcmSluKdyShjfsGvUX1d1iaKA2mu85SmZvuMOOeSES42t9YytMKAcen63oYlN0peH00lHQi4jEr";


export const CONTACT_VERIFICATION="/contact/send"

export const CONTACT_VERIFY="/contact/verify/"

export const PAYMENT_DETAILS ="/paymentdetails"
export const FORGOT_CHANGE="/forgetpassword/verify";
export const FORGOT_PASSWORD_EMAIL="/forgetpassword/email/";

export const FORGOT_PASSWORD="/forgetpassword";
export const FORGOT="/forgot";

export const SINGLE_HOME_ADD= "/homes/single";

export const PAUSE_SUBSCRIPTION = "/paymentdetails/pause";

export const RESUME_SUBSCRIPTION = "/paymentdetails/resume";

export const CARD_DETAILS = "/paymentdetails/addcard";

export const CARD_DELETE = "/paymentdetails/deletecard";

export const BILLING_DETAILS = "/paymentdetails/invoice";

export const SUBSCRIPTION_AUDIT="/group/audit/";

export const OFFLINE_PAYMENT="offlinepayment/offlinecompletepayment"

export const ACCOUNT_CONFIG = "/groupconfig/accounts"

export const AUDIT ="/audit"

export const OFFLINE_PAYMENT_VIEW="/offlinepayment/"

export const DASHBOARD_PRESIST="/presist"

export const CARD_PAYMENT_MANUAL = "/paymentdetails/addcardmanual"

export const PAYMENT_TYPE = "/paymentdetails/subscription"

export const CARD_DETAILS_REDIRECT = "/paymentdetails/addcard/details";

export const UPDATE_HOMEOWNER = "/homes/user";

export const HOMEOWNERDUES_BY_ID = "/homeownerdues/";

export const GET_ACTIVE_GIF="/customer/active"
