import React, { useState } from 'react';
import logo from "../../assets/img/logo.png";
import "../../App.css";
import CustomInputField from '../CustomInputField';
import CustomButton from '../CustomButton';
import { useForm } from "react-hook-form";
import { EMAIL_REGEX_PATTERN, EMAIL_REGEX_PATTERNS } from '../../utills/ApplicationRegex';
import { EMAIL_INVALID_MESSAGE, EMAIL_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, FORGOT_PASSWORD } from '../../utills/ApplicationRouting';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToastContainer from '../CustomToastContainer';
import CustomLoading from '../CustomLoading';
import Recaptcha from 'react-google-recaptcha';

const ForgotPasswordForm = () => {
  const { handleSubmit, control, formState: { errors }, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [captchaToken, setCaptchaToken] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const handleVerify = (token) => {
    if (token) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
    console.log(token);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (!isCaptchaValid) {
        toast.error("You must complete the CAPTCHA to proceed");
        setIsLoading(false);
        return;
      }
      const response = await axios.get(`${BASE_URL}${FORGOT_PASSWORD}/${data.email}`);
      if (response.status === 200) {
        setIsLoading(false);
        localStorage.setItem("email", data.email);
        toast.success("An email has been sent to your registered email address.");
        setTimeout(() => {
          setIsLoading(false);
          navigate("/", { replace: true });
        }, 4000);
      } else {
        toast.error(error.response.status);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false)
      // Handle error response, e.g., display an error message
      if (error.response && error.response.data) {
        const message = error.response.data.message;
        toast.error(message, { position: "top-center" });
        console.log("error.response.status" + error.response.status);
      } else {
        console.error(error); // Log the entire error for further debugging
        toast.error("An error occurred while processing your request.");
      }
    } 
  };

  return (
    <div className="w-full lg:w-full">
      <section>
        <CustomToastContainer/>
        {isLoading &&<CustomLoading/>}
        <div className="flex flex-col items-center justify-center mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <div
                className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 "
              >
                <img className="w-44 h-14" src={logo} alt="logo" />
              </div>
              <h1 className="font-bold leading-tight tracking-tight text-gray-900 text-center">
                Forgot Password
              </h1>
              <form className="space-y-4 md:space-y-6 " onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <CustomInputField
                    fieldName="email"
                    requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                    fieldPattern={EMAIL_REGEX_PATTERNS}
                    
                    patternErrorMessage={EMAIL_INVALID_MESSAGE}
                    fieldId="forgot-password-email"
                    fieldType="text"
                    fieldLabel="Email"
                    fieldControl={control}
                    fieldError={errors}
                  />
                </div>
                <div className="flex items-center justify-center">
                <Recaptcha
                    sitekey='6Lc6O5AqAAAAAAAiMlNjiSQNK1K-wsispctL-Ebh'
                    onChange={handleVerify}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <CustomButton
                    fieldType="submit"
                    buttonName="Forgot Password"

                  />
                </div>
                <hr />
                <div className="flex items-center justify-center">
                  <span>
                    <a
                      href="/"
                      className="text-sm font-medium text-primary-600 hover:underline  text-blue-600"
                    >
                      Sign in
                    </a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPasswordForm;