import {
  Button,
  Card,
  CardContent,
} from "@mui/material";
import React, {  useState,useEffect } from "react";
import { useNavigate,useParams } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import {
  MIN_LENGTH_VALIDATE_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  JOB_CATEGORY_INVAILD_MESSAGE,MAX_LENGTH_VALIDATE_MESSAGE_FIFTY ,
} from "../utills/ApplicationConstants";
import {
  ONLY_ALPHABETIC,
} from "../utills/ApplicationRegex";
import CustomInputField from "../components/CustomInputField";
import CustomButton from "../components/CustomButton";
import {  useForm } from "react-hook-form";
import { request } from "../services/AxiosConfig";
import {  JOB_CATEGORY ,BASE_URL } from "../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from"../components/CustomLoading"
import axios from "axios";
import CustomTooltipField from "../components/CustomTooltipField";




function EditJobCategory() {
  const [editData, setEditData] = useState([]);
  const { token } = useSelector((state) => state.hybridhoa);
    const[isLoading,setIsLoading]=useState(false)
  const { externalOccupationId } = useParams();

  
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const handleInputChange = (e) => {
    console.log(e.target.value);
    setEditData(e.target.value);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue, setError, clearErrors, watch,
  } = useForm();

  const getJobCategoryById = async (id) => {
    
    try {
      const storedToken = token;
    setIsLoading(true)

      const response = await axios.get(`${BASE_URL}${JOB_CATEGORY}/${externalOccupationId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
    setIsLoading(false)

        const data = response.data;
        setEditData(data.occupation);
        console.log("Occupation" + data.occupation);
      } else {
    setIsLoading(false)

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
    setIsLoading(false)

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getJobCategoryById();
  }, [])

  const onSubmitEdit = (e) => {
    setIsLoading(true)
    const postData = {
      occupation: editData,
    };
    const storedToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };
    if (editData === "" || editData === null) {
      setError("editjobCategory", {
        type: "manual",
        message: JOB_CATEGORY_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if(editData.trim().length < 2){
      setError("editjobCategory", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if(editData.trim().length > 50){
      setError("editjobCategory", {
        type: "manual",
        message: MAX_LENGTH_VALIDATE_MESSAGE_FIFTY,
      });
      setIsLoading(false);
      return;
    }
  

    request({
      url: `${JOB_CATEGORY}/${externalOccupationId}`,
      method: "put",
      data: postData,
      headers: config.headers,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setTimeout(() => {
  
            setIsLoading(false); 
            navigate(`/jobcategory`);
          }, 5000);
        }else{
          setIsLoading(false); 
  
        }
        
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error.response);
        if (error.response) {
          setIsLoading(false)
          console.log("error.response.status" + error.response.status);
        }
      });
      // setIsLoading(false)
    console.log(editData);
  };
  
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Edit Job Category" />

        {isLoading && <CustomLoading />}
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmitEdit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
         
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomTooltipField
            // requiredErrorMessage={JOB_CATEGORY_REQUIRED_MESSAGE}
            fieldPattern={ONLY_ALPHABETIC}
            patternErrorMessage={JOB_CATEGORY_INVAILD_MESSAGE}
            // minLength={2}
            // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            // maxLength={50}
            // maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_FIFTY}
            fieldName="editjobCategory"
            fieldId="editjobCategory"
            fieldType="text"
            fieldLabel="Job-Category"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
            value={editData}
            onInput={handleInputChange}
            multiline={true}
            tooltipMessage="You can edit the Job category here"

            
          />

             
 
            </section>
           
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default EditJobCategory;
