import React from 'react';
import CustomHeading from './CustomHeading';
import { Button, Card, CardContent, Tooltip } from '@mui/material';
import { CustomStyle } from './CustomStyle';
import { InfoOutlined } from '@mui/icons-material';
import CustomAccordion from './CustomAccordion';

function CustomViewWithTooltipWithMultipleButtons({ data, title ,buttons  }) {
  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <CustomHeading title={title} />
        <div className="md:full md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
        {buttons.map((button, index) => (
            <Button key={index} variant="contained" onClick={button.onClick}>
              <span className={CustomStyle.containedButtonStyle}>{button.label}</span>
            </Button>
          ))}
        </div>
      </div>
      <Card>
        <CardContent>
          {data.map((group, groupIndex) => (
            <div key={groupIndex}>
              <CustomAccordion heading={group.groupName} defaultExpanded={true}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-5 mt-5">
                  {group.items.map((item, itemIndex) => (
                    <div className="flex items-center" key={itemIndex}>
                      <div className={`${CustomStyle.borderedDiv} w-full md:w-1/2`}>
                        <div>
                          <h3 className="viewPageText">
                            <b>{item.label}:</b> {item.data}
                          </h3>
                        </div>
                      </div>
                      {item.text && (
                        <Tooltip title={item.text} placement='right' arrow>
                          <InfoOutlined />
                        </Tooltip>
                      )}
                    </div>
                  ))}
                </section>
              </CustomAccordion>
            </div>
          ))}
        </CardContent>
      </Card>
    </div>
  );
}

export default CustomViewWithTooltipWithMultipleButtons;
