import React from "react";
import { Controller } from "react-hook-form";
import { IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

const CustomInputFielddueandamount = ({
  fieldName,
  requiredErrorMessage,
  fieldPattern,
  patternErrorMessage,
  fieldId,
  fieldType,
  fieldLabel,
  fieldControl,
  fieldError,
  fieldTrigger,
  value,
  onChange,
  defaultValue,
  fieldRequiredIcon,
  disabled,
  multiline,
  numberOfRows,
  validator,
  minLength,
  minLengthErrorMessage,
  maxLength,
  maxLengthErrorMessage,
  isMinLength,
  validationRules,
  onInput,
  validate,
  tooltipTitle,
  tooltipMessage
}) => {
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );

  const handleInput = (event) => {
    let inputValue = event.target.value;

    // Remove all non-numeric characters except decimal points
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    // Remove leading zeros, but keep '0' if it's part of decimal number
    if (inputValue.startsWith('0')) {
        // If input starts with '0', handle it based on whether it is part of a decimal number
        if (inputValue.startsWith('0.') && inputValue.length > 2) {
            inputValue = inputValue.substring(1); // Remove leading zero before the decimal point
        } else {
            inputValue = inputValue.replace(/^0+/, ''); // Remove leading zeros
        }
    }

    // Prevent leading zero at the start of the input
    if (inputValue.startsWith('0')) {
        inputValue = inputValue.substring(1); // Remove the leading zero
    }

    // Ensure only one decimal point is allowed
    const parts = inputValue.split('.');
    if (parts.length > 2) {
        inputValue = parts[0] + '.' + parts.slice(1).join('');
    }

    // Ensure only up to 2 decimal places are allowed
    if (inputValue.includes('.')) {
        const [integerPart, decimalPart] = inputValue.split('.');
        if (integerPart === '' || integerPart === '0') {
            // If the integer part is empty or zero, only keep the decimal part, up to 2 digits
            inputValue = `${decimalPart.slice(0, 2)}`;
        } else {
            // Otherwise, include up to 2 decimal digits
            inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        }
    } else if (inputValue.length === 0) {
        inputValue = '';
    }

    // Set the cleaned value to the input field
    event.target.value = inputValue;
};


  

  return (
    <div className="flex items-center md:w-1/2 w-full">
      <Controller
        name={fieldName}
        control={fieldControl}
        defaultValue={defaultValue}
        rules={{
          required: requiredErrorMessage,
          pattern: {
            value: fieldPattern,
            message: patternErrorMessage,
          },
          minLength: {
            value: minLength,
            message: minLengthErrorMessage,
          },
          maxLength: {
            value: maxLength,
            message: maxLengthErrorMessage,
          },
          validate: validate && typeof validate === 'function' ? validate : undefined,
        }}
        render={({ field, fieldState }) => 
          <>
            <TextField
              fullWidth
              {...field}
              label={
                <CustomInputLabel
                  label={fieldLabel}
                  requiredIcon={fieldRequiredIcon}
                />
              }
              type={fieldType}
              id={fieldId}
              margin="dense"
              variant="standard"
              disabled={disabled}
              multiline={multiline}
              rows={numberOfRows}
              value={value}
              onInput={(e) => {
                handleInput(e);
                if (onInput) onInput(e);
              }}
              onBlur={(e) => field.onChange(e.target.value.trim())}
              inputProps={{
                style: {
                  fontSize: 16,
                  fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
                },
              }}
              error={fieldState.invalid}
              helperText={fieldState.invalid ? fieldState.error?.message : ""}
            />
          </>
        }
      />
      {tooltipMessage && (
        <Tooltip
          title={
            <Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default CustomInputFielddueandamount;
