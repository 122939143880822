import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  FormLabel,
  TextField,
} from "@mui/material";
import React, { useState ,useEffect } from "react";
import {
  DateCalendar,
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiTelInput } from "mui-tel-input";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomButton from "../components/CustomButton";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomDateEditPicker from "../components/CustomDateEditPicker";
import {
  CONTACT_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FROM_DATE_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
  LEASE_END_DATE_REQUIRED_MESSAGE,
  LEASE_START_DATE_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,MAX_LENGTH_TWO_FIFTY,FAX_INVALID_MESSAGE,ZIPCODE_INVALID_MESSAGE,ZIPCODE_MIN_LENGTH_MESSAGE,ZIPCODE_MAX_LENGTH_MESSAGE,ZIPCODE_REQUIRED_MESSAGE,CITY_INVALID_MESSAGE,STATE_INVALID_MESSAGE,STATE_REQUIRED_MESSAGE,
  CITY_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE_TEN
} from "../utills/ApplicationConstants";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import {
  EMAIL_REGEX_PATTERN,
  ONLY_ALPHABETIC,FAX_REGEX_PATTERN,EMAIL_REGEX_PATTERNS,ONLY_DIGITS
} from "../utills/ApplicationRegex";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL, HOMES ,UPDATE_HOMEOWNER} from "../utills/ApplicationRouting";
import { request } from '../services/AxiosConfig';
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading";
import CustomPhoneInput from "../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomAttachmentFile from "../components/CustomAttachmentFile";

function EditHomeOwner() {
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [leaseStartDate, setLeaseStartDate] = useState("");
  const [leaseEndDate, setLeaseEndDate] = useState("");
  const [dob, setDob] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [homeOwnerLoginId, setHomeOwnerLoginId] = useState("");
  const [homeDetails, setHomeDetails] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contact , setContact] = useState("");
  const [work, setWork] = useState("");
  const [home, setHome] = useState("");
  const [fax, setFax] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [address, setAddress] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const [addressForCommunication, setAddressForCommunication] = useState("");
  const navigate = useNavigate();
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };
  const { token } = useSelector(state => state.hybridhoa);

  const handleClick = () => {
    navigate(-1);
  };
  const {
    handleSubmit,
    control, formState,
    formState: { errors },
    setError, clearErrors,setValue,
  } = useForm();
  // const { homeOwnerLoginId } = useParams();
  const {homeId,email}=useParams();


  const getHomeDetailsById = async () => {
    setIsLoading(true);
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${HOMES}/${email}/${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      if (response.status === 200) {
        setIsLoading(false)
        const data = response.data;
        console.log(data);
        setHomeDetails(data);
        setHomeOwnerLoginId(data.homeOwnerLoginId);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        // setEmail(data.email);
        setContact(data.contact);
        setHome(data.home);
        setWork(data.work);
        setFax(data.fax);
        setValue("phoneNumber", data.contact)
        setNewEmail(data.email)
        setAddress(data.address)
        setAddressForCommunication(data.addressForCommunication)
        setCity(data.city)
        setState(data.state)
        setZipcode(data.zipcode)
        // setHomeIdRenter(data.homeId)
        console.log(data.homeOwnerLoginId);
        console.log(data.homeId);
        console.log(homeId);
      
      
        setIsLoading(false);
       
      } else {
        setIsLoading(false);
        console.log("error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomeDetailsById();
  }, [email]);

 

  const onSubmit = async(data) => {
  console.log("inside on submit");
    setIsLoading(true);

    
    if (firstName == "" || firstName == null) {
      setError("firstName", {
        type: "manual",
        message: FIRST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (firstName.trim().length < 2) {
      setError("firstName", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (firstName.trim().length > 60) {
      setError("firstName", {
        type: "manual",
        message: MAX_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (lastName == "" || lastName == null) {
      setError("lastName", {
        type: "manual",
        message: LAST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (lastName.trim().length < 1) {
      setError("lastName", {
        type: "manual",
        message: MIN_LENGTH_ONE_CHARACTERS,
      });
      setIsLoading(false);
      return;
    }

    if (lastName.trim().length > 60) {
      setError("lastName", {
        type: "manual",
        message: MAX_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!addressForCommunication) {
      setError("addressforcommunication", {
        type: "manual",
        message: ADDRESS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!city) {
      setError("city", {
        type: "manual",
        message: CITY_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!state) {
      setError("state", {
        type: "manual",
        message: STATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!zipcode) {
      setError("zipcode", {
        type: "manual",
        message: ZIPCODE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
   
 
    
    const putData={
      firstName:firstName,
      lastName:lastName,
      city:city,
      state:state,
      contact:contact,
      work:work,
      home:home,
      fax:fax,
      addressForCommunication:addressForCommunication,
      zipcode:zipcode,
      email:newEmail,
      homeOwnerLoginId:homeOwnerLoginId

    }
    request({
      url: `${UPDATE_HOMEOWNER}/${email}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setTimeout(() => {
            navigate("/homeowners");
          }, 4000);
          setIsLoading(false);
        }
        else {
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
    // request({ url: `${UPDATE_HOMEOWNER}/${email}`, method: "put", data: putData }).then(res => {
    //   setIsLoading(false);
    //   console.log(res);
    //   if (res.status == 200) {

    //     setTimeout(() => {
    //       setIsLoading(false);
    //       // navigate("/homeowners");
    //       navigate(-1);
    //     }, 4000);

    //   }
    // }).catch(error => {
    //   console.log(error.res);
    //   setIsLoading(false);
    //   if (error.response) {

    //     console.log("error.response.status" + error.response.status);


    //   }
    // });
  };


  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Edit HomeOwner" />
        {isLoading && <CustomLoading />}
        <CustomToastContainer />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Home Owner Details" />
              <CustomInputField
                // requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={firstName}
                tooltipMessage="Enter the first name "
                onInput={(event) => setFirstName(event.target.value)}
              />

              <CustomInputField
                fieldName="lastName"
                // requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
               
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                tooltipMessage="Enter the last name"
                fieldError={errors}
                value={lastName}
                fieldRequiredIcon="*"
                onInput={(event) => setLastName(event.target.value)}
              />
            
            </section>
            <br/>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Address Details" />
              <CustomInputField
                fieldName="address"
                // requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
                // fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                // patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="address"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={address}
                disabled={true}
                tooltipMessage={"Address of the Homeowner"}
              />
               <CustomInputField
                fieldName="addressforcommunication"
                // requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
                // fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                // patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="addressforcommunication"
                fieldType="text"
                fieldLabel="Address for communication"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={addressForCommunication}
                tooltipMessage={"Enter the Address for communication."}
                onInput={(event) => setAddressForCommunication(event.target.value)}
              />
               <CustomInputField
                fieldName="city"
                // requiredErrorMessage={CITY_REQUIRED_MESSAGE}
                // fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                // patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="city"
                fieldType="text"
                fieldLabel="City"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={city}
                tooltipMessage={"Enter the city."}
                onInput={(event) => setCity(event.target.value)}
              />
               <CustomInputField
                fieldName="state"
                // requiredErrorMessage={STATE_REQUIRED_MESSAGE}
                // fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                // patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="state"
                fieldType="text"
                fieldLabel="State"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={state}
                tooltipMessage={"Enter the State."}
                onInput={(event) => setState(event.target.value)}
              />
               <CustomInputField
                fieldName="zipcode"
                // requiredErrorMessage={ZIPCODE_REQUIRED_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={ZIPCODE_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                fieldId="zipcode"
                fieldType="text"
                fieldLabel="zipcode"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={zipcode}
                tooltipMessage={"Enter the zipcode."}
                onInput={(event) => setZipcode(event.target.value)}
              />
              </section>
            <br/>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Contact Details" />
              <CustomInputField
                fieldName="email"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                validator={false}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage="You can edit your email here"
                value={newEmail}
                // disabled={true}
                onInput={(event) => setNewEmail(event.target.value)}
              />


              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: CONTACT_REQUIRED_MESSAGE,
                  validate: {
                    isValidPhoneNumber: (value) =>
                      isValidPhoneNumber(value) || "Please provide a valid Contact number",
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      clearErrors={clearErrors}
                      country="US"
                      label="Cell"
                      tooltipMessage="You can edit your personal phone number here"
                      requiredIcon="*"
                      value={contact}
                      onChange={(value) => {
                        field.onChange(value);
                        setContact(value); // Update the contact state
                      }}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={
                        fieldState.invalid ? fieldState.error?.message : ""
                      }
                    />
                  </>
                )}
              />



              <div>
                <Controller
                  name="home"
                  control={control}
                  rules={{
                    validate: {
                      isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <CustomPhoneInput
                        isRequiredIcon={false}
                        isRequired={false}
                        fieldControl={control}
                        label="Home"
                        clearErrors={clearErrors}
                        country="US"
                        fieldError={errors}
                        value={home}
                        tooltipMessage="You can edit your home contact here"
                        onChange={(value) => {
                          field.onChange(value);
                          setHome(value); // Update the contact state
                        }}
                      />
                    </>
                  )}
                />
                {errors.home && (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {errors.home.message}
                  </FormHelperText>
                )}
              </div>
              <div>
                <Controller
                  name="work"
                  control={control}
                  rules={{
                    validate: {
                      isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <CustomPhoneInput
                        isRequiredIcon={false}
                        isRequired={false}
                        fieldControl={control}
                        label="Work"
                        clearErrors={clearErrors}
                        country="US"
                        tooltipMessage="You can edit your work contact here"
                        fieldError={errors}
                        value={work}
                        onChange={(value) => {
                          field.onChange(value);
                          setWork(value); // Update the contact state
                        }}
                      />
                    </>
                  )}
                />
                {errors.work && (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {errors.work.message}
                  </FormHelperText>
                )}
              </div>

              <CustomInputField
                fieldName="fax"
                // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={FAX_REGEX_PATTERN}
                patternErrorMessage={FAX_INVALID_MESSAGE}
                maxLength={10}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_TEN}
                validator={false}
                fieldId="fax"
                fieldType="text"
                fieldLabel="Fax"
                fieldControl={control}
                fieldError={errors}
                value={fax}
                tooltipMessage="You can edit your fax number here"
                onInput={(event) => setFax(event.target.value)}
              // fieldRequiredIcon="*"
              />


            </section>
            <br />
            
            <br />
            <br />
            <div className="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Submit" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default EditHomeOwner;
