import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import {
  DESCRIPTION_REQUIRED_MESSAGE,
  DUE_DATE_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MAX_LENGTH_TWO_FIFTY,
  MIN_LENGTH_VALIDATE_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  DATE_OF_BIRTH_REQUIRED_MESSAGE,
  ORGANIZATION_REQUIRED_MESSAGE,
  ROLE_REQUIRED_MESSAGE,
  ROLE_INVALID_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  SSN_REQUIRED_MESSAGE,
  ROUTING_NUMBER_REQUIRED_MESSAGE,
  ACCOUNT_NUMBER_REQUIRED_MESSAGE,
  SSN_INVALID_MESSAGE,
  ROUTING_NUMBER_INVALID_MESSAGE,
  ACCOUNT_NUMBER_INVALID_MESSAGE,
  WEBSITE_INVALID_MESSAGE,
  ACCOUNT_NUMBER_MINIMUM_MESSAGE,
  SSN_MIN_LENGTH,
} from "../utills/ApplicationConstants";
import {
  ALPHANUMBERIC_ALL_SPECIAL_CHARACTERS,
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  ALPHANUMERIC_PUNCHUCATION_SLASH_UNDERSCORE,
  ALPHANUMERIC_PUNCTUATION_SLASH_UNDERSCORE_WITH_SPACE,
  FIVE_DIGITS_TWO_DECIMAL,
  ONLY_ALPHABETIC,
  ONLY_ALPHABETIC_REGEX_PATTERN,
  EMAIL_REGEX_PATTERN,
  ONLY_DIGITS,
  WEBSITE,
} from "../utills/ApplicationRegex";
import CustomInputField from "../components/CustomInputFieldWithHelperText";
import CustomButton from "../components/CustomButton";
import { Controller, useForm } from "react-hook-form";
import CustomDateEditPicker from "../components/CustomDateEditPicker";
import dayjs from "dayjs";
import CustomRadioButton from "../components/CustomRadioButton";
import { request } from "../services/AxiosConfig";
import { BASE_URL, PAYMENT_DETAILS } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomInputFieldPreventPaste from "../components/CustomInputFieldWithHelperTextPastePrevent";
import CustomLoading from "../components/CustomLoading";
import { AccountBalanceWalletTwoTone, InfoOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function EditPaymentGateway() {
  const [dateofBirth, setDateofBirth] = React.useState("");
  const { token } = useSelector((state) => state.hybridhoa);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [ssn, setSsn] = React.useState("");
  const [role, setRole] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [routingNumber, setRoutingNumber] = React.useState("");
  const [bussinessEmail, setBussinessEmail] = React.useState("");
  const [hoaDescription, setHoaDescription] = React.useState("");
  const [oragnizationType, setOragnizationType] = React.useState("");
  const { groupId } = useParams();
  const [showConfirmAccountNumber, setShowConfirmAccountNumber] =
    useState(false);
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [confirmAccount, setConfirmAccount] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [routing, setRouting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const accountNumberPattern = /^\d+$/;
  const routingNumberPattern = /^\d+$/;

  const handleorganizationTypeChange = (event) => {
    const selectedValue = event.target.value;
    setOragnizationType(selectedValue);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleSsnChange = (e) => {
    setSsn(e.target.value);
  };
  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };
  const handleBussinessEmailChange = (e) => {
    setBussinessEmail(e.target.value);
  };
  const handleHoaDescriptionChange = (e) => {
    setHoaDescription(e.target.value);
  };
  const handleRoutingNumberChange = (e) => {
    setRoutingNumber(e.target.value);
    if (!routingNumber) {
      setError("routingNumber", {
        type: "manual",
        message: "Routing number is required",
      });
    } else {
      clearErrors("routingNumber");
    }
  };
  const handleAccountNumberChange = (event) => {
    const newValue = event.target.value;
    setAccountNumber(event.target.value);
    setShowConfirmAccountNumber(newValue !== "");
    if (!accountNumber) {
      setError("accountNumber", {
        type: "manual",
        message: "Account number required",
      });
    } else {
      clearErrors("accountNumber");
    }
  };

  const handleConfirmAccountNumberChange = (event) => {
    setConfirmAccountNumber(event.target.value);
    if (event.target.value) {
      if (event.target.value !== accountNumber) {
        setError("confirmAccountNumber", {
          type: "manual",
          message: "The confirm account number entered does not match the account number. Please ensure both account numbers match",
        });
      } else {
        clearErrors("confirmAccountNumber");
      }
    }
  };

  const getPaymentDetails = async () => {
    console.log(groupId);
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${PAYMENT_DETAILS}/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setIsLoading(false);
        console.log(data);
        setFirstName(data.legalFirstName);
        setLastName(data.legalLastName);
        setOragnizationType(data.orgType);
        setRole(data.legalRole);
        setAccountNumber(data.accountNumber);
        setBussinessEmail(data.businessEmail);
        setSsn(data.ssn);
        setHoaDescription(data.hoaDesc);
        setDateofBirth(data.dob);
        setRoutingNumber(data.routingNumber);
        console.log(data.orgType);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [groupId]);

  const onSubmit = (data) => {
    if (!dateofBirth) {
      setError("dateofBirth", {
        type: "manual",
        message: DUE_DATE_REQUIRED_MESSAGE,
      });

      return;
    }
    if (!firstName) {
      setError("firstName", {
        type: "manual",
        message: FIRST_NAME_REQUIRED_MESSAGE,
      });

      return;
    }
    if (firstName.trim().length < 2) {
      setError("firstName", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });

      return;
    }
    if (firstName.trim().length > 250) {
      setError("firstName", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });

      return;
    }



    if (!lastName) {
      setError("lastName", {
        type: "manual",
        message: LAST_NAME_REQUIRED_MESSAGE,
      });

      return;
    }

    if (lastName.trim().length < 2) {
      setError("lastName", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });

      return;
    }
    if (lastName.trim().length > 250) {
      setError("lastName", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });

      return;
    }

    if (!ssn) {
      setError("ssn", {
        type: "manual",
        message: SSN_REQUIRED_MESSAGE,
      });

      return;
    }

    if (ssn.trim().length < 4) {
      setError("ssn", {
        type: "manual",
        message: SSN_MIN_LENGTH,
      });

      return;
    }
    if (ssn.trim().length > 4) {
      setError("ssn", {
        type: "manual",
        message: SSN_MIN_LENGTH,
      });

      return;
    }
    if (!hoaDescription) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });

      return;
    }

    if (hoaDescription.trim().length < 2) {
      setError("description", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });

      return;
    }
    if (hoaDescription.trim().length > 250) {
      setError("description", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });

      return;
    }
    if (!role) {
      setError("role", {
        type: "manual",
        message: ROLE_REQUIRED_MESSAGE,
      });

      return;
    }
    if (role.trim().length < 2) {
      setError("role", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });

      return;
    }
    if (role.trim().length > 250) {
      setError("role", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });

      return;
    }


    if (bussinessEmail) {
      if (bussinessEmail.trim().length < 2) {
        setError("bussinessEmail", {
          type: "manual",
          message: MIN_LENGTH_VALIDATE_MESSAGE,
        });

        return;
      }
    }

    if (bussinessEmail) {
      if (bussinessEmail.trim().length > 250) {
        setError("bussinessEmail", {
          type: "manual",
          message: MAX_LENGTH_TWO_FIFTY,
        });

        return;
      }
    }




    if (!routingNumber) {
      setError("routingNumber", {
        type: "manual",
        message: ROUTING_NUMBER_REQUIRED_MESSAGE,
      });

      return;
    }
    if (!routingNumberPattern.test(routingNumber)) {
      setError("routingNumber", {
        type: "manual",
        message: ROUTING_NUMBER_INVALID_MESSAGE,
      });
      return;
    }

    if (!accountNumber) {
      setError("accountNumber", {
        type: "manual",
        message: ACCOUNT_NUMBER_REQUIRED_MESSAGE,
      });

      return;
    }

    if (accountNumber.trim().length < 2) {
      setError("accountNumber", {
        type: "manual",
        message: ACCOUNT_NUMBER_MINIMUM_MESSAGE,
      });

      return;
    }
    if (!accountNumberPattern.test(accountNumber)) {
      setError("accountNumber", {
        type: "manual",
        message: ACCOUNT_NUMBER_INVALID_MESSAGE,
      });
      return;
    }
    if (showConfirmAccountNumber) {
      if (!confirmAccountNumber) {
        setError("confirmAccountNumber", {
          type: "manual",
          message: "Confirm account number is required",
        });
        return;
      }
    }
    if (confirmAccountNumber) {
      if (accountNumber !== confirmAccountNumber) {
        setError("confirmAccountNumber", {
          type: "manual",
          message: "The confirm account number entered does not match the account number. Please ensure both account numbers match",
        });
        return;
      }
    }



    setIsLoading(true);
    // console.log("Form Data:", data);
    console.log("First Name:", firstName);
    console.log("Last Name:", lastName);
    console.log("Date of Birth:", dateofBirth);
    console.log("Due Type:", oragnizationType);
    console.log("Role:", role);
    console.log("Bussiness Email:", bussinessEmail);
    console.log("Description:", hoaDescription);
    console.log("SSN:", ssn);
    console.log("Routing Number:", routingNumber);
    console.log("Account Number:", accountNumber);

    const postData = {
      legalFirstName: firstName.trim(),
      legalLastName: lastName.trim(),
      orgType: oragnizationType,
      legalRole: role.trim(),
      hoaDesc: hoaDescription.trim(),
      ssn: ssn.trim(),
      routingNumber: routingNumber,
      dob: dateofBirth ? dayjs(dateofBirth).format("YYYY-MM-DD") : "",
      businessEmail: bussinessEmail,
      accountNumber: accountNumber,
    };

    request({
      url: `${PAYMENT_DETAILS}/${groupId}`,
      method: "put",
      data: postData,
    })
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setTimeout(() => {
            navigate(`/paymentgatewayview`);
          }, 4000);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
        }
      });
  };

  const dobDate = dayjs().subtract(18, "year").endOf("day");
  const handlePublishDateChange = (selectedPublishDate) => {
    setDateofBirth(selectedPublishDate);
    clearErrors("dueDate");
  };
  const renderTooltip = (message) => (
    <Tooltip
      title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>}
      placement="right"
    >
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
  return (
    <div>
      {/* <CustomLoading/> */}
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        {isLoading && <CustomLoading />}
        <CustomHeading title="Edit Payment Gateway" />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>

      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Basic Details" />

              <CustomInputField
                fieldName="firstName"

                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}

                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={firstName}
                helperText="Enter your First name exactly as it is recorded with the IRS."
                onInput={handleFirstNameChange}
                tooltipMessage={
                  "Enter your First name exactly as it is recorded with the IRS."
                }
              />
              <CustomInputField
                fieldName="lastName"
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={lastName}
                onInput={handleLastNameChange}
                helperText="Enter your Last name exactly as it is recorded with the IRS."
              />
              <CustomDateEditPicker
                fieldName="dateofBirth"
                label="Date of Birth"
                control={control}
                maxDate={dobDate}
                onChange={handlePublishDateChange}
                fieldRequiredIcon="*"
                value={dateofBirth}
                tooltipMessage={"Enter your Date of Birth"}
              />
              <CustomInputField
                fieldName="ssn"
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={SSN_INVALID_MESSAGE}
                fieldId="ssn"
                fieldType="text"
                fieldLabel="SSN"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                helperText="Last 4 digits of Social Security number"
                value={ssn}
                onInput={handleSsnChange}
                tooltipMessage={
                  "Enter your social security number. It should be secured"
                }
              />

              <CustomHeading title="Organization Details" />
              <div className="flex items-center md:w-1/2 ">
                <div className="w-full ">
                  <Controller
                    name="organizationType"
                    control={control}

                    fieldRequiredIcon="*"
                    render={({ field, fieldState }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={fieldState.invalid}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Please Select Organization Type <span style={{ color: "red" }}> * </span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={oragnizationType}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            handleorganizationTypeChange(e);
                          }}
                          label="Please Select Organization Type"
                        >
                          <MenuItem value="Non-Profit Organization">
                            Non-Profit Organization
                          </MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {fieldState.invalid ? fieldState.error?.message : ""}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div>{renderTooltip("Select one of Organization")}</div>
              </div>

              <CustomInputField
                fieldName="description"
                fieldId="description"
                fieldType="text"
                fieldLabel="Hoa Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}

                value={hoaDescription}
                onInput={handleHoaDescriptionChange}
                tooltipMessage={"Enter description"}
              />
              <CustomHeading title="Role" />
              <CustomInputField
                fieldName="role"
                fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                patternErrorMessage={ROLE_INVALID_MESSAGE}
                fieldId="role"
                fieldType="text"
                fieldLabel="Role"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={role}
                onInput={handleRoleChange}
                tooltipMessage={"Enter your Role"}
              />
              <CustomHeading title="Business website" />
              <CustomInputField
                fieldName="Bussiness Email"
                fieldPattern={WEBSITE}
                patternErrorMessage={WEBSITE_INVALID_MESSAGE}
                fieldId="bussinessEmail"
                fieldType="text"
                fieldLabel="Business Website"
                fieldControl={control}
                fieldError={errors}
                onInput={handleBussinessEmailChange}
                value={bussinessEmail}
                tooltipMessage={"Enter your business website url"}
              />

              <CustomHeading title="Banking Details" />

              <div className="flex items-center md:w-1/2 w-full">
                <Controller
                  name="accountNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type={newPassword ? "text" : "password"}
                      variant="standard"
                      label={<span>
                        Account Number
                        <span style={{ color: "red" }}> * </span>
                      </span>}
                      error={!!fieldState?.error}
                      value={accountNumber}
                      onChange={handleAccountNumberChange}
                      helperText={fieldState?.error?.message}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setNewPassword(!newPassword)}
                              edge="end"
                            >
                              {newPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "14px" }}>
                      Provide vaild account number for adding a payment . It
                      should be secured{" "}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </div>

              <div className="flex items-center md:w-1/2 w-full">
                <Controller
                  name="routingNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type={routing ? "text" : "password"}
                      variant="standard"
                      value={routingNumber}
                      onChange={handleRoutingNumberChange}
                      label={<span>
                        Routing Number
                        <span style={{ color: "red" }}> * </span>
                      </span>}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setRouting(!routing)}
                              edge="end"
                            >
                              {routing ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: "14px" }}>
                      Enter your routing number it should be secured{" "}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <IconButton size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </div>

              {showConfirmAccountNumber && (
                <div className="flex items-center md:w-1/2 w-full">
                  <Controller
                    name="confirmAccountNumber"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type={confirmAccount ? "text" : "password"}
                        variant="standard"
                        label={<span>
                          Confirm Account Number
                          <span style={{ color: "red" }}> * </span>
                        </span>}
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        sx={{ width: "100%" }}
                        value={confirmAccountNumber}
                        onChange={handleConfirmAccountNumberChange}
                        onPaste={(e) => e.preventDefault()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setConfirmAccount(!confirmAccount)
                                }
                                edge="end"
                              >
                                {confirmAccount ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: "14px" }}>
                        Confirm your account number
                      </Typography>
                    }
                    placement="right"
                    arrow
                  >
                    <IconButton size="small">
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default EditPaymentGateway;
