import {
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInputFieldFullWidth from "../components/CustomInputFieldFullWidth";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import {
  AMOUNT_EXCEEDED,
  AMOUNT_INVALID_MESSAGE,
  AMOUNT_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REIMBURSE_COMMENT,
} from "../utills/ApplicationConstants";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  HO,
  HOMEOWNERDUES_BY_ID,
  REIMBURSED_DUES,
} from "../utills/ApplicationRouting";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from "../components/CustomLoading";
import CustomInputFielddueandamountFullWidth from "../components/CustomInputFielddueandamountFullWidth";
import CustomMaterialTableForReimbursed from "../components/CustomMaterialTableForReimbursed";
function ApplyAddDueReimburse() {
  const { token, homeId } = useSelector((state) => state.hybridhoa);
  const [selectedRowId, setSelectedRowId] = useState(""); 
  const [selectedHomeOwnerLoginId, setSelectedHomeOwnerLoginId] = useState("");
  const [comment, setComment] = useState("");
  const [waivedAmount, setWaivedAmount] = useState("");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const [selectedDueAmount, setSelectedDueAmount] = useState("");
  const [title, setTitle] = useState("");
  const [dueType, setDueType] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const { homeOwnerDuesId } = useParams();
  

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue, setError, clearErrors, watch, reset
  } = useForm();


  const [homeOwnerDetailsId, setHomeOwnerDetailsId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dueData, setDueData] = useState([]);
  const [dueHomeId, setDueHomeId] = useState("")
  const [homeOwnerLoginId, setHomeOwnerLoginId] = useState("")
  const [homeAddresses, setHomeAddresses] = useState([]);

  //   const handleApply = (row) => {
  //     console.log("Row data:", row.original || row); // Adjust according to your row structure
  //     const data = row.original || row; // Fallback to `row` if `row.original` is not available
  //     navigate(`/applyeaddreimburse/${data.homeOwnerDuesId}`)
  //     setSelectedRowId(data.homeOwnerDuesId);
  //     setDueHomeId(data.homeId);
  //     setSelectedDueAmount(data.dueAmount);
  //     setSelectedHomeOwnerLoginId(data.homeOwnerLoginId);
  //     setTitle(data.title);
  //     setDueType(data.dueType);
  //     setDueAmount(data.dueAmount);
  //     console.log("Due Amount:", data.dueAmount);



  //     // Open a modal or dialog
  //     setApplyOpen(true);
  // };

  const getSingleReimburseDue = async () => {
    try {
      const storedToken = token;
      setIsLoading(false);

      const response = await axios.get(
        `${BASE_URL}${REIMBURSED_DUES}/due/${homeOwnerDuesId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);
      if (response.status == 200) {
        const data = response.data;
        setDueHomeId(data.homeId);
        setSelectedDueAmount(data.dueAmount);
        setSelectedHomeOwnerLoginId(data.homeOwnerLoginId);
        setTitle(data.title);
        setDueType(data.dueType);
        setDueAmount(data.dueAmount);
        console.log(data);

        setIsLoading(false);

        // setType(data);
        console.log(data);
        // setHomeAddressOptions(data);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSingleReimburseDue();
  }, []);

  const onSubmit = async (data) => {
    console.log("homeIdff", homeOwnerDetailsId);
    const parsedWaivedAmount = parseFloat(waivedAmount);
    const parsedDueAmount = parseFloat(dueAmount);

    let waivedAmount1 = waivedAmount;

    // Check if dueAmount is a string and its length is greater than 1
    if (typeof waivedAmount1 === 'string' && waivedAmount1.length > 1) {
      // Trim leading zeros
      waivedAmount1 = waivedAmount1.replace(/^0+/, '');
    }

    if (parsedWaivedAmount > parsedDueAmount) {
      setError("waivedOffAmount", {
        type: "manual",
        message: AMOUNT_EXCEEDED
      });

      setIsLoading(false);
      return;
    }
    if (!waivedAmount || parseFloat(waivedAmount) === 0) {
      setError("waivedOffAmount", {
        type: "manual",
        message: "Please provide Valid Due Amount (i.e xxxxx.xx)",
      });
      return;
    }

    setIsLoading(true)
    console.log("selected");
    const payload = {
      totalAmount: selectedDueAmount,
      waivedAmount: waivedAmount1,
      comment: comment,
      appliedTo: selectedHomeOwnerLoginId,
      homeOwnerDuesId: homeOwnerDuesId,
      title: title,
      dueType: dueType,
      appliedToHomeId: dueHomeId,

    };

    request({
      url: `${REIMBURSED_DUES}`,
      method: "post",
      data: payload,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setWaivedAmount('');
          setComment('');
          setTimeout(() => {

            setIsLoading(false);
            navigate('/applyreimbursedue')
          }, 4000)
          setIsLoading(false);

        } else {
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });

  };

  const handleWaivedChange = (event) => {
    setWaivedAmount(event.target.value);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <div>
         {isLoading && <CustomLoading />}
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Apply Waived Off" />
        <CustomToastContainer />
     

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>

      {/* <div class="grid xl:grid-cols-2"> */}
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomInputFielddueandamountFullWidth
                fieldName="waivedOffAmount"
                fieldId="waivedOffAmount"
                fieldType="text"
                fieldLabel="Waived Off Amount"
                requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                onInput={handleWaivedChange}
                value={waivedAmount}
                invalidOption="invalid home address"
                tooltipMessage="Enter the Amount to be waived off"
              />
              <br />
              <br />

              <CustomInputFieldFullWidth
                fieldName="reimburseComment"
                fieldType="text"
                fieldId="reimburseComment"
                fieldLabel="Waived Off Comment"
                requiredErrorMessage={REIMBURSE_COMMENT}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={500}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                fieldControl={control}
                fieldError={errors}
                multiline={true}
                numberOfRows={1}
                fieldRequiredIcon="*"
                onInput={handleCommentChange}
                value={comment}
                tooltipMessage="Enter your comment here"

              />


            </section>
            {/* </div> */}
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Apply" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default ApplyAddDueReimburse;
