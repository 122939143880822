import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileSaver from "file-saver"; // Import FileSaver library
import axios from "axios";
import { BASE_URL_IMAGE } from "../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import { CustomStyle } from "./CustomStyle";

const base64ToBlob = (base64Data, contentType) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const getContentType = (fileUrl) => {
  const extension = fileUrl.split(".").pop().toLowerCase();

  switch (extension) {
    case "pdf":
      return "application/pdf";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "csv":
      return "text/csv";
    case "doc":
      return "application/msword";
    case "xls":
      return "application/vnd.ms-excel";
    default:
      return "application/octet-stream";
  }
};

function AttachmentList({ docname, fileUrl, documentnamed, uploadedTimes }) {
  const { token } = useSelector((state) => state.hybridhoa);

  console.log(docname);
  console.log(documentnamed);
  console.log(uploadedTimes);

  const handleSubmit = async (fileUrl, documentName) => {
    if (!fileUrl) {
      console.log("error: no file URL provided");
      return;
    }

    try {
      const response = await axios.get(`${BASE_URL_IMAGE}/${fileUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
          
        // },
        responseType: "arraybuffer",
      });

      const contentType = getContentType(fileUrl);
      const fileBlob = new Blob([response.data], { type: contentType });

      // FileSaver.saveAs(fileBlob, documentName);
      // const downloadLink = document.createElement('a');
      // downloadLink.href = URL.createObjectURL(fileBlob);
      // downloadLink.download = docname; // Set the filename as desired
      // downloadLink.click();
      const fileURL = URL.createObjectURL(fileBlob);
      const isImage = ['image/png', 'image/jpeg', 'image/jpg'].includes(contentType);

      if (contentType.startsWith("image/")) {
    
        // const fileURL = URL.createObjectURL(fileBlob);
        const newTab = window.open("");
        newTab.document.write(`
        <div style="display: flex; flex-direction: row; align-items: flex-end; position: fixed; top: 1px; right: 60px;">
          <div id="downloadButton" class="btn btn-secondary " style="  right: 0px; border: none;  ;">
          <svg width="64px" height="64px" viewBox="-15.12 -15.12 54.24 54.24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24" fill="white"></rect> <path d="M5 12V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V12" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 3L12 15M12 15L16 11M12 15L8 11" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </div>
            <div id="closeButton" class="btn btn-secondary " style="border: none;  right: 0px">
            <svg width="64px" height="64px" viewBox="-14.4 -14.4 52.80 52.80" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
          </div>
          </div>
        <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
       
          <img src="${fileURL}" style="max-width: 70%; max-height: 70%; height: auto;">
         </div>
      `);

        // Add download functionality to the button
        newTab.document.getElementById("downloadButton").onclick = () => {
          const downloadLink = document.createElement('a');
          downloadLink.href = fileURL;
          downloadLink.download = docname;
          downloadLink.click();
        };
        newTab.document.getElementById("closeButton").onclick = () => {
          newTab.close();
        };
      } else {
        // For non-image files, trigger the download directly
        // FileSaver.saveAs(fileBlob, documentnamed);
        const fileURL = URL.createObjectURL(fileBlob);
        window.open(fileURL);
      }
} catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-29 !important">
        <div className={CustomStyle.viewPageText}>
          <div className="break-all">
            {fileUrl && (
            <>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => handleSubmit(docname, documentnamed)}
              >
                <FileDownloadIcon />
              </a>
            </>
            )}
            {fileUrl && (
<>
            <span>{docname}</span>

            <span> on {uploadedTimes}</span>
            </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AttachmentList;

// import React from "react";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import FileSaver from "file-saver"; // Import FileSaver library
// import axios from "axios";
// import { BASE_URL, BASE_URLS } from "../utills/ApplicationRouting";
// import { useSelector } from "react-redux";
// import { CustomStyle } from "./CustomStyle";

// const base64ToBlob = (base64Data, contentType) => {
//   const sliceSize = 1024;
//   const byteCharacters = atob(base64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   return new Blob(byteArrays, { type: contentType });
// };

// function AttachmentList({
//   docname,
//   TEST,
//   fileUrl,
//   documentnamed,
//   uploadedTimes,
// }) {
//   const { token } = useSelector((state) => state.hybridhoa);

//   const handleSubmit = async (fileUrl) => {
//     if (!fileUrl) {
//       console.log("error no file");
//       return;
//     }

//     try {
//       const response = await axios.get(`${BASE_URLS}/${fileUrl}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log(token);
//       if (fileUrl) {
//         console.log("status check");
//        const pdfBlob = base64ToBlob([response.data.renter], "application/pdf");
//         console.log(pdfBlob);
//         FileSaver.saveAs(pdfBlob, fileUrl);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <div>
//       <div className="grid grid-cols-1 md:grid-cols-1 gap-29 !important">
//         <div className={CustomStyle.viewPageText}>
//           {/* <label className="md:pr-2">Attachments:</label> */}
//           {Array.isArray(fileUrl) &&
//             fileUrl.map((fileUrl, index) => (
//               <div key={index} className="break-all">
//                 {fileUrl.length > index && (
//                   <>
//                     <a
//                       style={{ cursor: "pointer" }}
//                       onClick={() => handleSubmit(documentnamed[index])}
//                     >
//                       {/* Pass the correct document name */}
//                       <FileDownloadIcon />
//                     </a>
//                   </>
//                 )}
//                 {docname.length > index && <span>{docname[index]}</span>}
//                 {uploadedTimes.length > index && (
//                   <span> on {uploadedTimes[index]}</span>
//                 )}
//                 {/* <hr className="my-4 border-gray-300 md:hidden" /> */}
//               </div>
//             ))}
//         </div>
//       </div>
//     </div>
//   );
// }
