import {
  Close,
  Delete,
  DeleteForever,
  DeleteOutline,
  EditNoteOutlined,
  EditOutlined,
  EmailOutlined,
  FileDownload,
  FileUploadOutlined,
  ListAlt,
  Telegram,
  InfoOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import CustomInputField from "../components/CustomInputField";
import {
  ADDRESS_INVALID_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  JOB_CATEGORY_INVAILD_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE_FIFTY,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  EMAIL_REGEX_PATTERN,
  ONLY_ALPHABETIC,
} from "../utills/ApplicationRegex";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomModel from "../components/CustomModel";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import {
  BASE_URL,
  HOMES,
  HOME,
  UPLOADS,
  HOME_UPLOAD,
  HOMES_DETAILS,
  ADD_SINGLE_HO,
  SINGLE_HOME_ADD,
  JOB_CATEGORY,
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomAutoComplete from "../components/CustomAutoComplete";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import moment from "moment";
import CustomLoading from "../components/CustomLoading";
import HomesAutoComplete from "../components/Homesautocomplete";
import { original } from "@reduxjs/toolkit";

function AllocatedHome() {
  const navigate = useNavigate();
  const [addOpen, setAddOpen] = useState(false);
  const [addSingleOpen, setAddSingleOpen] = useState(false);
  const [singleHoOpen, setSingleHoOpen] = useState(false);
  const [singleHoValidationOpen, setSingleHoValidationOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteHomeIdOpen, setDeleteHomeIdOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [unassignedviewOpen, setUnassignedViewOpen] = useState(false);
  const [deleteEmailOpen, setDeleteEmailOpen] = useState(false);
  const [deleteEmailErrorOpen, setDeleteEmailErrorOpen] = useState(false);
  const [resendOpen, setResendOpen] = useState(false);
  const fileTypes = ["xlsx"];
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const [datas, setData] = useState("");
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const { email } = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedHomeOption, setSelectedHomeOption] = useState("option1");
  const [homesData, setHomesData] = useState([]);
  const [homeId, setHomeId] = useState("");
  const [originalData,setOriginalData]=useState([])
  const [editOpen, setEditOpen] = useState(false);
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [editData, setEditData] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [singleHoIsPresentOpen, setSingleHoIsPresentOpen] = useState(false);
  const handleFileChange = (file) => {
    if (file.size > 10 * 1024 * 1024) {
      // Check if file size is larger than 10MB
      setErrorMessage("File size exceeds the maximum limit of 10MB.");
      setFile(null); // Clear the selected file
      return; // Exit function
    }
    setErrorMessage("");
    setFile(file);
  };

  

  const homeAddess = [
    { label: "HomeReq3" },
    { label: "PrimaryGroup5", id: 2 },
    { label: "demoaddress", id: 3 },
    { label: "cutomeraddress", id: 4 },
    { label: "resend1", id: 5 },
    { label: "resend2", id: 6 },
    { label: "HomeEmailAddress1", id: 7 },
    { label: "HomeEmailAddress", id: 8 },
    { label: "assign email", id: 9 },
    { label: "adresstharun", id: 10 },
  ];



  const handleAddClickOpen = () => {
    navigate(`/addhomes`)
  };

  const handleSingleHoClickOpen = () => {
    navigate(`/assignhome`)
    console.log("insie assign homes");
  };

  const handleSingleHoValidationClickOpen = () => {
    setSingleHoValidationOpen(true);
    console.log("insie assign homes2");

  };
  const handleSingleHoClose = (status) => {
    if (status === 200) {
      setSingleHoOpen(false);
    }
  };
  const handleSingleHoValidationClose = () => {
    setSingleHoValidationOpen(false);
  };
  const [selectedRowEmail, setSelectedRowEmail] = useState("");
  const [selectedRowHomeId, setSelectedRowHomeId] = useState("");
  const handleDeleteOpen = (selectedRow) => {
    setSelectedRowEmail(selectedRow.email);
    console.log(selectedRow.email);
    setDeleteOpen(true);
  };

  const handleAddressDeleteOpen = (selectedRow) => {
    setSelectedRowHomeId(selectedRow.homeId);
    console.log(selectedRow.homeId);
    setDeleteHomeIdOpen(true);
  };
  const handleWarningViewClick = (selectedRow) => {
    setSelectedRowEmail(selectedRow.email);
    console.log(selectedRow.email);
    setViewOpen(true);
  };

  const handleWarningUnassignedViewClick = (selectedRow) => {
    setSelectedRowEmail(selectedRow.email);
    console.log(selectedRow.email);
    setUnassignedViewOpen(true);
  };


  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleHomeIdDeleteClose = () => {
    setDeleteHomeIdOpen(false);
  };
  const handleDeleteEmailClose = () => {
    setDeleteEmailOpen(false);
  };
  const handleDeleteEmailErrorClose = () => {
    setDeleteEmailErrorOpen(false);
  };

  const handleViewClose = () => {
    setViewOpen(false);
  };

  const handleUnassignedViewClose = () => {
    setUnassignedViewOpen(false);
  };
 
  const handleResendClose = () => {
    setResendOpen(false);
  };

  const handleInputChange = (e) => {
    console.log(e.target.value);
    setEditData(e.target.value);
  };

  const getHomes = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setOriginalData(data)
        // Filter out homes with null homeOwnerDetails
        const filteredData = data.filter((home) => home.isAssigned === "0");
        setHomesData(filteredData);

        const homeIds = filteredData.map((home) => home.homeId);

        homeIds.forEach((singleHomeId) => {
          setHomeId(singleHomeId);

          console.log("Individual HomeId:", singleHomeId);
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);
  const handleViewClick = (selectedRow) => {
    console.log();
    navigate(`/homes/${selectedRow.original.email}/${selectedRow.original.homeId}`);
  };



  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const [allcoatedHomeData, setAllocatedHomeData] = useState([]);
  const [response, setResponse] = useState([]);

  

  const getSingleHomeById = async (id) => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${SINGLE_HOME_ADD}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        setUpdateRes(data.homeId);
        setEditData(data.address);
        console.log("Occupation" + data.homeId);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClickOpen = (selectedRow) => {
    console.log("Occupation Id " + selectedRow);
    navigate(`/homeowner/edit/${selectedRow.original.homeId}`)
    setEditOpen(true);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.homeId);
    setSelectedRowId(selectedRow.original.homeId);
    getSingleHomeById(selectedRow.original.homeId);
  };

  
  const onSubmitDelete = async () => {
    const DeleteByEmail = selectedRowEmail;
    console.log(selectedRowEmail);
    // return;
    request({ url: `${HOMES}/${DeleteByEmail}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getAllocatedHomeDetails();
        getHomes();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitHomeIdDelete = async () => {
    const DeleteByHomeId = selectedRowHomeId;
    console.log(selectedRowHomeId);
    // return;
    request({ url: `${HOMES}${HOME}/${DeleteByHomeId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getHomes();
        getAllocatedHomeDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onSubmitResend = (data) => {
    console.log("Resend successfully");
  };

 
  const handleDeleteEmailOpen = (selectedRow) => {
    setDeleteEmailOpen(true);
  };

  const handleDeleteEmailErrorOpen = (selectedRow) => {
    setDeleteEmailErrorOpen(true);
  };

  const getAllocatedHomeDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${HOMES}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setAllocatedHomeData(data);
        setIsLoading(false);
        
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllocatedHomeDetails();
  }, []);

  const allocatedHomeTable = useMemo((rowData, index) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 2,
      Cell: ({ row }) => row.index + 1,
    },
    
    {
      accessorKey: "firstName",
      header: "First Name",
      filterVariant: "text",
      size: 2,
    },
     {
      accessorKey: "lastName",
      header: "Last Name",
      filterVariant: "text",
      size: 2,
    },
    {
      accessorKey: "email",
      header: "Assigned Email",
      enableClickToCopy: true,
      size: 2,
    },
    {
      accessorKey: "address",
      header: "Home Address",
      enableClickToCopy: true,
      filterVariant: "text",
      size: 2,
    },
    {
      accessorKey: "addressForCommunication",
      header: "Address For Communication",
      filterVariant: "text",
      size: 2,
    },
     {
      accessorKey: "isRequestForAccess",
      header: "Status",
      filterVariant: "text",
      size: 2,
      accessorFn: (row) => (row && row.isRequestForAccess ? "Active" : "In-Active"),
    }, 
  
  ]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

;
const generateCsv = (config) => (data) => {
  const header = Object.keys(data[0]).join(',');
  const rows = data.map(row => Object.values(row).join(','));
  return [header, ...rows].join('\n');
};

const download = (config) => (csvContent) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `${config.filename}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const handleExportData = () => {
  const excludedProperties = [
    "homeId",
    "groupId",
    "auditBy",
    "homeOwnerDetails",
    "status",
    "name",
    "id",
    "createdBy",
    "isOnRent",
    "homeOwnerLoginId",
    "homeOwnerDetailsId",
    "isAssigned",
    "roles",
    "city",
    "contact"
    
    
  ];

  const formattedData = Object.values(allcoatedHomeData).map((item) => {
    const formattedDatetime = item.createdDateAndTime 
      ? moment(item.createdDateAndTime).format("MM/DD/YY HH:mm")
      : "";

    // Transform true/false to Active/Inactive
    const status = item.isRequestForAccess ? "Active" : "Inactive";

    const filteredItem = {
      ...item,
      createdDateAndTime: formattedDatetime,
      isRequestForAccess: status, // Replace true/false with Active/Inactive
    };

    excludedProperties.forEach((property) => {
      delete filteredItem[property];
    });

    return filteredItem;
  });

  const csvConfigWithFilename = {
    ...csvConfig,
    filename: 'Home Details'
  };

  const csv = generateCsv(csvConfigWithFilename)(formattedData);
  const csvWithCustomHeader = csv.replace('isRequestForAccess', 'Status');
  download(csvConfigWithFilename)(csvWithCustomHeader);
};

  

  

 

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const okCancelDeleteHomeIdButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleHomeIdDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleHomeIdDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>Are you sure you want to Un-Assign email</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  const deleteHomeIdModel = (
    <form
      onSubmit={handleSubmit(onSubmitHomeIdDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>Are you sure you want to Delete Home Address</Typography>
      </DialogContent>
      <DialogActions>{okCancelDeleteHomeIdButtonActions}</DialogActions>
    </form>
  );
  const okCancelEmailButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleDeleteEmailClose}
      />
    </>
  );

  const okCancelEmailErrorButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleDeleteEmailErrorClose}
      />
    </>
  );

  const deleteEmailModel = (
    <>
      <DialogContent dividers>
        <Typography>This home address is already un-assigned</Typography>
      </DialogContent>
      <DialogActions>{okCancelEmailButtonActions}</DialogActions>
    </>
  );

  const deleteEmailErrorModel = (
    <>
      <DialogContent dividers>
        <Typography>
          Unable to delete home address. Please first unassign the home owner
          associated with this address before attempting to delete it
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelEmailErrorButtonActions}</DialogActions>
    </>
  );

  const okCancelViewButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleViewClose}
      />
    </>
  );

  const okCancelUnassignedViewButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleUnassignedViewClose}
      />
    </>
  );

  const handleSingleHoIsPresentClose = () => {
    setSingleHoIsPresentOpen(false);
  };

  const handleSingleHoIsPresentOpen = () => {
    setSingleHoIsPresentOpen(true);
  };

  const okCancelNoHomesActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleSingleHoIsPresentClose}
      />
    </>
  );

  const okCancelUnassignedValidationViewButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleSingleHoValidationClose}
      />
    </>
  );

 


  const deleteViewModel = (
    <>
      <DialogContent dividers>
        <Typography>User has not completed request for access</Typography>
      </DialogContent>
      <DialogActions>{okCancelViewButtonActions}</DialogActions>
    </>
  );

  const deleteUnassignedViewModel = (
    <>
      <DialogContent dividers>
        <Typography>Email is not assigned to Home address</Typography>
      </DialogContent>
      <DialogActions>{okCancelUnassignedViewButtonActions}</DialogActions>
    </>
  );

  const deleteUnassignedValidationViewModel = (
    <>
      <DialogContent dividers>
        <Typography>All homes have already been assigned</Typography>
      </DialogContent>
      <DialogActions>
        {okCancelUnassignedValidationViewButtonActions}
      </DialogActions>
    </>
  );

  const deleteHomeValidationViewModel = (
    <>
      <DialogContent dividers>
        <Typography>You don't have any homes, please add homes and assign them.</Typography>
      </DialogContent>
      <DialogActions>
        {okCancelNoHomesActions}
      </DialogActions>
    </>
  );

  const resendOkCancelButtonActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleResendClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Cancel"
        click={handleResendClose}
      />
    </>
  );

  const resendModel = (
    <form
      onSubmit={handleSubmit(onSubmitResend)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          Are you sure you want to resend the email notification?
        </Typography>
      </DialogContent>
      <DialogActions>{resendOkCancelButtonActions}</DialogActions>
    </form>
  );

  

  const rowActions = (row) => {
    const actions = [];

    if (row.original.isRequestForAccess == true) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleViewClick(row),
      });
    }

    if ((row.original.isRequestForAccess == false ||row.original.isRequestForAccess == null)&& row.original.email != null) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleWarningViewClick(row),
      });
    }

    if ((row.original.isRequestForAccess == false||row.original.isRequestForAccess == null) && row.original.email == null) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleWarningUnassignedViewClick(row),
      });
    }

    actions.push({
      label: "Edit",
      icon: <EditNoteOutlined color="primary" />,
      click: () => handleEditClickOpen(row),
    });

    if (row.original.isRequestForAccess == true && row.original.email) {
      actions.push({
        label: "Un-Assign",
        icon: <EmailOutlined color="error" />,
        click: () => handleDeleteOpen(row.original),
      });
    }
    if ((row.original.isRequestForAccess == false||row.original.isRequestForAccess == null )&& row.original.email) {
      actions.push({
        label: "Un-Assign",
        icon: <EmailOutlined color="error" />,

        click: () => handleDeleteOpen(row.original),
      });
    }
    if ((row.original.isRequestForAccess== false ||row.original.isRequestForAccess == null)&& !row.original.email) {
      actions.push({
        label: "Un-Assign",
        icon: <EmailOutlined color="error" />,

        click: () => handleDeleteEmailOpen(row.original),
      });
    }
    if ((row.original.isRequestForAccess == false||row.original.isRequestForAccess == null )&& !row.original.email) {
      actions.push({
        label: "Delete",
        icon: <DeleteOutlineOutlined color="error" />,

        click: () => handleAddressDeleteOpen(row.original),
      });
    }
    if (row.original.isRequestForAccess== true && row.original.email) {
      actions.push({
        label: "Delete",
        icon: <DeleteOutlineOutlined color="error" />,

        click: () => handleDeleteEmailErrorOpen(row.original),
      });
    } 
    if ((row.original.isRequestForAccess== false||row.original.isRequestForAccess == null) && row.original.email) {
      actions.push({
        label: "Delete",
        icon: <DeleteOutlineOutlined color="error" />,

        click: () => handleDeleteEmailErrorOpen(row.original),
      });
    }

    // {
    //   label: "Delete",
    //   icon: <Delete color="primary" />,
    //   click: () =>handleDeleteOpen(row.original),
    // },
    // {
    //   label: "Resend",
    //   icon: <Telegram color="primary" />,
    //   click: handleResendOpen,
    // },

    return actions;
  };

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add Homes"
        click={handleAddClickOpen}
      />

      {/* <CustomButton
        fieldType="button"
        buttonName="Add Home"
        click={handleAddSingleHomeClickOpen}
      /> */}

      {homesData.length != 0 && (
        <CustomButton
          fieldType="button"
          buttonName="Assign HomeOwner"
          click={handleSingleHoClickOpen}
        />
      )}
    {originalData.length === 0 ? (
  <CustomButton
    fieldType="button"
    buttonName="Assign HomeOwner"
    click={handleSingleHoIsPresentOpen}
  />
) : (
  homesData.length === 0 && (
    <CustomButton
      fieldType="button"
      buttonName="Assign HomeOwner"
      click={handleSingleHoValidationClickOpen}
    />
  )
)}

      
      {allocatedHomeTable.length > 0 ? (
        <Button
          color="primary"
          variant="contained"
          onClick={handleExportData}
          startIcon={<FileUploadOutlined />}
        >
          <span className={CustomStyle.containedButtonStyle}>Export</span>
        </Button>
      ) : null}
    </>
  );
  return (
    <>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <CustomHeading title="Allocated Homes" />

     
      

     
      <CustomModel
        title=" Warning "
        content={deleteUnassignedValidationViewModel}
        action={okCancelUnassignedValidationViewButtonActions}
        openStatus={singleHoValidationOpen}
        closeStatus={handleSingleHoValidationClose}
        iconCloseStatus={handleSingleHoValidationClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
      />

      <CustomModel
        title="Un-Assign Email"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
      />

<CustomModel
        title="Warning"
        content={deleteHomeValidationViewModel}
        action={okCancelNoHomesActions}
        openStatus={singleHoIsPresentOpen}
        closeStatus={handleSingleHoIsPresentClose}
        iconCloseStatus={handleSingleHoIsPresentClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
      />

      <CustomModel
        title="Delete Home Address"
        submit={onSubmitHomeIdDelete}
        content={deleteHomeIdModel}
        action={okCancelDeleteHomeIdButtonActions}
        openStatus={deleteHomeIdOpen}
        closeStatus={handleHomeIdDeleteClose}
        iconCloseStatus={handleHomeIdDeleteClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
      />

      <CustomModel
        title="Un-Assign Email "
        content={deleteEmailModel}
        action={okCancelEmailButtonActions}
        openStatus={deleteEmailOpen}
        closeStatus={handleDeleteEmailClose}
        iconCloseStatus={handleDeleteEmailClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
      />
      <CustomModel
        title=" Delete Home Owner"
        content={deleteEmailErrorModel}
        action={okCancelEmailErrorButtonActions}
        openStatus={deleteEmailErrorOpen}
        closeStatus={handleDeleteEmailErrorClose}
        iconCloseStatus={handleDeleteEmailErrorClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
      />

      <CustomModel
        title=" Warning "
        content={deleteViewModel}
        action={okCancelViewButtonActions}
        openStatus={viewOpen}
        closeStatus={handleViewClose}
        iconCloseStatus={handleViewClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
      />

      <CustomModel
        title=" Warning "
        content={deleteUnassignedViewModel}
        action={okCancelUnassignedViewButtonActions}
        openStatus={unassignedviewOpen}
        closeStatus={handleUnassignedViewClose}
        iconCloseStatus={handleUnassignedViewClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        reset={reset}
      />
      <CustomModel
        title="Resend Email"
        submit={onSubmitResend}
        content={resendModel}
        action={resendOkCancelButtonActions}
        openStatus={resendOpen}
        closeStatus={handleResendClose}
        iconCloseStatus={handleResendClose}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />

      

      <CustomMaterialTable
        columns={allocatedHomeTable}
        data={allcoatedHomeData}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
      />
    </>
  );
}

export default AllocatedHome;
