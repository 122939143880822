import React from "react";
import ForgotPasswordForm from "../../components/forgotpassword/ForgotPasswordForm";



function Forgot() {
  return <div> <ForgotPasswordForm/> </div>;
}

export default Forgot;
