import { FileDownload, FileUploadOutlined, ListAlt } from "@mui/icons-material";
import { Button } from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import { BASE_URL, COMPLIANCE_REQUEST } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Loading from "react-loading";
import CustomLoading from "../components/CustomLoading"
import CustomMaterialWithoutActons from "../components/CustomMaterialWithoutActons";


function ComplianceRequest() {
  const navigate = useNavigate();
  const [isLoading,setIsLoading] =useState(true);

  const handleViewClick = (selectedRow) => {
    navigate(`/compliance/${selectedRow.row.original.complainceId}`);
  };

  const [complianceRequestDetails,setComplianceRequestDetails] = useState([]);

  const { token } = useSelector(state => state.hybridhoa);

  const getComplianceRequestDetails = async () => {
    setIsLoading(true);
    try {
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${COMPLIANCE_REQUEST}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setComplianceRequestDetails(data);
        setIsLoading(false);

      } else {
          setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
        setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getComplianceRequestDetails();
},[])

 

  const complianceRequestTable = useMemo((rowData) => [
    {
      accessorKey: "crId",
      header: "Compliance Id",
      size: 20,
      
    },
    {
      accessorKey: "assignedToAddresss",
      header: "Address",
      accessorFn: (row) => row.createdByAddress ? row.createdByAddress.address || "" : "",
        sortDescFirst: true,
    },
    {
      accessorKey: "category",
      header: "Category",
      accessorFn: (row) => row.categoryDetails ? row.categoryDetails.category || "" : "",
      sortDescFirst: true,
    },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    {
      accessorKey: "createdToName",
      header: "Created To",
      accessorFn: (row) => `${row.createdToDetails? row.createdToDetails.firstName : ""} ${row.createdToDetails? row.createdToDetails.lastName : ""} `,
        sortDescFirst: true,
      // Cell: ({ row }) =>
      //   `${row.original.createdByDetails.firstName} ${row.original.createdByDetails.lastName}`,
      // Cell: ({ row }) => {
      //   if (row.original.createdToName === null) {
      //     return ""; // If appliedToDetails is null, return an empty string
      //   } else if (Array.isArray(row.original.createdToName)) {
      //     // If appliedToDetails is an array, construct full names
      //     return row.original.createdToName
      //       .map((detail) => `${detail.firstName} ${detail.lastName}`)
      //       .join(", ");
      //   } else {
      //     // If appliedToDetails is an object, construct the full name
      //     return `${row.original.createdToName.firstName || ""} ${
      //       row.original.createdToName.lastName || ""
      //     }`;
      //   }
      // },
    },
    {
      accessorKey: "assignedToAddress",
      header: "Created To Address",
      accessorFn: (row) => row.createdToAddress ? row.createdToAddress.address || "" : "",
        sortDescFirst: true,
    },
    // {
    //   accessorKey: "category",
    //   header: "Category",
    // },
    {
      accessorKey: "status",
      header: "Status",
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
  //   {
  //   accessorKey: "auditDateAndTime",
  //   header: "Audit Date",
  //   size: 20,
  //   accessorFn: (row) =>
  //     moment(row.auditDateAndTime).format("MM/DD/YYYY"),
  // },
  ]);

  // const rowActions = [
  //   {
  //     label: "View",
  //     icon: <ListAlt color="primary" />,
  //     click: (row)=>handleViewClick(row),
  //   },
  // ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const excludedProperties = [
      "complainceId",
      "createdBy",
      "auditBy",
      "groupId",
      "category",
      "createdByDetails",
      "role",
      "bylawId",
      "externalUserId",
      "jobId",
      "auditDateAndTime",
      "categoryDetails",
      "fileUrl",
      "id",
      "homeId",
      "createdFor",
      "isFineApplicable",
      "bylawDetails"
    ];

    const formattedData = Object.values(complianceRequestDetails).map((item) => {
      const formattedDatetime = moment(item.createdDateAndTime).format(
        "MM/DD/YYYY"
      );

      const filteredItem = {
        ...item,
        
        createdDateAndTime: formattedDatetime,
      };

      excludedProperties.forEach((property) => {
        delete filteredItem[property];
      });

      return filteredItem;
    });

    
  const csvConfigWithFilename = {
    ...csvConfig,
    filename: 'Compliance Request Report' 
  };

  const csv = generateCsv(csvConfigWithFilename)(formattedData);
  download(csvConfigWithFilename)(csv);
  };

  const tableActionButtons = (
    <>
      {complianceRequestDetails.length > 0 ? 
    <Button
    color="primary"
    variant="contained"
    onClick={handleExportData}
    startIcon={<FileUploadOutlined />}
  >
    <span className={CustomStyle.containedButtonStyle}>
      Export All Data
    </span>
  </Button>
  :
  null
    }
    </>
  );

  return (
    <div >
<CustomHeading title="Compliance" />
{isLoading && <CustomLoading />}
        <CustomMaterialWithoutActons
          columns={complianceRequestTable}
          data={complianceRequestDetails}
          // rowActions={rowActions}
          // actionButtons={tableActionButtons}
          isLoading={isLoading}
          onRowClick={handleViewClick}
        />
  </div>
  
  );
}

export default ComplianceRequest;
