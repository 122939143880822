import { EditNoteOutlined, ListAlt } from "@mui/icons-material";
import React, { useMemo, useState, useEffect } from "react";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomModel from "../components/CustomModel";
import CustomButton from "../components/CustomButton";
import {
  AMOUNT_EXCEEDED,
  AMOUNT_INVALID_MESSAGE,
  AMOUNT_REQUIRED_MESSAGE,
  Amount_EQUAL_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  NUMERS_ALLOWED,
  REIMBURSE_COMMENT,
} from "../utills/ApplicationConstants";
import { FIVE_DIGITS_TWO_DECIMAL, ONLY_DIGITS } from "../utills/ApplicationRegex";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, REIMBURSED_DUES } from "../utills/ApplicationRouting";
import CustomLoading from "../components/CustomLoading";
import CustomToastContainer from "../components/CustomToastContainer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomInputFieldFullWidth from "../components/CustomInputFieldFullWidth";

function ReimburseDue() {
  const navigate = useNavigate();
  const [editOpen, setEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.hybridhoa);
  const [selectedHomeRowId, setSelectedHomeRowId] = useState("");

  const [reimburseDue, setReimburseDue] = useState("");
  const handleViewClick = (selectedRow) => {
    navigate(`/viewreimbursedue/${selectedRow.row.original.reimbursedDueId}`);
  };

  const handleAddClick = () => {
    navigate("/applyreimbursedue");
  };
  const [editGroupReimburseId, setEditGroupReimburseId] = useState("");
  const handleEditClickOpen = (selectedRow) => {
    console.log("selectedId", selectedRow.original.reimbursedDueId);

    navigate(`/applyeeditreimburse/${selectedRow.original.reimbursedDueId}`)
    setEditGroupReimburseId(selectedRow.original.reimbursedDueId);
    // setEditOpen(true);
  };
  const handleEditClose = () => {
    if (false) {
      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
  };
  const getReimbursedDues = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      
      const response = await axios.get(`${BASE_URL}${REIMBURSED_DUES}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status == 200) {
        const data = response.data;
        setReimburseDue(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getReimbursedDues();
  }, []);
  const [waivedAmount, setWaivedAmount] = useState("");
  const [comment, setComment] = useState("");
  const [appliedTo, setAppliedTo] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [homeOwnerDuesId, setHomeOwnerDuesId] = useState("");
  const getWaivedamount = async () => {
   
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(
        `${BASE_URL}${REIMBURSED_DUES}/${editGroupReimburseId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);
    
      if (response.status === 200) {
        setIsLoading(false);
        const data = response.data;
        setWaivedAmount(data.waivedAmount);
        setComment(data.comment);
        setAppliedTo(data.appliedTo);
        setTotalAmount(data.totalAmount);
        console.log(data.totalAmount);
        setHomeOwnerDuesId(data.homeOwnerDuesId);
        // setTopic(data.topic);
        // setDescription(data.description);
        // setIsNotify(data.isNotify);
        // setType(data.type);
        console.log("Bylaw" + data);
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!editGroupReimburseId) {
      // If not present, just return and don't call getWaivedamount
      console.log("editGroupReimburseId is required to fetch waived amount.");
      return;
    }
    getWaivedamount();
  }, [editGroupReimburseId]);
  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
   
    const parsedWaivedAmount = parseFloat(waivedAmount);
const parsedDueAmount = parseFloat(totalAmount);
    if (parsedWaivedAmount > parsedDueAmount) {
      setError("dueAmount", {
        type: "manual",
        message: AMOUNT_EXCEEDED
      });
      
      setIsLoading(false);
      return;
    } 
    if (!comment || !comment.trim()) {
      setError("reimburseComment", {
        type: "manual",
        message: REIMBURSE_COMMENT
      });
      setIsLoading(false);
      return;
    }
    if (!waivedAmount || !waivedAmount.trim()) {
      setError("dueAmount", {
        type: "manual",
        message: AMOUNT_INVALID_MESSAGE
      });
      
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    const payload = {
      totalAmount: totalAmount,
      comment: comment,
      waivedAmount: waivedAmount.trim(),
      appliedTo: appliedTo,
      homeOwnerDuesId: homeOwnerDuesId,
    };
    try {
      const response = await axios.put(
        `${BASE_URL}${REIMBURSED_DUES}/${editGroupReimburseId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          
        }
      );
      if (response.status === 200) {
        setIsLoading(false);
        getReimbursedDues()
        getWaivedamount()
        toast.success(response.data, { position: "top-center" }); 
      setTimeout(()=>{
        setEditOpen(false)
      },4000)
        
      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
      console.log(response);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const message = error.response.data.message;
        toast.error(message, { position: "top-center" });
        console.log("error.response.status" + error.response.status);
      } else {
        console.error(error); // Log the entire error for further debugging
        toast.error("An error occurred while processing your request.");
      }
      console.log(error);
    }
  };

    
const convertToPascalCase = (str) => {
  // Check if the input string is undefined or empty
  if (str === undefined || str === '' || str === null) return '';

  // Remove underscores and convert string to Pascal case
  return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

  const reimburseDueTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "title",
      header: "Title",
      accessorFn: (row) => {
        const title = row && row.title;
        if (title && title.length > 28) {
          return title.slice(0, 28) + "...";
        }
        return title;
      }
    },
    
    {
      accessorKey: "dueType",
      header: "Due Type",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.dueType),
    }, 
     {
      accessorKey: "description",
      header: "Description",
      size: 20,
      // accessorFn: (row) => `${row?.dueDetails?.description ?? " "}`,
      accessorFn: (row) => {
        const description = row.dueDetails && row.dueDetails.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    {
      accessorKey: "totalAmount",
      header: "Amount",
      size: 20,
    },

    {
      accessorKey: "appliedTo",
      header: "Applied To",
      size: 20,
      accessorFn: (row) => `${row && row.appliedToDetails?.firstName ? row.appliedToDetails?.firstName : " "} ${row && row.appliedToDetails?.lastName ? row.appliedToDetails?.lastName : " "} `,
      // Cell: ({ row }) => {
       
      //     return `${row.original.appliedToDetails.firstName || ''} ${row.original.appliedToDetails.lastName || ''}`;
      // }
    },  {
      accessorKey: "appliedToHomeAddress",
      header: "Address",
      size: 20,
    },
    {
      accessorKey: "waivedAmount",
      header: "Waived off Amount",
      size: 20,
    },
  ]);
  const rowActions= (row) => {

    const actions = [];

    if (row.original.dueDetails && !row.original.dueDetails.isPaymentStarted) {
      actions.push({
        label: "Edit",
        icon: <EditNoteOutlined color="primary" />,
        click: () => handleEditClickOpen(row),
      });
    }
    return actions;
  }    

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClick}
      />
    </>
  );

  const editActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Apply"
      click={handleEditClose}
    />
  );
  const [showValidation, setShowValidation] = useState(false);

  const handlewaivedAmountChange = (event) => {
    const newValue = event.target.value;
    // if (newValue < 0) {
    //   return;
    // }
    setWaivedAmount(newValue);
    if (newValue > totalAmount) {
      setShowValidation(true);
    } else {
      setShowValidation(false);
    }
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const editModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
          {isLoading && <CustomLoading />}
          <CustomInputFieldFullWidth
  fieldName="dueAmount"
  fieldPattern={ONLY_DIGITS}  
  patternErrorMessage={NUMERS_ALLOWED}
  fieldId="dueAmount"
  fieldType="text"
  fieldLabel="Waived Off Amount"
  fieldControl={control}
  fieldError={errors}
  fieldRequiredIcon="*"
  value={waivedAmount}
  onInput={handlewaivedAmountChange}
  tooltipMessage={"Enter the amount to be waived off"}
  
/>
            {/* <Controller
  name="dueAmount"
  control={control}
  defaultValue={10}
  rules={{
    pattern: {
      value: /[^0-9]/g,
      message: "Please enter numbers only.",
    },
    validate: (value) =>
      parseFloat(value) <= parseFloat(totalAmount) ||
      "WaivedOffAmount should be less than or equal to original Amount",
  }}
  render={({ field, fieldState }) => (
    <div>
      <label htmlFor="dueAmount">Due Amount</label>
      <input
        type="text"
        id="dueAmount"
        {...field}
        onBlur={(e) => {
          field.onBlur(e);
          const inputValue = e.target.value;
          if (!inputValue || !inputValue.trim() || parseFloat(inputValue) <= parseFloat(totalAmount)) {
            setErrorDisplayed(false); // Hide error message if value is valid
          } else {
            setErrorDisplayed(true); // Show error message if value is invalid
          }
        }}
      />
      {fieldState.error && (
        <p style={{ color: "red" }}>{fieldState.error.message}</p>
      )}
    </div>
  )}
/> */}

            <CustomInputFieldFullWidth
              fieldName="reimburseComment"
              fieldType="text"
              fieldId="reimburseComment"
              fieldLabel="Waived Off Comment"
              // requiredErrorMessage={REIMBURSE_COMMENT}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldControl={control}
              fieldError={errors}
              multiline={true}
              numberOfRows={1}
              fieldRequiredIcon="*"
              // defaultValue="This is testing Reimbursement comment"
              value={comment}
              onInput={handleCommentChange}
              tooltipMessage={"Enter the comments for Waived Off"}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{editActionButton}</DialogActions>
      </form>
    </>
  );

  return (
    <div>
      <CustomHeading title="Waived Off Dues" />
      {isLoading && <CustomLoading />}
      <CustomToastContainer/>
      <CustomModel
        title="Edit Waived Off Due"
        submit={onSubmit}
        content={editModel}
        action={editActionButton}
        openStatus={editOpen}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        isLoading={isLoading}

      />

      <CustomMaterialTable
        columns={reimburseDueTable}
        data={reimburseDue}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        onRowClick = {handleViewClick}
      />
    </div>
  );
}

export default ReimburseDue;
