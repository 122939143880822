import { FileDownload, FileUploadOutlined, ListAlt, Topic } from "@mui/icons-material";
import {  Button} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import React, {  useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import moment from "moment";
import { BASE_URL, CATEGORY, MAINTENANCE_REQUEST } from "../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomLoading from "../components/CustomLoading"
import CustomMaterialWithoutActons from "../components/CustomMaterialWithoutActons";


function MaintenanceRequest() {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);

  const handleViewClick = (selectedRow) =>{
    navigate(`/maintenance/${selectedRow.row.original.maintenanceId}`)
  }

  const [maintenanceRequestDetails,setMaintenanceRequestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { token } = useSelector(state => state.hybridhoa);


  const getCategory = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const allCategories = response.data;
        const filteredCategories = allCategories.filter(category => category.type === 'MR');
        setIsLoading(false);
        setCategoryData(filteredCategories);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch category data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching category data:", error);
    }
  };
  
  
  // useEffect(() => {
  //   getCategory();
  // }, []);


  const getMaintenanceRequestDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${MAINTENANCE_REQUEST}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setMaintenanceRequestDetails(data);
        setIsLoading(false);

       console.log(data);
      } else {
          setIsLoading(false);
        throw new Error("Failed to fetch maintenance request data");
      }
    } catch (error) { 
       setIsLoading(false);
      console.error("Error fetching maintenance request data:", error);
      // setIsLoading(true);
    }
  };



useEffect(()=>{
  getCategory();
  getMaintenanceRequestDetails();
},[])
 

  const maintenanceRequestTable = useMemo((rowData) => [
    {
      accessorKey: "mrId",
      header: "Maintenance Id",
      size: 20,
      
    },
    
    {
      accessorKey: "createdBy",
      header: "Created By",
      Cell: ({ row }) => {
        if (row.original.createdByDetails && row.original.createdByDetails.firstName && row.original.createdByDetails.lastName) {
          return `${row.original.createdByDetails.firstName} ${row.original.createdByDetails.lastName}`;
        } else {
          return "";
        }
      }
    },
    {
      accessorKey: "assignedToAddress",
      header: "Address",
      accessorFn: (row) => row.homeDetails ? row.homeDetails.address || "" : "",
        sortDescFirst: true,
    },
    {
      accessorKey: "topic",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.topic;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    {
      accessorKey: "status",
      header: "Status",
    },
    {
      accessorKey: "isUrgent",
      header: "urgent?",
      size: 20,
      accessorFn:(row)=>(row &&row.isUrgent) ? 'Yes':'No'
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
    // {
    //   accessorKey: "auditDateAndTime",
    //   header: "Audit Date",
    //   accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY"),
    // },
   
  ]);
  // const rowActions = [
  //   {
  //     label: "View",
  //     icon: <ListAlt color="primary" />,
  //     click: handleViewClick,
  //   }
  // ];

 
 
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    boolDisplay:{true: "Yes", false: "No"}
  });
  // const handleExportData = () => {
  //   const csv = generateCsv(csvConfig)(maintenanceRequestDetails);
  //   download(csvConfig)(csv);
  // };

  const handleExportData = () => {
    const excludedProperties = [
      "homeId",
      "groupId",
      "homeOwnerDuesId",
      "auditBy",
      "title",
      "homeOwnerDetails",
      "requestForAccess",
      "id",
      "attachments",
      // "createdBy",
      "isOnRent",
      "fileUrl",
      "homeOwnerLoginId",
      "homeOwnerDetailsId",
     
      "complainceId",
     "category",
     "address",
     "createdToName",
     "createdByHomeAddress",
      "reviewComments",
      "categoryDetails",
      "createdToDetails",
      "byLawDetails",
      "createdToDetails",
      "createdByName",
      "homeOwner",
      "bylawId",
      "parentId",
      "comments",
      "bylawDetails",
      "crId",
      "assignedTo",
      "homesDetails",
      ,"isHidden","documentId","externalUserDetails",
      "isPublic","publishDate","auditDateAndTime","createdToAddress",
      // "startDate","endDate",
      "isAssigned","totalAmount","homeDetails","createdByDetails",
      "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
     "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
    ];
  
   
  
    const formattedData = Object.values(maintenanceRequestDetails).map((item) => {
      const formattedDatetime = moment(item.createdDateAndTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
      const formattedDatetimess = moment(item.auditDateAndTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
      const startDates = item.startDate ? moment(item.startDate).format("MM/DD/YYYY") : ""
      const endDates= item.endDate ? moment(item.endDate).format("MM/DD/YYYY") : ""
      console.log("Start Date After Formatting:", startDates);
console.log("End Date After Formatting:", endDates);
      const createdBy = item.createdByDetails
        ? `${item.createdByDetails.firstName} ${item.createdByDetails.lastName}`
        : '';
        const createdByAddress = item.homeDetails
        ? `${item.homeDetails.address}`
        : "" ;
        
        const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
      const filteredItem = {
        ...item,
        createdBy:createdBy,
        createdByAddress:createdByAddress,
        createdDateAndTime: formattedDatetime,
        // auditDateAndTime:formattedDatetimess,
        startDate:startDates,
        endDate:endDates
        // ...(createdTo && { createdTo }),
        
              };
  
      excludedProperties.forEach((property) => {
        delete filteredItem[property];
      });
  
      return filteredItem;
    });
  
    const csvConfigWithFilename = {
      ...csvConfig,
      filename: 'Maintenance details' 
    };
  
    const csv = generateCsv(csvConfigWithFilename)(formattedData);
  download(csvConfigWithFilename)(csv);
  };
  const tableActionButtons = (
    <>
      {maintenanceRequestDetails.length > 0 ? 
    <Button
    color="primary"
    variant="contained"
    onClick={handleExportData}
    startIcon={<FileUploadOutlined />}
  >
    <span className={CustomStyle.containedButtonStyle}>
      Export All Data
    </span>
  </Button>
  :
  null
    }
    </>
  );
 
  return (
    <div>
    
      <CustomHeading title="Maintenance"/>
      {isLoading && <CustomLoading />}
        <CustomMaterialWithoutActons
          columns={maintenanceRequestTable}
          data={maintenanceRequestDetails}
          // rowActions={rowActions}
          actionButtons={tableActionButtons}
          isLoading={isLoading}
          onRowClick={handleViewClick}
        />
     
    </div>
  )
}

export default MaintenanceRequest