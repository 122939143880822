import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TextField, Tooltip, IconButton, Typography } from "@mui/material";
import { PHONE_INVALID_MESSAGE, PHONE_REQUIRED_MESSAGE } from '../utills/ApplicationConstants';
import { InfoOutlined } from "@mui/icons-material";

function CustomPhoneInput({ label, fieldControl, value, onChange, country = 'US', disabled, error, onKeyDown, isRequiredIcon = true, isRequired=true, formSubmitted, tooltipMessage }) {
  const [isValid, setIsValid] = useState(true);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [isRequiredError, setIsRequiredError] = useState(false);

  useEffect(() => {
    if (formSubmitted) {
      const trimmedValue = String(value).trim();
      const isValidNumber = isValidPhoneNumber(trimmedValue, country);
      setIsValid(isValidNumber);
      setIsRequiredError(!trimmedValue && isRequired);
    }
  }, [value, country, isRequired, formSubmitted]);

  const handlePhoneNumberChange = (phoneNumber, selectedCountry) => {
    const trimmedValue = phoneNumber ? phoneNumber.trim() : '';
    onChange(trimmedValue);
  
    const countryToUse = selectedCountry || country;
    const isValidNumber = isValidPhoneNumber(trimmedValue, countryToUse);
    setIsValid(isValidNumber);
    setIsRequiredError(!trimmedValue && isRequired);
  
    if (!isValidNumber && isUserTyping) {
      // Reset value to an empty string if it's not a valid phone number and the user is typing
      onChange('');
      setIsUserTyping(false);
      setIsRequiredError(false);
    }
  };

  const handleInputBlur = () => {
    setIsUserTyping(false);
    if (isValid) {
      setIsRequiredError(false);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Backspace') {
      // Always clear the value when the backspace key is pressed
      onChange('');
      setIsUserTyping(false);
      setIsRequiredError(false);
    }
  
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <div className={`flex items-center w-full ${tooltipMessage ? 'md:w-1/2' : 'w-auto'}`}>
 
      <TextField
        label={
          <>
            {label}
            {isRequiredIcon &&   <span style={{ paddingLeft: '7px', fontSize: '20px', color: 'red' }}>*</span>}
          </>
        }
        variant="standard"
        size="large"
       
        InputProps={{
          style: {
            fontSize: 16,
            fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
          },
          startAdornment: (
            <PhoneInput
              control={fieldControl}
              value={value}
              onChange={(phoneNumber, selectedCountry) => handlePhoneNumberChange(phoneNumber, selectedCountry)}
              defaultCountry={country ? country : ''}
              countryCallingCodeEditable={false} // This prevents the country code from being editable
              style={{ width: '100%' }}
              limitMaxLength={15}
              inputStyle={{ width: '100%' }}
              className="custom-phone-input"
              disabled={disabled}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              // Render the flag next to the input
              international
              initialValueFormat="national"
              // render={({ country, ...rest }) => (
              //   <>
              //     <span>{rest.countryCallingCode}</span>
              //     <img
              //       alt={country}
              //       src={`https://flagcdn.com/16x12/${country.toLowerCase()}.png`}
              //     />
              //     <input {...rest} />
              //   </>
              // )}
            />
          ),
          
          inputProps: {
            readOnly: true,
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 16,
            fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
          },
        }}
        disabled={disabled}
        error={(formSubmitted && (!isValid || (isRequiredError && !isUserTyping))) || error}
        helperText={
          (error && `${error.message}`) ||
          (formSubmitted && !isValid && `${PHONE_INVALID_MESSAGE}`) ||
          (formSubmitted && isRequiredError && !isUserTyping && `${PHONE_REQUIRED_MESSAGE}`) || ''
        }
      />
       {tooltipMessage && (
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
 

  );
}

export default CustomPhoneInput;
