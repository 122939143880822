import {
  Button,
  Card,
  CardContent,
} from "@mui/material";
import React, {  useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import {
  MIN_LENGTH_VALIDATE_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  JOB_CATEGORY_INVAILD_MESSAGE,MAX_LENGTH_VALIDATE_MESSAGE_FIFTY ,
} from "../utills/ApplicationConstants";
import {
  ONLY_ALPHABETIC,
} from "../utills/ApplicationRegex";
import CustomInputField from "../components/CustomInputField";
import CustomButton from "../components/CustomButton";
import {  useForm } from "react-hook-form";
import { request } from "../services/AxiosConfig";
import {  JOB_CATEGORY } from "../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomLoading from"../components/CustomLoading"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CustomTooltipField from "../components/CustomTooltipField"


function AddJobCategory() {
  const { token } = useSelector(state => state.hybridhoa);
  const[isLoading,setIsLoading]=useState(false)
  
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const handleInputChange = (e) => {
    console.log(e.target.value);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue, setError, clearErrors, watch,
  } = useForm();

  const onSubmit = (data) => {

    setIsLoading(true);
    const postData = {
      occupation: data.jobCategory,
    };
    const storedToken = token;
    setIsLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };

    request({
      url: JOB_CATEGORY,
      method: "post",
      data: postData,
      headers: config.headers,
    }).then(res => {
      // setIsLoading(false); 
      // console.log(res);
      if (res.status == 200) {
        setTimeout(() => {

          setIsLoading(false); 
          navigate(`/jobcategory`);
        }, 5000);
      }else{
        setIsLoading(false); 

      }
    }).catch(error => {
      console.log(error.response);
      setIsLoading(false); 
      if (error.response) {   


      }
    });



  };
  
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Add Job Category" />

        {isLoading && <CustomLoading />}
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          {/* <div class="grid xl:grid-cols-2"> */}
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomTooltipField
            requiredErrorMessage={JOB_CATEGORY_REQUIRED_MESSAGE}
            fieldPattern={ONLY_ALPHABETIC}
            patternErrorMessage={JOB_CATEGORY_INVAILD_MESSAGE}
            minLength={2}
            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            maxLength={50}
            maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_FIFTY}
            fieldName="jobCategory"
            fieldId="jobCategory"
            fieldType="text"
            fieldLabel="Job-Category"
            fieldControl={control}
            fieldError={errors}
            fieldRequiredIcon="*"
            onInput={handleInputChange}
            multiline={true}
            tooltipMessage="Enter the Job category.You can use it for external user"
           
          />

             
 
            </section>
            {/* </div> */}
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Add" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default AddJobCategory;
