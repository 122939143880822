import { ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import { BASE_URL, DOCUMENT } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomLoading from "../components/CustomLoading";
import CustomMaterialWithoutActons from "../components/CustomMaterialWithoutActons";

function Documents() {
  const navigate = useNavigate();
  const handleViewClick = (selectedRow) => {
    navigate(`/document/${selectedRow.row.original.documentId}`);
  };

  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state) => state.hybridhoa);
  const getAllocatedHomeDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${DOCUMENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setDocuments(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllocatedHomeDetails();
  }, []);
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const documentsTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "category",
      header: "Category",
      size: 20,
    },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    {
      accessorKey: "uploadedBy",
      header: "Uploaded By",
      accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
      sortDescFirst: true, 
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "publishDate",
      header: "Publish Date",
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.status),
    },
    {
      accessorKey: "Document Type",
      header: "Document Type",
      size: 20,
      accessorFn: (row) => 
      row.category === "CONFIDENTIAL" 
        ? "N/A"
        : row.isPublic 
          ? "General Document - This will be Published General Document to Homeowners" 
          : "Need 51% Approval from Board Member",
      
    },
   
  ]);

  // const rowActions = [
  //   {
  //     label: "View",
  //     icon: <ListAlt color="primary" />,
  //     click: (row) => handleViewClick(row),
  //   },
  // ];

  return (
    <div>
      <CustomHeading title="Documents" />
      {isLoading && <CustomLoading />}
      <CustomMaterialWithoutActons
        columns={documentsTable}
        data={documents}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default Documents;
