import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUpload = ({ onImageUpload }) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        // if (file.size > 10 * 1024 * 1024) { // 10MB size limit
        //   setError('File size exceeds 10MB');
        // } else {
        //   setError('');
        // }

        const reader = new FileReader();

        reader.onloadend = () => {
          const base64Image = reader.result;
          setImage(base64Image);
          setFileName(file.name);
          console.log("Image uploaded", { base64Image, file }); // Debugging log
        
          onImageUpload({ base64Image, file }); // Pass both base64 and file
        };

        reader.readAsDataURL(file);
      }
    }
  });

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop a JPEG, JPG, or PNG image here, or click to select one</p>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {image && (
        <div>
          {/* <img src={image} alt="Preview" style={{ maxWidth: '300px', maxHeight: '300px' }} /> */}
          <p>Selected file: {fileName}</p>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;