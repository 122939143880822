import { useNavigate } from "react-router-dom"
import { CustomStyle } from "../components/CustomStyle"
import { Button, Card, CardContent } from "@mui/material"
import CustomViewPage from "../components/CustomViewPage"



function ViewRenter() {
    const navigate = useNavigate()

    const handleClick =() =>{
        navigate(-1)
    }
    const data = {
      pageTitle:"Renter Details",
      buttons :[
        {
          buttonTitle:"Back",
          onClick : handleClick
        },
       
    
      ],
      content : [
        {
          topic :'Lease start date',
          description :' 11/14/2023'
        },
        {
          topic :'Lease end date',
          description :'11/30/2023'
        },
        {
          topic :'First Name ',
          description :'Home'
        },
        {
          topic :'Last Name',
          description :'Req test Three'
        },
        {
          topic :'Contact',
          description :'+919898887788'
        },
        {
          topic :'Email',
          description :'homereqtest3@quadprosoft.com'
        },
        {
          topic :'Created By',
          description :'Home Req test Three'
        },
        {
          topic :'City',
          description :'demo'
        },
        {
          topic :'Date Of Birth',
          description :'10/11/1999'
        },
        {
          topic :'Attachments ',
          description :''
        },
         ]
    }
  return (
    <div>
      <CustomViewPage data={data}/>
    </div>
  )
}

export default ViewRenter