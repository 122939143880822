import { FileDownload, FileUploadOutlined, ListAlt } from "@mui/icons-material";
import FileUploadIcon from '@mui/icons-material/FileUpload';

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomButton from "../components/CustomButton";
import { Controller, useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import {
  FROM_DATE_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  TYPE_REQUIRED_MESSAGE,
} from "../utills/ApplicationConstants";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_URL, BASE_URL_IMAGE, GROUP_CONFIG, REPORT_CR_MR } from "../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../services/AxiosConfig";
import { useEffect } from "react";
import { pdf } from "@react-pdf/renderer";
import PDFDocumentList from "../components/Templatelistpdf";
import CustomLoading from "../components/CustomLoading";
import CustomMaterialWithoutActons from "../components/CustomMaterialWithoutActons";

function Audit_Mr() {
  const [type, setType] = useState("MR");
  const { token } = useSelector((state) => state.hybridhoa);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [listData,setListData]=useState([]);

 
  const [selectedType, setSelectedType] = useState("ALL");
  const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const[footerAdress,setFooterAdress]=useState('')
  const[groupAdress,setGroupAdress]=useState('')
  const minDate = dayjs().subtract(3, "year").startOf("day");
  const maxDate = dayjs().endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  const [toDate, setToDate] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [testImages,setTestImages]=useState('')
   const [complainceImage,setComplainceImage]=useState("");
   const handleFromDateChange = (selectedFromDate) => {
    // setListData([])
    setToDate("")
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
    
  };

  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);
    // setListData([])
  };
  
  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data);
        const logoConfig = data.find(config => config.topic === 'Logo');
        const footerValue =data.find(config => config.topic === 'Contact Details')
        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        if(footerValue){
          setFooterAdress(footerValue.content);
        } const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
     getGroupConfigurationDetails()
  },[])
  const getFile=async () => {
        try {
          // const storedToken = token;
          const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
            // headers: {
            //   Authorization: `Bearer ${storedToken}`,
            // },
            responseType: "blob",
          });
          const blob = response.data; // Accessing the blob data from the response
          const fileURL = URL.createObjectURL(blob);
          setImageBlob(fileURL);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(()=>{
        getFile()
      },[logoUrl])
  
  const [tableData, setTableData] = useState([]);

  // useEffect(() => {
  //   // Retrieve table data from sessionStorage when component mounts
  //   const storedData = sessionStorage.getItem("auditCrMrTableData");
  //   if (storedData) {
  //     setTableData(JSON.parse(storedData));
  //   }
  // }, []);
  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem('AuditMaintainence'));
    if (savedFormData) {
      setValue('fromDate', savedFormData.fromDate);
      setValue('toDate', savedFormData.toDate);
      // setValue('selectedType', savedFormData.selectedType); // Uncomment if you have a selectedType field
      setFromDate(dayjs(savedFormData.fromDate));
      setToDate(dayjs(savedFormData.toDate));
    }
  }, [setValue]);
  const onSubmit = (data) => {
    if (!data.fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!data.toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    localStorage.setItem('AuditMaintainence', JSON.stringify(data));

    const postData = {
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      to: dayjs(toDate).format("YYYY-MM-DD"),
      type: "MR",
    };
    request({ url: REPORT_CR_MR, method: "post", data: postData })
      .then((res) => {
        // setIsLoading(false);
        console.log(res);
        if (res.status === 200) {
          console.log(res.data.data);
    setIsLoading(false);

          let filteredData = [];
          if (type === "MR") {
            filteredData = res.data.data;
            localStorage.setItem("maintenanceReport", JSON.stringify(filteredData));
            setListData(filteredData);
            console.log(filteredData);
            // localStorage.setItem('mr', JSON.stringify(filteredData));
           
            
          }else{
    setIsLoading(false);

            throw new Error("Failed to fetch data");

          }
          setTableData(res.data.data);
          setIsLoading(false);
          // sessionStorage.setItem(
          //   "auditCrMrTableData",
          //   JSON.stringify(res.data.data)
          // );
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          console.log("error.response.status" + error.res.status);
        }
      });
  };
  useEffect(() => {
    // Retrieve data from local storage
    const storageData = localStorage.getItem("maintenanceReport");
    if (storageData) {
      // Parse the JSON data
      const parsedData = JSON.parse(storageData);
      // Set the parsed data in the state
      setListData(parsedData);
      console.log("parsed", parsedData);
    }
  }, []);
  // const onSubmit = async (event) => {
  //   event.preventDefault();

  //   const fromDates = dayjs(fromDate);
  //   const toDates = dayjs(toDate);

  //   if (!fromDates.isValid() || !toDates.isValid()) {
  //     console.error("Invalid fromDate or toDate");
  //     return;
  //   }
  //   if (toDates.isBefore(fromDates)) {
  //     alert(TO_DATE_ERROR_MESSAGE);
  //     return;
  //   }
  //   const payload = {
  //     from: fromDates.format("YYYY-MM-DD"),
  //     to: toDates.format("YYYY-MM-DD"),
  //     type: type,
  //   };

  //   try {
  //     const response = await axios.post(`${BASE_URL}${REPORT_CR_MR}`, payload, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         // 'Content-Type':'multipart/form-data'
  //       },
  //     });

  //     console.log(response.data);

  //     if (response.status === 200) {
  //       // Set the data in the state variable
  //       setTableData(response.data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   // Retrieve data from local storage
  //   const storageData = localStorage.getItem('mr');
  //   if (storageData) {
  //     // Parse the JSON data
  //     const parsedData = JSON.parse(storageData);
  //     // Set the parsed data in the state
  //     setListData(parsedData);
  //     console.log("parsed",parsedData);
  //   }
  // }, []);
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const navigate = useNavigate();
  // const handleViewClick = (selectedRow) => {
  //   navigate(`/viewmaintenance/${selectedRow.original.maintenanceId}`);
  // };

  const handleViewClick = (selectedRow) => {
    navigate(`/auditmr/${selectedRow.row.original.maintenanceId}`);
    console.log(selectedRow.row.original.maintenanceId);
  };
  const handleCrViewClick = (selectedRow) => {
    navigate(`/compliance/${selectedRow.original.complainceId}`);
    console.log(selectedRow.original.complainceId);
  };
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const auditCrMrTable = useMemo(
    (rowData, index) => [
      {
        accessorKey: "mrId",
        header: "Maintenance Id",
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        accessorFn: (row) => {
          if (row.createdByDetails === null) {
            return ''; // If createdByDetails is null, return an empty string
          } else if (Array.isArray(row.createdByDetails)) {
            // If createdByDetails is an array, construct full names
            return row.createdByDetails.map(detail => `${detail.firstName} ${detail.lastName}`).join(', ');
          } else {
            // If createdByDetails is an object, construct the full name
            return `${row.createdByDetails.firstName || ''} ${row.createdByDetails.lastName || ''}`;
          }
        },
        Cell: ({ row }) => {
          if (row.original.createdByDetails === null) {
            return ''; // If createdByDetails is null, return an empty string
          } else if (Array.isArray(row.original.createdByDetails)) {
            // If createdByDetails is an array, construct full names
            return row.original.createdByDetails.map(detail => `${detail.firstName} ${detail.lastName}`).join(', ');
          } else {
            // If createdByDetails is an object, construct the full name
            return `${row.original.createdByDetails.firstName || ''} ${row.original.createdByDetails.lastName || ''}`;
          }
        }
      },
      
      {
        accessorKey: "assignedToAddress",
        header: "Address",
        accessorFn: (row) => row.homesDetails ? row.homesDetails.address || "" : "",
          sortDescFirst: true,
      },
      // {
      //   accessorKey: "type",
      //   header: "Type",
      //   Cell: ({ row }) => {
      //     const mrId = row.original.maintenanceId;

      //     return (
      //       <span>
      //         {type === "MR" && mrId
      //           ? "Maintenance Request"
      //           : "Maintenance Request"}
      //       </span>
      //     );
      //   },
      // },
      // {
      //   accessorKey: "type",
      //   header: "Type",
      //   // Implementing custom search filter for the "type" column
      //   filter: ({ filterValue, row }) => {
      //     // If filterValue is empty, return true to show all rows
      //     if (!filterValue) return true;
          
      //     // Implement your custom search logic here
      //     const mrId = row.original.maintenanceId;
      //     const typeValue = type === "MR" && mrId ? "Maintenance Request" : "Maintenance Request";
  
      //     // Return true if the typeValue matches the filterValue
      //     return typeValue.toLowerCase().includes(filterValue.toLowerCase());
      //   },
      //   Cell: ({ row }) => {
      //     const mrId = row.original.maintenanceId;
  
      //     return (
      //       <span>
      //         {type === "MR" && mrId
      //           ? "Maintenance Request"
      //           : "Maintenance Request"}
      //       </span>
      //     );
      //   },
      // },
      {
        accessorKey: "Category",
        header: "Category",
        accessorFn: (row) => row.categoryDetails ? row.categoryDetails.category || "" : "",
          sortDescFirst: true,
      },
      {
        accessorKey: "topic",
        header: "Description",
        accessorFn: (row) => {
          const description = row && row.topic;
          if (description && description.length > 28) {
            return description.slice(0, 28) + "...";
          }
          return description;
        }
      },
      {
        accessorKey: "status",
        header: "Status",
        accessorFn: (row) => convertToPascalCase(row.status),

      },

      {
        accessorKey: "createdDateAndTime",
        header: "Created Date",
        size: 20,
        accessorFn: (row) =>
          moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      },
      // {
      //   accessorKey: "auditDateAndTime",
      //   header: "Audit Date",
      //   size: 20,
      //   accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY"),
      // },
    ],
    [type]
  );
  const rowActions = (row) => {
    console.log(row.original.complainceId);
    const actions = [];

    if (row.original.maintenanceId) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleViewClick(row),
      });
    }
    if (row.original.complainceId) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleCrViewClick(row),
      });
    }
    return actions;
  };
  const currentDate = moment(); // Get the current date
  let dateTimeText = currentDate.format("DD MMMM YYYY"); 
  const handleRenderPDF = () => {
    if (!listData || listData.length === 0) {
      console.error('No data available for PDF rendering.');
      return;
    }

    const styles = {
      title: {
        fontSize: 18,
        color: "black",
        textAlign: "center",
        padding: "5px",
      },
      emptys: {
        marginTop: "3%",
      },emptyModule:{
        marginTop:"7px"
       },
      content: {
        fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
           adress:{
            fontSize: 12,
            color: "black",
            textAlign: "left",
            
            // paddingLeft:"60px",
            padding:"5px",
            // fontWeight:"bold"
          },
      description: {
        fontSize: 15,
        color: "black",
        textAlign: "left",
        paddingLeft: "60px",
        padding: "5px",
        fontWeight: "bold",
      },
      signature: {
        fontSize: 14,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
      headers: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "4px",
      },
      heading: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Oswald",
      },
      afterfrom: {
        marginTop: "8%",
      },
      from: {
        fontSize: 11,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "2px",
      },
      empty: {
        marginTop: "128%",
      },
      secondEmpty: {
        marginTop: "30%",
        fontSize:"20px",
        color:"red", 
      }, headerImage:{
        marginTop:"1%",
   paddingLeft:"70px",
      paddingRight:"50px",
      alignItems:"center",
  
        width:"470px",
        height:"160px",
        // backgroundColor:"yellow"
      },
      formempty: {
        marginTop: "49%",
      },
      fromBorder: {
        border: "1px solid black",
      },
      description:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      toBorder: {
        border: "1px solid black",
      },letterPad:{
        marginTop:"30%"
      },images:{
        width:"200px",
        height:"100px"
        
            }
    };
    const renderPageContent = (details) => {
      const letterContent = [];
    
      if (details && details.maintenanceId) {
        // logo:imageBlob
        letterContent.push(
          // Section for Compliance Request Notice
          {
            type: "text",
            text: groupAdress,
            style: styles.title,
          },
          {type: 'text', text: "Maintenance Request", style: styles.title },
          { type: "text", text: " " },
          
          // { text: dateTimeText, style:styles.adress },
          {style:styles.fromBorder},
          {type: 'text', text: "",  style:styles.formempty},
          { type: 'text',text: "From", style: styles.from },
          { type: "text", text: footerAdress, style: styles.from },
          { type: 'text', text: groupAdress, style: styles.from },
          { type: 'text',text: "",  style:styles.emptyModule},

        //   { type: 'text',text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.from },
        //  {  type: 'text',text: `${(details && details.address && details.address.address) || " " }`, style: styles.from },
        //   {  type: 'text',text: `${(details && details.createdByDetails && details.createdByDetails.city) || ""}, ${(details && details.createdByDetails && details.createdByDetails.zipcode) || " "}`, style: styles.from},
          { type: 'text',text: "",  style:styles.afterfrom},

          // { text: " "  },
          // { text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.adress, fontSize: 15 },
          // { type: 'text',text: "To :", style: styles.headers  },
          {
            type: "text",
            text: `${
              (details &&
               details.createdByDetails &&
               (details.createdByDetails.firstName || '') + 
               (details.createdByDetails.firstName && details.createdByDetails.lastName ? ' ' : '') +
               (details.createdByDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text: ` ${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.city) ||
              ""
            }${
              details && details.createdByDetails && details.createdByDetails.city && details.createdByDetails.zipcode
                ? ", "
                : ""
            } ${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          {type: 'text', text: "",  style:styles.empty},
          { type: 'text',text: "",  style:styles.secondEmpty},
          // { type: 'text',text: "",  style:styles.letterPad},
          {type: 'image',src:imageBlob, style:styles.headerImage  },
          { type: 'text', text: groupAdress, style: styles.title },
          { type: 'text', text: "Maintenance Request", style: styles.title },
          {type:'text',text:dateTimeText,style: styles.content},

          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: `${
              (details &&
               details.createdByDetails &&
               (details.createdByDetails.firstName || '') + 
               (details.createdByDetails.firstName && details.createdByDetails.lastName ? ' ' : '') +
               (details.createdByDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text:  ` ${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.city) ||
              ""
            }${
              details && details.createdByDetails && details.createdByDetails.city && details.createdByDetails.zipcode
                ? ", "
                : ""
            } ${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: 'text',
            text: `RE:  ${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") }`,
            style: styles.re
          },
          { type: 'text',text: "",  style:styles.emptyModule},

          { type: 'text',text:"DearResident", style: styles.content },
          { type: 'text',text: "You have previously asked to address the following matter with your property.", style: styles.content },
          { type: 'text',text: `This is to inform you about the maintenance request: ${
            details?.mrId ?? ""
          }_${moment(details?.createdDateAndTime).format('MM/DD/YYYY hh:mm A')}`, style: styles.content },
          {type: 'text',  text: `${details?.description ?? ''}`, style: styles.content,  },
          {
            type: 'text',
            text: details && details.startDate && details.endDate
              ? `We have scheduled the desired start of the maintenance ${moment(details.startDate).format("MM/DD/YYYY")} and desired end of maintenance on ${moment(details.endDate).format("MM/DD/YYYY")}. Please ensure these dates are noted. Should any adjustments be needed, contact me at your earliest convenience.`
              : "",
            style: styles.content
          }
          ,
          { type: 'text',text: "If you have any questions, feel that you have received this letter in error, or would like to request a variance, please feel free to contact our office.", style: styles.content },
          { type: 'text',text: "Thank you in advance for your attention to this matter.", style: styles.content },
          {type: 'text', text: "",  }, 
          { type: 'text',text: "", style: styles.content },
          { type: 'text',text: "", style: styles.content },
          {type: 'text', text: "Sincerely,", style: styles.adress },
          { 
            type: "text", 
            text: `${footerAdress} (computer generated letter)`, 
            style: styles.adress 
          },
         

        );
      }
      if (details.attachments && Array.isArray(details.attachments) && details.attachments.length > 0) {
        letterContent.push({ type: "text", text: "Attachments:", style: styles.content });
      
        details.attachments.forEach((attachment) => {
          const fileRemoval = attachment.file.substring(12);
      
          letterContent.push(
            { type: 'text', text: fileRemoval, style: styles.content },
            {
              type: 'image',
              src: `${BASE_URL_IMAGE}/${attachment.file}`,
              style: styles.images
            }
          );
        });
      } else {
        letterContent.push({ type: "text", text: " ", style: styles.content });
      }
    
      
    
      return letterContent;
    };
    
    
const logo= imageBlob;
    const data = listData.map(details => ({
      letterContent: renderPageContent(details)
        
    }));

    return (
      <PDFDocumentList data={data} styles={styles} logo={logo}/>
    );
  };
  const handleExportData = async () => {
    // console.log("test",logoUrl);
    try {
      const pdfData = handleRenderPDF();
      const blob = await pdf(pdfData).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  // const rowActions = [
  //   {
  //     label: "View",
  //     icon: <ListAlt color="primary" />,
  //     click: (row) => handleViewClick(row),
  //   },
  // ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    boolDisplay: { true: "Yes", false: "No" },
  });
  // const handleExportData = () => {
  //   const csv = generateCsv(csvConfig)(maintenanceRequestDetails);
  //   download(csvConfig)(csv);
  // };

  // const handleExportData = () => {
  //   const excludedProperties = [
  //     "maintenanceId",
  //     "createdBy",
  //     "auditBy",
  //     "groupId",
  //     "category",
  //     "createdByDetails",
  //     "role",
  //     "bylawId",
  //     "externalUserId",
  //     "jobId",
  //     "auditDateAndTime",
  //     "categoryDetails",
  //     "fileUrl",
  //     "id",
  //     "complainceId",
  //     "homeId",
  //     "bylawDetails",
  //     "externalUserDetails",
  //     "jobDetails",
  //     "documentId",
  //     "publishDate",
  //     "isPublic",
  //     "isHidden"
  //   ];

  //   const formattedData = Object.values(tableData).map((item) => {
  //     const formattedDatetime = moment(item.createdDateAndTime).format(
  //       "MM/DD/YYYY"
  //     );

  //     const filteredItem = {
  //       ...item,

  //       createdDateAndTime: formattedDatetime,
  //     };

  //     excludedProperties.forEach((property) => {
  //       delete filteredItem[property];
  //     });

  //     return filteredItem;
  //   });

  //   const csvConfigWithFilename = {
  //     ...csvConfig,
  //     filename: "Maintenance Request Report",
  //   };

  //   const csv = generateCsv(csvConfigWithFilename)(formattedData);
  //   download(csvConfigWithFilename)(csv);
  // };
  
  // const handleMaintenanceCsv = () => {
  //   const excludedProperties = [
  //     "homeId", "groupId", "homeOwnerDuesId", "auditBy", "title", "homeOwnerDetails",
  //     "requestForAccess", "id", "isOnRent", "fileUrl", "homeOwnerLoginId", 
  //     "homeOwnerDetailsId", "complainceId", "category", "address", "createdToName", 
  //     "createdByHomeAddress", "reviewComments", "categoryDetails", "createdToDetails",
  //     "byLawDetails", "createdToDetails", "createdByName", "homeOwner", "bylawId", 
  //     "parentId", "comments", "bylawDetails", "crId", "externalUserDetails", 
  //     "documentId", "publishDate", "assignedTo", "isPublic", "homeDetails", 
  //     "isHiddden", "isAssigned", "totalAmount", "homeDetails", "createdByDetails", 
  //     "groupDuesId", "specialAssType", "dueFrequency", "tite", "role", "categoryId","auditDateAndTime",
  //     "maintenanceId", "externalUserId", "jobId", "isAssignedToBm", "jobDetails", "auditList"
  //   ];
    
  //   const formattedData = listData.map((item) => {
  //     const formattedDatetime = moment(item.createdDateAndTime).format("MM/DD/YYYY HH:mm:ss");
  //     const formattedDatetimess = moment(item.auditDateAndTime).format("MM/DD/YYYY HH:mm:ss");

  //     const createdBy = item.createdByDetails
  //       ? `${item.createdByDetails.firstName} ${item.createdByDetails.lastName}`
  //       : '';
  //     const createdByAddress = item.homesDetails
  //       ? `${item.homesDetails.address}`
  //       : '';
  //       console.log("cretaed",createdByAddress);
  //     const createdTo = item.createdToDetails
  //       ? `${item.createdToDetails.firstName} ${item.createdToDetails.lastName}`
  //       : '';
  //     const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
  //     const filteredItem = {
  //       ...item,
  //       createdBy,
  //       createdByAddress,
  //       createdDateAndTime: formattedDatetime,
  //       auditDateAndTime:formattedDatetimess,
  //       type,
  //     };

  //     excludedProperties.forEach((property) => {
  //       delete filteredItem[property];
  //     });

  //     return {
  //       mrid: item.mrId, // Assuming `mrid` is a property in `item`
  //       createdBy,
  //       createdAddress: createdByAddress,
  //       description: item.description, // Assuming `description` is a property in `item`
  //       createdDateAndTime: formattedDatetime,
  //       status: item.status, // Assuming `status` is a property in `item`
  //       AuditDateAndTime: item.auditDateAndTime // Assuming `auditDateAndTime` is a property in `item`
  //     };
  //   });

  //   const csvConfigWithFilename = {
  //     ...csvConfig,
  //     filename: 'Maintenance report'
  //   };

  //   const csv = generateCsv(csvConfigWithFilename)(formattedData);
  //   download(csvConfigWithFilename)(csv);
  // };

  const handleMaintenanceCsv = () => {
    const excludedProperties = [
      "homeId",
      "groupId",
      "homeOwnerDuesId",
      "auditBy",
      "title",
      "homeOwnerDetails",
      "requestForAccess",
      "id",
      "attachments",
      "topic","isUrgent",
      // "createdBy",
      "isOnRent",
      "fileUrl",
      "homeOwnerLoginId",
      "homeOwnerDetailsId",
     
      "complainceId",
     "category",
     "address",
     "createdToName",
     "createdByHomeAddress",
      "reviewComments",
      "categoryDetails",
      "createdToDetails",
      "byLawDetails",
      "createdToDetails",
      "createdByName",
      "homeOwner",
      "bylawId",
      "parentId",
      "comments",
      "bylawDetails",
      "crId",
      "assignedTo",
      "homesDetails",
      ,"isHidden","documentId","externalUserDetails",
      "isPublic","publishDate","auditDateAndTime","createdToAddress",
      // "startDate","endDate",
      "isAssigned","totalAmount","homeDetails","createdByDetails",
      "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
     "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
    ];
  
   
  
    const formattedData = Object.values(listData).map((item) => {
      const formattedDatetime = moment(item.createdDateAndTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
      const formattedDatetimess = moment(item.auditDateAndTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
      const startDates = item.startDate ? moment(item.startDate).format("MM/DD/YYYY") : ""

      const endDates= item.endDate ? moment(item.endDate).format("MM/DD/YYYY") : ""

      console.log("Start Date After Formatting:", startDates);
console.log("End Date After Formatting:", endDates);
      const createdBy = item.createdByDetails
        ? `${item.createdByDetails.firstName} ${item.createdByDetails.lastName}`
        : '';
        const createdByAddress = item.homesDetails
        ? `${item.homesDetails.address}`
        : "" ;
        const createdTo = item.createdToDetails
        ? `${item.createdToDetails.firstName} ${item.createdToDetails.lastName}`
        : '';
        const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
      const filteredItem = {
        ...item,
        createdBy:createdBy,
        createdByAddress:createdByAddress,
        createdDateAndTime: formattedDatetime,
        // auditDateAndTime:formattedDatetimess,
        startDate:startDates,
        endDate:endDates
        // ...(createdTo && { createdTo }),
        
              };
  
      excludedProperties.forEach((property) => {
        delete filteredItem[property];
      });
  
      return filteredItem;
    });
  
    const csvConfigWithFilename = {
      ...csvConfig,
      filename: 'Maintenance Report' 
    };
  
    const csv = generateCsv(csvConfigWithFilename)(formattedData);
  download(csvConfigWithFilename)(csv);
  };
  const tableActionButtons = (
    <>
      {listData && listData.length > 0 && (
        <>
        <Button
            color="primary"
            variant="contained"
            onClick={() => handleMaintenanceCsv(listData)}
            startIcon={<FileUploadIcon/>}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export All Data
            </span>
          </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleExportData}
          startIcon={<FileUploadIcon />}
        >
          <span className={CustomStyle.containedButtonStyle}>Export As Pdf</span>
        </Button>
        </>
      )}
    </>
  );

  return (
    <div>
      <CustomHeading title="Audit" />
      {isLoading && <CustomLoading />}
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomDatePicker
                fieldName="fromDate"
                label="From Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                value={fromDate}

                onChange={(date) => handleFromDateChange(date)}
                tooltipMessage="Select from date"

              />

              <CustomDatePicker
                fieldName="toDate"
                label="To Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                value={toDate}
                tooltipMessage="Select to date"

                onChange={handleToDateChange}
              />

              {/* <Controller
                name="dueFrequency"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please select type{" "}
                      <span style={{ color: "red" }}> * </span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={"MR"}
                      onChange={(e) => {
                        setType(e.target.value);
                        field.onChange(e.target.value);
                      }}
                      label="Select type"
                    >
                      <MenuItem value="MR">Maintenance Request</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              /> */}
            </section>
            <div class="text-left mt-10">
              <CustomButton
                fieldType="submit"
                onClick={handleSubmit(onSubmit)}
                buttonName="Search"
              />
            </div>
          </form>
        </CardContent>
      </Card>
      <br />
      <br />

      <CustomMaterialWithoutActons
        columns={auditCrMrTable}
        data={listData}
        // rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
     
    </div>
  );
}

export default Audit_Mr;