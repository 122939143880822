import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Tooltip,
  Typography,
  Select,
  FormControl,
  OutlinedInput,Card,CardContent,
  Menu, Checkbox
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../components/CustomInputField";
import { ROLES_REQUIRED_MESSAGE } from "../utills/ApplicationConstants";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../components/CustomHeading";
import CustomButton from "../components/CustomButton";
import {
  ASSIGN_ROLES,
  BASE_URL,
  HOME_OWNERS,
  HOME_OWNERS_EDIT,
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomLoading from "../components/CustomLoading";
import { request } from "../services/AxiosConfig";

import { styled } from '@mui/material/styles';
import CustomToastContainer from "../components/CustomToastContainer";

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function HomeOwners() {
  const { email } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedRowEmail, setSelectedRowEmail] = useState(null);
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);
  const [homeOwnerDetails, setHomeOwnerDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editedHomeOwnerDetails, setEditedHomeOwnerDetails] = useState([]);
  const [homeOwnerloginid, setHomeOwnerloginId] = useState("");
  const [role, setRole] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const handleEditClickOpen = (row) => {
    setEditOpen(true);
    setSelectedRowEmail(row.original.email);
    setHomeOwnerloginId(row.original.homeOwnerLoginId)
    console.log("orinal row", row.original);
    console.log(row.original.email);
    setRole(row.original.roles);
    console.log(row.original.roles);
    setSelectedRoles(row.original.roles);
  };

  useEffect(() => {
    console.log("roles", role);
  }, [role])




  const [fieldChips, setFieldChips] = useState([]);

  const [selectedRoles, setSelectedRoles] = useState(['ROLE_HO']);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked && !selectedRoles.includes(value)) {
      setSelectedRoles([...selectedRoles, value]);
    } else {
      setSelectedRoles(selectedRoles.filter((role) => role !== value));
    }
  };

  useEffect(() => {
    // Filter fieldChips based on keys present in selectedRoles
    const selectedChips = [
      { key: 'ROLE_HO', label: 'HOME OWNER' },
      { key: 'ROLE_BM', label: 'BOARD MEMBER' },
      { key: 'ROLE_ARC', label: 'ARC' },
    ].filter((chip) => selectedRoles.includes(chip.key));
    setFieldChips(selectedChips);
  }, [selectedRoles]);

  useEffect(() => {
    // Automatically check the checkboxes corresponding to the roles from the backend
    ['ROLE_HO', 'ROLE_BM', 'ROLE_ARC'].forEach((roleKey) => {
      if (role.includes(roleKey)) {
        setSelectedRoles((prevSelectedRoles) =>
          prevSelectedRoles.includes(roleKey) ? prevSelectedRoles : [...prevSelectedRoles, roleKey]
        );
      }
    });
  }, [role]);

  
  //
  const handleEditClose = (status) => {
    if (status == 200) {
      setEditOpen(false);
    }

  };

  const handleClick = () => {
    navigate(-1);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const roles = ["ROLE_ARC", "ROLE_BM", "ROLE_HO"];
  const [personName, setPersonName] = useState([]);

  
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  

  // const getHomeOwnerDetails = async () => {
  //   try {
  //     setIsLoading(true);
  //     const storedToken = token;

  //     const response = await axios.get(`${BASE_URL}/${HOME_OWNERS}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       const data = response.data;
  //       setHomeOwnerDetails(data);
  //       console.log("home owners list ", data);
  //       // if (data.length > 0) {
  //       //   setHomeOwnerloginId(data[0].homeOwnerLoginId);
  //       // }

  //       setIsLoading(false);
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getHomeOwnerDetails();
  // }, []);


  useEffect(() => {
    console.log("roles", role);
    console.log("rlen", role.length);
  }, [role]);
  const {homeId}=useParams();
  const getHomeOwnerDetailsEdit = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      console.log("HomeId" + selectedRowEmail);

      const response = await axios.get(
        `${BASE_URL}${HOME_OWNERS_EDIT}/${email}/${homeId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        const data = response.data;
        console.log(data)
        setEditedHomeOwnerDetails(data);
       
      setFirstName(data.firstName || "");
      setLastName(data.lastName || "");
        setRole(data.roles) // Use a separate state for edited data
        setIsLoading(false);
        setHomeOwnerloginId(data.homeOwnerLoginId)
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomeOwnerDetailsEdit();
  }, [selectedRowEmail]);
  // roles

  const onSubmitEdit = async (data) => {
    const storedToken = token;
    setIsLoading(true);

    // Extracting keys of selected chips
    const selectedChipKeys = fieldChips.map(chip => chip.key);

    const postData = {
      roles: selectedChipKeys,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };

    if (!selectedChipKeys || selectedChipKeys.length === 0) {
      // Display an alert if roles are empty
      alert("Please select at least any one role.");
      setIsLoading(false);
      return; // Exit the function without submitting
    }

    try {
      const res = await request({
        url: `${ASSIGN_ROLES}${homeOwnerloginid}`,
        method: "post",
        data: postData,
        headers: config.headers,
      });

      console.log(res);
      if (res.status == 200) {
        handleEditClose(res.status);
        // getHomeOwnerDetails();
        reset();
        setTimeout(() => {
          setIsLoading(false);
          navigate(`/homeowners`)
        }, 3000);
      
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.response);
      setIsLoading(false);
      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    }
  };
  const editAction = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    />
  )

  

  return (

    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Roles" />

        {isLoading && <CustomLoading />}
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmitEdit)}
          defaultValue={[""]}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
         
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomInputField
            fieldName="homeOwner"
            fieldId="homeOwner"
            fieldLabel="Name"
            fieldControl={control}
            fieldError={errors}
            disabled={true}
            value={firstName || lastName ? `${firstName} ${lastName}`.trim() : ""}
            tooltipMessage="Name of the Homeowner"
          />

          <br />


          <br />

         </section>
          <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
          <Typography className="text-12" gutterBottom>
            Roles
          </Typography>
         
          <div class="flex flex-row items-center mt-2">
            {['ROLE_HO', 'ROLE_BM', 'ROLE_ARC'].map((roleKey) => (
              <div key={roleKey} className="ml-2">
                <Checkbox
                  checked={roleKey === 'ROLE_HO' || selectedRoles.includes(roleKey)}
                  onChange={handleCheckboxChange}
                  value={roleKey}
                  disabled={roleKey === 'ROLE_HO'}
                />
                {roleKey === 'ROLE_HO' ? (
                  <span className="items-center justify-center">HOME OWNER</span>
                ) : roleKey === 'ROLE_BM' ? (
                  <span className="items-center justify-center">BOARD MEMBER</span>
                ) : (
                  <span className="items-center justify-center">ARC</span>
                )}
              </div>
            ))}
          </div>
          <div class="bg-yellow-200 text-yellow-800 p-2 rounded flex items-center mt-5 w-1/2">
            <svg class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <span class="text-sm">Note: Select a role from the checkbox and assign it to a particular homeowner.</span>
          </div>
             
 
            </section>
           
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>


   
  );
}

export default HomeOwners;
