import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { Box, MenuItem, Typography } from "@mui/material";
import CustomCellRenderer from "./CustomCellRenderer"; // Import the custom cell renderer
import { setPageIndex } from "../redux/Hybridhoa";

const CustomMaterialTable = (props) => {

  const navigate = useNavigate();
  const actions = props.rowActions;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  // const dispatch = useDispatch();
  // const location = useLocation();
  // const currentUrl = location.pathname;
  // const actions = props.rowActions;
  // const pageIndexes = useSelector((state) => state.hybridhoa.pageIndexes);
  // const savedPageIndex = pageIndexes[currentUrl] || 0;
  // const [pagination, setPagination] = useState({
  //   pageIndex: savedPageIndex,
  //   pageSize: 5,
  // });

  // useEffect(() => {
  //   const storedPageIndex = parseInt(localStorage.getItem(currentUrl), 10);
  //   if (!isNaN(storedPageIndex)) {
  //     setPagination((prev) => ({ ...prev, pageIndex: storedPageIndex }));
  //   }
  // }, [currentUrl]);

  // useEffect(() => {
  //   localStorage.setItem(currentUrl, pagination.pageIndex);
  //   dispatch(setPageIndex({ url: currentUrl, pageIndex: pagination.pageIndex }));
  // }, [pagination.pageIndex, dispatch, currentUrl]);

  // Customize columns with custom cell renderer for long text
  // const columns = useMemo(
  //   () =>
  //     props.columns.map((column) => ({
  //       ...column,
  //       Cell: (props) =>
  //         column.accessorKey === "description" || "title" ? (
  //           <CustomCellRenderer cell={props.cell} />
  //         ) : (
  //           props.cell.getValue()
  //         ),
  //     })),
  //   [props.columns]
  // );

  return (
    <MaterialReactTable
      columns={props.columns}
      data={props.data}
      enableColumnOrdering
      enableRowActions
      positionActionsColumn="last"
      enableSorting={false}
      initialState={{
        pagination: pagination,
      }}
      onPaginationChange={setPagination}
      state={{
        pagination,
      }}
      muiTableBodyRowProps={(row) => ({
        onClick: () => props.onRowClick(row),
        sx: {
          cursor: "pointer",
        },
      })}
      muiTableHeadCellProps={{
        sx: {
          fontWeight: "semi-bold",
          fontSize: 14,
          fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          fontSize: 14,
          fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
        },
      }}
      enableColumnPinning
      renderRowActionMenuItems={({ row }) => {
        const renderActions = Array.isArray(actions)
          ? actions.map((i) => (
              <MenuItem key={i.label} onClick={() => i.click(row)}>
                {i.icon}
                <Typography sx={{ pl: 1 }}>{i.label}</Typography>
              </MenuItem>
            ))
          : actions(row).map((i) => (
              <MenuItem key={i.label} onClick={i.click}>
                {i.icon}
                <Typography sx={{ pl: 1 }}>{i.label}</Typography>
              </MenuItem>
            ));

            return renderActions.length > 0 ? renderActions : (
              <MenuItem disabled>
                <Typography sx={{ pl: 1 }}>N/A</Typography>
              </MenuItem>
            );
      }}
      renderTopToolbarCustomActions={() => (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              p: "0.5rem",
              flexWrap: "wrap",
            }}
          >
            {props.actionButtons}
          </Box>
        </>
      )}
    />
  );
};

export default CustomMaterialTable;
