import React, { useState } from "react";
import CustomHeading from "../components/CustomHeading";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomInputField from "../components/CustomInputField";
import {
  COMMON_ERROR_MESSAGE,
  COMMON_INVALID_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MAX_LENGTH_FOUR,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import { ONLY_ALPHABETIC, ONLY_DIGITS } from "../utills/ApplicationRegex";
import dayjs from "dayjs";
import CustomLoading from "../components/CustomLoading";

function EditPaymentGateway() {
  const [dob, setDob] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const dobDate = dayjs().subtract(18, "year").endOf("day");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const handleDobChange = (selectedDob) => {
    setDob(selectedDob);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const onSubmit = () => {};

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Edit PaymentGateway" />
        <CustomToastContainer />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-2 gap-5">
              <CustomInputField
                requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="lastName"
                requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomDatePicker
                fieldName="dob"
                label="Date of Birth"
                control={control}
                maxDate={dobDate}
                onChange={handleDobChange}
              />

              <Controller
                name="OrganizationType"
                defaultValue=""
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Organization type
                      <span style={{ color: "#D32F2F" }}>*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      //   value={type}
                      //   onChange={(e) => {
                      //     setType(e.target.value);
                      //     field.onChange(e.target.value);
                      //   }}
                      label="Organization type"
                    >
                      <MenuItem value="Nonprofit Organization">
                        Nonprofit Organization
                      </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              />

              <CustomInputField
                fieldName="securityNumber"
                requiredErrorMessage={COMMON_ERROR_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={COMMON_INVALID_MESSAGE}
                minLength={4}
                minLengthErrorMessage={MAX_LENGTH_FOUR}
                maxLength={4}
                maxLengthErrorMessage={MAX_LENGTH_FOUR}
                validator={false}
                fieldId="securityNumber"
                fieldType="text"
                fieldLabel="Last 4 digits of Social Security number"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
              <CustomInputField
                fieldName="role"
                requiredErrorMessage={COMMON_ERROR_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={COMMON_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="role"
                fieldType="text"
                fieldLabel="Role"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="businessWebsite"
                fieldId="businessWebsite"
                fieldType="text"
                fieldLabel="Business website"
                fieldControl={control}
                fieldError={errors}
              />

              <CustomInputField
                fieldName="hoaDescription"
                fieldId="hoaDescription"
                fieldType="text"
                fieldLabel="HOA description "
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                numberOfRows={3}
              />
            </section>
            <CustomHeading title="Bank Details" />
            <section class="grid md:grid-cols-1 xl:grid-cols-2 gap-5">
              <CustomInputField
                fieldName="routingNumber"
                requiredErrorMessage={COMMON_ERROR_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={COMMON_INVALID_MESSAGE}
                minLength={4}
                minLengthErrorMessage={MAX_LENGTH_FOUR}
                maxLength={4}
                maxLengthErrorMessage={MAX_LENGTH_FOUR}
                validator={false}
                fieldId="routingNumber"
                fieldType="text"
                fieldLabel="Routing Number"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />

              <CustomInputField
                fieldName="accountNumber"
                requiredErrorMessage={COMMON_ERROR_MESSAGE}
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={COMMON_INVALID_MESSAGE}
                minLength={4}
                minLengthErrorMessage={MAX_LENGTH_FOUR}
                maxLength={4}
                maxLengthErrorMessage={MAX_LENGTH_FOUR}
                validator={false}
                fieldId="accountNumber"
                fieldType="text"
                fieldLabel="Account Number"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
            </section>

            <br />
            <br />

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
              {/* {isLoading && <CustomLoading/>} */}
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default EditPaymentGateway;
