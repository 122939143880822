import React, { useEffect, useMemo,useState } from 'react'
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomHeading from "../components/CustomHeading";
import { FileDownload, FileDownloadOutlined, FileUploadOutlined, ListAlt } from '@mui/icons-material';
import { CustomStyle } from '../components/CustomStyle';
import { download, generateCsv, mkConfig } from "export-to-csv";
import { Button } from "@mui/material";
import { useSelector } from 'react-redux';
import { BASE_URL, BASE_URL_IMAGE, BILLING_DETAILS } from '../utills/ApplicationRouting';
import axios from 'axios';
import moment from 'moment';
import CustomLoading from "../components/CustomLoading"
import CustomMaterialTableActions from '../components/CustomMaterialTableActions';
function BillingHistory() {

  const [isLoading,setIsLoading] = useState(true);
  const [billingDetails,setBillingDetails] = useState([]);
 
  const { token,userDetails } = useSelector((state) => state.hybridhoa);
  const handleViewClick = async (selectedRow) => {
    console.log("enetered billing");
      try {
        const storedToken = token;
  
        console.log(storedToken);
        console.log(selectedRow);
  
        const response = await axios.get(`${BASE_URL_IMAGE}/invoice/${selectedRow}`, {
             // headers: {
          //   Authorization: `Bearer ${storedToken}`,
          // },
          responseType: "blob",
        });
        console.log("groupId",groupId);
        console.log(response.data);
  
        // console.log("groupId",groupId);
        console.log(response.data);
        const fileBlob = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileBlob);
      window.open(fileURL);
  
      } catch (error) {
        setIsLoading(false);

        // Handle error
        console.error("Error fetching data:", error);
      }
    // console.log(selectedRow.original.invoice);
    // window.open(selectedRow.original.url)
  };
  const groupId= userDetails.groupId;
  const getAllBillingHistory = async () => {
    console.log("enetered billing");
      try {
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${BILLING_DETAILS}/${groupId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log("groupId",groupId);
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          
          console.log(data);
          setBillingDetails(data);
          
                   
          setIsLoading(false);
        } else {
          setIsLoading(false);

          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        setIsLoading(false);

        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getAllBillingHistory();
    }, []);
    const convertToPascalCase = (str) => {
      // Check if the input string is undefined or empty
      if (str === undefined || str === '' || str === null) return '';
    
      // Remove underscores and convert string to Pascal case
      return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };
    
 
    const billingHistoryTable = useMemo((rowData) => [
        {
          accessorKey: "serialNo",
          header: "#",
          size: 2,
          Cell: ({ row }) => row.index + 1,
        },
        {
          accessorKey: "createdDateAndTime",
          header: "Paid Date",
          accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
        },
        {
          accessorKey: "totalSubscriptionAmount",
          header: "Subscription Amount",
        }, {
          accessorKey: "planType",
          header: "Plan Type",
          accessorFn: (row) => convertToPascalCase(row.planType),
        },{
          accessorKey: "invoicenumber",
          header: "Invoice Number",
          accessorFn: (row) => {
            const invoiceNumber = row.invoicenumber;
            return invoiceNumber ? invoiceNumber : "N/A";
          }
        },
        {
          accessorKey: "status",
          header: "Status",
          accessorFn: (row) => convertToPascalCase(row.status),
        },
        
       
      ]);
      const rowActions = [
        {
          label: "Download Invoice",
          icon: <FileDownloadOutlined color="primary" />,
          click: (row) => handleViewClick(row.original.invoice),
        },
      ];
  return (
    <div>
<CustomHeading title="Billing History"/>
{isLoading && <CustomLoading />}
<CustomMaterialTableActions 
columns = {billingHistoryTable}
data = {billingDetails}
rowActions={rowActions}
/>
    </div>
  )
}

export default BillingHistory