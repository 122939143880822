import {
  Close,
  Delete,
  DeleteForever,
  DeleteOutline,
  EditNoteOutlined,
  EditOutlined,
  EmailOutlined,
  FileDownload,
  FileUploadOutlined,
  ListAlt,
  Telegram,
  InfoOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,Card,CardContent,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import CustomInputField from "../components/CustomInputField";
import {
  ADDRESS_INVALID_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  JOB_CATEGORY_INVAILD_MESSAGE,
  JOB_CATEGORY_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE_FIFTY,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../utills/ApplicationConstants";
import {
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  EMAIL_REGEX_PATTERNS,
  ONLY_ALPHABETIC,
} from "../utills/ApplicationRegex";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomModel from "../components/CustomModel";
import CustomMaterialTable from "../components/CustomMaterialTable";
import CustomButton from "../components/CustomButton";
import {
  BASE_URL,
  HOMES,
  HOME,
  UPLOADS,
  HOME_UPLOAD,
  HOMES_DETAILS,
  ADD_SINGLE_HO,
  SINGLE_HOME_ADD,
  JOB_CATEGORY,
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomAutoComplete from "../components/CustomAutoComplete";
import { request } from "../services/AxiosConfig";
import CustomToastContainer from "../components/CustomToastContainer";
import moment from "moment";
import CustomLoading from "../components/CustomLoading";
import HomesAutoComplete from "../components/Homesautocomplete";
import CustomTooltipField from "../components/CustomTooltipField"

function EditAllocatedHome() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  // const [homeId, setHomeId] = useState("");
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [editData, setEditData] = useState([]);
  const [email, setEmail] = useState([]);
  const { homeId } = useParams();
 const [lastName, setLastName] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const handleInputChange = (e) => {
    console.log(e.target.value);
    setEditData(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
 
  const handleClick = () => {
    navigate(-1);
  };

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
 

  const getSingleHomeById = async (id) => {
    try {
      setIsLoading(true);

      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${SINGLE_HOME_ADD}/${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        setIsLoading(false);
        setUpdateRes(data.homeId);
        setEditData(data.address);
        setEmail(data.email)
        setFirstName(data.firstName);
        setLastName(data.lastName)
        console.log("Occupation" + data.homeId);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getSingleHomeById();
  }, [])

  const onSubmitEdit = (e) => {
    const storedToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };
    if (editData.trim() === "" || editData.trim() === null) {
      setError("editHomeAddress", {
        type: "manual",
        message: HOME_ADDRESS_REQUIRED_MESSAGE,
      });
      return;
    }
    const postData = {
      homeAddress: editData.trim(),
      email:email,
    };
    setIsLoading(true);
    request({
      url: `${HOMES}/${selectedRow}`,
      method: "put",
      data: postData,
      headers: config.headers,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setTimeout(() => {

            setIsLoading(false); 
            navigate(`/homes`);
          }, 3000);
        } else {
          setIsLoading(false);
          getAllocatedHomeDetails();
          setIsLoading(false);
          
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
    console.log(editData);
  };

   
 


  
  return (
  
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Edit Homes" />

        {isLoading && <CustomLoading />}
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <Button variant="contained" onClick={handleClick}>
            <span class="text-sm font-sans capitalize font-normal">Back</span>
          </Button>
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmitEdit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          {/* <div class="grid xl:grid-cols-2"> */}
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomInputField
              fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
              patternErrorMessage={ADDRESS_INVALID_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={50}
              maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_FIFTY}
              fieldName="editHomeAddress"
              fieldId="editHomeAddress"
              fieldType="text"
              fieldLabel="Home Address"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={editData}
              onInput={handleInputChange} 
              tooltipMessage="You can edit the home address here"
              />
              
             
         <CustomInputField
                fieldName="First Name"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldId="firstName"
                fieldType="text"
                fieldLabel="firstName"
                fieldControl={control}
                fieldError={errors}
                // fieldRequiredIcon="*"
                disabled
                value={firstName}
                onInput={handleEmailChange}
                tooltipMessage="First name of the Homeowner"
               
              />
              <CustomInputField
                fieldName="Last Name"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="lastName"
                fieldControl={control}
                fieldError={errors}
                // fieldRequiredIcon="*"
                disabled
                value={lastName}
                onInput={handleEmailChange}
                tooltipMessage="Last name of the Homeowner"
               
              />
             
             <CustomInputField
                fieldName="email"
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                // fieldRequiredIcon="*"
                disabled
                value={email}
                onInput={handleEmailChange}
                tooltipMessage="You can edit the email here"
               
              />
            </section>
            {/* </div> */}
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
    
  );
}

export default EditAllocatedHome;
