import React, { useEffect, useState } from 'react';
import { Card, CardContent, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import CustomHeading from '../components/CustomHeading';
import CustomInputField from '../components/CustomInputField';
import CustomButton from '../components/CustomButton';
import CustomTooltipField from '../components/CustomTooltipField';
import CustomToastContainer from '../components/CustomToastContainer';
import CustomLoading from '../components/CustomLoading';
import { BASE_URL, BYLAW } from '../utills/ApplicationRouting';
import { DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_FIVE_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_VALIDATE_MESSAGE, TOPIC_INVALID_MESSAGE, TOPIC_REQUIRED_MESSAGE } from '../utills/ApplicationConstants';
import { request } from '../services/AxiosConfig';

function EditBylaw() {
    const [isNotify, setIsNotify] = useState(false);
    const [byLawIds, setByLawIds] = useState([]);
    const [topic, setTopic] = useState("");
    const [description, setDescription] = useState("");
    const [editType, setEditType] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { token } = useSelector((state) => state.hybridhoa);
    const navigate = useNavigate();
    const { byLawId } = useParams();

    const { handleSubmit, control, setValue, setError, formState: { errors } } = useForm();

    useEffect(() => {
        const fetchBylaw = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${BASE_URL}${BYLAW}/${byLawId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.status === 200) {
                    const data = response.data;
                    setByLawIds(data);
                    setTopic(data.topic);
                    setDescription(data.description);
                    setIsNotify(data.isNotify);
                    setEditType(data.type);
                } else {
                    setIsLoading(false);
                    throw new Error("Failed to fetch data");
                }
            } catch (error) {
                setIsLoading(false);
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBylaw();
    }, [byLawId, token]);

    const onSubmitEdit = async (data) => {
        if (!description || description.trim() === "") {
            setError("description", { type: "manual", message: "Description is required." });
            return;
        }
        if (!topic || topic.trim() === "") {
            setError("topic", { type: "manual", message: "Topic is required." });
            return;
        }

        setIsLoading(true);
        const payload = { topic: topic.trim(), description: description.trim(), type: editType, isNotify };

        try {
            const response = await request({
                url: `${BYLAW}/${byLawId}`,
                method: "put",
                data: payload,
            });

            if (response.status === 200) {
                setTimeout(() => navigate(-1), 4000);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleViewClick = () => navigate(-1);

    const renderTooltip = (message) => (
        <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
            <IconButton><InfoOutlined /></IconButton>
        </Tooltip>
    );

    return (
        <div>
            {isLoading && <CustomLoading />}
            <CustomToastContainer />
            <div className="flex flex-col md:flex-row">
                <CustomHeading title="Edit Bylaw" />
                <div className="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
                    <CustomButton fieldType="button" buttonName="Back" click={handleViewClick} />
                </div>
            </div>
            <Card>
                <form onSubmit={handleSubmit(onSubmitEdit)} className="space-y-4 md:space-y-6">
                    <CardContent>
                        <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-2">
                            <CustomTooltipField
                                fieldName="topic"
                                fieldId="topic"
                                fieldType="text"
                                fieldLabel="Topic"
                                patternErrorMessage={TOPIC_INVALID_MESSAGE}
                                minLength={2}
                                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                                maxLength={250}
                                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                                fieldRequiredIcon="*"
                                fieldControl={control}
                                fieldError={errors}
                                multiline
                                numberOfRows={2}
                                value={topic}
                                onInput={(e) => setTopic(e.target.value)}
                                tooltipMessage="Please enter the Topic"
                            />

                            <div className="flex items-center md:w-1/2">
                                <div className="w-full">
                                    <Controller
                                        name="bylawType"
                                        defaultValue={editType}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <FormControl variant="standard" fullWidth error={fieldState.invalid}>
                                                <InputLabel id="bylaw-type-label">
                                                    Please select type
                                                    <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <Select
                                                    labelId="bylaw-type-label"
                                                    id="bylaw-type-select"
                                                    value={editType}
                                                    onChange={(e) => {
                                                        setEditType(e.target.value);
                                                        field.onChange(e.target.value);
                                                    }}
                                                    label="Select type"
                                                >
                                                    <MenuItem value="ARC">ARC</MenuItem>
                                                    <MenuItem value="BYLAW">ByLaw</MenuItem>
                                                    <MenuItem value="COVENANTS">Covenants</MenuItem>
                                                </Select>
                                                <FormHelperText style={{ color: "#D32F2F" }}>
                                                    {fieldState.invalid ? fieldState.error?.message : ""}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </div>
                                <div>{renderTooltip("Please select the bylaw type")}</div>
                            </div>

                            <CustomTooltipField
                                fieldName="description"
                                fieldId="description"
                                minLength={2}
                                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                                maxLength={5000}
                                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                                fieldLabel="Description"
                                fieldControl={control}
                                fieldError={errors}
                                fieldRequiredIcon="*"
                                multiline
                                numberOfRows={2}
                                value={description}
                                onInput={(e) => setDescription(e.target.value)}
                                tooltipMessage="Please enter the description"
                            />
                        </section>
                        <div className="text-center mt-10">
                            <CustomButton fieldType="submit" buttonName="Update" />
                        </div>
                    </CardContent>
                </form>
            </Card>
        </div>
    );
}

export default EditBylaw;
