import {
    Close,
    ContentCopy,
    Delete,
    DeleteForever,
    FileDownload,
    ListAlt,
    Telegram,
} from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField,
    Typography, Tooltip,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import {
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { setPageIndex } from "../redux/Hybridhoa";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { blue } from "@mui/material/colors";

function CustomMaterialTableActions(props) {
    const navigate = useNavigate();
    const actions = props.rowActions;
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });

    // Call the onApply function passed as a prop
    const handleApply = (row) => {
        if (props.onApply) {
            props.onApply(row); // Pass row data to the callback
        }
    };

    return (
        <MaterialReactTable
            columns={props.columns}
            data={props.data}
            enableColumnOrdering
            enableRowActions={actions ? true : false}
            positionActionsColumn="last"
            enableSorting={false}
            initialState={{
                pagination: pagination,
            }}
            onPaginationChange={setPagination}
            state={{
                pagination
            }}
            muiTableHeadCellProps={{
                sx: {
                    fontWeight: "semi-bold",
                    fontSize: 14,
                    fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
                },
            }}
            muiTableBodyCellProps={{
                sx: {
                    fontSize: 14,
                    fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
                },
            }}
            enableColumnPinning
            renderRowActionMenuItems={() => null} // Disable default actions menu

            // Custom button renderer
            renderRowActions={({ row }) => (

                <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip style={{ cursor: "pointer" }}>
                    <span 
                        style={{ 
                            color: '#1976D2', 
                            textDecoration: 'underline',
                            textDecorationColor: '#1976D2', 
                            cursor: 'pointer'
                        }} 
                        onClick={() => handleApply(row)}
                    >
                        Apply Waived Off
                    </span>
                </Tooltip>
            </Box>
            
            )}

            renderTopToolbarCustomActions={() => (
                <Box
                    sx={{
                        display: "flex",
                        gap: "1rem",
                        p: "0.5rem",
                        flexWrap: "wrap",
                    }}
                >
                    {props.actionButtons}
                </Box>
            )}
        />
    );
}

export default CustomMaterialTableActions;
