import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import CustomHeading from '../components/CustomHeading';
import CustomButton from '../components/CustomButton';
import CustomLoading from '../components/CustomLoading';
import CustomInputField from '../components/CustomInputField';
import { Card, CardContent, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { AMOUNT_INVALID_MESSAGES, AMOUNT_REQUIRED_MESSAGE, DESCRIPTION_REQUIRED_MESSAGE } from '../utills/ApplicationConstants';
import { OFFLINE_PAYMENT } from '../utills/ApplicationRouting';
import { request } from '../services/AxiosConfig';
import { AMOUNT_REGEX, FIVE_DIGITS_TWO_DECIMAL } from '../utills/ApplicationRegex';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomToastContainer from '../components/CustomToastContainer';
import { InfoOutlined } from '@mui/icons-material';
import CustomInputFielddueandamount from '../components/CustomInputFielddueandamount';
import CustomBackButtonWithoutDisable from '../components/CustomBackButtonWithoutDisable';

function OfflinePaymentForm() {
                  const [paymentType, setPaymentType] = useState("");
                  const [comment, setComment] = useState("");
                  const [waivedAmount, setWaivedAmount] = useState("");
                  const [description, setDescription] = useState(""); 
                  const [invoice,setInvoice]=useState("");
                  const [errorPayment, setErrorPayment] = useState(false);
                  const [selectedRows, setSelectedRows] = useState([]);
                  const [homeOwnerDetailsId, setHomeOwnerDetailsId] = useState("");
                  const [amount, setAmount] = useState("");
                  const [isLoading, setIsLoading] = useState(false);
                  const [totalAmount, setTotalAmount] = useState(0);
                  const [isMinimum,setIsMinimum] = useState(false);

                  const navigate = useNavigate();
                  const {
                                    handleSubmit:handleSubmitModel,
                                    control: controlModal,
                                    formState: { errors: errorsModal },
                                    setValue: setValueModal,
                                    setError: setErrorModal,
                                    reset: resetModal,
                                    watch: watchModal,
                                  } = useForm();

                                  const location = useLocation();
                                 
                                
                                  useEffect(() => {
                                    if (location.state && location.state.selectedRows) {
                                      console.log("selected Rows"+JSON.stringify(location.state.selectedRows));
                                      console.log("selected Rows"+location.state.homeOwnerDetailsId);
                                      setSelectedRows(location.state.selectedRows);
                                      console.log("selected Rows"+location.state.selectedRows.length);
                                      setHomeOwnerDetailsId(location.state.homeOwnerDetailsId);

                                    }
                                  }, [location.state]);
                                
                                  useEffect(() => {
                                    // Calculate total amount from selected rows
                                    const total = selectedRows.reduce((acc, row) => acc + parseFloat(row.dueAmount), 0);
                                    setTotalAmount(total);
                                    setAmount(total)
                                  }, [selectedRows]);

                                  useEffect(() => {
                                    // Check if selectedRows length is exactly 1 and both amount and totalAmount are numbers
                                    if (selectedRows.length === 1 && !isNaN(amount) && !isNaN(totalAmount)) {
                                      // Check if parsedAmount is different from totalAmount
                                      if (
                                        parseFloat(amount).toFixed(1) !== parseFloat(totalAmount).toFixed(1) ||
                                        parseFloat(amount) !== parseFloat(totalAmount)
                                      ) {
                                        setIsMinimum(true); // Set isMinimum to true if amounts are not equal
                                      } else {
                                        setIsMinimum(false); // Set isMinimum to false if amounts are equal
                                      }
                                    }
                                  }, [amount, totalAmount, selectedRows]);

                  const handleWaivedChange = (event) => {
                                    setWaivedAmount(event.target.value);
                                  };
                                
                                  const handleDescriptionChange = (event) => {
                                    setDescription(event.target.value);
                                  };
                                  
                                  const handleInvoiceChange = (event) => {
                                    setInvoice(event.target.value);
                                  };
                                  const handleCommentChange = (event) => {
                                    setComment(event.target.value);
                                  };
                                  const handlePaymentTypeChange = (event) => {
                                    const selectedPaymentType = event.target.value;
                                    setPaymentType(selectedPaymentType);
                                    setErrorPayment(false)
                                };
                                
                                const getCustomInputLabel = () => {
                                  if (paymentType === 'CHECK') {
                                    return `${paymentType} Number`;
                                  } else {
                                      return '';
                                  }
                                };

                                const handleAmountChange = (event) => {
                                    const inputValue = event.target.value;
                                      setAmount(inputValue);
                                  };

                                  const onSubmit = async (data) => {
                                    if (!amount) {
                                      setErrorModal("amount", {
                                        type: "manual",
                                        message: AMOUNT_REQUIRED_MESSAGE
                                      });
                                      return
                                    }
                                
                                    if(amount > totalAmount) {
                                      setErrorModal("amount", {
                                        type: "manual",
                                        message: "The entered amount exceeds the total due."
                                      });
                                      return;
                                    }
                                    
                                    if (parseFloat(amount) === 0 && !/^(0\.\d{1,2}|[1-9]\d{0,4}\.\d{1,2})$/.test(amount)) {
                                      setErrorModal("amount", {
                                        type: "manual",
                                        message: "Amount is invalid: It must be in the format '0.xx' or 'xxxxx.xx'. A value of '0' is not allowed.",
                                      });
                                      return;
                                    }
                                 
                                    // const slicedDues = selectedRows.slice(1);
                                    // console.log("slicedDues",slicedDues)
                                    // if (slicedDues.length === 1) {
                                    //   const selectedRowValidate = slicedDues[0].dueAmount;
                                    //   isMinimum = selectedRowValidate === amount ? false : true 
                                    //   console.log("isMinimum",isMinimum)
                                    // }else{
                                    //   isMinimum = false
                                    //    console.log("isMinimum else",isMinimum);
                                    // }
                                    const homeOwnerDuesList = selectedRows.map((row) => {
                                      
                                      let amounts = parseFloat(amount);
                                      console.log(amounts.toFixed(1));
                                      
                                      console.log(row.dueAmount);
                                      return {
                                        homeOwnerDuesId: row.homeOwnerDuesId,
                                        dueAmount: row.dueAmount,
                                        description:row.description,
                                        invoicenumber:row.invoiceNumber|| null,
                                        title:row.title
                                        // Include other necessary properties from your row data
                                      };
                                    });
                                
                                    const stripeResponses={
                                  amount:amount,
                                  paymentType:paymentType,
                                 referenceNumber:waivedAmount,
                                 invoiceNumber:invoice,
                                 review:comment,
                                 description:description
                                    }
                                    const jsonString = JSON.stringify(stripeResponses);
                                
                                    if (!paymentType) {
                                      setErrorPayment(true)
                                      return
                                    }
                                    console.log("paymenttype",paymentType)
                                   
                                        setIsLoading(false);
                                        
                                     
                                    setIsLoading(true)
                                    console.log("selected");
                                    const postData = {
                                      amount:  parseFloat(amount).toFixed(1),
                                      isMinimum: isMinimum,
                                      homeOwnerDuesList: homeOwnerDuesList,
                                      stripeResponse:jsonString,
                                      homeOwnerDetailsId:homeOwnerDetailsId
                                    };
                                
                                    request({
                                      url: `${OFFLINE_PAYMENT}`,
                                      method: "post",
                                      data: postData,
                                    })
                                      .then((res) => {
                                        console.log(res);
                                        if (res.status == 200) {
                                          setTimeout(()=>{
                                          navigate(-1);
                                          },4000)
                                               setIsLoading(false);
                                         
                                        }else{
                                          setIsLoading(false); 
                                
                                        }
                                      })
                                      .catch((error) => {
                                        setIsLoading(false); 
                                
                                        console.log(error.response);
                                        if (error.response) {
                                          setIsLoading(false); 
                                
                                          console.log("error.response.status" + error.response.status);
                                        }
                                      });
                                    
                                  }

                                  const handleClick = () => {
                                    navigate(-1);
                                  };

                                  const renderTooltip = (message) => (
                                    <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
                                      <IconButton>
                                        <InfoOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  );

  return (
    <div>
                   <div class="flex flex-col md:flex-row">
                   <CustomToastContainer />
        <CustomHeading title="Offline Payment" />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomBackButtonWithoutDisable
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
 <form onSubmit={handleSubmitModel(onSubmit)} className="space-y-4 md:space-y-6 ">
 <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
                  <div>
                  <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
        <FormControl variant="standard" fullWidth error={errorPayment}>
      <InputLabel id="payment-type-label">Payment Type<span style={{ color: "red" }}> *</span></InputLabel>
      <Select
        labelId="payment-type-label"
        id="payment-type"
        value={paymentType}
        onChange={handlePaymentTypeChange}
      >
        <MenuItem value="CHECK">Check</MenuItem>
        <MenuItem value="OTHERS">Others</MenuItem>
      </Select>
      {errorPayment && <FormHelperText>Payment Type is required</FormHelperText>}
    </FormControl>
    </div>
  <div>
  {renderTooltip("Please select an option from the dropdown menu")}
  </div>
</div>

   {paymentType === "CHECK" ? 
  <CustomInputField
    fieldName="referenceNumber"
    fieldId="referenceNumber"
    fieldType="text"
    fieldLabel={getCustomInputLabel()}
    // requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
    fieldControl={controlModal}
    fieldError={errorsModal}
    // fieldRequiredIcon="*"
    onInput={handleWaivedChange}
    value={waivedAmount}
    tooltipMessage={"Please enter the check number"}
  />: 
  <CustomInputField
  fieldName="description"
  fieldId="description"
  fieldType="text"
  fieldLabel="Other Payment Details"
  requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
  fieldControl={controlModal}
  fieldError={errorsModal}
  fieldRequiredIcon="*"
  onInput={handleDescriptionChange}
  value={description}
  tooltipMessage={"Please provide a brief description of your payment"}
/>}


{/* <CustomInputField
    fieldName="amount"
    fieldId="amount"
    fieldType="text"
    fieldLabel="Amount"
    fieldPattern={FIVE_DIGITS_TWO_DECIMAL}
    patternErrorMessage={AMOUNT_INVALID_MESSAGES}
//     requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
    fieldControl={controlModal}
    fieldError={errorsModal}
    fieldRequiredIcon="*"
    onInput={handleAmountChange}
    value={amount}
        disabled={selectedRows.length > 1}
        tooltipMessage={"Enter the amount of payment. Must be a valid number"}
  /> */}

<CustomInputFielddueandamount
                fieldName="amount"
                // requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
                fieldPattern={FIVE_DIGITS_TWO_DECIMAL}
                patternErrorMessage={"Please provide Valid Amount (i.e xxxxx.xx)"}
                fieldId="amount"
                fieldType="text"
                fieldLabel="Amount"
                fieldControl={controlModal}
                fieldError={errorsModal}
                disabled={selectedRows.length > 1}
                fieldRequiredIcon="*"
                // defaultValue={10}
                value={amount}
                onInput={handleAmountChange}
                tooltipMessage={"Enter the amount. This should be a valid monetary amount formatted as xxxxx.xx"}
              />
 
   <CustomInputField
    fieldName="invoiceNumber"
    fieldId="invoiceNumber"
    fieldType="text"
    fieldLabel="Invoice Number"
    // requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
    fieldControl={controlModal}
    fieldError={errorsModal}
    // fieldRequiredIcon="*"
    onInput={handleInvoiceChange}
    value={invoice}
    tooltipMessage={"Enter the invoice number associated with your payment"}
  />
        <CustomInputField
            fieldName="review"
            fieldType="text"
            fieldId="review"
            fieldLabel="Review"
            // requiredErrorMessage={REIMBURSE_COMMENT}
            // minLength={2}
            // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            // maxLength={5000}
            // maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
            fieldControl={controlModal}
            fieldError={errorsModal}
            multiline={true}
            numberOfRows={1}
            // fieldRequiredIcon="*"
            onInput={handleCommentChange}
            value={comment}
            tooltipMessage={"Provide any additional details or review about your payment"}
          />
      </div>
      <div>
                 
      </div>
      </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Submit" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
   
    </div>
  )
}

export default OfflinePaymentForm