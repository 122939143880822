import { Button, Card, CardContent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomStyle } from "../components/CustomStyle";
import CustomHeading from "../components/CustomHeading";
import CustomViewPage from "../components/CustomViewPage";
import { BASE_URL, PAYMENT_DETAILS } from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Loading from "react-loading";
import CustomLoading from "../components/CustomLoading";
import { data } from "autoprefixer";
import CustomViewHeading from "../components/CustomViewHeading";
import CustomViewWithTooltip from "../components/CustomViewWithTooltip";
import CustomTooltipWithEditButton from "../components/CustomTooltipWithEditButton";

function PaymentGatewayView() {
  const navigate = useNavigate();

  const { token, userLoginDetails } = useSelector((state) => state.hybridhoa);

  const [PaymentDetailsData, setPaymentDetailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const groupId = userLoginDetails.groupId;

  const handleClick = () => {
    navigate(`/paymentgatewayedit/${groupId}`);
  };
  const getPaymentDetails = async () => {
    // setIsLoading(true)

    try {
      setIsLoading(true);
      const storedToken = token;
      console.log("group id in try", groupId);

      //   console.log("ByLawId"+byLawId);
      const response = await axios.get(
        `${BASE_URL}${PAYMENT_DETAILS}/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      if (response.status === 200) {
        const paymentdata = response.data;
        setPaymentDetailsData(paymentdata);
        setIsLoading(false);
        console.log("paymentdetails", paymentdata);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTimeout(() => {
        setIsLoading(false);
        
      }, 4000);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [groupId]);

  const data = [
    {
      groupName: "Basic Details",
      items: [
        {
          label: "Legal First Name",
          data: `${
            PaymentDetailsData.legalFirstName === null || PaymentDetailsData.legalFirstName === undefined
              ? ""
              : PaymentDetailsData.legalFirstName
          }`,
          text: "First Name of the Admin",
        },
        {
          label: "Legal Last Name",
          data: `${
            PaymentDetailsData.legalLastName === null
              ? ""
              : PaymentDetailsData.legalLastName
          }`,
          text: "Last Name of the Admin",
        },
        {
          label: "Date Of Birth",
          data: `${
            PaymentDetailsData.dob === null
              ? ""
              : moment(PaymentDetailsData.dob).format("MM/DD/YYYY")
          }`,
          text: "Date of Birthday",
        },
        {
          label: "Last Four digit of Social Security Number",
          data: `${
            PaymentDetailsData.ssn === null ? "" : PaymentDetailsData.ssn
          }`,
          text: "Last Four digit of Social Security Number",
        },
      ],
    },
    {
      groupName: "Organization Details",
      items: [
        {
          label: "Organization Type",
          data: `${
            PaymentDetailsData.orgType === null
              ? ""
              : PaymentDetailsData.orgType
          }`,
          text: "Organization Type",
        },
        {
          label: "HOA Desc",
          data: `${
            PaymentDetailsData.hoaDesc === null
              ? ""
              : PaymentDetailsData.hoaDesc
          }`,
          text: "HOA Desc",
        },
      ],
    },

    {
      groupName: "Role",

      items: [
        {
          label: "Role",
          data: `${
            PaymentDetailsData.legalRole === null
              ? ""
              : PaymentDetailsData.legalRole
          }`,
          text: "Role",
        },
      ],
    },

    {
      groupName: "Business website",

      items: [
        {
          label: "Website",
          data: `${
            PaymentDetailsData.businessEmail === null
              ? ""
              : PaymentDetailsData.businessEmail
          }`,
          text: "Your website url",
        },
      ],
    },
    {
      groupName: "Banking Details",

      items: [
        {
          label: "Routing Number",
          data: `${
            PaymentDetailsData.routingNumber === null
              ? ""
              : PaymentDetailsData.routingNumber
          }`,
          text: "Your Routing Number",
        },
        {
          label: "Account Number",
          data: `${
            PaymentDetailsData.accountNumber === null
              ? ""
              : PaymentDetailsData.accountNumber
          }`,
          text: "Your Account Number",
        },
      ],
    },
  ];

  return (
    <div>
      {isLoading && <CustomLoading />}
    
        <CustomTooltipWithEditButton data={data}  onClick={handleClick}
        title={"View PaymentHOA's Payment Gateway Informations"}/>
      
    </div>
  );
}

export default PaymentGatewayView