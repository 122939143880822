import React, { useRef, useEffect, useState } from 'react';
import CustomHeading from './CustomHeading';
import { Button, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { CustomStyle } from './CustomStyle';
import { InfoOutlined } from '@mui/icons-material';
import CustomAccordion from './CustomAccordion';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';

function CustomViewWithTooltipexpand({ data, onClick, title }) {
  const [rendered, setRendered] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const waitForRender = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust timeout as needed
      setRendered(true);
    };
    waitForRender();
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const exportToPdf = async () => {
    if (!rendered) return; // Exit if not fully rendered

    const input = cardRef.current;
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    const pdfUrl = pdf.output('bloburl');
    window.open(pdfUrl);
  };

  return (
    <div style={{ boxShadow: '2px 2px 28px rgba(0, 0, 255, 0.5)' }}>
 
      {title && (
        <div className="flex flex-col md:flex-row">
          <Typography>
            <span
              className="font-bold text-lg"
              style={{
                maxWidth: 'calc(100% - 100px)',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {title}
            </span>
          </Typography>
          <div className="md:full md:pl-4 sm:text-left md:text-right sm:w-full mb-2 space-x-2">
            <Button variant="contained" onClick={onClick}>
              <span className={CustomStyle.containedButtonStyle}>Back</span>
            </Button>
            <Button variant="contained" onClick={exportToPdf}>
              <span className={CustomStyle.containedButtonStyle}>Export to PDF</span>
            </Button>
          </div>
        </div>
      )}
      <Card ref={cardRef} >
        <CardContent>
          {data.map((group, groupIndex) => (
            <div key={groupIndex}>
              <CustomAccordion heading={group.groupName} defaultExpanded={false}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-5 mt-5">
                  {group.items.map((item, itemIndex) => (
                    <div
                      className={`flex items-center ${item.label === 'Apply Fine' ? 'w-full' : ''}`}
                      key={itemIndex}
                    >
                      <div className={`${CustomStyle.borderedDiv} w-full ${item.label !== 'Apply Fine' ? 'md:w-1/2' : ''}`}>
                        <div>
                          <h3 className="viewPageText">
                            <b>
                              {item.label === 'Attachment' && item.text ? (
                                <div className="flex items-center">
                                  <div className="flex-1">{item.label}</div>
                                  {item.label === 'Attachment' && (
                                    <Tooltip title={item.text} placement="right" arrow>
                                      <InfoOutlined className="ml-64 sm:ml-64" />
                                    </Tooltip>
                                  )}
                                </div>
                              ) : (
                                <span>{item.label} :</span>
                              )}
                            </b>
                            {' '}
                            {item.data}
                            {item.label2 && item.data2 && (
                              <>
                                <b className='ml-12'>{item.label2}:</b>
                                {' '}
                                {item.data2}
                              </>
                            )}
                          </h3>
                        </div>
                      </div>
                      {item.label !== 'Attachment' && item.text && (
                        <Tooltip title={item.text} placement="right" arrow>
                          <InfoOutlined />
                        </Tooltip>
                      )}
                    </div>
                  ))}
                </section>
              </CustomAccordion>
            </div>
            
          ))}
        </CardContent>
      </Card>
    </div>
  );
}

export default CustomViewWithTooltipexpand;
