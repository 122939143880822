import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL, OFFLINE_PAYMENT_VIEW, PAYMENT_CONFIG_LIST } from '../utills/ApplicationRouting';
import CustomViewWithTooltip from '../components/CustomViewWithTooltip';
import moment from 'moment';
import CustomLoading from '../components/CustomLoading';
import CustomViewHeading from '../components/CustomViewHeading';

function ViewOfflinePayment() {

  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector(state => state.hybridhoa);
  const { homeOwnerDuesId } = useParams();
  const [offlinepaymentData, setOfflinePaymentData] = useState("")
  const [createdToDetails, setCreatedToDetails] = useState("")
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(-1)
  }

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';

    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getOfflinePaymentbyId = async () => {
   

    try {
      setIsLoading(true);
      const storedToken = token;

      console.log("groupDuesId" + homeOwnerDuesId);
      const response = await axios.get(`${BASE_URL}${OFFLINE_PAYMENT_VIEW}${homeOwnerDuesId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        setOfflinePaymentData(data)
        setCreatedToDetails(data.homeOwnerDetails)
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getOfflinePaymentbyId();
  }, [homeOwnerDuesId])



  const data = [
    {
      groupName: 'Payment Details',
      items: [
        { label: 'Title', data: `${offlinepaymentData?.title || ""}`, text: 'Title of the payment group' },
        { label: 'Description', data: `${offlinepaymentData?.description || ""}`, text: 'Description of the payment group' },
        // { label: 'For All Homes', data: `${offlinepaymentData?.isForAllHomes ? "Yes" : "No" || ""}`, text: 'Indicates if the due is for all homes' },
        { label: 'Status', data: `${convertToPascalCase(offlinepaymentData?.status) || ""}`, text: 'Current status of the due (e.g., Pending, Paid)' },
        { label: 'Due Type', data: `${convertToPascalCase(offlinepaymentData?.dueType) || ""}`, text: 'Type of due (e.g., Regular, Special)' },
        { label: 'Due Frequency', data: `${convertToPascalCase(offlinepaymentData?.dueFrequency) || ""}`, text: 'Frequency of the due (e.g., Monthly, Quarterly)' },
        { label: 'Due Amount', data: `${offlinepaymentData?.dueAmount || ""}`, text: 'Amount to be paid' },
      ]
    },
    {
      groupName: 'Date And Time Details',
      items: [
        { label: 'Due Date', data: `${moment(offlinepaymentData?.dueDate).format("MM/DD/YYYY ") || ""}`, text: 'The date when the payment is due' },
        { label: 'Last Date', data: `${moment(offlinepaymentData?.endDate).format("MM/DD/YYYY ") || ""}`, text: 'The last date for payment without penalties' },
        { label: 'Created Date & Time', data: `${moment(offlinepaymentData?.createdDateAndTime || "").format("MM/DD/YYYY hh:MM:ss a")}`, text: 'Date and time when the payment configuration was created' },
        { label: 'Audit Date & Time', data: `${moment(offlinepaymentData?.auditDateAndTime).format("MM/DD/YYYY hh:MM:ss a") || ""}`, text: 'Date and time of the last audit' }
      ]
    },
  ];

  // Check if due type is "Special Assessment" and add the item accordingly
  if (offlinepaymentData?.dueType === "SPECIAL_ASSESSMENT_DUE") {
    data[0].items.push({ label: 'Special Assessment Due Type', data: `${offlinepaymentData?.specialAssType || ""}`, text: 'Type of special assessment due' });
  }

  if (offlinepaymentData?.dueType === "REGULAR_DUE") {
    data.push({
      groupName: 'Created To Details',
      items: [
        { label: 'For All Homeowners', data: 'Yes', text: 'This due is for all homeowners' }
      ]
    });
  } else {
    data.push({
      groupName: 'Created To Details',
      items: [
        {
          label: 'Firstname',
          label2: 'Lastname',
          data: `${createdToDetails?.firstName || ""}`,
          text: 'First name of the person or entity to whom the configuration was created',
          data2: `${createdToDetails?.lastName || ""}`
        },
        { label: 'Address', data: `${offlinepaymentData?.homeDetails?.address || ""}`, text: 'Address of the person or entity to whom the configuration was created' },
        { label: 'Email', data: `${createdToDetails?.email || ""}`, text: 'Email of the person or entity to whom the configuration was created' },
        { label: 'Contact', data: `${createdToDetails?.contact || ""}`, text: 'Contact number of the person or entity to whom the configuration was created' },
      ]
    });
  }
  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewHeading onClick={handleClick} title={"Offline Payment"} data={data} 
      description={offlinepaymentData?.description || ""}  >

      <CustomViewWithTooltip  data={data} />

      </CustomViewHeading>
    </div>
  )
}

export default ViewOfflinePayment